import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { useAppSelector } from '../hooks'
import type { RootState } from '../store'
interface Lineitem {
    cost_per_unit: number
    tax_id: number
    quantity: number
    subtotal: number
    tax_total: number
    grand_total: number
}
interface InvoiceTotals {
    subtotal: any
    tax_total: any
    grand_total: any
}
interface Customer {
    first_name: string
    last_name: string
    address: string
    city: string
    province: string
    country: string
    postal: string
}
interface LineItemUpdate {
    index: number
    key: string
    value: any
}

interface ArrayKeyValue {
    key:
        | 'first_name'
        | 'last_name'
        | 'billing_name'
        | 'billing_address'
        | 'billing_city'
        | 'billing_province'
        | 'billing_country'
        | 'billing_postal_code'
        | 'emails'
        | 'customer_id'
    value: any
}

const initialState: Paywell.API.V2.Invoice = {
    id: 0,
    uuid: '',
    invoice_number: '',
    user_id: 0,
    agent_id: 1,
    due_date: '',
    invoice_date: '',
    invoice_subtotal: 0,
    invoice_tax_total: 0,
    invoice_grand_total: 0,
    outstanding_balance: 0,
    payment_status: 'unpaid',
    payment_available: false,
    cc: '',
    mm: '',
    yy: '',
    billing: '',
    customer_id: '',
    currency: '',
    first_name: '',
    last_name: '',
    billing_name: '',
    billing_address: '',
    billing_city: '',
    billing_province: '',
    billing_country: '',
    billing_postal_code: '',
    emails: [],
    notes: '',
    line_items: [],
    created_at: '',
    user_first_name: '',
    user_last_name: '',
    user_telephone: '',
    user_business_name: '',
    user_address: '',
    user_city: '',
    user_province: '',
    user_country: '',
    user_postal: '',
    nmi_public_key: '',
    tax_number: '',
}

export const InvoiceSlice = createSlice({
    name: 'invoice',
    initialState,

    reducers: {
        setLineItems: (state, action: PayloadAction<any>) => {
            state.line_items = action.payload
        },
        addLineItems: (state, action: PayloadAction<any>) => {
            state.line_items = [...state.line_items, { ...action.payload }]
        },
        removeLineItem: (state, action: PayloadAction<number>) => {
            state.line_items = state.line_items.filter(
                (line: any, index: number) => index != action.payload
            )
        },
        updateLineItem: (
            state,
            action: PayloadAction<Array<LineItemUpdate>>
        ) => {
            action.payload.forEach((array) => {
                state.line_items[array.index][
                    array.key as keyof Paywell.API.V2.LineItems
                ] = array.value
            })
        },
        updateInvoice: (state, action: PayloadAction<InvoiceTotals>) => {
            var value = action.payload
            state.invoice_subtotal = value.subtotal
            state.invoice_tax_total = value.tax_total
            state.invoice_grand_total = value.grand_total
        },
        updateCustomer: (
            state,
            action: PayloadAction<Array<ArrayKeyValue>>
        ) => {
            action.payload.forEach((array) => {
                state[array.key] = array.value
            })
        },
        getTotal: (state) => {
            var total = 0
            state.line_items.map((line, index) => {
                total += line.grand_total
            })
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setLineItems,
    addLineItems,
    removeLineItem,
    updateLineItem,
    updateInvoice,
    updateCustomer,
} = InvoiceSlice.actions

export default InvoiceSlice.reducer
