import NumberFormatDollars from '../../../utils/NumberFormatDollars'
import { useAppSelector } from '../../../redux/hooks'
interface Props {
    invoiceTotal: number
}

export default function InvoiceCheckoutCheck({ invoiceTotal }: Props) {
    const branding = useAppSelector((state) => state.branding)
    return (
        <div>
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                Check
            </h2>

            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                Below are the details on how to pay via check
            </p>
            <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm  leading-6 text-gray-900">
                            Make the check out to:{' '}
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 font-medium">
                            {branding.check_payee}{' '}
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm  leading-6 text-gray-900">
                            For the amount of:{' '}
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 font-medium">
                            {NumberFormatDollars(invoiceTotal)}
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm  leading-6 text-gray-900">
                            Please mail check to:
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 font-medium">
                            {branding.company_name}
                            <br />
                            {branding.address}
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm  leading-6 text-gray-900">
                            Please use:
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 font-medium">
                            A courier service that provides a tracking number,
                            such as Fedex or UPS. Do not use Canada Post.
                        </dd>
                        <p className="mt-1 max-w-2xl text-sm leading-6 text-red-500"></p>
                    </div>
                </dl>
                <p className="mt-2">
                    If you have any questions, please contact us at{' '}
                    <a
                        href={`mailto:${branding.contact_email}`}
                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                    >
                        {branding.contact_email}
                    </a>{' '}
                    or{' '}
                    <a
                        href={`tel:+${branding.contact_phone}`}
                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                    >
                        {branding.contact_phone}
                    </a>
                    .{' '}
                </p>
            </div>
        </div>
    )
}
