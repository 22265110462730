const loadNMI = (
    public_key: string,
    setLoading: Function,
    setCollectJs: Function
) => {
    const existingScript = document.getElementById('collectjs')
    var customCss = `{
    "background-color": "white",
    "border": "unset",
    "outline": "none",
    "padding": "5px"
  }`
    var focusCss = `{
    "border": "unset",
    "outline-color": "white",
    "color": "black"
  }`

    var invalidCss = `{"color":"red", "border-radius": "5px"}`

    var dataValidationCallback =
        '(function (field, valid, message) {collectJsValidationCallback(field, valid, message)})'

    if (!existingScript) {
        const script = document.createElement('script')
        script.src = 'https://secure.networkmerchants.com/token/Collect.js'
        script.setAttribute('data-tokenization-key', public_key)
        script.setAttribute('data-custom-css', customCss)
        script.setAttribute('data-focus-css', focusCss)
        script.setAttribute('data-invalid-css', invalidCss)
        script.setAttribute('data-validation-callback', dataValidationCallback)

        script.async = true
        script.id = 'collectjs'
        document.body.appendChild(script)

        script.onload = () => {
            setLoading(false)
            setCollectJs(true)
        }
    }
}

export default loadNMI
