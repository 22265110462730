import React, { useEffect, useState } from 'react'

import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { RegistrationFormRepository } from '../../api/RegistrationFormRepository'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { setBankingInfo } from '../../redux/state-slices/BankingInfoSlice'
import useDebounce from '../../utils/useDebounce'
import Loading from '../../shared-components/Loading'
import { setStep } from '../../redux/state-slices/StepSlice'
import Error from '../../shared-components/Error'
import {
    numOnlyRegex,
    validateAccountNumber,
    validateBankingInfo,
    validateInstitutionNumber,
    validateTransitNumber,
} from '../../utils/RegexHelper'
import MockVoidCheque from '../onboarding_flow/MockVoidCheque'

interface BankingInfoValidAttributes {
    routing_number: boolean | undefined
    institution_number: boolean | undefined
    account_number: boolean | undefined
}

export default function DebitBankingInfo() {
    const dispatch = useAppDispatch()
    const bankingInfo = useAppSelector((state) => state.banking_info)
    const authUser = useAppSelector((state) => state.auth_user)
    const debounceValue = useDebounce(bankingInfo, 1500)
    const registrationFormRepository = new RegistrationFormRepository()
    const [validForm, setValidForm] = useState(false)
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const [bankingInfoValid, setBankingInfoValid] =
        useState<BankingInfoValidAttributes>({
            routing_number: undefined,
            institution_number: undefined,
            account_number: undefined,
        })

    useEffect(() => {
        window.scrollTo(0, 0)
        setStepState()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        validateForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankingInfo])

    useEffect(() => {
        postFormValuesMutation.mutate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceValue])

    const setStepState = () => {
        dispatch(setStep(2))
    }

    const postFormValuesMutation = useMutation({
        mutationFn: () =>
            registrationFormRepository.postBankingInfo(
                bankingInfo,
                authUser?.id
            ),
    })

    const submitFormValuesMutation = useMutation({
        mutationFn: () =>
            registrationFormRepository.postBankingInfo(
                bankingInfo,
                authUser?.id
            ),
        onSuccess: () => {
            navigate('/onboarding/debit/pricing')
        },
    })

    const uploadChequeMutation = useMutation({
        mutationFn: (formData: FormData) =>
            registrationFormRepository.uploadVoidCheque(
                formData,
                bankingInfo.id
            ),
        onSuccess: () => {},
    })

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (validForm) {
            submitFormValuesMutation.mutate()
        }
    }

    const validateForm = () => {
        setValidForm(validateBankingInfo(bankingInfo))
    }

    const handleTransitChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        if ((val.length < 10 && numOnlyRegex(val)) || val === '') {
            setBankingInfoValid({
                ...bankingInfoValid,
                routing_number: validateTransitNumber(val),
            })

            dispatch(
                setBankingInfo({
                    ...bankingInfo,
                    routing_number: val,
                })
            )
        }
    }

    const handleInstitutionChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value

        setBankingInfoValid({
            ...bankingInfoValid,
            institution_number: validateInstitutionNumber(val),
        })

        if ((val.length < 5 && numOnlyRegex(val)) || val === '') {
            setBankingInfoValid({
                ...bankingInfoValid,
                institution_number: validateInstitutionNumber(val),
            })
            dispatch(
                setBankingInfo({
                    ...bankingInfo,
                    institution_number: val,
                })
            )
        }
    }

    const handleAccountChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value

        if ((val.length < 16 && numOnlyRegex(val)) || val === '') {
            setBankingInfoValid({
                ...bankingInfoValid,
                account_number: validateAccountNumber(val),
            })
            dispatch(
                setBankingInfo({
                    ...bankingInfo,
                    account_number: val,
                })
            )
        }
    }

    const handleVoidChequeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formData = new FormData()

        if (e.target.files?.length === 1) {
            let fileName = e.target.files[0].name
            formData.append('voidcheque', e.target.files[0], fileName)

            dispatch(
                setBankingInfo({
                    ...bankingInfo,
                    void_cheque_file_name: fileName,
                })
            )

            uploadChequeMutation.mutate(formData)
        }
    }

    const handleInvalidClass = (key: keyof BankingInfoValidAttributes) => {
        if (bankingInfoValid[key] === false) {
            return '!border-2 !border-rose-500'
        }

        return ''
    }

    return (
        <form
            className="space-y-8 divide-y divide-gray-200"
            onSubmit={(e) => handleSubmit(e)}
        >
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div className="">
                    <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="accountnumber"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Account Number
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={bankingInfo.account_number || ''}
                                    onChange={handleAccountChange}
                                    type="text"
                                    name="accountnumber"
                                    id="accountnumber"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'account_number'
                                    )}`}
                                />
                                {bankingInfoValid.account_number === false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-account-number"
                                    >
                                        Minimum 5 characters required
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="transit"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Transit / Routing #
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={bankingInfo.routing_number || ''}
                                    onChange={handleTransitChange}
                                    type="text"
                                    name="transit"
                                    id="transit"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'routing_number'
                                    )}`}
                                />
                                {bankingInfoValid.routing_number === false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-routing-number"
                                    >
                                        Minimum 5 characters required
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="institution"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Bank / Institution #
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={bankingInfo.institution_number || ''}
                                    onChange={handleInstitutionChange}
                                    type="text"
                                    name="institution"
                                    id="institution"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'institution_number'
                                    )}`}
                                />
                                {bankingInfoValid.institution_number ===
                                    false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-institution-number"
                                    >
                                        Minimum 3 characters required
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                        htmlFor="voidcheque"
                        className="block text-sm font-medium text-gray-700 flex mt-1"
                    >
                        VOID Cheque or Direct Deposit Document
                        <span
                            className="ml-1 cursor-pointer text-blue-700"
                            onClick={() => setOpen(!open)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                />
                            </svg>
                        </span>
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <div className="flex items-center">
                            <div className="sm:col-span-6">
                                <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                    <div className="space-y-1 text-center">
                                        <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <div className="flex text-sm text-gray-600">
                                            <label
                                                htmlFor="file-upload"
                                                className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>Upload a file</span>
                                                <input
                                                    required={
                                                        bankingInfo.void_cheque_file_name
                                                            ? false
                                                            : true
                                                    }
                                                    id="file-upload"
                                                    name="file-upload"
                                                    type="file"
                                                    className="sr-only"
                                                    onChange={
                                                        handleVoidChequeFile
                                                    }
                                                    accept=".jpg,.png,.pdf,.jpeg"
                                                />
                                            </label>
                                            <p className="pl-1">
                                                or drag and drop
                                            </p>
                                        </div>
                                        <p className="text-xs text-gray-500">
                                            PNG, JPG, PDF, up to 5MB
                                        </p>
                                        {bankingInfo.void_cheque_file_name ? (
                                            <p className="text-lg text-black-500 mt-100 border-solid border-2">
                                                {
                                                    bankingInfo.void_cheque_file_name
                                                }
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-5">
                <div className="flex justify-start">
                    {!submitFormValuesMutation.isLoading && (
                        <button
                            disabled={!validForm}
                            type="submit"
                            className="ml-3 disabled:bg-gray-400 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
                        >
                            Save and Continue
                        </button>
                    )}
                    {submitFormValuesMutation.isLoading && (
                        <Loading width="8" height="8" />
                    )}
                </div>

                {submitFormValuesMutation.isError && (
                    <div className="md:w-2/5 mt-4">
                        <Error
                            title="There was an error submitting"
                            message="Please reach out to one of our representatives if this error persists"
                        />
                    </div>
                )}
            </div>
            {open && <MockVoidCheque open={open} setOpen={setOpen} />}
        </form>
    )
}
