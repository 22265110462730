type GenericMapping = {
    [key: number]: string
}

const ACCOUNT_STATUS: GenericMapping = {
    1: 'Waiting',
    2: 'Active',
    3: 'Suspended',
    4: 'Pending Approval',
    5: 'Declined',
    6: 'Registering',
}

export const SUPPLIER: GenericMapping = {
    1: 'CT Payments',
    2: 'Elavon',
    4: 'PAL',
    5: 'DCB',
    6: 'Nuvei',
    7: 'TMS',
    8: 'Fiserv',
    9: 'PSPS',
}

export const USER_TYPES: GenericMapping = {
    1: 'Customer',
    2: 'Agent',
    3: 'Admin',
    4: 'Compliance',
}

export const PROVINCES: GenericMapping = {
    1: 'British Columbia',
    2: 'Alberta',
    3: 'Saskatchewan',
    4: 'Manitoba',
    5: 'Ontario',
    6: 'Quebec',
    7: 'New Brunswick',
    8: 'PEI',
    9: 'Nova Scotia',
    10: 'NFLD',
    11: 'Yukon',
    12: 'NWT',
    13: 'Nunavut',
    14: 'United States',
}

export const COUNTRIES: GenericMapping = {
    1: 'Canada',
    2: 'United States',
}

export const EFT_PROVIDER: GenericMapping = {
    1: 'National Bank',
    2: 'Paysafe',
    3: 'Credit Card Only',
}

export const CONTACT_TYPE: GenericMapping = {
    1: 'Main Contact',
    2: 'Business Info',
    3: 'Billing Contact',
    4: 'Shipping Info',
    5: 'Secondary Contact',
    6: 'UBO',
}

export const ACCOUNT_STATUS_OPTIONS = {
    1: 'Waiting',
    2: 'Active',
    3: 'Suspended',
    4: 'Pending Approval',
    5: 'Declined',
    6: 'Registering',
}
export const getReadableAccountStatus = (accountStatusId: number): string => {
    const status = ACCOUNT_STATUS[accountStatusId] || 'N/A'

    return status
}

export const getReadableSupplier = (supplierId: number): string => {
    const supplier = SUPPLIER[supplierId] || 'N/A'

    return supplier
}

export const getReadableUserType = (userTypeId: number): string => {
    const userType = USER_TYPES[userTypeId] || 'N/A'

    return userType
}

export const getBackgroundClassNameAccountStatus = (
    accountStatusId: number
) => {
    switch (accountStatusId) {
        case 1:
            return 'bg-yellow-100 ring-yellow-500 text-yellow-700'
        case 2:
            return 'bg-green-100 ring-green-500 text-green-700'
        case 3:
            return 'bg-red-100 ring-red-500 text-red-700'
        case 4:
            return 'bg-yellow-100 ring-yellow-500 text-yellow-700'
        case 5:
            return 'bg-red-100 ring-red-500 text-red-700'
        case 6:
            return 'bg-yellow-100 ring-yellow-500 text-yellow-700'
    }
}

export const getReadableProvince = (provinceId: number): string => {
    const province = PROVINCES[provinceId] || 'N/A'

    return province
}

export const getReadableCountry = (countryId: number): string => {
    const country = COUNTRIES[countryId] || 'N/A'

    return country
}

export const getReadableBusinessFormation = (
    businessFormation: string | null | undefined
) => {
    switch (businessFormation) {
        case 'association_estate_trust':
            return 'Association, Estate, or Trust'
        case 'corporation':
            return 'Corporation'
        case 'international_organization':
            return 'International Organization'
        case 'llc':
            return 'LLC'
        case 'medical_legal_corporation':
            return 'Medical or Legal Corporation'
        case 'non_profit':
            return 'Non-Profit'
        case 'partnership':
            return 'Partnership'
        case 'sole_proprietorship':
            return 'Sole Proprietorship'
        case 'tax_exempt_organization':
            return 'Tax Exempt Organization'
        case 'other':
            return 'Other'
    }
}

export const getReadableEftProvider = (eftProviderId: number): string => {
    const eftProvider = EFT_PROVIDER[eftProviderId] || 'N/A'

    return eftProvider
}

export const getReadableContactType = (contactTypeId: number): string => {
    const contactType = CONTACT_TYPE[contactTypeId] || 'N/A'

    return contactType
}
