import { BaseRepository } from './BaseRepository'

export class ContactRepository extends BaseRepository {
    getUsersContacts = async (userId: number) => {
        return await super
            .getHttpClientInstance()
            .get<Array<Paywell.API.V2.UserContact>>(
                `/api/v2/contacts/get-user-contacts/${userId}`
            )
    }

    updateUserContacts = async (
        userId: number,
        contacts: Array<Paywell.API.V2.UserContact>
    ) => {
        return await super
            .getHttpClientInstance()
            .post<Array<Paywell.API.V2.UserContact>>(
                `/api/v2/contacts/update-user-contacts/${userId}`,
                contacts
            )
    }

    createContact = async (userId: number) => {
        return await super
            .getHttpClientInstance()
            .post<Paywell.API.V2.UserContact>(
                `/api/v2/contacts/create-contact-for-user/${userId}`
            )
    }
}
