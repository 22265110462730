import { Navigate, Outlet } from 'react-router-dom'
import { useAppSelector } from './redux/hooks'
import { LoginReferral } from './routes/ReferralCheck';

const PrivateRoutes = () => {
    const authUser = useAppSelector((state) => state.auth_user)
    const loginReferral = LoginReferral()
    if (authUser === null) {
        return <Navigate to={loginReferral} />
    } else {
        return authUser ? <Outlet /> : <Navigate to={loginReferral} replace />
    }
}

export default PrivateRoutes
