import Loading from '../../shared-components/Loading'
import { Listbox, Menu, Transition } from '@headlessui/react'
import {
    ArrowUpTrayIcon,
    CheckIcon,
    ChevronUpDownIcon,
    PlusCircleIcon,
    TrashIcon,
} from '@heroicons/react/20/solid'
import React, { Fragment, useEffect, useState } from 'react'
import { EFT_PROVIDER, getReadableEftProvider } from '../../utils/UserUtils'
import { UserRepository } from '../../api/UserRepositoy'
import { useAppDispatch } from '../../redux/hooks'
import { useMutation, useQuery } from 'react-query'
import { setError } from '../../redux/state-slices/GlobalNotificationSlice'
import { useNavigate } from 'react-router-dom'
import DialogModal from '../../shared-components/DialogModal'
import { extractFileName } from '../../utils/StringUtils'
import { numOnlyRegex } from '../../utils/RegexHelper'
import { BankingRepository } from '../../api/BankingRepository'
import { forEach } from 'lodash'

interface UserBankingEditProps {
    user: Paywell.API.V2.User | null
    userCopy: Paywell.API.V2.User | null
    updateUserInfo: (user: Paywell.API.V2.User) => void
    saveChanges: () => void
}

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ')
}
export default function UserBankingEdit({
    user,
    userCopy,
    updateUserInfo,
    saveChanges,
}: UserBankingEditProps) {
    const userRepository = new UserRepository()
    const bankingRepository = new BankingRepository()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [bankAccountObjects, setBankAccountObject] = useState<
        Paywell.API.V2.UserBankingInfo[] | undefined
    >(undefined)
    const [bankAccountObjectCopy, setBankAccountObjectCopy] = useState<
        Paywell.API.V2.UserBankingInfo[] | undefined
    >(undefined)
    const [validBankAccounts, setValidBankAccounts] = useState<boolean>(true)
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const [selectedBankAccountId, setSelectedBankAccountId] = useState<
        number | null
    >(null)

    const [confirmDeleteBankAccountDialog, setConfirmDeleteBankAccountDialog] =
        useState(false)

    const validForm = validBankAccounts

    useEffect(() => {
        validateForm()
    }, [userCopy, bankAccountObjects])

    const postBankingDetails = async () => {
        if (!user?.id) return
        return (await bankingRepository.postBankingInfo(bankAccountObjects))
            .data
    }

    const updateBankAccountsMutation = useMutation(() => postBankingDetails(), {
        onSuccess: () => {
            saveChanges()
        },
        onError: () => {
            dispatch(
                setError([
                    `There was an error saving the banking information. Please try again.`,
                ])
            )
        },
    })

    const deleteBankAccountMutation = useMutation(
        (bankAccountId: number) =>
            bankingRepository.deleteBankAccount(bankAccountId),

        {
            onSuccess: () => {
                setBankAccountObject(
                    bankAccountObjects?.filter(
                        (account) => account.id !== selectedBankAccountId
                    )
                )
            },
            onError: () => {
                dispatch(setError(['There was an error uploading the file']))
            },
        }
    )

    const uploadVoidChequeMutation = useMutation(
        (args: {
            formData: FormData
            bankAccountId: number
            fileName: string
            index: number
        }) =>
            bankingRepository.uploadVoidCheque(
                args.formData,
                args.bankAccountId
            ),

        {
            onSuccess: (_, { fileName, index }) => {
                updateVoidChequeFileName(fileName, index)
            },
            onError: () => {
                dispatch(setError(['There was an error uploading the file']))
            },
        }
    )

    const createBankAccountMutation = useMutation(
        () => bankingRepository.createBankAccount(user?.id || 0),
        {
            onSuccess: (data) => {
                let returnedBankAccount = data.data
                if (!bankAccountObjects) {
                    setBankAccountObject([returnedBankAccount])
                } else {
                    let newBankAccounts = [...bankAccountObjects]
                    newBankAccounts.push(returnedBankAccount)
                    setBankAccountObject(newBankAccounts)
                }
            },
            onError: () => {
                dispatch(
                    setError(['There was an error creating the bank account'])
                )
            },
        }
    )

    const updateVoidChequeFileName = (fileName: string, index: number) => {
        if (!bankAccountObjects) return
        let newBankAccounts = bankAccountObjects?.map((account) => ({
            ...account,
        }))
        newBankAccounts[index].void_cheque_file_name = fileName
        setBankAccountObject(newBankAccounts)
    }

    const validateForm = () => {
        if (!userCopy) return

        forEach(bankAccountObjects, (bankAccount) => {
            if (
                !validateBankingObject(bankAccount.account_number) ||
                !validateBankingObject(bankAccount.institution_number) ||
                !validateBankingObject(bankAccount.routing_number)
            ) {
                setValidBankAccounts(false)
            } else {
                setValidBankAccounts(true)
            }
        })
    }

    const validateBankingObject = (value: string) => {
        if (numOnlyRegex(value) || value === '' || value === null) {
            return true
        }
        return false
    }
    const fetchBankingDetails = async () => {
        if (!user?.id) return
        return (await userRepository.getBankingDetails(user?.id)).data
    }

    const bankingDetailsQuery = useQuery(
        ['bankingDetails-edit', user?.id],
        fetchBankingDetails,
        {
            onSuccess: (data) => {
                setBankAccountObject(data)
                setBankAccountObjectCopy(data)
            },
            onError: (error) => {
                dispatch(
                    setError([
                        'An error has occurred while fetching banking details.',
                    ])
                )
            },
            refetchOnWindowFocus: false,
        }
    )

    const handleVoidChequeFile = (
        e: React.ChangeEvent<HTMLInputElement>,
        bankAccountId: number,
        index: number
    ) => {
        const formData = new FormData()
        if (e.target.files?.length === 1) {
            let fileName = e.target.files[0].name
            let fieldName = 'voidcheque'

            formData.append(fieldName, e.target.files[0], fileName)

            uploadVoidChequeMutation.mutate({
                formData,
                bankAccountId,
                fileName,
                index,
            })
        }

        e.target.value = '' //reset the input field
    }

    const handleBankAccountNumberChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        if (!userCopy) return
        let val = e.target.value
        if (val.length < 16 && bankAccountObjects) {
            let newBankAccounts = bankAccountObjects?.map((account) => ({
                ...account,
            }))

            newBankAccounts[index].account_number = e.target.value
            setBankAccountObject(newBankAccounts)
        }
    }

    const handleBankAccountInstitutionNumberChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        if (!userCopy) return
        let val = e.target.value
        if (val.length < 5 && bankAccountObjects) {
            let newBankAccounts = bankAccountObjects?.map((account) => ({
                ...account,
            }))

            newBankAccounts[index].institution_number = e.target.value
            setBankAccountObject(newBankAccounts)
        }
    }

    const handleBankAccountRoutingNumberChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        if (!userCopy) return
        let val = e.target.value
        if (val.length < 10 && bankAccountObjects) {
            let newBankAccounts = bankAccountObjects?.map((account) => ({
                ...account,
            }))

            newBankAccounts[index].routing_number = e.target.value
            setBankAccountObject(newBankAccounts)
        }
    }

    const handleBankAccountSettlementDistributionChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        if (!userCopy) return // Ensure there's a user copy to work with
        let val = e.target.value // Get the current input value

        // Convert input value to number and ensure it is not greater than 100
        const inputValue = Number(val)
        const isValidValue =
            !isNaN(inputValue) && inputValue >= 0 && inputValue <= 100

        if (isValidValue && bankAccountObjects) {
            // Create a new copy of the bank accounts array
            let newBankAccounts = bankAccountObjects.map((account, idx) => {
                // Return the account as is for other indices
                if (idx !== index) return account

                // Update the settlement_weight for the specified index
                return {
                    ...account,
                    settlement_weight: inputValue, // Directly use the validated numeric value
                }
            })

            // Update the state with the new bank accounts array
            setBankAccountObject(newBankAccounts)
        }
    }

    const handleIsPrimaryChange = (e: boolean, index: number) => {
        if (!userCopy) return

        if (bankAccountObjects) {
            let newBankAccounts = bankAccountObjects?.map((account) => ({
                ...account,
            }))
            newBankAccounts[index].primary = e
            setBankAccountObject(newBankAccounts)
        }
    }

    const handleSaveChanges = () => {
        if (!validForm) return
        updateBankAccountsMutation.mutate()
    }

    const hasUnsavedChanges = () => {
        return (
            JSON.stringify(user) !== JSON.stringify(userCopy) ||
            JSON.stringify(bankAccountObjects) !==
                JSON.stringify(bankAccountObjectCopy)
        )
    }

    const handleDeleteBankAccount = (bankAccountId: number) => {
        setSelectedBankAccountId(bankAccountId)
        setConfirmDeleteBankAccountDialog(true)
    }

    const confirmDeleteBankAccount = () => {
        if (!selectedBankAccountId) return
        deleteBankAccountMutation.mutate(selectedBankAccountId)
    }

    const cancelDeleteBankAccount = () => {
        setConfirmDeleteBankAccountDialog(false)
    }
    const handleCancel = () => {
        if (!user) return
        if (hasUnsavedChanges()) {
            setIsDialogOpen(true) // Show the dialog if there are unsaved changes
        } else {
            navigate('/users/' + user.id + '/banking') // No unsaved changes, just navigate away
        }
    }
    const confirmCancel = () => {
        setIsDialogOpen(false) // Close the dialog
        if (user) updateUserInfo(user) // Reset the user to the original state (cancel changes
        navigate('/users/' + user?.id + '/banking') // Proceed with the cancel action
    }

    const cancelCancel = () => {
        setIsDialogOpen(false) // Close the dialog without canceling changes
    }

    const invalidClass =
        '!border-2 !border-rose-500 focus:ring-rose-500 focus:border-rose-500'
    return (
        <div className={`p-10 relative`}>
            {updateBankAccountsMutation.isLoading ||
                uploadVoidChequeMutation.isLoading ||
                (createBankAccountMutation.isLoading && (
                    <div
                        id="loading-screen"
                        className=" w-full h-full absolute block top-0 left-0 bg-white opacity-75 z-50"
                    >
                        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
                            <Loading height={'8'} width={'8'} />
                        </span>
                    </div>
                ))}

            <div className="mb-2 flex justify-end space-x-2">
                <button
                    disabled={!validForm}
                    onClick={handleSaveChanges}
                    type="button"
                    className={`block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm disabled:bg-gray-400 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                >
                    Save
                </button>
                <button
                    onClick={handleCancel}
                    type="button"
                    className="flex  rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                    Cancel
                </button>
            </div>

            <div className={'border border-gray-100 rounded-md mt-4 p-2'}>
                <div className="flex justify-between">
                    <div className={'px-4 sm:px-0'}>
                        <h3 className="text-base font-semibold leading-7 text-gray-900">
                            Banking Information
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                            View and edit banking information.
                        </p>
                    </div>
                    {bankAccountObjects && bankAccountObjects?.length <= 1 && (
                        <PlusCircleIcon
                            onClick={() => createBankAccountMutation.mutate()}
                            className={'h-7 w-7 text-indigo-500 cursor-pointer'}
                        />
                    )}
                </div>

                {(bankingDetailsQuery.isLoading ||
                    bankingDetailsQuery.isFetching) && (
                    <div className="flex justify-center items-center h-32">
                        <Loading height={'8'} width={'8'} />
                    </div>
                )}

                {bankingDetailsQuery.isSuccess &&
                    !bankingDetailsQuery.isLoading &&
                    !bankingDetailsQuery.isFetching && (
                        <ul
                            className={`grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-${bankAccountObjects?.length} xl:gap-x-8 mt-4`}
                        >
                            {bankAccountObjects &&
                                bankAccountObjects.map((bankAccount, index) => (
                                    <li
                                        key={bankAccount.id}
                                        className="overflow-hidden rounded-xl border border-gray-200"
                                    >
                                        <div className="flex justify-between items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                                            <div>
                                                <div className="text-sm font-medium leading-6 text-gray-900">
                                                    Bank Account #{index + 1}
                                                </div>
                                                {bankAccount.primary ? (
                                                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                        Primary
                                                    </span>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <TrashIcon
                                                onClick={() =>
                                                    handleDeleteBankAccount(
                                                        bankAccount.id
                                                    )
                                                }
                                                className="fill-red-500 h-5 w-5 cursor-pointer"
                                            />
                                        </div>
                                        <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                                            <div className="flex justify-between gap-x-4 py-3">
                                                <dt className="text-gray-500">
                                                    Primary
                                                </dt>
                                                <Listbox
                                                    value={bankAccount?.primary}
                                                    onChange={(value) =>
                                                        handleIsPrimaryChange(
                                                            value,
                                                            index
                                                        )
                                                    }
                                                >
                                                    {({ open }) => (
                                                        <>
                                                            <div className="relative mt-2">
                                                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                                    <span className="block truncate">
                                                                        {bankAccount?.primary
                                                                            ? 'Yes'
                                                                            : 'No'}
                                                                    </span>
                                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                        <ChevronUpDownIcon
                                                                            className="h-5 w-5 text-gray-400"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </span>
                                                                </Listbox.Button>

                                                                <Transition
                                                                    show={open}
                                                                    as={
                                                                        Fragment
                                                                    }
                                                                    leave="transition ease-in duration-100"
                                                                    leaveFrom="opacity-100"
                                                                    leaveTo="opacity-0"
                                                                >
                                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                        <Listbox.Option
                                                                            className={({
                                                                                active,
                                                                            }) =>
                                                                                classNames(
                                                                                    active
                                                                                        ? 'bg-indigo-600 text-white'
                                                                                        : 'text-gray-900',
                                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                )
                                                                            }
                                                                            value={
                                                                                true
                                                                            }
                                                                        >
                                                                            {({
                                                                                selected,
                                                                                active,
                                                                            }) => (
                                                                                <>
                                                                                    <span
                                                                                        className={classNames(
                                                                                            selected
                                                                                                ? 'font-semibold'
                                                                                                : 'font-normal',
                                                                                            'block truncate'
                                                                                        )}
                                                                                    >
                                                                                        {
                                                                                            'Yes'
                                                                                        }
                                                                                    </span>

                                                                                    {selected && (
                                                                                        <span
                                                                                            className={classNames(
                                                                                                active
                                                                                                    ? 'text-white'
                                                                                                    : 'text-indigo-600',
                                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                            )}
                                                                                        >
                                                                                            <CheckIcon
                                                                                                className="h-5 w-5"
                                                                                                aria-hidden="true"
                                                                                            />
                                                                                        </span>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                        <Listbox.Option
                                                                            className={({
                                                                                active,
                                                                            }) =>
                                                                                classNames(
                                                                                    active
                                                                                        ? 'bg-indigo-600 text-white'
                                                                                        : 'text-gray-900',
                                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                )
                                                                            }
                                                                            value={
                                                                                false
                                                                            }
                                                                        >
                                                                            {({
                                                                                selected,
                                                                                active,
                                                                            }) => (
                                                                                <>
                                                                                    <span
                                                                                        className={classNames(
                                                                                            selected
                                                                                                ? 'font-semibold'
                                                                                                : 'font-normal',
                                                                                            'block truncate'
                                                                                        )}
                                                                                    >
                                                                                        {
                                                                                            'No'
                                                                                        }
                                                                                    </span>

                                                                                    {selected && (
                                                                                        <span
                                                                                            className={classNames(
                                                                                                active
                                                                                                    ? 'text-white'
                                                                                                    : 'text-indigo-600',
                                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                            )}
                                                                                        >
                                                                                            <CheckIcon
                                                                                                className="h-5 w-5"
                                                                                                aria-hidden="true"
                                                                                            />
                                                                                        </span>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    </Listbox.Options>
                                                                </Transition>
                                                            </div>
                                                        </>
                                                    )}
                                                </Listbox>
                                            </div>

                                            <div className="flex justify-between gap-x-4 py-3">
                                                <dt className="text-gray-500">
                                                    Account Number
                                                </dt>
                                                <input
                                                    type="text"
                                                    name={`user-bank-account-${index}-account-number`}
                                                    id={`user-bank-account-${index}-account-number`}
                                                    value={
                                                        bankAccount?.account_number ||
                                                        ''
                                                    }
                                                    onChange={(e) =>
                                                        handleBankAccountNumberChange(
                                                            e,
                                                            index
                                                        )
                                                    }
                                                    className={`${
                                                        !validateBankingObject(
                                                            bankAccount.account_number
                                                        ) && invalidClass
                                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                                />
                                            </div>
                                            <div className="flex justify-between gap-x-4 py-3">
                                                <dt className="text-gray-500">
                                                    Institution Number
                                                </dt>
                                                <input
                                                    type="text"
                                                    name={`user-bank-account-${index}-institution-number`}
                                                    id={`user-bank-account-${index}-institution-number`}
                                                    value={
                                                        bankAccount?.institution_number ||
                                                        ''
                                                    }
                                                    onChange={(e) =>
                                                        handleBankAccountInstitutionNumberChange(
                                                            e,
                                                            index
                                                        )
                                                    }
                                                    className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                                />
                                            </div>
                                            <div className="flex justify-between gap-x-4 py-3">
                                                <dt className="text-gray-500">
                                                    Routing Number
                                                </dt>
                                                <input
                                                    type="text"
                                                    name={`user-bank-account-${index}-routing-number`}
                                                    id={`user-bank-account-${index}-routing-number`}
                                                    value={
                                                        bankAccount?.routing_number ||
                                                        ''
                                                    }
                                                    onChange={(e) =>
                                                        handleBankAccountRoutingNumberChange(
                                                            e,
                                                            index
                                                        )
                                                    }
                                                    className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                                />
                                            </div>
                                            <div className="flex justify-between gap-x-4 py-3">
                                                <dt className="text-gray-500">
                                                    Settlement Distribution
                                                </dt>
                                                <input
                                                    type="text"
                                                    name={`user-bank-account-${index}-settlement-distribution`}
                                                    id={`user-bank-account-${index}-settlement-distribution`}
                                                    value={
                                                        bankAccount?.settlement_weight ||
                                                        ''
                                                    }
                                                    onChange={(e) =>
                                                        handleBankAccountSettlementDistributionChange(
                                                            e,
                                                            index
                                                        )
                                                    }
                                                    className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                                />
                                            </div>
                                            <div className="flex justify-between gap-x-4 py-3">
                                                <dt className="text-gray-500">
                                                    Void Cheque
                                                </dt>
                                                <label
                                                    htmlFor={`void-cheque-${index} `}
                                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                >
                                                    <div
                                                        className={
                                                            'flex space-x-2'
                                                        }
                                                    >
                                                        <span>
                                                            {extractFileName(
                                                                bankAccount.void_cheque_file_name ||
                                                                    ''
                                                            )}
                                                        </span>
                                                        <ArrowUpTrayIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </div>
                                                    <input
                                                        id={`void-cheque-${index} `}
                                                        name={`void-cheque-${index} `}
                                                        type="file"
                                                        onChange={(e) =>
                                                            handleVoidChequeFile(
                                                                e,
                                                                bankAccount.id,
                                                                index
                                                            )
                                                        }
                                                        className="sr-only"
                                                        accept={
                                                            '.pdf, .png, .jpg, .jpeg'
                                                        }
                                                    />
                                                </label>
                                            </div>
                                        </dl>
                                    </li>
                                ))}
                        </ul>
                    )}
            </div>

            <DialogModal
                open={confirmDeleteBankAccountDialog}
                setOpen={setConfirmDeleteBankAccountDialog}
                title="Delete Bank Account?"
                description="Are you sure you want to delete this bank account? This action cannot be undone."
                confirmAction={confirmDeleteBankAccount}
                cancelAction={cancelDeleteBankAccount}
            />
            <DialogModal
                open={isDialogOpen}
                setOpen={setIsDialogOpen}
                title="Unsaved Changes"
                description="You have unsaved changes. Are you sure you want to cancel? Your changes will be lost."
                confirmAction={confirmCancel}
                cancelAction={cancelCancel}
            />
        </div>
    )
}
