import { useAppSelector } from './redux/hooks'
import { Outlet, Navigate } from 'react-router-dom'

const VerifiedUser = () => {
    const authUser = useAppSelector((state) => state.auth_user)

    return authUser && authUser.email_verified ? (
        <Outlet />
    ) : (
        <Navigate to="confirm-email" replace />
    )
}

export default VerifiedUser
