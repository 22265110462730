import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

const initialState: Paywell.API.V2.BankingInfo = {
    id: null,
    routing_number: null,
    institution_number: null,
    account_number: null,
    void_cheque_file_name: null,
}

export const BankingInfoSlice = createSlice({
    name: 'banking_info',
    initialState: initialState as Paywell.API.V2.BankingInfo,

    reducers: {
        setBankingInfo: (
            state: Paywell.API.V2.BankingInfo,
            action: PayloadAction<Paywell.API.V2.BankingInfo>
        ) => {
            const bankingInfo: Paywell.API.V2.BankingInfo = {
                id: action.payload.id || null,
                routing_number: action.payload.routing_number || null,
                institution_number: action.payload.institution_number || null,
                account_number: action.payload.account_number || null,
                void_cheque_file_name:
                    action.payload.void_cheque_file_name || null,
            }
            return bankingInfo
        },
    },
})

// Action creators are generated for each case reducer function
export const { setBankingInfo } = BankingInfoSlice.actions

export default BankingInfoSlice.reducer
