import React, {useContext, useEffect, useState} from 'react'
import Loading from "../shared-components/Loading";

interface FastTransactionPending {
  manualInstructionsClickedFn: (e: any) => void
}

const FastTransactionPending = (props: FastTransactionPending) => {
  return (
    <>
      <div className="bg-white py-24 px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <div className="flex justify-center mt-4">
            <Loading height='8' width='8'/>
          </div>
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl mt-3">Transaction Pending</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Please complete the transaction with your bank in the new tab that was opened.
          </p>
        </div>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">Don't See Your Bank?</h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>If you don't see your bank in the new tab that was opened, no problem! Click the button below to send a manual e-Transfer. </p>
              </div>
              <div className="mt-5">
                <button
                  type="button"
                  onClick={props.manualInstructionsClickedFn}
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                >
                  Send e-Transfer Manually
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default FastTransactionPending