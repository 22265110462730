import { useState } from 'react'
import NewEftModal from './NewEftModal'
import NumberFormatDollars from '../utils/NumberFormatDollars'
import { useQuery } from 'react-query'
import { EftsRepository } from '../api/EftsRepository'
import Loading from '../shared-components/Loading'
import PaginationV2 from '../invoices/components/PaginationV2'
import { useSearchParams } from 'react-router-dom'

export default function EftBatches() {
    const [newEftModalIsOpen, setNewEftModalIsOpen] = useState(false)
    const [previewEfts, setPreviewEfts] = useState(false)
    const eftsRepository = new EftsRepository()
    const [searchParams] = useSearchParams()
    const page = searchParams.get('page') ? searchParams.get('page') : 1

    const getEftsSchedulesQuery = useQuery(
        ['efts-schedules', page],
        async () => {
            return await eftsRepository.getEftSchedules(page)
        },
        {
            retry: false,
            refetchOnWindowFocus: true,
            onSuccess: (data) => {},
        }
    )

    const renderEftsSchedules = () => {
        if (getEftsSchedulesQuery.isSuccess) {
            if (getEftsSchedulesQuery.data.data.data.length > 0) {
                return getEftsSchedulesQuery.data.data.data.map(
                    (eftSchedule) => {
                        return (
                            <tr
                                key={eftSchedule.id}
                                className="even:bg-gray-50"
                            >
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                    {eftSchedule.id}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {eftSchedule.from_date}
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {eftSchedule.to_date}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {NumberFormatDollars(
                                        eftSchedule.split_amount
                                    )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {NumberFormatDollars(
                                        eftSchedule.paysafe_max_amount
                                    )}
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {eftSchedule.paysafe_days_between}
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {NumberFormatDollars(
                                        eftSchedule.nbc_max_amount
                                    )}
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {eftSchedule.nbc_days_between}
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {eftSchedule.eft_start_date}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 space-x-2">
                                    <a
                                        href={`eft-batches/${eftSchedule.id}/efts`}
                                        className="text-blue-600 hover:text-blue-900"
                                    >
                                        View
                                    </a>

                                    <a
                                        href={`eft-batches/${eftSchedule.id}/eft-transactions`}
                                        className="text-blue-600 hover:text-blue-900"
                                    >
                                        Edit
                                    </a>
                                </td>
                            </tr>
                        )
                    }
                )
            } else {
                return (
                    <tr>
                        <td
                            colSpan={9}
                            className="text-center py-4 text-gray-500 italic"
                        >
                            No EFTs found
                        </td>
                    </tr>
                )
            }
        } else if (getEftsSchedulesQuery.isLoading) {
            return (
                <tr>
                    <td
                        colSpan={9}
                        className="text-center py-4 text-gray-500 italic"
                    >
                        <Loading height="8" width="8" />
                    </td>
                </tr>
            )
        } else if (getEftsSchedulesQuery.isError) {
            return (
                <tr>
                    <td
                        colSpan={9}
                        className="text-center font-medium py-4 text-red-500 italic"
                    >
                        Error loading EFTs
                    </td>
                </tr>
            )
        }
    }

    const total = getEftsSchedulesQuery?.data?.data?.total
    const from = getEftsSchedulesQuery?.data?.data?.from
    const to = getEftsSchedulesQuery?.data?.data?.to
    const next = getEftsSchedulesQuery.data?.data?.next_page_url
    const prev = getEftsSchedulesQuery.data?.data?.prev_page_url
    const links = getEftsSchedulesQuery?.data?.data?.links

    return (
        <div className="pt-10 pl-20 pr-20">
            {!previewEfts && (
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">
                                Efts
                            </h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Generate EFT files for invoices & commissions
                                (coming soon).
                            </p>
                        </div>
                        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            <button
                                onClick={() => setNewEftModalIsOpen(true)}
                                type="button"
                                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Generate EFTs
                            </button>
                        </div>
                    </div>
                    <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                            >
                                                Id
                                            </th>

                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                From Date
                                            </th>

                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                To Date
                                            </th>

                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Split Amount
                                            </th>

                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Paysafe Max Amount
                                            </th>

                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Paysafe Days Between
                                            </th>

                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                NBC Max Amount
                                            </th>

                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                NBC Days Between
                                            </th>

                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Eft Start Date
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="bg-white">
                                        {renderEftsSchedules()}
                                        <tr>
                                            <td colSpan={10}>
                                                <PaginationV2
                                                    pages={links}
                                                    from={from}
                                                    to={to}
                                                    total={total}
                                                    next={next}
                                                    prev={prev}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {newEftModalIsOpen && !previewEfts && (
                <NewEftModal setNewEftModalIsOpen={setNewEftModalIsOpen} />
            )}
        </div>
    )
}
