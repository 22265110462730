import React, { Ref, PropsWithChildren, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useSlate } from 'slate-react'
import { Text } from 'slate'
import { escape } from 'querystring'
import escapeHtml from 'escape-html'
import { jsx } from 'slate-hyperscript'

interface BaseProps {
    className: string
    [key: string]: unknown
}

export const Button = React.forwardRef(
    (
        {
            className,
            active,
            reversed,
            ...props
        }: PropsWithChildren<
            {
                active: boolean
                reversed: boolean
            } & BaseProps
        >,
        ref: Ref<HTMLSpanElement>
    ) => <span {...props} ref={ref} />
)

export const EditorValue = React.forwardRef(
    ({
        className,
        value,
        ...props
    }: PropsWithChildren<
        {
            value: any
        } & BaseProps
    >) => {
        const textLines = value.document.nodes
            .map((node: any) => node.text)
            .toArray()
            .join('\n')
        return (
            <div {...props}>
                <div>Slate's value as text</div>
                <div>{textLines}</div>
            </div>
        )
    }
)

export const Icon = React.forwardRef(
    (
        { className, ...props }: PropsWithChildren<BaseProps>,
        ref: Ref<HTMLSpanElement>
    ) => <span {...props} ref={ref} />
)

export const Instruction = React.forwardRef(
    (
        { className, ...props }: PropsWithChildren<BaseProps>,
        ref: Ref<HTMLDivElement>
    ) => <div {...props} ref={ref} />
)

export const Menu = React.forwardRef(
    (
        { className, ...props }: PropsWithChildren<BaseProps>,
        ref: Ref<HTMLDivElement>
    ) => (
        <div
            {...props}
            className="flex flex-wrap space-x-4"
            data-test-id="menu"
            ref={ref}
        />
    )
)

export const Portal = ({ children }: any) => {
    return typeof document === 'object'
        ? ReactDOM.createPortal(children, document.body)
        : null
}

export const Toolbar = React.forwardRef(
    (
        { className, ...props }: PropsWithChildren<BaseProps>,
        ref: Ref<HTMLDivElement>
    ) => {
        let editor = useSlate()
        return <Menu {...props} ref={ref} />
    }
)

export const serialize = (node: any) => {
    if (Text.isText(node)) {
        let string = escapeHtml(node.text)
        if (node.text === '') {
            string = '&nbsp;'
        }
        if (node.bold) {
            string = `<strong>${string}</strong>`
        }

        if (node.italic) {
            string = `<em>${string}</em>`
        }

        if (node.underline) {
            string = `<u>${string}</u>`
        }

        return string
    }

    const children = node.children.map((n: any) => serialize(n)).join('')

    const textAlignStyle = node.align ? `style="text-align: ${node.align}"` : ''

    switch (node.type) {
        case 'paragraph':
            return `<p ${textAlignStyle}>${children}</p>`
        case 'bulleted-list':
            return `<ul ${textAlignStyle}>${children}</ul>`
        case 'numbered-list':
            return `<ol ${textAlignStyle}>${children}</ol>`
        case 'list-item':
            return `<li ${textAlignStyle}>${children}</li>`
        case 'heading-one':
            return `<h1 ${textAlignStyle}>${children}</h1>`
        case 'heading-two':
            return `<h2 ${textAlignStyle}>${children}</h2>`
        case 'break-line':
            return `<br/>`
    }
}

export const deserialize = (el: any, markAttributes = {}) => {
    if (el.nodeType === Node.TEXT_NODE) {
        if (el.textContent === '\n') {
            return null
        }
        return jsx('text', markAttributes, el.textContent)
    } else if (el.nodeType !== Node.ELEMENT_NODE) {
        return null
    }

    const nodeAttributes: any = { ...markAttributes }

    // define attributes for text nodes
    switch (el.nodeName) {
        case 'STRONG':
            nodeAttributes.bold = true
            break
        case 'EM':
            nodeAttributes.italic = true
            break
        case 'U':
            nodeAttributes.underline = true
            break
    }

    const children: any = Array.from(el.childNodes)
        .map((node) => deserialize(node, nodeAttributes))
        .flat()

    if (children.length === 0) {
        children.push(jsx('text', nodeAttributes, ''))
    }

    let align = el.getAttribute('style')
    let alignObj = align
        ? { align: align.split(':')[1].trim('').replace(';', '') }
        : {}

    switch (el.nodeName) {
        case 'BODY':
            return jsx('fragment', {}, children)
        case 'P':
            return jsx('element', { type: 'paragraph', ...alignObj }, children)
        case 'A':
            return jsx(
                'element',
                { type: 'link', url: el.getAttribute('href') },
                children
            )
        case 'H1':
            return jsx(
                'element',
                { type: 'heading-one', ...alignObj },
                children
            )
        case 'H2':
            return jsx(
                'element',
                { type: 'heading-two', ...alignObj },
                children
            )
        case 'OL':
            return jsx(
                'element',
                { type: 'numbered-list', ...alignObj },
                children
            )
        case 'UL':
            return jsx(
                'element',
                { type: 'bulleted-list', ...alignObj },
                children
            )
        case 'LI':
            return jsx('element', { type: 'list-item', ...alignObj }, children)
        case 'BR':
            return jsx('element', { type: 'break-line', ...alignObj }, children)

        default:
            return children
    }
}
