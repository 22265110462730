import { BaseRepository } from './BaseRepository'

export class EftsRepository extends BaseRepository {
    getEftSchedules = async (page: string | 1 | null) => {
        let url = `/api/v2/eft-schedules?page=${page}`

        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.EftSchedulesPaginated>(url)
    }

    previewEfts = async (
        from: string,
        to: string,
        splitAmount: number,
        paysafeMax: number,
        paysafeDaysBetween: number,
        nbcMax: number,
        nbcDaysBetween: number,
        eftStartDate: string
    ) => {
        let url = `/api/v2/eft-schedules`

        let inputs = {
            from_date: from,
            to_date: to,
            split_amount: splitAmount,
            paysafe_max: paysafeMax,
            paysafe_days_between: paysafeDaysBetween,
            nbc_max: nbcMax,
            nbc_days_between: nbcDaysBetween,
            eft_start_date: eftStartDate,
        }
        return await super.getHttpClientInstance().post(url, inputs)
    }

    getEftTransactions = async (scheduleId: string) => {
        let url = `/api/v2/eft-schedules/${scheduleId}/eft-transactions`
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.EftTransactionsObject>(url)
    }

    updateEftTransaction = async (
        scheduleId: number,
        transactionId: number,
        eftTransactionDate: string,
        providerId: number
    ) => {
        let url = `/api/v2/eft-schedules/${scheduleId}/eft-transactions/${transactionId}`

        let inputs = {
            eft_transaction_date: eftTransactionDate,
            provider_id: providerId,
        }

        return await super.getHttpClientInstance().put(url, inputs)
    }

    generateEfts = async (scheduleId: string) => {
        //    Route::resource('eft-schedules.efts2', EftsController::class);
        let url = `/api/v2/eft-schedules/${scheduleId}/efts2`
        return await super.getHttpClientInstance().post(url)
    }

    getEfts = async (scheduleId: string) => {
        let url = `/api/v2/eft-schedules/${scheduleId}/efts2`
        return await super
            .getHttpClientInstance()
            .get<Array<Paywell.API.V2.Eft>>(url)
    }

    downloadEft = async (eftId: number) => {
        let url = `/api/v2/efts2/${eftId}/download`
        const response = await super.getHttpClientInstance().get(url, {
            responseType: 'blob',
        })

        const blob = new Blob([response.data], { type: response.data.type })

        const contentDisposition =
            (response.headers as any)['content-disposition'] || ''
        const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/)
        const filename = filenameMatch
            ? filenameMatch[1]
            : 'default-filename.ext'

        // Create a link element to trigger the download
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
    }
}
