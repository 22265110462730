import { BaseRepository } from './BaseRepository'

export class LineItemsRepository extends BaseRepository {
    getLineItems = async (id: string) => {
        return await super
            .getHttpClientInstance()
            .get<[Paywell.API.V2.LineItems]>(`/api/v2/invoices/${id}/lineitems`)
    }

    addLineItem = async (
        invoice_id: any,
        product_id: number,
        tax_id: number,
        quantity: number,
        cost_per_unit: number
    ) => {
        return await super
            .getHttpClientInstance()
            .post<Paywell.API.V2.LineItems>(
                `api/v2/invoices/${invoice_id}/lineitems  `,
                {
                    product_id: product_id,
                    tax_id: tax_id,
                    quantity: quantity,
                    cost_per_unit: cost_per_unit,
                }
            )
    }

    updateLineItem = async (
        id: number,
        invoice_id: number,
        product_id: number,
        tax_id: number,
        quantity: number,
        cost_per_unit: string | number,
        description: string
    ) => {
        return await super
            .getHttpClientInstance()
            .patch<Paywell.API.V2.LineItems>(
                `api/v2/invoices/${invoice_id}/lineitems/${id}  `,
                {
                    product_id: product_id,
                    tax_id: tax_id,
                    quantity: quantity,
                    cost_per_unit: cost_per_unit,
                    description: description,
                }
            )
    }
    deleteLineItem = async (id: number, invoice_id: number) => {
        return await super
            .getHttpClientInstance()
            .delete(`api/v2/invoices/${invoice_id}/lineitems/${id}  `)
    }
}
