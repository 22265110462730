import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { setOnboardingType } from '../redux/state-slices/OnboardingTypeSlice'
import { useNavigate } from 'react-router-dom'
import { setSteps } from '../redux/state-slices/StepSlice'

const onlineSteps = [
    {
        id: '01',
        name: 'Identity Info',
        href: '#',
        status: 'current',
        url: 'etransfer/identity-info',
    },
    {
        id: '02',
        name: 'Business Info',
        href: '#',
        status: 'upcoming',
        url: 'etransfer/business-info',
    },
    {
        id: '03',
        name: 'Banking Info',
        href: '#',
        status: 'upcoming',
        url: 'etransfer/banking-info',
    },
    {
        id: '04',
        name: 'Pricing',
        href: '#',
        status: 'upcoming',
        url: 'etransfer/pricing',
    },
]
const terminalSteps = [
    {
        id: '01',
        name: 'Personal Info',
        href: '#',
        status: 'current',
        url: 'debit/identity-info',
    },
    {
        id: '02',
        name: 'Business Info',
        href: '#',
        status: 'upcoming',
        url: 'debit/business-info',
    },
    {
        id: '03',
        name: 'Banking Info',
        href: '#',
        status: 'upcoming',
        url: 'debit/banking-info',
    },
    {
        id: '04',
        name: 'Pricing',
        href: '#',
        status: 'upcoming',
        url: 'debit/pricing',
    },
    {
        id: '05',
        name: 'Contract',
        href: '#',
        status: 'upcoming',
        url: 'debit/contract',
    },
]
export default function OnboardingPathChooser() {
    const dispatch = useAppDispatch()
    const branding = useAppSelector((state) => state.branding)
    const onboardingType = useAppSelector((state) => state.onboarding_type)
    const navigate = useNavigate()
    useEffect(() => {
        if (onboardingType === 'etransfer') {
            navigate('/onboarding/etransfer')
        } else if (onboardingType === 'debit') {
            navigate('/onboarding/debit')
        }
    }, [onboardingType])

    const paths = [
        {
            name: 'Debit Processing',
            onboarding_type: 'debit',
            description:
                "We offer in-store and online debit solutions for merchants requiring highly customized solutions meeting the needs of Canada's diverse communities.",
            imageUrl: '/debit.png',
            disabled: false,
        },
        {
            name: 'E-Transfer Automation',
            onboarding_type: 'etransfer',
            description: `You would be best served speaking to one of our consultants to ensure we would get you the best service for your industry. Please feel free to contact us at ${branding.contact_email}.`,
            imageUrl: '/etransfer.png',
            disabled: true,
        },
        {
            name: 'Invoicing/Accounts Receivable Automation',
            onboarding_type: 'etransfer',
            description: `You would be best served speaking to one of our consultants to ensure we would get you the best service for your industry. Please feel free to contact us at ${branding.contact_email}.`,
            imageUrl: '/accounts-receivable-invoicing.png',
            disabled: true,
        },
        {
            name: 'Credit Card Processing',
            onboarding_type: 'etransfer',
            description: `You would be best served speaking to one of our consultants to ensure we would get you the best service for your industry. Please feel free to contact us at ${branding.contact_email}.`,
            imageUrl: '/credit.png',
            disabled: true,
        },
    ]
    const handleActionClick = (onboardingType: string) => {
        dispatch(setOnboardingType(onboardingType))
        if (onboardingType === 'debit') {
            dispatch(setSteps(terminalSteps))
        } else {
            dispatch(setSteps(onlineSteps))
        }
    }

    return (
        <div className="bg-gray py-12">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Welcome to onboarding, let's get started.
                    </h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        Please select the path that best describes your current
                        situation.
                    </p>
                </div>
                <ul
                    role="list"
                    className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none "
                >
                    {paths.map((path) => (
                        <li
                            onClick={() => {
                                if (!path.disabled) {
                                    handleActionClick(path.onboarding_type)
                                }
                            }}
                            key={path.name}
                            className={``}
                        >
                            <img
                                className={`aspect-[3/2] w-full rounded-2xl object-cover ${
                                    path.disabled
                                        ? 'opacity-50'
                                        : 'hover:cursor-pointer hover:opacity-75'
                                }`}
                                src={path.imageUrl}
                                alt=""
                            />
                            <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                                {path.name}
                            </h3>
                            <p className="text-base leading-7 text-gray-600">
                                {path.description}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
