import { getReadableContactType } from '../utils/UserUtils'
import React from 'react'
import { ContactRepository } from '../api/ContactRepository'
import { useQuery } from 'react-query'
import { setError } from '../redux/state-slices/GlobalNotificationSlice'
import { useAppDispatch } from '../redux/hooks'
import Loading from '../shared-components/Loading'

import { useNavigate } from 'react-router-dom'

interface ContactProps {
    user: Paywell.API.V2.User | null
    isAdmin?: boolean
}
export default function Contactinfo({ user, isAdmin }: ContactProps) {
    const contactRepository = new ContactRepository()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const fetchContacts = async () => {
        const contacts = await contactRepository.getUsersContacts(user?.id || 0)
        return contacts.data
    }

    const contactsQuery = useQuery(['user-contacts'], () => fetchContacts(), {
        onSuccess: (data) => {},
        onError: (error) => {
            dispatch(setError(['Error retrieving contacts. Please try again']))
        },
        refetchOnWindowFocus: false,
    })

    const isLoading = contactsQuery.isLoading || contactsQuery.isFetching
    const contacts = contactsQuery.data

    return (
        <div className={`p-10`}>
            {isAdmin && (
                <div className="mb-2 flex justify-end">
                    <button
                        onClick={() =>
                            navigate('/users/' + user?.id + '/contacts/edit')
                        }
                        type="button"
                        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Edit Page
                    </button>
                </div>
            )}
            <div className={'border border-gray-100 rounded-md p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Merchant Contact Information
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                        View and edit merchant contact information.
                    </p>
                </div>

                {isLoading && (
                    <span
                        className={
                            'w-full flex justify-center text-gray-500 italic'
                        }
                    >
                        <Loading height={'8'} width={'8'} />
                    </span>
                )}
                {contacts && contacts?.length === 0 && (
                    <span
                        className={
                            'w-full flex justify-center text-gray-500 italic'
                        }
                    >
                        No contacts found
                    </span>
                )}
                {contactsQuery.isSuccess &&
                    !isLoading &&
                    contacts &&
                    contacts?.length > 0 && (
                        <ul
                            className={`grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 xl:gap-x-8 mt-4`}
                        >
                            {contacts.map((contact, index) => (
                                <li
                                    key={contact.id || index}
                                    className="overflow-hidden rounded-xl border border-gray-200"
                                >
                                    <div className="flex justify-between items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                                        <div className="text-sm font-medium leading-6 text-gray-900">
                                            {getReadableContactType(
                                                contact.contact_type_id
                                            )}
                                        </div>
                                        <a
                                            href={
                                                '/legacy/contacts/' + contact.id
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            className={
                                                'text-sm font-medium leading-6 text-blue-500'
                                            }
                                        >
                                            View Contact{' '}
                                        </a>
                                    </div>
                                    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                                        <div className="flex justify-between gap-x-4 py-3">
                                            <dt className="text-gray-500">
                                                First Name
                                            </dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="font-medium text-gray-900">
                                                    {contact.first_name}
                                                </div>
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-3">
                                            <dt className="text-gray-500">
                                                Last Name
                                            </dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="font-medium text-gray-900">
                                                    {contact.last_name}
                                                </div>
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-3">
                                            <dt className="text-gray-500">
                                                Address
                                            </dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="font-medium text-gray-900">
                                                    {contact.address}
                                                </div>
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-3">
                                            <dt className="text-gray-500">
                                                Drivers License (Front)
                                            </dt>
                                            <dd className="flex items-start gap-x-2">
                                                {contact.drivers_licence_front_url ? (
                                                    <a
                                                        href={
                                                            contact.drivers_licence_front_url ||
                                                            ''
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="text-blue-500"
                                                    >
                                                        View
                                                    </a>
                                                ) : (
                                                    ''
                                                )}
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-3">
                                            <dt className="text-gray-500">
                                                Drivers License (Back)
                                            </dt>
                                            <dd className="flex items-start gap-x-2">
                                                {contact.drivers_licence_back_url ? (
                                                    <a
                                                        href={
                                                            contact.drivers_licence_back_url ||
                                                            ''
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="text-blue-500"
                                                    >
                                                        View
                                                    </a>
                                                ) : (
                                                    ''
                                                )}
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                            ))}
                        </ul>
                    )}
            </div>
        </div>
    )
}
