import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { TransactionsRepository } from '../api/TransactionsRepository'
import { useQuery } from 'react-query'
import NumberFormatDollars from '../utils/NumberFormatDollars'
import { formatDate } from '../utils/StringUtils'
import {
    getHumanReadableType,
    getHumanReadableStatus,
} from '../utils/TransactionHelper'
import Loading from '../shared-components/Loading'
import { getBackgroundClassName } from '../utils/TransactionHelper'
import Error from '../shared-components/Error'
export default function TransactionView() {
    const { id: transactionId } = useParams()
    const transactionRepository = new TransactionsRepository()
    const navigate = useNavigate()
    const fetchTransaction = async (transactionId: string) => {
        return (await transactionRepository.getTransaction(transactionId)).data
    }

    const transactionQuery = useQuery(
        ['transaction', transactionId],
        () => {
            if (transactionId) {
                return fetchTransaction(transactionId)
            }
        },
        {
            onSuccess: () => {},
            onError: () => {},
            refetchOnWindowFocus: false,
        }
    )

    useEffect(() => {
        if (!transactionId) {
            navigate('/')
        }
    }, [transactionId])

    const transaction = transactionQuery.data

    if (transactionQuery.isLoading || transactionQuery.isFetching) {
        return (
            <div className="flex h-screen">
                <div className="m-auto">
                    <Loading width="8" height="8" />
                </div>
            </div>
        )
    }

    if (transactionQuery.isError) {
        return (
            <div className="flex h-screen justify-center mt-4">
                <div className="w-full p-4">
                    <Error
                        title="There was an retrieving your transaction. Please try again."
                        message="Please reach out to one of our representatives if this error persists"
                    />
                </div>
            </div>
        )
    }
    if (transaction) {
        return (
            <div className="flex justify-center mt-4 p-4">
                <div className="lg:col-start-3 lg:row-end-1">
                    <h2 className="sr-only">Transaction Information</h2>
                    <div className="rounded-lg bg-white shadow-sm ring-1 ring-gray-900/5">
                        <dl className="flex flex-wrap">
                            <div className="flex-auto pl-6 pt-6">
                                <dt className="text-sm font-semibold leading-6 text-gray-900">
                                    Amount
                                </dt>
                                <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
                                    {NumberFormatDollars(transaction.amount)}
                                </dd>
                            </div>
                            <div className="flex-none self-end px-6 pt-4">
                                <dt className="sr-only">Status</dt>
                                <dd
                                    className={`inline-flex items-center rounded-md ${getBackgroundClassName(
                                        transaction.status_code
                                    )} px-2 py-1 text-xs font-medium text-black ring-1 ring-inset`}
                                >
                                    {getHumanReadableStatus(
                                        transaction.status_code
                                    )}
                                </dd>
                            </div>
                            <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                                <dt className="flex-none">
                                    <span className="sr-only">Sender</span>
                                    <svg
                                        className="h-6 w-5 text-gray-400"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-5.5-2.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM10 12a5.99 5.99 0 00-4.793 2.39A6.483 6.483 0 0010 16.5a6.483 6.483 0 004.793-2.11A5.99 5.99 0 0010 12z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </dt>
                                <dd className="text-sm font-medium leading-6 text-gray-900">
                                    {transaction.name_on_card}
                                </dd>
                            </div>

                            <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                <dt className="flex-none">
                                    <span className="sr-only">Due date</span>
                                    <svg
                                        className="h-6 w-5 text-gray-400"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path d="M5.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H6a.75.75 0 01-.75-.75V12zM6 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H6zM7.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H8a.75.75 0 01-.75-.75V12zM8 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H8zM9.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V10zM10 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H10zM9.25 14a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V14zM12 9.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V10a.75.75 0 00-.75-.75H12zM11.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H12a.75.75 0 01-.75-.75V12zM12 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H12zM13.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H14a.75.75 0 01-.75-.75V10zM14 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H14z" />
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </dt>
                                <dd className="text-sm leading-6 text-gray-500">
                                    <time dateTime="2023-01-31">
                                        {formatDate(transaction.process_date)}
                                    </time>
                                </dd>
                            </div>

                            <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                <dt className="flex-none">
                                    <span className="sr-only">Status</span>
                                    <svg
                                        className="h-6 w-5 text-gray-400"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M2.5 4A1.5 1.5 0 001 5.5V6h18v-.5A1.5 1.5 0 0017.5 4h-15zM19 8.5H1v6A1.5 1.5 0 002.5 16h15a1.5 1.5 0 001.5-1.5v-6zM3 13.25a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zm4.75-.75a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5h-3.5z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </dt>
                                <dd className="text-sm leading-6 text-gray-500">
                                    Payment Method:{' '}
                                    {getHumanReadableType(transaction.type)}
                                </dd>
                            </div>

                            {transaction.card_number && (
                                <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                    <dt className="flex-none">
                                        <span className="sr-only">
                                            Card Number
                                        </span>
                                    </dt>
                                    <dd className="text-sm leading-6 text-gray-500">
                                        Card Number:{' '}
                                        {transaction.card_number || 'N/A'}
                                    </dd>
                                </div>
                            )}
                            <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                <dt className="flex-none">
                                    <span className="sr-only">
                                        Transaction Code
                                    </span>
                                </dt>
                                <dd className="text-sm leading-6 text-gray-500">
                                    Transaction Code:{' '}
                                    {transaction.transaction_code || 'N/A'}
                                </dd>
                            </div>

                            {transaction.description && (
                                <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                    <dt className="flex-none">
                                        <span className="sr-only">
                                            Transaction Summary
                                        </span>
                                    </dt>
                                    <dd className="text-sm leading-6 text-gray-500">
                                        Transaction Summary:{' '}
                                        {transaction.description}
                                    </dd>
                                </div>
                            )}
                            {transaction.response_text && (
                                <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                    <dt className="flex-none">
                                        <span className="sr-only">
                                            Transaction Description
                                        </span>
                                    </dt>
                                    <dd className="text-sm leading-6 text-gray-500">
                                        Transaction Description:{' '}
                                        {transaction.response_text}
                                    </dd>
                                </div>
                            )}
                        </dl>
                        <div className="mt-6 border-t border-gray-900/5 px-6 py-6 flex justify-end">
                            <button
                                onClick={() => navigate(-1)}
                                type="button"
                                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return null
    }
}
