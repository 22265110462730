import React, {useContext, useEffect, useState} from 'react'
import './HostedCheckout.scss'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {HostedCheckoutRepository} from '../api/HostedCheckoutRepository'
import {SentryUtils} from '../utils/SentryUtils'
import Error from '../shared-components/Error'
import Loading from '../shared-components/Loading'
import CancelModal from './CancelModal'
import ManualInstructions from "./ManualInstructions";
import openUrlInNewTab from "../utils/LinkUtils";
import PathChooser from "./PathChooser";
import FastTransactionPending from "./FastTransactionPending";

const HostedCheckout = () => {

  const {uuid} = useParams()
  const hostedCheckoutRepository = new HostedCheckoutRepository()

  const [displayManualInstructions, setDisplayManualInstructions] = useState<boolean>(false)
  const [fastTransactionPending, setFastTransactionPending] = useState<boolean>(false)

  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false)

  const hostedCheckoutResult = useQuery(
    ['hostedCheckout', uuid],
    async () => {
      return (
        await hostedCheckoutRepository.get(
          SentryUtils.expect<string>(uuid)
        )
      ).data
    },
    {refetchInterval: 5000, refetchIntervalInBackground: true}
  )

  const cancelClicked = () => {
    setCancelModalOpen(true)
  }

  const returnToMerchant = () => {
    if (hostedCheckoutResult.data) {
      window.location.replace(hostedCheckoutResult.data.cancel_url)
    }
  }

  useEffect(() => {
    if (
      hostedCheckoutResult.data?.weborder.payment_status == 'approved' ||
      hostedCheckoutResult.data?.weborder.payment_status == 'wrong_amount'
    ) {
      window.location.replace(hostedCheckoutResult.data.success_url)
      return
    }

    if (hostedCheckoutResult.data?.weborder.payment_status == 'expired') {
      window.location.replace(hostedCheckoutResult.data.cancel_url)
      return
    }

  }, [hostedCheckoutResult.data?.weborder.payment_status])


  const manualInstructionsClicked = (e: any) => {
    setFastTransactionPending(false)
    setDisplayManualInstructions(true)
    e.stopPropagation()
  }

  const hideManualInstructions = () => {
    setDisplayManualInstructions(false)
  }

  const fastClicked = (e: any) => {
    if (hostedCheckoutResult.data?.weborder.interac_url === undefined) {
      return;
    }
    openUrlInNewTab(hostedCheckoutResult.data?.weborder.interac_url)
    setFastTransactionPending(true)
    e.stopPropagation()
  }

  return (
    <>
      {hostedCheckoutResult.isLoading ? <Loading height='8' width='8'/> : <></>}
      {hostedCheckoutResult.isError ? (
        <Error
          title="An error has occurred"
          message="Error loading, please refresh the page."
        />
      ) : (
        <></>
      )}
      {hostedCheckoutResult.data ?
        (
          (displayManualInstructions || hostedCheckoutResult.data?.weborder.interac_url === null) ?
            <ManualInstructions
              hostedCheckoutData={hostedCheckoutResult.data}
              returnToMerchantFn={returnToMerchant}
              hideManualInstructions={hideManualInstructions}
            /> : (
              fastTransactionPending ? <FastTransactionPending manualInstructionsClickedFn={manualInstructionsClicked}/> : (
                <PathChooser fastClickedFn={fastClicked} manualInstructionsClickedFn={manualInstructionsClicked}/>
              )
            )
        ) : (
          <></>
        )}
      <CancelModal
        isOpen={cancelModalOpen}
        closeModal={() => {
          setCancelModalOpen(false)
        }}
        cancelTransaction={returnToMerchant}
        returnToMerchant={returnToMerchant}
      />
    </>
  )
}

export default HostedCheckout
