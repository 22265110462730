import {
    ArrowDownIcon,
    ArrowUpIcon,
    TrashIcon,
    XMarkIcon,
} from '@heroicons/react/20/solid'
import { useMutation, useQuery } from 'react-query'
import { Link, useSearchParams } from 'react-router-dom'
import { InvoicesRepository } from '../../api/InvoicesRepository'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
    resetConfirm,
    setConfirm,
    setError,
    setLoad,
    setSuccess,
} from '../../redux/state-slices/GlobalNotificationSlice'
import ErrorV2 from '../../shared-components/ErrorV2'
import PaginationV2 from '../components/PaginationV2'
import SkeletonLoader from '../components/SkeletonLoader'
import NumberFormatDollars from '../../utils/NumberFormatDollars'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { testParagraphRegex } from '../../utils/RegexHelper'
import useDebounce from '../../utils/useDebounce'

export default function Invoices() {
    const dispatch = useAppDispatch()
    const authUser = useAppSelector((state) => state.auth_user)
    const isAdmin = authUser?.user_type_id === 3
    const [searchText, setSearchText] = useState('')
    const searchTextDebounce = useDebounce(searchText, 500)
    const [searchParams] = useSearchParams()
    const page = searchParams.get('page') ? searchParams.get('page') : 1
    const [sortOrder, setSortOrder] = useState('desc')
    const [sortColumn, setSortColumn] = useState('created_at')
    const [checkedInvoices, setCheckedInvoices] = useState<string[]>([])
    const [selectedAction, setSelectedAction] = useState<
        'eft' | 'e-transfer' | 'cheque'
    >('eft')
    const invoicesRepository = new InvoicesRepository()

    const invoiceQuery = useQuery(
        ['invoices', page, searchTextDebounce, sortColumn, sortOrder],
        () =>
            invoicesRepository.getInvoices(
                page,
                searchTextDebounce,
                sortColumn,
                sortOrder
            )
    )

    const total = invoiceQuery.data?.data?.total
    const from = invoiceQuery.data?.data?.from
    const to = invoiceQuery.data?.data?.to
    const next = invoiceQuery.data?.data?.next_page_url
    const prev = invoiceQuery.data?.data?.prev_page_url
    const invoices = invoiceQuery.data?.data?.data
    const invoiceLinks = invoiceQuery.data?.data?.links

    const deleteInvoiceMutation = useMutation({
        mutationKey: 'deleteInvoice',
        mutationFn: (id: string) => invoicesRepository.deleteInvoice(id),
        onError: (data) => {
            dispatch(
                setError([
                    'An error has occurred while attempting to delete. Please try again',
                ])
            )
        },

        onSuccess: (data) => {
            invoiceQuery.refetch()
            dispatch(resetConfirm(''))
            dispatch(setLoad(false))
            dispatch(setSuccess(['You have successfully deleted the invoice.']))
        },
    })

    const markAsPaidMutation = useMutation({
        mutationKey: 'markAsPaid',
        mutationFn: (invoiceUuids: string[]) =>
            invoicesRepository.markInvoicesPaid(invoiceUuids, selectedAction),
        onError: (data) => {
            dispatch(setError(['An error has occurred. Please try again']))
        },

        onSuccess: (data) => {
            invoiceQuery.refetch()
            setCheckedInvoices([])

            dispatch(
                setSuccess([
                    'You have successfully marked the invoices as paid.',
                ])
            )
        },
    })

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (testParagraphRegex(e.target.value) || val === '') {
            setSearchText(e.target.value)
        }
    }

    const handleColumnClick = (columnName: string) => {
        setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
        setSortColumn(columnName)
    }

    const getCurrentSortIcon = (columnName: string) => {
        if (sortColumn === columnName) {
            if (sortOrder === 'asc') {
                return <ArrowUpIcon className="h-5 w-5 text-blue-600" />
            } else {
                return <ArrowDownIcon className="h-5 w-5 text-blue-600" />
            }
        }
    }

    const clearSortAndOrder = () => {
        setSortOrder('desc')
        setSortColumn('created_at')
    }

    const handleCheck = (id: string) => {
        let checkedInvoicesCopy = [...checkedInvoices]
        if (checkedInvoicesCopy.includes(id.toString())) {
            checkedInvoicesCopy = checkedInvoicesCopy.filter(
                (invoiceId) => invoiceId !== id.toString()
            )
        } else {
            checkedInvoicesCopy.push(id.toString())
        }
        setCheckedInvoices(checkedInvoicesCopy)
    }

    const handleSelectAll = () => {
        let checkedInvoicesCopy = [...checkedInvoices]
        if (checkedInvoicesCopy.length === invoices?.length) {
            checkedInvoicesCopy = []
        } else {
            checkedInvoicesCopy = invoices?.map((invoice) => invoice.uuid)!
        }
        setCheckedInvoices(checkedInvoicesCopy)
    }

    const isChecked = (uuid: string) => {
        if (checkedInvoices.includes(uuid)) {
            return true
        }
        return false
    }

    const handleMarkAsPaid = () => {
        if (markAsPaidMutation.isLoading) {
            return
        }

        markAsPaidMutation.mutate(checkedInvoices)
    }

    const handleSelectedAction = (type: 'eft' | 'cheque' | 'e-transfer') => {
        setSelectedAction(type)
    }

    const selectAllisChecked = () => {
        if (invoices?.length === 0) return false
        if (checkedInvoices.length === invoices?.length) {
            return true
        }
        return false
    }

    return (
        <div className="mt-4">
            <div className="flex flex-1 justify-start mb-4">
                <div className="flex w-full max-w-lg lg:max-w-lg items-center">
                    <label htmlFor="search" className="sr-only">
                        Search
                    </label>

                    <div className="relative text-gray-400 focus-within:text-gray-600 w-2/5">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <MagnifyingGlassIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                            />
                        </div>
                        <input
                            id="search"
                            className="block w-full rounded-md border-1 focus:border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Search"
                            type="search"
                            name="search"
                            value={searchText}
                            onChange={handleSearch}
                        />
                    </div>
                    {invoiceQuery.isLoading && (
                        <div className="ml-2 pointer-events-none">
                            <svg
                                aria-hidden="true"
                                className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                />
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    )}
                </div>
            </div>

            <div className="flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    {invoiceQuery.isError && (
                        <div>
                            <ErrorV2
                                title="An error has occured"
                                message="An error has occurred while loading your invoices. Please try refreshing, if this keeps happening contact support."
                            />
                        </div>
                    )}
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        {isAdmin && (
                                            <th
                                                onClick={() =>
                                                    handleColumnClick('id')
                                                }
                                                scope="col"
                                                className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 cursor-pointer hover:text-blue-600"
                                            >
                                                <span className="flex">
                                                    Invoice ID
                                                    {getCurrentSortIcon('id')}
                                                </span>
                                            </th>
                                        )}
                                        <th
                                            onClick={() =>
                                                handleColumnClick(
                                                    'invoice_number'
                                                )
                                            }
                                            scope="col"
                                            className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 cursor-pointer hover:text-blue-600"
                                        >
                                            <span className="flex">
                                                Invoice No.
                                                {getCurrentSortIcon(
                                                    'invoice_number'
                                                )}
                                            </span>
                                        </th>

                                        <th
                                            onClick={() =>
                                                handleColumnClick(
                                                    'billing_name'
                                                )
                                            }
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer hover:text-blue-600"
                                        >
                                            <span className="flex">
                                                Customer
                                                {getCurrentSortIcon(
                                                    'billing_name'
                                                )}
                                            </span>
                                        </th>

                                        <th
                                            onClick={() =>
                                                handleColumnClick('due_date')
                                            }
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer hover:text-blue-600"
                                        >
                                            <span className="flex">
                                                Due Date
                                                {getCurrentSortIcon('due_date')}
                                            </span>
                                        </th>
                                        <th
                                            onClick={() =>
                                                handleColumnClick(
                                                    'invoice_subtotal'
                                                )
                                            }
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer hover:text-blue-600"
                                        >
                                            <span className="flex">
                                                Subtotal
                                                {getCurrentSortIcon(
                                                    'invoice_subtotal'
                                                )}
                                            </span>
                                        </th>
                                        <th
                                            onClick={() =>
                                                handleColumnClick(
                                                    'invoice_tax_total'
                                                )
                                            }
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer hover:text-blue-600"
                                        >
                                            <span className="flex">
                                                Tax total
                                                {getCurrentSortIcon(
                                                    'invoice_tax_total'
                                                )}
                                            </span>
                                        </th>
                                        <th
                                            onClick={() =>
                                                handleColumnClick(
                                                    'invoice_grand_total'
                                                )
                                            }
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer hover:text-blue-600"
                                        >
                                            <span className="flex">
                                                Grand total
                                                {getCurrentSortIcon(
                                                    'invoice_grand_total'
                                                )}
                                            </span>
                                        </th>
                                        <th
                                            onClick={() =>
                                                handleColumnClick(
                                                    'outstanding_balance'
                                                )
                                            }
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer hover:text-blue-600"
                                        >
                                            <span className="flex">
                                                Balance
                                                {getCurrentSortIcon(
                                                    'outstanding_balance'
                                                )}
                                            </span>
                                        </th>
                                        <th
                                            scope="col"
                                            className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6 cursor-pointer hover:text-blue-600"
                                        >
                                            <span className="sr-only">
                                                Edit
                                            </span>
                                        </th>
                                        <th
                                            scope="col"
                                            className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6 cursor-pointer hover:text-blue-600"
                                        ></th>
                                        <th
                                            scope="col"
                                            className="relative whitespace-nowrap py-3.5 pr-4 sm:pr-6 cursor-pointer hover:text-blue-600"
                                        >
                                            {sortColumn !== 'created_at' && (
                                                <XMarkIcon
                                                    className="h-5 w-5 cursor-pointer"
                                                    onClick={clearSortAndOrder}
                                                />
                                            )}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {(invoiceQuery.isLoading ||
                                        invoiceQuery.isError) && (
                                        <SkeletonLoader
                                            rows={25}
                                            columns={9}
                                            isError={invoiceQuery.isError}
                                        />
                                    )}
                                    {invoices &&
                                        invoices.map((invoice) => (
                                            <tr key={invoice.id}>
                                                {isAdmin && (
                                                    <td className="space-x-2 whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                        <input
                                                            onChange={() =>
                                                                handleCheck(
                                                                    invoice.uuid
                                                                )
                                                            }
                                                            checked={isChecked(
                                                                invoice.uuid
                                                            )}
                                                            disabled={
                                                                markAsPaidMutation.isLoading
                                                            }
                                                            type="checkbox"
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 disabled:opacity-50"
                                                        />
                                                        <Link
                                                            to={`/invoicing/invoices/${invoice.uuid}`}
                                                        >
                                                            {invoice.id}
                                                        </Link>
                                                    </td>
                                                )}
                                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                    <Link
                                                        to={`/invoicing/invoices/${invoice.uuid}`}
                                                    >
                                                        {invoice.invoice_number}
                                                    </Link>
                                                </td>
                                                <td className="font-bold py-2 pl-4 pr-3 text-sm text-gray-500">
                                                    <Link
                                                        to={`/invoicing/customers/${invoice.customer_id}`}
                                                    >
                                                        {invoice.billing_name}
                                                    </Link>
                                                </td>
                                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                                    {invoice.due_date}
                                                </td>
                                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                                    {NumberFormatDollars(
                                                        invoice.invoice_subtotal
                                                    )}
                                                </td>
                                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                                    {NumberFormatDollars(
                                                        invoice.invoice_tax_total
                                                    )}
                                                </td>
                                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                                    {NumberFormatDollars(
                                                        invoice.invoice_grand_total
                                                    )}
                                                </td>
                                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 font-bold">
                                                    {NumberFormatDollars(
                                                        invoice.outstanding_balance
                                                    )}
                                                </td>
                                                <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <a
                                                        href={`/invoicing/invoices/${invoice.uuid}`}
                                                        target={'_blank'}
                                                        rel="noreferrer"
                                                        className="text-blue-600 hover:text-blue-900"
                                                    >
                                                        View
                                                    </a>
                                                </td>
                                                <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <a
                                                        href={`/invoicing/invoices/edit/${invoice.uuid}`}
                                                        target={'_blank'}
                                                        rel="noreferrer"
                                                        className="text-blue-600 hover:text-blue-900"
                                                    >
                                                        Edit
                                                        <span className="sr-only"></span>
                                                    </a>
                                                </td>
                                                <td>
                                                    <TrashIcon
                                                        className="fill-red-500 h-4 w-4 cursor-pointer"
                                                        onClick={(e) =>
                                                            dispatch(
                                                                setConfirm({
                                                                    title: 'Confirm Invoice Deletion',
                                                                    message:
                                                                        'Are you sure you would like to delete this invoice? Doing this cannot be undone!',
                                                                    onClick:
                                                                        () => {
                                                                            deleteInvoiceMutation.mutate(
                                                                                invoice.uuid
                                                                            )
                                                                            dispatch(
                                                                                setLoad(
                                                                                    true
                                                                                )
                                                                            )
                                                                        },
                                                                })
                                                            )
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    {isAdmin && (
                                        <tr>
                                            <td
                                                colSpan={11}
                                                className="relative whitespace-nowrap py-4 pl-6 pr-8 text-left text-sm font-medium sm:pr-6"
                                            >
                                                <div className="flex items-center space-x-2">
                                                    <input
                                                        checked={selectAllisChecked()}
                                                        disabled={
                                                            markAsPaidMutation.isLoading
                                                        }
                                                        onChange={
                                                            handleSelectAll
                                                        }
                                                        type="checkbox"
                                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 disabled:opacity-50"
                                                    />
                                                    <span className="">
                                                        Select all
                                                    </span>
                                                    {checkedInvoices.length >
                                                        0 && (
                                                        <>
                                                            {' '}
                                                            <select
                                                                onChange={(e) =>
                                                                    handleSelectedAction(
                                                                        e.target
                                                                            .value as
                                                                            | 'eft'
                                                                            | 'cheque'
                                                                            | 'e-transfer'
                                                                    )
                                                                }
                                                                className=" rounded-md border-1 focus:border-0 bg-white  text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:text-sm "
                                                            >
                                                                <option value="eft">
                                                                    Mark as paid
                                                                    by EFT
                                                                </option>
                                                                <option value="cheque">
                                                                    Mark as paid
                                                                    by cheque
                                                                </option>
                                                                <option value="e-transfer">
                                                                    Mark as paid
                                                                    by
                                                                    e-transfer
                                                                </option>
                                                            </select>
                                                            <button
                                                                onClick={
                                                                    handleMarkAsPaid
                                                                }
                                                                disabled={
                                                                    markAsPaidMutation.isLoading ||
                                                                    !selectedAction
                                                                }
                                                                type="button"
                                                                className="rounded bg-indigo-50 px-2 py-1 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 disabled:opacity-50"
                                                            >
                                                                Go
                                                            </button>
                                                        </>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td colSpan={11}>
                                            <PaginationV2
                                                pages={invoiceLinks}
                                                from={from}
                                                to={to}
                                                total={total}
                                                next={next}
                                                prev={prev}
                                                searchTextDebounce={
                                                    searchTextDebounce
                                                }
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
