import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { QuickAddsRepository } from '../api/QuickAddsRepository'
import NumberFormatDollars from '../utils/NumberFormatDollars'
import Loading from '../shared-components/Loading'
import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'
import useDebounce from '../utils/useDebounce'
import { numOnlyRegex, testAlphaNumericWithSpaces } from '../utils/RegexHelper'

interface Props {
    setQuickAddsModalIsOpen: Function
    fetchQuickAdds: Function
}

export default function AddQuickAddsModal({
    setQuickAddsModalIsOpen,
    fetchQuickAdds,
}: Props) {
    const quickAddsRepository = new QuickAddsRepository()
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const searchTermDebounced = useDebounce(searchTerm, 500)
    const [selectedUser, setSelectedUser] = useState<number>(0)
    const [validQuickAdd, setValidQuickAdd] = useState<boolean>(false)
    const [addedProducts, setAddedProducts] = useState<
        Paywell.API.V2.NewQuickAdd[] | []
    >([
        {
            product_id: 0,
            modified_price: '',
            quantity: 0,
            billing_cycle_id: 0,
            billing_cycle_start_date: '',
        },
    ])

    const quickAddsInfoQuery = useQuery(
        ['quick-add-users', searchTermDebounced],
        async () => {
            return await quickAddsRepository.quickAddsRequiredInfo(
                searchTermDebounced
            )
        },

        {
            retry: false,
            refetchOnWindowFocus: false,
        }
    )

    const saveQuickAddMutation = useMutation({
        mutationFn: (quickAdd: Paywell.API.V2.QuickAdd) =>
            quickAddsRepository.createQuickAdd(quickAdd),
        onSuccess: () => {
            setQuickAddsModalIsOpen(false)
            fetchQuickAdds()
        },

        onError: (error) => {
            // Handle error
        },
    })

    const saveQuickAdd = () => {
        let quickAdd: Paywell.API.V2.QuickAdd = {
            user_id: selectedUser,
            quick_adds: addedProducts,
        }
        saveQuickAddMutation.mutate(quickAdd)
    }

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen)
    }

    useEffect(() => {
        validateQuickAdd()
    }, [selectedUser, addedProducts])

    const validateQuickAdd = () => {
        if (selectedUser === 0 || addedProducts.length === 0) {
            setValidQuickAdd(false)
            return
        }

        let valid = true

        addedProducts.map((product) => {
            if (
                product.product_id === 0 ||
                product.modified_price === '' ||
                product.quantity === 0 ||
                product.quantity === '' ||
                product.billing_cycle_id === 0 ||
                product.billing_cycle_start_date === ''
            ) {
                valid = false
                return
            }
            if (!validateModifiedPrice(product.modified_price)) {
                valid = false
                return
            }
        })

        setValidQuickAdd(valid)
    }

    const handleSelectedUser = (id: number) => {
        setIsDropdownOpen(false)
        setSelectedUser(id)
    }

    const handleSelectedProduct = (id: number, index: number) => {
        let addedProductsCopy = [...addedProducts]
        addedProductsCopy[index].product_id = id
        setAddedProducts(addedProductsCopy)
    }

    const handleModifiedPrice = (price: string, index: number) => {
        let newAddedProducts = [...addedProducts]

        if (price === '') {
            newAddedProducts[index].modified_price = ''
            setAddedProducts(newAddedProducts)
            return
        }

        if (price && parseInt(price) > 99999) return

        newAddedProducts[index].modified_price = price
        setAddedProducts(newAddedProducts)
    }

    const validateModifiedPrice = (price: string) => {
        const validDecimal = /^-?\d+(\.\d+)?$/
        return validDecimal.test(price)
    }

    const handleSelectedBillingCycle = (id: number, index: number) => {
        let newAddedProducts = [...addedProducts]
        newAddedProducts[index].billing_cycle_id = id
        setAddedProducts(newAddedProducts)
    }

    const handleBillingCycleStartDate = (date: string, index: number) => {
        let newAddedProducts = [...addedProducts]
        newAddedProducts[index].billing_cycle_start_date = date
        setAddedProducts(newAddedProducts)
    }
    const handleQuantityChange = (quantity: string, index: number) => {
        if (!numOnlyRegex(quantity) && quantity !== '') return

        let newAddedProducts = [...addedProducts]

        if (quantity === '') {
            newAddedProducts[index].quantity = ''
            setAddedProducts(newAddedProducts)
            return
        }

        if (quantity && Number(quantity) > 9999) return

        newAddedProducts[index].quantity = Number(quantity)
        setAddedProducts(newAddedProducts)
    }

    const addNewProduct = () => {
        setAddedProducts([
            ...addedProducts,
            {
                product_id: 0,
                modified_price: '',
                quantity: 0,
                billing_cycle_id: 0,
                billing_cycle_start_date: '',
            },
        ])
    }

    const removeProduct = (index: number) => {
        let newAddedProducts = [...addedProducts]
        newAddedProducts.splice(index, 1)
        setAddedProducts([...newAddedProducts])
    }

    const getSelectedUserName = () => {
        if (selectedUser === 0) return 'Select a user'
        return quickAddsInfoQuery.data?.data?.users_and_agents?.find(
            (user) => user.id === selectedUser
        )?.name
    }

    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (
            e.target.value === '' ||
            testAlphaNumericWithSpaces(e.target.value)
        ) {
            setSearchTerm(e.target.value)
        }
    }
    const showLoading =
        quickAddsInfoQuery.isLoading || quickAddsInfoQuery.isFetching

    const isSaving = saveQuickAddMutation.isLoading

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                        <>
                            <div>
                                <div className="mb-4">
                                    <div className="relative mt-2">
                                        <button
                                            onClick={() => toggleDropdown()}
                                            type="button"
                                            className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            aria-haspopup="listbox"
                                            aria-expanded="true"
                                            aria-labelledby="listbox-label"
                                        >
                                            <span className="block truncate">
                                                {getSelectedUserName()}
                                            </span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <svg
                                                    className="h-5 w-5 text-gray-400"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                                                        clip-rule="evenodd"
                                                    />
                                                </svg>
                                            </span>
                                        </button>

                                        {isDropdownOpen && (
                                            <ul
                                                className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-100 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                                tabIndex={-1}
                                                role="listbox"
                                                aria-labelledby="listbox-label"
                                                aria-activedescendant="listbox-option-3"
                                            >
                                                <li
                                                    className="text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9"
                                                    id="search"
                                                    role="search"
                                                >
                                                    <input
                                                        onChange={
                                                            handleSearchTermChange
                                                        }
                                                        value={searchTerm}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        type="text"
                                                        placeholder="Search"
                                                    />
                                                </li>
                                                {quickAddsInfoQuery.isSuccess &&
                                                    quickAddsInfoQuery.data?.data?.users_and_agents?.map(
                                                        (user) => {
                                                            return (
                                                                <li
                                                                    className="text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-blue-500 hover:text-white "
                                                                    id={`listbox-option-0 ${user.id}`}
                                                                    role="option"
                                                                >
                                                                    <span
                                                                        className="font-normal block truncate"
                                                                        onClick={() =>
                                                                            handleSelectedUser(
                                                                                Number(
                                                                                    user.id
                                                                                )
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            user.name
                                                                        }
                                                                    </span>
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {addedProducts.map((product, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="space-y-3 border-2 border-gray-400 mb-4 p-4 rounded-md"
                                    >
                                        <div>
                                            <div className="flex justify-between">
                                                <label
                                                    htmlFor={
                                                        `product` + `${index}`
                                                    }
                                                    className="mb-2 block text-sm font-medium leading-6 text-gray-900"
                                                >
                                                    Product
                                                </label>
                                                <XCircleIcon
                                                    className="w-6 h-6 text-red-600 cursor-pointer"
                                                    onClick={() =>
                                                        removeProduct(index)
                                                    }
                                                />
                                            </div>
                                            <select
                                                id={`product` + `${index}`}
                                                name={`product` + `${index}`}
                                                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                onChange={(e) =>
                                                    handleSelectedProduct(
                                                        Number(e.target.value),
                                                        index
                                                    )
                                                }
                                            >
                                                {quickAddsInfoQuery.isSuccess && (
                                                    <>
                                                        <option value="0">
                                                            Select Product
                                                        </option>
                                                        {quickAddsInfoQuery.data?.data?.products?.map(
                                                            (product) => (
                                                                <option
                                                                    key={
                                                                        product.id
                                                                    }
                                                                    value={
                                                                        product.id
                                                                    }
                                                                >
                                                                    {
                                                                        product.name
                                                                    }{' '}
                                                                    -
                                                                    {NumberFormatDollars(
                                                                        product.default_price
                                                                    )}
                                                                </option>
                                                            )
                                                        )}
                                                    </>
                                                )}
                                            </select>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor={
                                                    `modified-price` +
                                                    `${index}`
                                                }
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Modified Price
                                            </label>

                                            <input
                                                type="text"
                                                name={
                                                    `modified-price` +
                                                    `${index}`
                                                }
                                                id={
                                                    `modified-price` +
                                                    `${index}`
                                                }
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                value={
                                                    addedProducts[index]
                                                        .modified_price
                                                }
                                                onChange={(e) =>
                                                    handleModifiedPrice(
                                                        e.target.value,
                                                        index
                                                    )
                                                }
                                            />
                                        </div>
                                        <div>
                                            <label
                                                htmlFor={
                                                    `billing-cycle` + `${index}`
                                                }
                                                className="mb-2 block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Billing Cycle
                                            </label>
                                            <select
                                                id={
                                                    `billing-cycle` + `${index}`
                                                }
                                                name={
                                                    `billing-cycle` + `${index}`
                                                }
                                                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                onChange={(e) =>
                                                    handleSelectedBillingCycle(
                                                        Number(e.target.value),
                                                        index
                                                    )
                                                }
                                            >
                                                {quickAddsInfoQuery.isSuccess && (
                                                    <>
                                                        <option value="0">
                                                            Select Billing Cycle
                                                        </option>
                                                        {quickAddsInfoQuery.data?.data?.billing_cycles?.map(
                                                            (billingCycle) => (
                                                                <option
                                                                    key={
                                                                        billingCycle.id
                                                                    }
                                                                    value={
                                                                        billingCycle.id
                                                                    }
                                                                >
                                                                    {
                                                                        billingCycle.name
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                    </>
                                                )}
                                            </select>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor={
                                                    `billing-cycle-start-date` +
                                                    `${index}`
                                                }
                                                className="mb-2 block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Billing Cycle Start Date
                                            </label>
                                            <input
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                type="date"
                                                name={
                                                    `billing-cycle-start-date` +
                                                    `${index}`
                                                }
                                                id={
                                                    `billing-cycle-start-date` +
                                                    `${index}`
                                                }
                                                value={
                                                    addedProducts[index]
                                                        .billing_cycle_start_date
                                                }
                                                onChange={(e) =>
                                                    handleBillingCycleStartDate(
                                                        e.target.value,
                                                        index
                                                    )
                                                }
                                            />
                                        </div>
                                        <div>
                                            <label
                                                htmlFor={
                                                    `quantity` + `${index}`
                                                }
                                                className="mb-2 block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Quantity
                                            </label>
                                            <input
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                type="text"
                                                name={`quantity` + `${index}`}
                                                id={`quantity` + `${index}`}
                                                value={
                                                    addedProducts[index]
                                                        .quantity
                                                }
                                                onChange={(e) =>
                                                    handleQuantityChange(
                                                        e.target.value,
                                                        index
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </>

                        <div className="flex justify-end mb-4 mt-4">
                            <PlusCircleIcon
                                className="w-6 h-6 text-blue-600 cursor-pointer"
                                onClick={addNewProduct}
                            />
                        </div>

                        {quickAddsInfoQuery.isLoading && (
                            <Loading height="8" width="8" />
                        )}

                        <div
                            className={`mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-${
                                quickAddsInfoQuery.isError ? '1' : '2'
                            } sm:gap-3`}
                        >
                            <button
                                onClick={() => saveQuickAdd()}
                                type="button"
                                disabled={!validQuickAdd || isSaving}
                                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                Add
                            </button>

                            <button
                                onClick={() => setQuickAddsModalIsOpen(false)}
                                type="button"
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            >
                                Cancel
                            </button>
                        </div>

                        {isSaving && <Loading height="8" width="8" />}

                        {saveQuickAddMutation.isError && (
                            <div className="mt-3 text-center text-sm text-red-600">
                                Something went wrong. Please try again later.
                            </div>
                        )}

                        {quickAddsInfoQuery.isError && !showLoading && (
                            <div className="mt-3 text-center text-sm text-red-600">
                                Something went wrong. Please try again later.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
