import { BaseRepository } from './BaseRepository'

export class ProductRepository extends BaseRepository {

    paginatedProducts = async(page: any) => {
        console.log(page);
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.ProductsPaginated>(`/api/v2/products?paginated=1&page=${page}`)
    }
    getProducts = async() => {
        return await super
            .getHttpClientInstance()
            .get<[Paywell.API.V2.Products]>(`/api/v2/products?paginated=0`)
    }
    getProduct = async(
        id: number
    ) => {
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.Products>(`/api/v2/products/${id}`)
    }

    createProduct = async (
        name: string,
        price: string,
    ) => {
        return await super
            .getHttpClientInstance()
            .post<Paywell.API.V2.Taxes>(`/api/v2/products`, {
                product_name: name,
                product_price: price
            })
    }

    updateProduct = async (
        id: number,
        name: string,
        price: any,
    ) => {
        return await super
            .getHttpClientInstance()
            .put(`/api/v2/products/${id}`, {
                product_name: name,
                product_price: price
            })
    }

    deleteProduct = async (
        id: number,
    ) => {
        return await super
            .getHttpClientInstance()
            .delete(`/api/v2/products/${id}`)
    }
}
