/**
 * GenericResponse defines the attributes a response should contain from an http client
 * implementation.
 */
export interface GenericResponse<T = any> {
    data: T
    status: number
    statusText: string
    headers: object
}

type RequestWithData = (
    endpoint: string,
    data: object,
    headers?: object
) => Promise<GenericResponse>

/**
 * Defines what methods any http client implementation should make available.
 * All http clients should extend the BaseHttpClient class.
 */
export abstract class BaseHttpClient {
    private readonly httpClientName: string

    /**
     * BaseHttpClient constructor.
     * @param httpClientName The name of the http client implementation.
     */
    protected constructor(httpClientName: string) {
        this.httpClientName = httpClientName
    }

    /**
     * Function to configure the http client with the given parameters.
     * @param baseUrl The base API url.
     * @param timeout The default request timeout in milliseconds.
     * @param withCredentials Enable the the  credentials functionality described here https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
     * @return BaseHttpClient An instance of the BaseHttpClient implementation
     */
    abstract configure(
        baseUrl: string,
        timeout: number,
        withCredentials: boolean
    ): BaseHttpClient

    /**
     * Determines if the http client has been configured. Http cl
     * @return boolean True if the http client has been setup and false other wise.
     */
    abstract isConfigured(): boolean

    /**
     * The http client GET request implementation.
     * @param data Disregard for get requests.
     * @param endpoint The API endpoint to make the request to.
     * @param headers Custom headers for this request.
     * @return Promise<GenericResponse> The http client implementation should return a promise which
     * resolves if the request was successful and rejects otherwise. Rejects should only occur if the connection
     * failed for whatever reason, status code checking should not be made at the implementation level. E.g. a
     * response with HTTP status code 500 should still resolve.
     */
    protected abstract getImpl(
        endpoint: string,
        data: object,
        headers?: object
    ): Promise<GenericResponse>

    /**
     * The http client POST request implementation.
     * @param endpoint The API endpoint to make the request to.
     * @param data Object containing the data that will be placed inside the request body.
     * @param headers Custom headers for this request.
     * @return Promise<GenericResponse> The http client implementation should return a promise which
     * resolves if the request was successful and rejects otherwise. Rejects should only occur if the connection
     * failed for whatever reason, status code checking should not be made at the implementation level. E.g. a
     * response with HTTP status code 500 should still resolve.
     */
    protected abstract postImpl(
        endpoint: string,
        data: object,
        headers?: object
    ): Promise<GenericResponse>

    /**
     * The http client PUT request implementation.
     * @param endpoint The API endpoint to make the request to.
     * @param data Object containing the data that will be placed inside the request body.
     * @param headers Custom headers for this request.
     * @return Promise<GenericResponse> The http client implementation should return a promise which
     * resolves if the request was successful and rejects otherwise. Rejects should only occur if the connection
     * failed for whatever reason, status code checking should not be made at the implementation level. E.g. a
     * response with HTTP status code 500 should still resolve.
     */
    protected abstract putImpl(
        endpoint: string,
        data: object,
        headers?: object
    ): Promise<GenericResponse>

    /**
     * The http client PATCH request implementation.
     * @param endpoint The API endpoint to make the request to.
     * @param data Object containing the data that will be placed inside the request body.
     * @param headers Custom headers for this request.
     * @return Promise<GenericResponse> The http client implementation should return a promise which
     * resolves if the request was successful and rejects otherwise. Rejects should only occur if the connection
     * failed for whatever reason, status code checking should not be made at the implementation level. E.g. a
     * response with HTTP status code 500 should still resolve.
     */
    protected abstract patchImpl(
        endpoint: string,
        data: object,
        headers?: object
    ): Promise<GenericResponse>

    /**
     * The http client DELETE request implementation.
     * @param endpoint The API endpoint to make the request to.
     * @param data Disregard for delete requests.
     * @param headers Custom headers for this request.
     * @return Promise<GenericResponse> The http client implementation should return a promise which
     * resolves if the request was successful and rejects otherwise.
     */
    protected abstract deleteImpl(
        endpoint: string,
        data: object,
        headers?: object
    ): Promise<GenericResponse>

    /**
     * Perform a HTTP GET request using the HttpClient's implementation.
     * @param endpoint The API endpoint to make the request to.
     * @param headers Custom headers for this request.
     * @return Promise<GenericResponse> Returns a promise which resolves if the request was successful and
     *  rejects otherwise.
     */
    get<T = any>(
        endpoint: string,
        headers?: object
    ): Promise<GenericResponse<T>> {
        return this.handleResp(endpoint, {}, headers, this.getImpl)
    }

    /**
     * Perform a HTTP POST request using the HttpClient's implementation.
     * @param endpoint The API endpoint to make the request to.
     * @param data Object containing the data that will be placed inside the request body.
     * @param headers Custom headers for this request.
     * @return Promise<GenericResponse> Returns a promise which resolves if the request was successful and
     *  rejects otherwise.
     */
    post<T = any>(
        endpoint: string,
        data: any = undefined,
        headers?: object
    ): Promise<GenericResponse<T>> {
        return this.handleResp(endpoint, data, headers, this.postImpl)
    }

    /**
     * Perform a HTTP PUT request using the HttpClient's implementation.
     * @param endpoint The API endpoint to make the request to.
     * @param data Object containing the data that will be placed inside the request body.
     * @param headers Custom headers for this request.
     * @return Promise<GenericResponse> Returns a promise which resolves if the request was successful and
     *  rejects otherwise.
     */
    put(
        endpoint: string,
        data: any = undefined,
        headers?: object
    ): Promise<GenericResponse> {
        return this.handleResp(endpoint, data, headers, this.putImpl)
    }

    /**
     * Perform a HTTP patch request using the HttpClient's implementation.
     * @param endpoint The API endpoint to make the request to.
     * @param data Object containing the data that will be placed inside the request body.
     * @param headers Custom headers for this request.
     * @return Promise<GenericResponse> Returns a promise which resolves if the request was successful and
     *  rejects otherwise.
     */
    patch<T = any>(
        endpoint: string,
        data: any = undefined,
        headers?: object
    ): Promise<GenericResponse<T>> {
        return this.handleResp(endpoint, data, headers, this.patchImpl)
    }

    /**
     * Perform a HTTP DELETE request using the HttpClient's implementation.
     * @param endpoint The API endpoint to make the request to.
     * @param headers Custom headers for this request.
     * @return Promise<GenericResponse> Returns a promise which resolves if the request was successful and
     *  rejects otherwise.
     */
    delete(endpoint: string, headers?: object): Promise<GenericResponse> {
        return this.handleResp(endpoint, {}, headers, this.deleteImpl)
    }

    /**
     * Helper function that returns a rejected promise with a standardized message.
     * Used by the handleResponse function below.
     * @return Promise<any> A rejected promise.
     */
    private notConfiguredError(): Promise<any> {
        return Promise.reject(
            this.httpClientName + ' http client is not configured.'
        )
    }

    /**
     * This determines if a status code is considered successful or unsuccessful.
     * @param status The status code to check.
     */
    private static successfulStatus(status: number): boolean {
        return status >= 200 && status < 300
    }

    /**
     * Helper function that returns a rejected promise with a standardized message. Used if by the handleResp
     * function below.
     * @param status The HTTP status code
     */
    private static unsuccessfulResponseError(status: number): Promise<any> {
        return Promise.reject('Request failed with HTTP status code ' + status)
    }

    /**
     * Generic response handler
     * @param endpoint The API endpoint to make the request to.
     * @param data Object containing the data that will be placed inside the request body.
     * @param func The function implemented by the child HttpClient that actually makes the HTTP request.
     * @param headers Custom headers for this request.
     */
    handleResp(
        endpoint: string,
        data: object,
        headers: object | undefined,
        func: RequestWithData
    ) {
        if (!this.isConfigured()) {
            return this.notConfiguredError()
        }

        return new Promise<GenericResponse>((resolve, reject) => {
            func(endpoint, data, headers)
                .then((genericResponse) => {
                    if (
                        !BaseHttpClient.successfulStatus(genericResponse.status)
                    )
                        return reject(genericResponse)
                    resolve(genericResponse)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}
