import { BaseRepository } from './BaseRepository'

export class RegistrationFormRepository extends BaseRepository {
    getCSRF = async () => {
        return await super.getHttpClientInstance().get(`/sanctum/csrf-cookie`)
    }

    postIdentityInfo = async (
        identityInfo: Paywell.API.V2.IdentityInfo | null,
        contactId?: number | null
    ) => {
        let url = `/api/v2/contacts/${contactId}`

        return await super.getHttpClientInstance().put(url, identityInfo)
    }

    submitIdentityInfos = async (
        identityInfos: Paywell.API.V2.IdentityInfo[]
    ) => {
        let url = `/api/v2/contacts/submit-identity-infos`

        return await super.getHttpClientInstance().post(url, identityInfos)
    }

    postBusinessInfo = async (
        businessInfo: Paywell.API.V2.BusinessInfo | null,
        userId?: number | null
    ) => {
        let url = `/api/v2/register/${userId}`

        return await super.getHttpClientInstance().put(url, businessInfo)
    }

    postBankingInfo = async (
        bankingInfo: Paywell.API.V2.BankingInfo | null,
        userId?: number | null
    ) => {
        let url = `/api/v2/bank-account/${userId}`

        return await super.getHttpClientInstance().put(url, bankingInfo)
    }

    uploadVoidCheque = async (
        file: FormData,
        bankAccountId?: number | null
    ) => {
        let url = `/api/v2/bank-account/upload-void-cheque/${bankAccountId}`

        return await super.getHttpClientInstance().post(url, file)
    }

    uploadBusinessRegistration = async (file: FormData) => {
        let url = `/api/v2/register/upload-business-registration`

        return await super.getHttpClientInstance().post(url, file)
    }

    uploadProofOfAddress = async (file: FormData) => {
        let url = `/api/v2/register/upload-proof-of-address`

        return await super.getHttpClientInstance().post(url, file)
    }

    uploadDriversLicence = async (
        file: FormData,
        contactId?: number | null
    ) => {
        let url = `/api/v2/contacts/upload-drivers-licence/${contactId}`

        return await super.getHttpClientInstance().post(url, file)
    }

    uploadIdentityProofOfAddress = async (
        file: FormData,
        contactId?: number | null
    ) => {
        let url = `/api/v2/contacts/upload-proof-of-address/${contactId}`

        return await super.getHttpClientInstance().post(url, file)
    }

    uploadLivenessPhoto = async (file: FormData, contactId?: number | null) => {
        let url = `/api/v2/contacts/upload-liveness-photo/${contactId}`

        return await super.getHttpClientInstance().post(url, file)
    }

    completeRegistration = async (
        onboardingType: 'debit' | 'etransfer' = 'etransfer',
        numberOfTerminals?: number | null
    ) => {
        return await super
            .getHttpClientInstance()
            .post('/api/v2/register/complete-registration', {
                onboarding_type: onboardingType,
                number_of_terminals: numberOfTerminals,
            })
    }
    getPricingInfo = async () => {
        return await super.getHttpClientInstance().get(`/api/v2/get-pricing`)
    }

    addContact = async () => {
        let url = `/api/v2/contacts`

        return await super.getHttpClientInstance().post(url, {})
    }

    deleteContact = async (contactId: number) => {
        let url = `/api/v2/contacts/${contactId}`

        return await super.getHttpClientInstance().delete(url)
    }

    createEquipmentQuickAdd = async (numberOfTerminals: number) => {
        //TODO: add model type, for now backend is not expecting any data
        let url = `/api/v2/register/create-equipment-quickadd`

        return await super.getHttpClientInstance().post(url, {
            number_of_terminals: numberOfTerminals,
        })
    }
}
