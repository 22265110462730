import React from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
    userCopy: Paywell.API.V2.User | null
    isAdmin: boolean
}

export default function UserTicketSales({ userCopy, isAdmin }: Props) {
    const navigate = useNavigate()
    return (
        <div className={`p-10`}>
            {isAdmin && (
                <div className="mb-2 flex justify-end">
                    <button
                        onClick={() =>
                            navigate(
                                '/users/' + userCopy?.id + '/ticket-sales/edit'
                            )
                        }
                        type="button"
                        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Edit Page
                    </button>
                </div>
            )}
            <div className={'border border-gray-100 rounded-md p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Ticket Sales
                    </h3>
                </div>
                <div className="mt-6 border-t border-gray-100 grid grid-cols-2 gap-2">
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Average Ticket (Sale) Amount
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.avg_ticket_amount}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Highest Ticket (Sale) Amount
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.highest_ticket_amount}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Monthly Sales Volume (All Sales)
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.monthly_sales}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Predicted Monthly Sales
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.predicted_monthly_sales}
                                </dd>
                            </div>
                        </dl>
                    </div>
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Requested Highest Ticket Sales
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.requested_highest_ticket_amount}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Lowest Ticket Sale
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.lowest_ticket_amount}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    )
}
