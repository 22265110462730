import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: Paywell.ReduxStore.Onboarding.IdentityInfoForm[] = []

export const IdentityInfoSlice = createSlice({
    name: 'identity_info',
    initialState:
        initialState as Paywell.ReduxStore.Onboarding.IdentityInfoForm[],

    reducers: {
        setIdentityInfo: (
            state: Paywell.ReduxStore.Onboarding.IdentityInfoForm[],
            action: PayloadAction<
                Array<Paywell.ReduxStore.Onboarding.IdentityInfoForm>
            >
        ) => {
            return [...action.payload]
        },
    },
})

// Action creators are generated for each case reducer function
export const { setIdentityInfo } = IdentityInfoSlice.actions

export default IdentityInfoSlice.reducer
