import { useEffect, useState } from 'react'
import { numOnlyRegex } from '../utils/RegexHelper'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { setError } from '../redux/state-slices/GlobalNotificationSlice'
import { EftsRepository } from '../api/EftsRepository'
import { useDispatch } from 'react-redux'
import Loading from '../shared-components/Loading'

interface Props {
    setNewEftModalIsOpen: Function
}

export default function NewEftModal({ setNewEftModalIsOpen }: Props) {
    const [selectedEftType, setSelectedEftType] = useState('invoices')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [splitAmount, setSplitAmount] = useState(0)
    const [paysafeMaxAmount, setPaysafeMaxAmount] = useState(0)
    const [paysafeDaysBetween, setPaysafeDaysBetween] = useState<number | ''>(0)
    const [nbcMaxAmount, setNbcMaxAmount] = useState(0)
    const [nbcDaysBetween, setNbcDaysBetween] = useState<number | ''>(0)
    const [eftStartDate, setEftStartDate] = useState('')
    const [validForm, setValidForm] = useState(false)
    const navigate = useNavigate()
    const eftsRepository = new EftsRepository()
    const dispatch = useDispatch()

    useEffect(() => {
        validateForm()
    }, [
        fromDate,
        toDate,
        splitAmount,
        paysafeMaxAmount,
        paysafeDaysBetween,
        nbcMaxAmount,
        nbcDaysBetween,
        eftStartDate,
    ])
    const previewEftsMutation = useMutation({
        mutationKey: 'previewEfts',
        mutationFn: () =>
            eftsRepository.previewEfts(
                fromDate,
                toDate,
                splitAmount,
                paysafeMaxAmount,
                paysafeDaysBetween || -1,
                nbcMaxAmount,
                nbcDaysBetween || -1,
                eftStartDate
            ),

        onSuccess: (data) => {
            let scheduleId = data.data.schedule_id
            navigate(`/eft-batches/${scheduleId}/eft-transactions`)
        },
    })

    const validateForm = () => {
        let valid = true
        if (fromDate === '' || toDate === '' || eftStartDate === '') {
            valid = false
        }

        if (splitAmount < 1000) {
            valid = false
        }

        if (paysafeMaxAmount < 1000) {
            valid = false
        }

        if (paysafeDaysBetween <= 0 || paysafeDaysBetween === '') {
            valid = false
        }

        if (nbcMaxAmount < 1000) {
            valid = false
        }
        if (nbcDaysBetween <= 0 || nbcDaysBetween === '') {
            valid = false
        }

        if (splitAmount > paysafeMaxAmount || splitAmount > nbcMaxAmount) {
            valid = false
        }

        setValidForm(valid)
    }
    const isChecked = (eftType: string) => {
        return selectedEftType === eftType
    }

    const handleSplitAmountChange = (e: any) => {
        let number = e.target.value

        if (numOnlyRegex(number) || number === '') {
            setSplitAmount(parseInt(e.target.value) || 0)
        }
    }

    const handlePaysafeMaxAmountChange = (e: any) => {
        let number = e.target.value

        if (numOnlyRegex(number) || number === '') {
            setPaysafeMaxAmount(parseInt(e.target.value) || 0)
        }
    }

    const handlePaysafeDaysBetweenChange = (e: any) => {
        let number = e.target.value

        if (numOnlyRegex(number) || number === '') {
            setPaysafeDaysBetween(e.target.value)
        }
    }

    const handleNbcMaxAmountChange = (e: any) => {
        let number = e.target.value

        if (numOnlyRegex(number) || number === '') {
            setNbcMaxAmount(parseInt(e.target.value) || 0)
        }
    }

    const handleNbcDaysBetweenChange = (e: any) => {
        let number = e.target.value

        if (numOnlyRegex(number) || number === '') {
            setNbcDaysBetween(e.target.value)
        }
    }

    const handlePreviewEfts = () => {
        previewEftsMutation.mutate()
    }

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                        <div className="border-b border-gray-900/10 pb-6">
                            <fieldset>
                                <legend className="text-sm font-semibold leading-6 text-gray-900">
                                    Generate EFTs for
                                </legend>
                                <div className="mt-6 space-y-6">
                                    <div className="flex items-center gap-x-3">
                                        <input
                                            onChange={() =>
                                                setSelectedEftType('invoices')
                                            }
                                            checked={isChecked('invoices')}
                                            id="invoices"
                                            name="invoices"
                                            type="radio"
                                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                        <label
                                            htmlFor="invoices"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Invoices
                                        </label>
                                    </div>
                                </div>
                            </fieldset>
                            <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label
                                        htmlFor="from-date"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        From Date
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            onChange={(e) =>
                                                setFromDate(e.target.value)
                                            }
                                            type="date"
                                            name="from-date"
                                            id="from-date"
                                            className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm`}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label
                                        htmlFor="to-date"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        To Date
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            onChange={(e) =>
                                                setToDate(e.target.value)
                                            }
                                            type="date"
                                            name="to-date"
                                            id="to-date"
                                            className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label
                                    htmlFor="split-amount"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Split Amounts Greater Than
                                </label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                            onChange={handleSplitAmountChange}
                                            value={splitAmount}
                                            type="text"
                                            name="split-amount"
                                            id="split-amount"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label
                                    htmlFor="paysafe-max-amount"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Paysafe - Max Amount Per File
                                </label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                            onChange={
                                                handlePaysafeMaxAmountChange
                                            }
                                            value={paysafeMaxAmount}
                                            type="text"
                                            name="paysafe-max-amount"
                                            id="paysafe-max-amount"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label
                                    htmlFor="paysafe-days-between"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Paysafe - Days Between Files
                                </label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                            onChange={
                                                handlePaysafeDaysBetweenChange
                                            }
                                            value={paysafeDaysBetween}
                                            type="text"
                                            name="paysafe-days-between"
                                            id="paysafe-days-between"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label
                                    htmlFor="nbc-max-amount"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    NBC - Max Amount Per File
                                </label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                            onChange={handleNbcMaxAmountChange}
                                            value={nbcMaxAmount}
                                            type="text"
                                            name="nbc-max-amount"
                                            id="nbc-max-amount"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label
                                    htmlFor="nbc-days-between"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    NBC - Days Between Files
                                </label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                            onChange={
                                                handleNbcDaysBetweenChange
                                            }
                                            value={nbcDaysBetween}
                                            type="text"
                                            name="nbc-days-between"
                                            id="nbc-days-between"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label
                                    htmlFor="eft-start-date"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    EFT Start Date
                                </label>
                                <div className="mt-4">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                            onChange={(e) =>
                                                setEftStartDate(e.target.value)
                                            }
                                            type="date"
                                            name="eft-start-date"
                                            id="eft-start-date"
                                            className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`flex justify-between space-x-2 mt-5`}>
                            <button
                                onClick={() => setNewEftModalIsOpen(false)}
                                type="button"
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            >
                                Cancel
                            </button>
                            {!previewEftsMutation.isLoading && (
                                <button
                                    disabled={!validForm}
                                    onClick={handlePreviewEfts}
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    Preview
                                </button>
                            )}
                        </div>
                        {previewEftsMutation.isLoading && (
                            <div className="flex justify-center mt-5">
                                <Loading height={'8'} width={'8'} />
                            </div>
                        )}
                        {previewEftsMutation.isError &&
                            !previewEftsMutation.isLoading && (
                                <div className="flex justify-center mt-5">
                                    <p className="text-red-500">
                                        An error has occurred. Please try again.
                                    </p>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}
