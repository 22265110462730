interface Props {
    emailBody: string
}
export default function EmailReminderPreview({ emailBody }: Props) {
    /* This function is used to parse the HTML and change the
   <h1> and <h2> tags to <div> tags with the appropriate classes so
   the styling does not clash with tailwind. Same with <ol> and <ul> */
    const parseHtml = (html: string | null) => {
        let parser = new DOMParser()
        let doc = parser.parseFromString(html || '', 'text/html')

        // Change <h1> tags
        let h1Elements = doc.getElementsByTagName('h1')
        for (let i = 0; i < h1Elements.length; i++) {
            let h1 = h1Elements[i]
            let div = doc.createElement('div')
            div.className = 'text-2xl'
            div.innerHTML = h1.innerHTML

            let style = h1.getAttribute('style')
            if (style) {
                div.setAttribute('style', style)
            }

            h1.parentNode?.replaceChild(div, h1)
        }

        // Change <h2> tags
        let h2Elements = doc.getElementsByTagName('h2')
        for (let i = 0; i < h2Elements.length; i++) {
            let h2 = h2Elements[i]
            let div = doc.createElement('div')
            div.className = 'text-xl'

            // Copy the styles
            let style = h2.getAttribute('style')
            if (style) {
                div.setAttribute('style', style)
            }

            div.innerHTML = h2.innerHTML
            h2.parentNode?.replaceChild(div, h2)
        }

        // Change <ul> and <ol> tags
        let listElements = doc.querySelectorAll('ul, ol')
        for (let i = 0; i < listElements.length; i++) {
            listElements[i].setAttribute('class', 'list-disc list-inside')
        }
        return doc.body.innerHTML
    }

    return (
        <>
            <div
                className="text-black"
                dangerouslySetInnerHTML={{
                    __html: parseHtml(emailBody),
                }}
            />
        </>
    )
}
