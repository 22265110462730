import {
    Link,
    Route,
    Routes,
    useLocation,
    useNavigate,
    useParams,
    Navigate,
} from 'react-router-dom'
import UserInfo from './UserInfo'
import UserTicketSales from './UserTicketSales'
import { useMutation, useQuery } from 'react-query'
import { setError } from '../redux/state-slices/GlobalNotificationSlice'
import { UserRepository } from '../api/UserRepositoy'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { useEffect, useState } from 'react'
import Loading from '../shared-components/Loading'
import Error from '../shared-components/Error'
import UserFees from './UserFees'
import UserApiConfigs from './UserApiConfigs'
import UserBanking from './UserBanking'
import UserInvoices from './UserInvoices'
import UserInfoEdit from './Edit/UserInfoEdit'
import Contactinfo from './Contactinfo'
import ContactsEdit from './Edit/ContactsEdit'
import UserBankingEdit from './Edit/UserBankingEdit'
import UserTicketSalesEdit from './Edit/UserTicketSalesEdit'
import UserFeesEdit from './Edit/UserFeesEdit'
import UserApiConfigsEdit from './Edit/UserApiConfigsEdit'
import Notes from './notes/Notes'
import NotesEdit from './notes/NotesEdit'
import NotesView from './notes/NotesView'
import NotesNew from './notes/NotesNew'

export default function UserTabsWrapper() {
    const { id: userId } = useParams()
    const authUser = useAppSelector((state) => state.auth_user)
    const isAdmin = authUser?.user_type_id === 3
    const dispatch = useAppDispatch()
    const userRepository = new UserRepository()
    const navigate = useNavigate()
    const location = useLocation()
    const branding = useAppSelector((state) => state.branding)
    const [userObject, setUserObject] = useState<Paywell.API.V2.User | null>(
        null
    )
    const [userObjectCopy, setUserObjectCopy] =
        useState<Paywell.API.V2.User | null>(null)

    const initialTabs = () => {
        const baseTabs = [
            { name: 'User Info', href: 'info', current: true },
            { name: 'Contacts', href: 'contacts', current: false },
            { name: 'Banking', href: 'banking', current: false },
            { name: 'Ticket Sales', href: 'ticket-sales', current: false },
            { name: 'Fees', href: 'fees', current: false },
            {
                name: 'API Configurations',
                href: 'api-configurations',
                current: false,
            },
            { name: 'Invoices', href: 'invoices', current: false },
        ]

        if (isAdmin) {
            baseTabs.splice(1, 0, {
                name: 'Notes',
                href: 'notes',
                current: false,
            })
        }
        return baseTabs
    }

    const [tabs, setTabs] = useState(initialTabs)

    useEffect(() => {
        if (location.pathname.split('/').length === 3) {
            navigate('info', { replace: true })
        }
    }, [location, navigate])

    useEffect(() => {
        handleTabsChange()
    }, [location])

    const handleTabsChange = () => {
        const currentTab = location.pathname.split('/')[3]
        const newTabs = [...tabs]
        newTabs.filter((tab) => {
            tab.current = tab.href === currentTab
        })
        setTabs(newTabs)
    }
    const fetchUsers = async () => {
        if (!userId) return
        const response = await userRepository.getUser(userId)
        return response.data
    }

    const updateUser = async (user: Paywell.API.V2.User) => {
        const response = await userRepository.updateUser(user)
        return response.data
    }

    const updateUserMutation = useMutation(
        (updatedUserObject: Paywell.API.V2.User) => {
            return updateUser(updatedUserObject as Paywell.API.V2.User)
        },
        {
            onSuccess: (data) => {
                setUserObjectCopy(data)
                setUserObject(data)
            },
            onError: (error) => {
                setUserObjectCopy(userObject)
                dispatch(setError(['Error updating user. Please try again']))
            },
        }
    )

    const userQuery = useQuery(['user'], () => fetchUsers(), {
        onSuccess: (data) => {
            if (!data) return
            setUserObjectCopy(data)
            setUserObject(data)
        },
        onError: (error) => {
            dispatch(setError(['Error retrieving users. Please try again']))
        },
        refetchOnWindowFocus: false,
    })

    const saveChanges = (redirectTab: string) => {
        if (!userObjectCopy) return
        const newUserObject = { ...userObjectCopy }
        navigate(redirectTab)
        updateUserMutation.mutate(newUserObject)
    }

    const user = userQuery.data

    if (userQuery.isLoading || userQuery.isFetching)
        return (
            <div className="flex h-screen">
                <div className="m-auto">
                    <Loading height="8" width="8" />
                </div>
            </div>
        )
    if (userQuery.isError)
        return (
            <Error
                title="An error has occurred"
                message={`Please reach out to one of our representatives at ${branding.contact_email} if this error persists.`}
            />
        )
    // @ts-ignore
    return (
        <div className="p-5">
            <div className="overflow-hidden rounded-lg bg-white shadow mb-4">
                <h2 className="sr-only" id="profile-overview-title">
                    Profile Overview
                </h2>
                <div className="bg-white p-6">
                    <div className="sm:flex sm:items-center sm:justify-between">
                        <div className="sm:flex sm:space-x-5">
                            <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                                <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                                    {user?.name}
                                </p>
                                <p className="text-sm font-medium text-gray-600">
                                    {user?.email}
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 flex justify-center sm:mt-0">
                            <button
                                onClick={() => navigate(-1)}
                                className="flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    <select
                        id="tabs"
                        name="tabs"
                        onChange={(event) => navigate(`${event.target.value}`)}
                        className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                    >
                        {tabs.map((tab) => (
                            <option value={tab.href} key={tab.name}>
                                {tab.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <nav
                        className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
                        aria-label="Tabs"
                    >
                        {tabs.map((tab, tabIdx) => (
                            <Link
                                key={tab.name}
                                to={tab.href}
                                className={`group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10 ${
                                    tab.current
                                        ? 'text-gray-900 bg-indigo-50'
                                        : 'text-gray-500 hover:text-gray-700'
                                } ${tabIdx === 0 ? 'rounded-l-lg' : ''} ${
                                    tabIdx === tabs.length - 1
                                        ? 'rounded-r-lg'
                                        : ''
                                }`}
                                aria-current={tab.current ? 'page' : undefined}
                            >
                                {tab.name}
                                <span
                                    aria-hidden="true"
                                    className={`${
                                        tab.current
                                            ? 'bg-indigo-500'
                                            : 'bg-transparent'
                                    } absolute inset-x-0 bottom-0 h-0.5`}
                                />
                            </Link>
                        ))}
                    </nav>
                </div>
            </div>
            <div className={'mt-4 bg-white'}>
                <Routes>
                    <Route index element={<Navigate to="info" replace />} />
                    <Route
                        path="info"
                        element={
                            <UserInfo isAdmin={isAdmin} userCopy={userObject} />
                        }
                    />

                    <Route
                        path="contacts"
                        element={
                            <Contactinfo isAdmin={isAdmin} user={userObject} />
                        }
                    />
                    <Route
                        path="info/edit"
                        element={
                            <UserInfoEdit
                                userCopy={userObjectCopy}
                                user={userObject}
                                saveChanges={() => saveChanges('info')}
                                updateUserInfo={setUserObjectCopy}
                            />
                        }
                    />

                    <Route
                        path="contacts/edit"
                        element={<ContactsEdit user={userObject} />}
                    />
                    <Route
                        path="banking"
                        element={
                            <UserBanking
                                isAdmin={isAdmin}
                                userCopy={userObject}
                            />
                        }
                    />
                    <Route
                        path="banking/edit"
                        element={
                            <UserBankingEdit
                                userCopy={userObjectCopy}
                                user={userObject}
                                saveChanges={() => saveChanges('banking')}
                                updateUserInfo={setUserObjectCopy}
                            />
                        }
                    />
                    <Route
                        path="ticket-sales"
                        element={
                            <UserTicketSales
                                isAdmin={isAdmin}
                                userCopy={userObject}
                            />
                        }
                    />
                    <Route
                        path="ticket-sales/edit"
                        element={
                            <UserTicketSalesEdit
                                userCopy={userObjectCopy}
                                user={userObject}
                                saveChanges={() => saveChanges('ticket-sales')}
                                updateUserInfo={setUserObjectCopy}
                            />
                        }
                    />
                    <Route
                        path="fees"
                        element={
                            <UserFees isAdmin={isAdmin} userCopy={userObject} />
                        }
                    />

                    <Route
                        path="fees/edit"
                        element={
                            <UserFeesEdit
                                userCopy={userObjectCopy}
                                user={userObject}
                                saveChanges={() => saveChanges('fees')}
                                updateUserInfo={setUserObjectCopy}
                            />
                        }
                    />
                    <Route
                        path="api-configurations"
                        element={
                            <UserApiConfigs
                                isAdmin={isAdmin}
                                userCopy={userObject}
                            />
                        }
                    />
                    <Route
                        path="api-configurations/edit"
                        element={
                            <UserApiConfigsEdit
                                userCopy={userObjectCopy}
                                user={userObject}
                                saveChanges={() =>
                                    saveChanges('api-configurations')
                                }
                                updateUserInfo={setUserObjectCopy}
                            />
                        }
                    />
                    <Route
                        path="invoices"
                        element={<UserInvoices user={user} />}
                    />

                    {isAdmin && (
                        <>
                            <Route
                                path="notes"
                                element={<Notes userId={userObject?.id} />}
                            />
                            <Route
                                path="notes/:id/view"
                                element={<NotesView />}
                            />
                            <Route
                                path="notes/new"
                                element={<NotesNew userId={userObject?.id} />}
                            />
                            <Route
                                path="notes/:id/edit"
                                element={<NotesEdit userId={userObject?.id} />}
                            />
                        </>
                    )}
                </Routes>
            </div>
        </div>
    )
}
