import { useAppSelector } from '../redux/hooks'

const AccountSuspended = () => {
    const supportEmail = useAppSelector((state) => state.branding.contact_email)
    const branding = useAppSelector((state) => state.branding)
    const companyIcon = branding.company_icon
    return (
        <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-12 w-auto"
                    src={companyIcon || ''}
                    alt="Logo"
                />
                <h2 className="mt-6 text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    Account Suspended
                </h2>
                <p className="mt-4 text-center text-lg leading-8 text-gray-600">
                    We're sorry, but your account has been suspended. Please
                    contact our support team at{' '}
                    <a href={`mailto:${supportEmail}`} className="font-medium">
                        {supportEmail}
                    </a>{' '}
                    for further assistance.
                </p>
            </div>
        </div>
    )
}

export default AccountSuspended
