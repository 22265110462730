import { useAppSelector } from '../redux/hooks'
import { Outlet, Navigate } from 'react-router-dom'
import { useEffect } from 'react'

const AccountIsActive = () => {
    const authUser = useAppSelector((state) => state.auth_user)

    return authUser &&
        authUser.email_verified &&
        authUser.account_status === 'ACTIVE' ? (
        <Outlet />
    ) : (
        <Navigate to="/account" replace />
    )
}

export default AccountIsActive
