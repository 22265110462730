import { PlusIcon, MinusIcon } from '@heroicons/react/20/solid'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
    addEmail,
    removeEmail,
    setEmails,
    updateEmail,
} from '../../redux/state-slices/EmailSlice'
import { validateEmailAddress, validateInputKey } from '../../utils/RegexHelper'

export default function Email() {
    const dispatch = useAppDispatch()
    const state = useAppSelector((state) => state)

    const add = () => {
        dispatch(addEmail({ email: '' }))
    }

    const remove = (index: number) => {
        dispatch(removeEmail(index))
    }

    const update = (index: number, value: string) => {
        dispatch(
            updateEmail({
                index: index,
                value: value,
                validated: validateEmailAddress(value),
            })
        )
    }

    useEffect(() => {
        dispatch(setEmails([]))
    }, [])

    return (
        <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
            <div className="flex-col">
                {state.customer.emails.map((email: any, index: number) => (
                    <div className="flex gap-4 w-100">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            className={`mt-1 block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm
                            ${
                                email.validated
                                    ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                    : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                            }`}
                            placeholder="Email"
                            defaultValue={email.email}
                            onKeyUp={(e) =>
                                validateInputKey(
                                    e.key,
                                    (e.target as HTMLInputElement).value
                                ) &&
                                update(
                                    index,
                                    (e.target as HTMLInputElement).value
                                )
                            }
                            required
                        />

                        {(index > 0 && (
                            <MinusIcon
                                className="h-6 w-6 text-red-500 mt-2 cursor-pointer"
                                onClick={() => remove(index)}
                            />
                        )) || (
                            <PlusIcon
                                className="h-6 w-6 text-blue-500 mt-2 cursor-pointer"
                                onClick={() => add()}
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}
