import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { HttpClient } from '../BaseRepository'
import { BaseHttpClient, GenericResponse } from './BaseHttpClient'
import { SentryUtils } from '../../utils/SentryUtils'
import { setLoggedOut } from '../../redux/state-slices/AuthUserSlice'
import { store } from '../../redux/store'

type RequestWithoutData = (
    endpoint: string,
    config: object
) => Promise<AxiosResponse>
type RequestWithData = (
    endpoint: string,
    data: any,
    config: object
) => Promise<AxiosResponse>

/**
 * Http client implementation using the AXIOS http client library.
 */
export class AxiosHttpClient extends BaseHttpClient {
    private axiosInstance?: AxiosInstance

    constructor() {
        super(HttpClient.AXIOS)
    }

    /**
     * Configures the Axios HTTP client with axios.
     * @param baseUrl The base API url.
     * @param timeout The default request timeout in milliseconds.
     * @param withCredentials Enable the the  credentials functionality described here https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
     * @return BaseHttpClient An instance of AxiosHttpClient.
     */
    public configure = (
        baseUrl: string,
        timeout: number,
        withCredentials: boolean
    ): BaseHttpClient => {
        this.axiosInstance = axios.create({
            baseURL: baseUrl,
            timeout: timeout,
            validateStatus: undefined,
            withCredentials: withCredentials,
            headers: { Accept: 'application/json' },
        })

        // Configure the response interceptor
        this.axiosInstance.interceptors.response.use(
            function (response) {
                if (response.status === 401) {
                    store.dispatch(setLoggedOut())
                }
                return response
            },
            function (error) {
                return Promise.reject(error)
            }
        )
        return this
    }

    public isConfigured = (): boolean => {
        return this.axiosInstance !== undefined
    }

    public getImpl = (
        endpoint: string,
        data: any,
        headers: object
    ): Promise<GenericResponse> => {
        return this.handleRequestNoData(
            endpoint,
            headers,
            SentryUtils.expect<AxiosInstance>(this.axiosInstance).get
        )
    }

    public postImpl = (
        endpoint: string,
        data: any,
        headers: object
    ): Promise<GenericResponse> => {
        return this.handleRequestWithData(
            endpoint,
            data,
            headers,
            SentryUtils.expect<AxiosInstance>(this.axiosInstance).post
        )
    }

    public putImpl = (
        endpoint: string,
        data: any,
        headers: object
    ): Promise<GenericResponse> => {
        return this.handleRequestWithData(
            endpoint,
            data,
            headers,
            SentryUtils.expect<AxiosInstance>(this.axiosInstance).put
        )
    }

    public patchImpl = (
        endpoint: string,
        data: any,
        headers: object
    ): Promise<GenericResponse> => {
        return this.handleRequestWithData(
            endpoint,
            data,
            headers,
            SentryUtils.expect<AxiosInstance>(this.axiosInstance).patch
        )
    }

    public deleteImpl = (
        endpoint: string,
        data: any,
        headers: object
    ): Promise<GenericResponse> => {
        return this.handleRequestNoData(
            endpoint,
            headers,
            SentryUtils.expect<AxiosInstance>(this.axiosInstance).delete
        )
    }

    private handleRequestWithData = (
        endpoint: string,
        data: any,
        headers: object,
        func: RequestWithData
    ): Promise<GenericResponse> => {
        return new Promise((resolve, reject) => {
            func(endpoint, data, { headers: headers })
                .then((response) => {
                    const httpClientResponse: GenericResponse = {
                        data: response.data,
                        status: response.status,
                        statusText: response.statusText,
                        headers: response.headers,
                    }
                    resolve(httpClientResponse)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    private handleRequestNoData = (
        endpoint: string,
        headers: object,
        func: RequestWithoutData
    ): Promise<GenericResponse> => {
        return new Promise((resolve, reject) => {
            func(endpoint, { headers: headers })
                .then((response) => {
                    const httpClientResponse: GenericResponse = {
                        data: response.data,
                        status: response.status,
                        statusText: response.statusText,
                        headers: response.headers,
                    }
                    resolve(httpClientResponse)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}
