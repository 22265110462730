import { useLocation, useSearchParams } from "react-router-dom";

export const ReferalCheck = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const search = searchParams.get('redirect')
    const location: string = search || '/home'

    return location
}

export const LoginReferral = () => {
    const location = useLocation();
    return `/login?redirect=${location.pathname}`
}
