import { BaseRepository } from './BaseRepository'

export class SignNowRepository extends BaseRepository {
    getDocument = async () => {
        return await super.getHttpClientInstance().get(`/api/v2/get-contract`)
    }

    getContractDocumentStatus = async () => {
        return await super
            .getHttpClientInstance()
            .get(`/api/v2/contract-document-status`)
    }
}
