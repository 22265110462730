import { useAppSelector } from '../../../redux/hooks'

export default function InvoiceCheckoutCrypto() {
    const branding = useAppSelector((state) => state.branding)
    return (
        <div className="bg-white">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                Crypto
            </h2>
            <p className="mt-2">
                Please contact us at{' '}
                <a
                    href={`mailto:${branding.contact_email}`}
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                    {branding.contact_email}
                </a>{' '}
                or{' '}
                <a
                    href={`tel:+${branding.contact_phone}`}
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                    {branding.contact_phone}
                </a>{' '}
                for further information on how to pay via crypto.
            </p>
        </div>
    )
}
