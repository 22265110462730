import { TrashIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import {
    addLineItems,
    updateCustomer,
    setLineItems,
    updateInvoice,
} from '../../redux/state-slices/InvoiceSlice'
import LineItem from '../components/LineItems'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { ProductRepository } from '../../api/ProductRepository'
import { TaxRepository } from '../../api/TaxRepository'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { CustomersRepository } from '../../api/CustomersRepository'
import { InvoicesRepository } from '../../api/InvoicesRepository'
import { LineItemsRepository } from '../../api/LineIttemsRepository'
import {
    resetConfirm,
    setConfirm,
    setError,
    setLoad,
    setSuccess,
    setWarning,
} from '../../redux/state-slices/GlobalNotificationSlice'
import Loading from '../../shared-components/Loading'

import ErrorV2 from '../../shared-components/ErrorV2'
import CustomersNew from '../customers/CustomersNew'
import SkeletonLoader from '../components/SkeletonLoader'
import {
    getHumanReadableStatus,
    getHumanReadableType,
} from '../../utils/TransactionHelper'
import { formatDate } from '../../utils/StringUtils'
import TaxModal from './components/TaxModal'
import PaymentModal from './components/PaymentModal'

export default function InvoiceEdit() {
    const dispatch = useAppDispatch()
    const state = useAppSelector((state) => state)
    const queryClient = useQueryClient()

    const customerRepository = new CustomersRepository()
    const productRepository = new ProductRepository()
    const taxRepository = new TaxRepository()
    const invoiceRepository = new InvoicesRepository()
    const lineItemsRepository = new LineItemsRepository()

    const [isNewCustomer, setNewCustomer] = useState(0)
    const [isNewProduct, setNewProduct] = useState(false)
    const [isNewTax, setNewTax] = useState(false)
    const [validPaymentOptions, setValidPaymentOptions] = useState<
        boolean | undefined
    >(undefined)

    const [paymentHistory, setPaymentHistory] = useState<
        Paywell.API.V2.Transaction[] | null
    >(null)
    const [addPaymentModalOpen, setAddPaymentModalOpen] =
        useState<boolean>(false)

    const [paymentOptions, setSelectedPaymentOptions] =
        useState<Paywell.API.V2.PaymentMethodOptions>({
            id: 0,
            credit_card: false,
            etransfer: false,
            wire_transfer: false,
            crypto: false,
            check: false,
        })

    const { id } = useParams()
    const navigate = useNavigate()
    const taxQuery = useQuery(['taxes', isNewTax], taxRepository.getTaxes)
    const productQuery = useQuery(
        ['products', isNewProduct],
        productRepository.getProducts
    )
    const customerQuery = useQuery(
        ['customers', `${id}-${isNewCustomer}`],
        async () => {
            return await customerRepository.getCustomers()
        }
    )
    const lineItemsQuery = useQuery(['lineitems', id], async () => {
        return await lineItemsRepository.getLineItems(id ? id : '0')
    })
    const invoiceQuery = useQuery(
        ['invoice', id],
        async () => {
            return await invoiceRepository.getInvoice(id ? id : '0')
        },
        {
            onSuccess: (data) => {
                setInvoiceNumber(data.data.invoice_number)
            },
        }
    )

    const getPaymentMethodsQuery = useQuery(
        'payment-methods-invoice-edit',
        async () => {
            return await invoiceRepository.getPaymentMethods(invoice?.id)
        },
        {
            enabled: !!invoiceQuery.data?.data?.id,
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            retry: false,

            onSuccess: (data) => {
                const paymentMethods: Paywell.API.V2.PaymentMethodOptions = {
                    id: data?.data?.id,
                    credit_card: data?.data?.credit_card ? true : false,
                    etransfer: data?.data?.etransfer ? true : false,
                    wire_transfer: data?.data?.wire_transfer ? true : false,
                    crypto: data?.data?.crypto ? true : false,
                    check: data?.data?.check ? true : false,
                }

                if (
                    paymentMethods.credit_card ||
                    paymentMethods.etransfer ||
                    paymentMethods.wire_transfer ||
                    paymentMethods.crypto
                ) {
                    setValidPaymentOptions(true)
                }
                setSelectedPaymentOptions(paymentMethods)
            },
            onError: (error) => {},
        }
    )

    const updatePaymentOptionsMutation = useMutation({
        mutationFn: () =>
            invoiceRepository.updatePaymentMethodOptions(
                paymentOptions,
                invoice?.id
            ),
        onError: (error: any) => {
            if (error.status === 422) {
                dispatch(setWarning([error.data.message]))
            } else {
                dispatch(
                    setError([
                        'An error has occured. Please try again later. If the problem persists, please contact support.',
                    ])
                )
            }
        },
        onSuccess: (data) => {},
    })

    const products = productQuery.data?.data
    const taxes = taxQuery.data?.data
    const customers = customerQuery.data?.data
    const invoice = invoiceQuery.data?.data
    const lineItems = lineItemsQuery.data?.data

    const [customerId, setCustomerId] = useState<number>(-1)
    const [dueDate, setDueDate] = useState('')
    const [invoiceDate, setInvoiceDate] = useState('')
    const [invoiceNumber, setInvoiceNumber] = useState('')
    const [taxRate, setTaxRate] = useState(3)
    const countries = ['USA', 'CAD', 'Mexico']

    const paymentHistoryQuery = useQuery(
        ['paymentHistory', invoice?.uuid],
        async () => {
            return await invoiceRepository.getPaymentHistory(
                invoice?.uuid || ''
            )
        },
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                setPaymentHistory(data.data)
            },
            onError: (error) => {},
        }
    )

    useEffect(() => {
        if (invoice?.uuid) {
            paymentHistoryQuery.refetch()
        }
    }, [invoice?.uuid])

    const changeCustomer = (id: any) => {
        setCustomerId(id)
        if (!customers) return
        handleCustomer(customers[findCustomer(id)], true)
    }

    const handleCustomer = (customer: any, update: boolean) => {
        if (!customer) return
        dispatch(
            updateCustomer([
                { key: 'customer_id', value: customer.id },
                { key: 'first_name', value: customer.first_name },
                { key: 'last_name', value: customer.last_name },
                {
                    key: 'billing_address',
                    value: customer.billing_address,
                },
                {
                    key: 'billing_city',
                    value: customer.billing_city,
                },
                {
                    key: 'billing_province',
                    value: customer.billing_province,
                },
                {
                    key: 'billing_country',
                    value: customer.billing_country,
                },
                {
                    key: 'billing_postal_code',
                    value: customer.billing_postal_code,
                },
                {
                    key: 'emails',
                    value: JSON.parse(customer.emails),
                },
            ])
        )
        setTaxRate(customer.tax_id)
        if (update) {
            updateInvoiceMutation.mutate({
                customer,
                dueDate,
                invoiceNumber,
                invoiceDate,
            })
        }
    }

    const handleDueDateChange = (dueDate: string) => {
        setDueDate(dueDate)

        let index = findCustomer(customerId)
        if (customers) {
            const customer = customers[index]
            updateInvoiceMutation.mutate({
                customer,
                dueDate,
                invoiceNumber,
                invoiceDate,
            })
        }
    }

    const handleInvoiceDateChange = (invoiceDate: string) => {
        setInvoiceDate(invoiceDate)

        let index = findCustomer(customerId)
        if (customers) {
            const customer = customers[index]
            updateInvoiceMutation.mutate({
                customer,
                dueDate,
                invoiceNumber,
                invoiceDate,
            })
        }
    }

    const handleInvoiceNumberchange = (invoiceNumber: string) => {
        const pattern = /^\d*$/
        if (!pattern.test(invoiceNumber)) return
        if (invoiceNumber.length > 5) return
        setInvoiceNumber(invoiceNumber)
        let index = findCustomer(customerId)
        if (customers) {
            const customer = customers[index]
            updateInvoiceMutation.mutate({
                customer,
                dueDate,
                invoiceNumber,
                invoiceDate,
            })
        }
    }

    const handlePaymentOptionChange = (paymentMethod: string) => {
        const newPaymentMethods: Paywell.API.V2.PaymentMethodOptions = {
            ...paymentOptions,
        }
        switch (paymentMethod) {
            case 'credit-card':
                newPaymentMethods.credit_card = !paymentOptions.credit_card
                break
            case 'e-transfer':
                newPaymentMethods.etransfer = !paymentOptions.etransfer
                break
            case 'wire-transfer':
                newPaymentMethods.wire_transfer = !paymentOptions.wire_transfer
                break
            case 'crypto':
                newPaymentMethods.crypto = !paymentOptions.crypto
                break
            case 'check':
                newPaymentMethods.check = !paymentOptions.check
                break

            default:
                break
        }
        if (
            newPaymentMethods.credit_card ||
            newPaymentMethods.etransfer ||
            newPaymentMethods.wire_transfer ||
            newPaymentMethods.crypto ||
            newPaymentMethods.check
        ) {
            setValidPaymentOptions(true)
        } else {
            setValidPaymentOptions(false)
        }
        setSelectedPaymentOptions(newPaymentMethods)
    }

    const updateInvoiceMutation = useMutation({
        mutationFn: ({
            customer,
            dueDate,
            invoiceNumber,
            invoiceDate,
        }: {
            customer: Paywell.API.V2.Customers
            dueDate: string
            invoiceNumber: string
            invoiceDate?: string
        }) =>
            invoiceRepository.updateInvoice(
                id ? id : '0',
                dueDate,
                customer,
                invoiceNumber,
                invoiceDate
            ),
        onError: (err: any) => {
            if (err.data.message) dispatch(setError([err.data.message]))
            else
                dispatch(
                    setError([
                        'An error has occurred. Please try again later. If the problem persists, please contact support.',
                    ])
                )
        },
    })

    const saveCustomerMutation = useMutation({
        mutationKey: 'saveCustomer',
        mutationFn: () =>
            customerRepository.createCustomer(
                state.invoice.first_name,
                state.invoice.first_name,
                state.customer.emails,
                state.invoice.billing_address,
                state.invoice.billing_city,
                state.invoice.billing_province,
                state.invoice.billing_postal_code,
                state.invoice.billing_country,
                1,
                ''
            ),
        onError: (err: any) => {
            if (err.data.message) dispatch(setError([err.data.message]))
            else
                dispatch(
                    setError([
                        'An error has occured. If this keeps happening, please contact support',
                    ])
                )
        },

        onSuccess: (data) => {
            if (customers != null) {
                customerQuery.refetch()
                invoiceQuery.refetch()
            }

            handleCustomer(data?.data, true)
            dispatch(setSuccess(['You have successfully updated the invoice.']))
        },
    })

    const deleteInvoiceMutation = useMutation({
        mutationKey: 'deleteInvoice',
        mutationFn: (id: any) => invoiceRepository.deleteInvoice(id),
        onError: (err: any) => {
            if (err.data.message) dispatch(setError([err.data.message]))
            else
                dispatch(
                    setError([
                        'An error has occured. If this keeps happening, please contact support',
                    ])
                )
        },

        onSuccess: (data) => {
            navigate('/invoicing')
            dispatch(resetConfirm(''))
            dispatch(setLoad(false))
            dispatch(setSuccess(['You have successfully deleted the invoice.']))
        },
    })

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        saveCustomerMutation.mutate()
    }

    const findCustomer = (id: number) => {
        return customers ? customers?.findIndex((x) => x.id == id) : -1
    }

    const callback = async (customer: any, update: boolean) => {
        if (customers != null) {
            customerQuery.refetch()
            invoiceQuery.refetch()
        }
        setNewCustomer(isNewCustomer + 1)
        setCustomerId(customer.id)

        handleCustomer(customer, update)
        dispatch(setSuccess(['You have successfully updated the invoice.']))
    }

    const onCancel = () => {
        setCustomerId(-1)
    }

    const isNewCallback = (product: boolean, tax: boolean) => {
        if (product) setNewProduct(true)
        if (tax) setNewTax(true)

        setTimeout(function () {
            setNewProduct(false)
            setNewTax(false)
        }, 100)
    }

    useEffect(() => {
        if (invoice?.id && paymentOptions.id && validPaymentOptions) {
            updatePaymentOptionsMutation.mutate()
        }
    }, [paymentOptions])

    useEffect(() => {
        dispatch(
            updateInvoice({
                subtotal: invoice?.invoice_subtotal,
                tax_total: invoice?.invoice_tax_total,
                grand_total: invoice?.invoice_grand_total,
            })
        )
        dispatch(setLineItems([]))
        lineItems?.map((line, index) => {
            dispatch(
                addLineItems({
                    grand_total: line.grand_total,
                    tax_total: line.tax_total,
                    subtotal: line.subtotal,
                    quantity: line.quantity,
                    cost_per_unit: line.cost_per_unit,
                    product_id: line.product_id,
                    tax_id: line.tax_id,
                    id: line.id,
                    description: line.description,
                })
            )
        })

        if (products && customers && taxes) {
            const customer_id: number =
                invoice?.customer_id != null
                    ? parseInt(invoice.customer_id)
                    : -1
            setCustomerId(customer_id)
            handleCustomer(customers[findCustomer(customer_id)], false)
            setDueDate(invoice?.due_date ? invoice.due_date : '')
        }
    }, [lineItems, invoice])

    if (invoiceQuery.isLoading)
        return (
            <div>
                <Loading width="8" height="8" />
            </div>
        )

    if (
        invoiceQuery.isError ||
        lineItemsQuery.isError ||
        productQuery.isError ||
        customerQuery.isError
    )
        return (
            <div>
                <ErrorV2
                    title="An error has occured"
                    message="An error has occurred while loading your invoice. Please try refreshing, if this keeps happening contact support."
                />
            </div>
        )

    return (
        <>
            <PaymentModal
                invoice_uuid={invoice?.uuid}
                show={addPaymentModalOpen}
                onClose={() => setAddPaymentModalOpen(false)}
                callback={() => {
                    setAddPaymentModalOpen(false)
                    if (invoice?.uuid) {
                        paymentHistoryQuery.refetch()
                    }
                }}
            />
            <div className="sm:flex ">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">
                        Edit Invoice
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Edit invoice #{invoice?.id}
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <div className="pt-5">
                        <div className="flex justify-end">
                            <Link
                                to={`/invoicing/invoices/${id}`}
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Done
                            </Link>
                            <button
                                type="button"
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-500 py-1 px-1 text-sm font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
                                onClick={(e) =>
                                    dispatch(
                                        setConfirm({
                                            title: 'Confirm Invoice Deletion',
                                            message:
                                                'Are you sure you would like to delete this invoice? Doing this cannot be undone!',
                                            onClick: () => {
                                                deleteInvoiceMutation.mutate(id)
                                                dispatch(setLoad(true))
                                            },
                                        })
                                    )
                                }
                            >
                                <TrashIcon className="fill-white h-5 w-5 cursor-pointer" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div className="space-y-6 sm:space-y-5"></div>

                    <div className="divide-y divide-gray-200 pt-8 sm:space-y-5 ">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
                            <label
                                htmlFor="invoice_number"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Invoice Number
                                </h3>
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                <input
                                    disabled={
                                        invoiceQuery.isLoading ||
                                        invoiceQuery.isFetching
                                    }
                                    type="number"
                                    id="invoice_number"
                                    name="invoice_number"
                                    className="focus:border-sky-600 focus:ring-sky-600 disabled:bg-gray-200"
                                    max="99999"
                                    min="0"
                                    defaultValue={invoice?.invoice_number}
                                    value={invoiceNumber}
                                    pattern="\d+"
                                    onChange={(e) =>
                                        handleInvoiceNumberchange(
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="divide-y divide-gray-200 pt-8 sm:space-y-5 ">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
                            <label
                                htmlFor="invoice_date"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Invoice Date
                                </h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                    Select Invoice Date
                                </p>
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                <input
                                    type="date"
                                    id="invoice_date"
                                    name="invoice_date"
                                    className="focus:border-sky-600 focus:ring-sky-600"
                                    defaultValue={invoice?.invoice_date}
                                    onChange={(e) =>
                                        handleInvoiceDateChange(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="divide-y divide-gray-200 pt-8 sm:space-y-5 ">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
                            <label
                                htmlFor="due_date"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Due Date
                                </h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                    Select Due Date
                                </p>
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                <input
                                    type="date"
                                    id="due_date"
                                    name="due_date"
                                    className="focus:border-sky-600 focus:ring-sky-600"
                                    defaultValue={invoice?.due_date}
                                    onChange={(e) =>
                                        handleDueDateChange(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                        <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                                Personal Information
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                Use a permanent address where you can receive
                                mail.
                            </p>
                        </div>
                        <div className="space-y-6 sm:space-y-5">
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                <label
                                    htmlFor="first-name"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    Choose a Customer, or create a new one
                                </label>
                                <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                    <select
                                        value={customerId}
                                        onChange={(e) =>
                                            changeCustomer(e.target.value)
                                        }
                                        className="focus:border-sky-600 focus:ring-sky-600"
                                    >
                                        <option value="-1">Select</option>
                                        <option value="-2">New Customer</option>
                                        {customers &&
                                            customers.map((customer, index) => (
                                                <option value={customer.id}>
                                                    {customer.first_name}{' '}
                                                    {customer.last_name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            {customers && customerId > 0 && (
                                <>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="first-name"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Full Name
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                            <input
                                                type="text"
                                                name="first-name"
                                                id="first-name"
                                                placeholder="First name"
                                                value={state.invoice.first_name}
                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:max-w-xs sm:text-sm disabled:bg-slate-50"
                                                disabled={
                                                    customerId >= 0
                                                        ? true
                                                        : false
                                                }
                                                onChange={(e) =>
                                                    dispatch(
                                                        updateCustomer([
                                                            {
                                                                key: 'first_name',
                                                                value: e.target
                                                                    .value,
                                                            },
                                                        ])
                                                    )
                                                }
                                                required
                                            />
                                            <input
                                                type="text"
                                                name="last-name"
                                                id="last-name"
                                                placeholder="Last name"
                                                value={state.invoice.last_name}
                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:max-w-xs sm:text-sm disabled:bg-slate-50"
                                                disabled={
                                                    customerId >= 0
                                                        ? true
                                                        : false
                                                }
                                                onChange={(e) =>
                                                    dispatch(
                                                        updateCustomer([
                                                            {
                                                                key: 'last_name',
                                                                value: e.target
                                                                    .value,
                                                            },
                                                        ])
                                                    )
                                                }
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Email address
                                        </label>

                                        <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                            <div className="flex flex-row">
                                                <div className="flex-col gap-4 w-100">
                                                    {state.invoice.emails &&
                                                        state.invoice.emails.map(
                                                            (email, index) => (
                                                                <input
                                                                    id="email"
                                                                    name="email"
                                                                    type="email"
                                                                    placeholder="Email"
                                                                    value={
                                                                        email.email
                                                                    }
                                                                    className="mt-1 block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm disabled:bg-slate-50"
                                                                    disabled={
                                                                        customerId >=
                                                                        0
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    required
                                                                />
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="country"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Address
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0 flex flex-col gap-4">
                                            <div className="flex gap-4">
                                                <input
                                                    type="text"
                                                    name="street-address"
                                                    id="street-address"
                                                    placeholder="Street address"
                                                    value={
                                                        state.invoice
                                                            .billing_address
                                                    }
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm disabled:bg-slate-50"
                                                    disabled={
                                                        customerId >= 0
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) =>
                                                        dispatch(
                                                            updateCustomer([
                                                                {
                                                                    key: 'billing_address',
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                },
                                                            ])
                                                        )
                                                    }
                                                    required
                                                />
                                                <input
                                                    type="text"
                                                    name="city"
                                                    id="city"
                                                    placeholder="City"
                                                    value={
                                                        state.invoice
                                                            .billing_city
                                                    }
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm disabled:bg-slate-50"
                                                    disabled={
                                                        customerId >= 0
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) =>
                                                        dispatch(
                                                            updateCustomer([
                                                                {
                                                                    key: 'billing_city',
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                },
                                                            ])
                                                        )
                                                    }
                                                    required
                                                />
                                            </div>
                                            <div className="flex gap-4">
                                                <input
                                                    type="text"
                                                    name="state-province"
                                                    id="state-province"
                                                    placeholder="State/Province"
                                                    value={
                                                        state.invoice
                                                            .billing_province
                                                    }
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm disabled:bg-slate-50"
                                                    disabled={
                                                        customerId >= 0
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) =>
                                                        dispatch(
                                                            updateCustomer([
                                                                {
                                                                    key: 'billing_province',
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                },
                                                            ])
                                                        )
                                                    }
                                                    required
                                                />
                                                <input
                                                    type="text"
                                                    name="postal-code"
                                                    id="postal-code"
                                                    placeholder="Postal Code"
                                                    value={
                                                        state.invoice
                                                            .billing_postal_code
                                                    }
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm disabled:bg-slate-50"
                                                    disabled={
                                                        customerId >= 0
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) =>
                                                        dispatch(
                                                            updateCustomer([
                                                                {
                                                                    key: 'billing_postal_code',
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                },
                                                            ])
                                                        )
                                                    }
                                                    required
                                                />
                                                <select
                                                    id="country"
                                                    name="country"
                                                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-sky-600 focus:outline-none focus:ring-sky-600 sm:text-sm disabled:bg-slate-50"
                                                    disabled={
                                                        customerId >= 0
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) =>
                                                        dispatch(
                                                            updateCustomer([
                                                                {
                                                                    key: 'billing_country',
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                },
                                                            ])
                                                        )
                                                    }
                                                    required
                                                >
                                                    <option value="">
                                                        Select Country
                                                    </option>
                                                    {countries.map(
                                                        (country) => (
                                                            <option
                                                                value={country}
                                                                selected={
                                                                    country ==
                                                                    state
                                                                        .invoice
                                                                        .billing_country
                                                                }
                                                            >
                                                                {country}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Tax Rate
                                        </label>

                                        <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                            <div className="flex flex-row">
                                                <div className="flex-col gap-4 w-100">
                                                    <select
                                                        id="tax-rates"
                                                        name="tax-rates"
                                                        className="mt-1 block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                        value={taxRate}
                                                        // onChange={(e) => {setTax(parseInt(e.target.value))}}
                                                        disabled
                                                    >
                                                        <option>
                                                            Select Tax
                                                        </option>
                                                        {taxes &&
                                                            taxes.map(
                                                                (
                                                                    tax,
                                                                    index
                                                                ) => (
                                                                    <option
                                                                        value={
                                                                            tax.id
                                                                        }
                                                                    >
                                                                        {
                                                                            tax.name
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {customerId == -2 && (
                                <CustomersNew
                                    callback={callback}
                                    onCancel={onCancel}
                                />
                            )}
                        </div>
                    </div>

                    <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Payment Methods for this invoice
                        </h3>
                        <div className="space-y-6 sm:space-y-5 items-start">
                            {getPaymentMethodsQuery.isSuccess &&
                                !getPaymentMethodsQuery.isFetching &&
                                !getPaymentMethodsQuery.isLoading && (
                                    <>
                                        <fieldset>
                                            <legend className="sr-only">
                                                Default Payment Options
                                            </legend>
                                            <div className="space-y-5">
                                                <div className="relative flex items-start">
                                                    <div className="flex h-6 items-center">
                                                        <input
                                                            id="credit-card"
                                                            type="checkbox"
                                                            checked={
                                                                paymentOptions.credit_card
                                                            }
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                            onChange={() =>
                                                                handlePaymentOptionChange(
                                                                    'credit-card'
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="ml-3 text-sm leading-6">
                                                        <label
                                                            htmlFor="credit-card"
                                                            className="font-medium text-gray-900"
                                                        >
                                                            Credit Card
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="relative flex items-start">
                                                    <div className="flex h-6 items-center">
                                                        <input
                                                            id="e-transfer"
                                                            type="checkbox"
                                                            checked={
                                                                paymentOptions.etransfer
                                                            }
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                            onChange={() =>
                                                                handlePaymentOptionChange(
                                                                    'e-transfer'
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="ml-3 text-sm leading-6">
                                                        <label
                                                            htmlFor="e-transfer"
                                                            className="font-medium text-gray-900"
                                                        >
                                                            Interac e-Transfer
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="relative flex items-start">
                                                    <div className="flex h-6 items-center">
                                                        <input
                                                            id="wire-transfer"
                                                            type="checkbox"
                                                            checked={
                                                                paymentOptions.wire_transfer
                                                            }
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                            onChange={() =>
                                                                handlePaymentOptionChange(
                                                                    'wire-transfer'
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="ml-3 text-sm leading-6">
                                                        <label
                                                            htmlFor="wire-transfer"
                                                            className="font-medium text-gray-900"
                                                        >
                                                            Wire Transfer
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="relative flex items-start">
                                                    <div className="flex h-6 items-center">
                                                        <input
                                                            id="crypto"
                                                            type="checkbox"
                                                            checked={
                                                                paymentOptions.crypto
                                                            }
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                            onChange={() =>
                                                                handlePaymentOptionChange(
                                                                    'crypto'
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="ml-3 text-sm leading-6">
                                                        <label
                                                            htmlFor="crypto"
                                                            className="font-medium text-gray-900"
                                                        >
                                                            Crypto
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="relative flex items-start">
                                                    <div className="flex h-6 items-center">
                                                        <input
                                                            id="check"
                                                            type="checkbox"
                                                            checked={
                                                                paymentOptions.check
                                                            }
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                            onChange={() =>
                                                                handlePaymentOptionChange(
                                                                    'check'
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="ml-3 text-sm leading-6">
                                                        <label
                                                            htmlFor="check"
                                                            className="font-medium text-gray-900"
                                                        >
                                                            Check
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        {validPaymentOptions === false && (
                                            <p className="text-red-600 font-medium">
                                                You must have at least one
                                                payment option selected
                                            </p>
                                        )}
                                    </>
                                )}
                            {(getPaymentMethodsQuery.isLoading ||
                                getPaymentMethodsQuery.isFetching) && (
                                <SkeletonLoader
                                    rows={4}
                                    columns={1}
                                    isError={getPaymentMethodsQuery.isError}
                                />
                            )}
                            {getPaymentMethodsQuery.isError &&
                                !getPaymentMethodsQuery.isLoading &&
                                !getPaymentMethodsQuery.isFetching && (
                                    <p className="text-red-600 font-medium">
                                        There was an error loading the payment
                                        methods for this invoice. Please refresh
                                        the page and try again.
                                    </p>
                                )}
                        </div>
                    </div>
                    <div className="space-y-6 divide-y divide-gray-200 pt-8 sm:space-y-5 sm:pt-10">
                        <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                                Invoice Line Items
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                Add, edit, and delete any products from the
                                invoice
                            </p>
                        </div>

                        <div className="space-y-6 divide-y divide-gray-200 sm:space-y-5 overflow-x-auto overflow-y-hidden">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                                        >
                                            Quantity
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                                        >
                                            Price
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                                        >
                                            Product
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                                        >
                                            Description
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                                        >
                                            Tax
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                                        >
                                            Sub
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                                        >
                                            Tax
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                                        >
                                            Grand
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                                        ></th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    <LineItem
                                        id={id}
                                        products={products ? products : []}
                                        taxes={taxes ? taxes : []}
                                        defaultTax={taxRate}
                                        lineItems={state.invoice.line_items}
                                        invoiceUpdate={isNewCallback}
                                    />
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <h2 className="font-bold mt-10 mb-5">
                        Payments for this invoice:{' '}
                    </h2>

                    <button
                        onClick={() => setAddPaymentModalOpen(true)}
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Add Payment
                    </button>

                    <div className="">
                        <table className="min-w-full divide-y divide-gray-300 overflow-hidden ring-1 ring-black ring-opacity-5 md:rounded-lg ">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                        Amount
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                        Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                        Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                        Type
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {paymentHistoryQuery.isSuccess &&
                                    paymentHistory &&
                                    paymentHistory?.length > 0 &&
                                    paymentHistory?.map((payment, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 text-left ">
                                                    ${payment.amount}
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-500 text-left ">
                                                    {payment.name_on_card}
                                                </td>
                                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 text-left ">
                                                    {formatDate(
                                                        payment.process_date
                                                    )}
                                                </td>
                                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 text-left ">
                                                    {getHumanReadableType(
                                                        payment.type
                                                    )}
                                                </td>
                                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 text-left ">
                                                    {getHumanReadableStatus(
                                                        payment.status_code
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    })}

                                {paymentHistoryQuery.isSuccess &&
                                    paymentHistory &&
                                    paymentHistory?.length === 0 && (
                                        <tr className=" bg-gray-50">
                                            <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 text-left italic ">
                                                No Payments Made
                                            </td>
                                        </tr>
                                    )}

                                {paymentHistoryQuery.isError && (
                                    <tr className="bg-gray-50">
                                        <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-red-600  italic text-left ">
                                            There was an error loading the
                                            payments. If this error persists,
                                            please contact support.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
