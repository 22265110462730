import { Navigate } from 'react-router-dom'
import AccountRejected from '../account-rejected/AccountRejected'
import AccountSuspended from '../account-suspended/AccountSuspended'
import PendingApproval from '../pending-approval/PendingApproval'
import { useAppSelector } from '../redux/hooks'

export default function HandleAccountStatus() {
    const authUser = useAppSelector((state) => state.auth_user)

    const accountStatus = authUser?.account_status

    if (accountStatus) {
        if (
            accountStatus === 'PENDING_APPROVAL' ||
            accountStatus === 'WAITING'
        ) {
            return <PendingApproval />
        } else if (accountStatus === 'REJECTED') {
            return <AccountRejected />
        } else if (accountStatus === 'SUSPENDED') {
            return <AccountSuspended />
        } else {
            return <Navigate to="/home" />
        }
    } else {
        return <Navigate to="/login" />
    }
}
