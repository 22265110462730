import { UserRepository } from '../api/UserRepositoy'
import { useQuery } from 'react-query'
import { setError } from '../redux/state-slices/GlobalNotificationSlice'
import { useAppDispatch } from '../redux/hooks'
import Loading from '../shared-components/Loading'
import { Switch } from '@headlessui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getReadableEftProvider } from '../utils/UserUtils'

interface Props {
    userCopy: Paywell.API.V2.User | null
    isAdmin: boolean
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function UserApiConfigs({ userCopy, isAdmin }: Props) {
    const userRepository = new UserRepository()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const fetchUserInteracInfo = async () => {
        if (!userCopy?.id) return
        return (await userRepository.getUserInteracInfo(userCopy?.id)).data
    }

    const userInteracInfoQuery = useQuery(
        ['interac-info', userCopy?.id],
        fetchUserInteracInfo,
        {
            onError: (error) => {
                dispatch(
                    setError([
                        'An error has occurred while fetching interac details.',
                    ])
                )
            },
            refetchOnWindowFocus: false,
        }
    )

    const interacInfo = userInteracInfoQuery.data
    return (
        <div className={`p-10`}>
            {isAdmin && (
                <div className="mb-2 flex justify-end">
                    <button
                        onClick={() =>
                            navigate(
                                '/users/' +
                                    userCopy?.id +
                                    '/api-configurations/edit'
                            )
                        }
                        type="button"
                        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Edit Page
                    </button>
                </div>
            )}
            <div className={'border border-gray-100 rounded-md p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        API Configurations
                    </h3>
                </div>
                <div className="mt-6 border-t border-gray-100 grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    NMI Public API Key
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.nmi_public_key}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    NMI Private API Key
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.nmi_private_key}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Default callback url
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.default_callback_url
                                        ? userCopy.default_callback_url
                                        : 'No default URL'}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Callback type
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.callback_type}
                                </dd>
                            </div>
                        </dl>
                    </div>
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Payment support email
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.payment_support_email}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Generate Order Numbers
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.gen_order_nums ? 'Yes' : 'No'}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900 block">
                                    <span>Request e-Transfer API</span>
                                    <div className={'font-light text-gray-500'}>
                                        If request e-Transfer API is on, the
                                        merchant will have the ability to use
                                        the V2 e-Transfer api (V2 e-Transfer).
                                    </div>
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.request_money ? 'Yes' : 'No'}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <div className={'border border-gray-100 rounded-md p-2 mt-4'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Interac e-Transfer Configuration
                    </h3>
                </div>
                {(userInteracInfoQuery.isLoading ||
                    userInteracInfoQuery.isFetching) && (
                    <div className="flex justify-center items-center h-32">
                        <Loading height={'8'} width={'8'} />
                    </div>
                )}
                {userInteracInfoQuery.isSuccess &&
                    !userInteracInfoQuery.isLoading &&
                    !userInteracInfoQuery.isFetching && (
                        <div className="mt-6 border-t border-gray-100 grid grid-cols-1 md:grid-cols-2 gap-2">
                            <div>
                                <dl className="divide-y divide-gray-100">
                                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                            DC Bank DDT ID
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            {interacInfo?.ddt_id}
                                        </dd>
                                    </div>
                                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                            IMAP Hostname
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 break-words">
                                            {interacInfo?.imap_hostname}
                                        </dd>
                                    </div>
                                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                            IMAP Username/e-Transfer Deposit
                                            Email
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 break-words">
                                            {interacInfo?.interac_email}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                            <div>
                                <dl className="divide-y divide-gray-100">
                                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                            IMAP Password/e-Transfer Deposit
                                            Email Password
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 break-words">
                                            {interacInfo?.interac_hash}
                                        </dd>
                                    </div>
                                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 block">
                                            Password for manual e-Transfers
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 break-words">
                                            {interacInfo?.interac_password}
                                        </dd>
                                    </div>
                                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                            e-Transfer Display Email
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 break-words">
                                            {interacInfo?.alias_interac_email}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    )}
            </div>
            <div className={'border border-gray-100 rounded-md mt-4 p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Wire Transfer Info
                    </h3>
                </div>

                <div className="mt-6 border-t border-gray-100 grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Account Number
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.wire_transfer_account_number}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Routing Number
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.wire_transfer_routing_number}
                                </dd>
                            </div>
                        </dl>
                    </div>
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Institution Number
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.wire_transfer_institution_number}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <div className={'border border-gray-100 rounded-md mt-4 p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        EFT Provider
                    </h3>
                </div>

                <div className="mt-6 border-t border-gray-100 grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Eft Provider
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {getReadableEftProvider(
                                        userCopy?.eft_provider_id || 0
                                    )}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <div className={'border border-gray-100 rounded-md p-2 mt-4'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Settlement Configuration
                    </h3>
                </div>
                <div className="mt-6 border-t border-gray-100 grid grid-cols-2 gap-2">
                    <dl className="divide-y divide-gray-100">
                        <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                Automatic settlements
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <Switch
                                    checked={userCopy?.auto_settlement}
                                    className={classNames(
                                        userCopy?.auto_settlement
                                            ? 'bg-indigo-600'
                                            : 'bg-gray-200',
                                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                                    )}
                                >
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            userCopy?.auto_settlement
                                                ? 'translate-x-5'
                                                : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                        )}
                                    />
                                </Switch>
                            </dd>
                        </div>
                    </dl>
                    <dl className="divide-y divide-gray-100">
                        <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                Daily Batch Email
                            </dt>
                            <Switch
                                checked={userCopy?.email_batches}
                                className={classNames(
                                    userCopy?.email_batches
                                        ? 'bg-indigo-600'
                                        : 'bg-gray-200',
                                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                                )}
                            >
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        userCopy?.email_batches
                                            ? 'translate-x-5'
                                            : 'translate-x-0',
                                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                    )}
                                />
                            </Switch>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    )
}
