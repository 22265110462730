import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { InvoicesRepository } from '../../api/InvoicesRepository'

import SkeletonLoader from '../components/SkeletonLoader'
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
    setError,
    setSuccess,
} from '../../redux/state-slices/GlobalNotificationSlice'
import { useEffect, useState } from 'react'
import Loading from '../../shared-components/Loading'
import {
    getHumanReadableStatus,
    getHumanReadableType,
} from '../../utils/TransactionHelper'
import { formatDate } from '../../utils/StringUtils'
import NumberFormatDollars from '../../utils/NumberFormatDollars'

export default function InvoiceView() {
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const invoiceRepository = new InvoicesRepository()
    const invoiceQuery = useQuery(['invoice', id], async () => {
        return await invoiceRepository.getInvoice(id ? id : '0')
    })

    const [paymentOptions, setSelectedPaymentOptions] =
        useState<Paywell.API.V2.PaymentMethodOptions>({
            id: 0,
            credit_card: false,
            etransfer: false,
            wire_transfer: false,
            crypto: false,
            check: false,
        })

    const emailInvoiceQuery = useQuery(
        'email-invoice',
        async () => {
            return await invoiceRepository.sendInvoiceEmail(id ? id : '0')
        },
        {
            enabled: false,
            staleTime: Infinity,
            retry: false,
            onSuccess: () => {
                dispatch(
                    setSuccess(['Email successfully sent to all recipients'])
                )
            },
            onError: () => {
                dispatch(
                    setError([
                        'Error sending email. Please try again. If the problem persists, please contact support.',
                    ])
                )
            },
        }
    )

    const invoice = invoiceQuery.data?.data
    const [paymentHistory, setPaymentHistory] = useState<
        Paywell.API.V2.Transaction[] | null
    >(null)

    useEffect(() => {
        if (invoice?.uuid) {
            paymentHistoryQuery.refetch()
        }
    }, [invoice?.uuid])

    const paymentHistoryQuery = useQuery(
        ['paymentHistory', invoice?.uuid],
        async () => {
            return await invoiceRepository.getPaymentHistory(
                invoice?.uuid || ''
            )
        },
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                setPaymentHistory(data.data)
            },
            onError: (error) => {},
        }
    )

    const getPaymentMethodsQuery = useQuery(
        'payment-methods-invoice-edit',
        async () => {
            return await invoiceRepository.getPaymentMethods(invoice?.id)
        },
        {
            enabled: !!invoiceQuery.data?.data?.id,
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            retry: false,
            onSuccess: (data) => {
                const paymentMethods: Paywell.API.V2.PaymentMethodOptions = {
                    id: data?.data?.id,
                    credit_card: data?.data?.credit_card ? true : false,
                    etransfer: data?.data?.etransfer ? true : false,
                    wire_transfer: data?.data?.wire_transfer ? true : false,
                    crypto: data?.data?.crypto ? true : false,
                    check: data?.data?.check ? true : false,
                }

                setSelectedPaymentOptions(paymentMethods)
            },
            onError: (error) => {},
        }
    )

    const checkoutURL = `${window.location.origin}/invoice/${id}`

    const sendEmail = () => {
        if (invoice?.uuid) {
            emailInvoiceQuery.refetch()
        }
    }
    const noInvoiceUUID = !invoice?.uuid

    return (
        <div className="h-auto ">
            <div className="sm:flex flex-row sm:items-center border-b-2 border-grey-500 pb-6">
                <div className="sm:flex-auto p-8">
                    <h1 className="text-xl font-semibold text-gray-900">
                        Viewing Invoice
                    </h1>
                    <p className="mt-2 text-sm text-gray-700 font-bold">
                        Invoice{' '}
                        {!invoice?.invoice_number ? (
                            <div className="animate-pulse inline-block">
                                <div className="h-2 w-8 bg-slate-200 rounded col-span-2 mt-2"></div>
                            </div>
                        ) : (
                            '#' + invoice?.invoice_number
                        )}{' '}
                        for{' '}
                        {!invoice?.billing_name ? (
                            <div className="animate-pulse inline-block">
                                <div className="h-2 w-28 bg-slate-200 rounded col-span-2 mt-2"></div>
                            </div>
                        ) : (
                            invoice?.billing_name
                        )}
                    </p>
                </div>
                <Link
                    to={`/invoicing/invoices/edit/${id}`}
                    type="submit"
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                >
                    Edit
                </Link>
            </div>
            <div className="flex justify-center w-1/4 m-auto p-2">
                <button
                    className="rounded-md cursor-pointer border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 disabled:bg-gray-400 disabled:cursor-not-allowed"
                    onClick={sendEmail}
                    disabled={
                        emailInvoiceQuery.isLoading ||
                        emailInvoiceQuery.isFetching ||
                        noInvoiceUUID
                    }
                >
                    Email Invoice
                </button>
                <ClipboardDocumentIcon
                    onClick={() => {
                        /* navigator.clipboard.writeText(checkoutURL)
                        dispatch(setSuccess(['Copied to clipboard']))*/
                        window.open(checkoutURL, '_blank')
                    }}
                    className="h-8 w-8 cursor-pointer ml-4 mt-1"
                />
            </div>
            <div className="bg-slate-50 px-8 pb-32 pt-12 ">
                <div className="flex flex-row justify-between pb-32">
                    <div className="flex flex-col w-3/4 text-lg">
                        <span className="text-5xl pb-12 font-bold">
                            {!invoice?.user_business_name
                                ? ''
                                : invoice?.user_business_name}{' '}
                        </span>
                        <span>{invoice?.tax_number}</span>
                        <span>
                            {!invoice?.user_first_name
                                ? ''
                                : invoice?.user_first_name}{' '}
                            {!invoice?.user_last_name
                                ? ''
                                : invoice?.user_last_name}
                        </span>
                        <span>
                            {!invoice?.user_address
                                ? ''
                                : invoice?.user_address}{' '}
                            {!invoice?.user_city
                                ? ''
                                : invoice?.user_city + ', '}{' '}
                            {!invoice?.user_province
                                ? ''
                                : invoice?.user_province + ', '}{' '}
                            {!invoice?.user_country
                                ? ''
                                : invoice?.user_country + ', '}{' '}
                            {!invoice?.user_postal ? '' : invoice?.user_postal}
                        </span>
                        <span>
                            {!invoice?.user_telephone
                                ? ''
                                : invoice?.user_telephone}
                        </span>
                    </div>
                    <div className="flex flex-col justify-end float-right">
                        <span className="text-5xl pb-12 text-center font-bold">
                            Invoice
                        </span>
                        <span>
                            <img src="http://localhost:3000/apple-touch-icon.png" />
                        </span>
                    </div>
                </div>
                <div className="flex flex-row justify-between pb-32 text-lg">
                    <div className="flex flex-col w-1/2">
                        <span className="border-b-2 border-grey-500 font-bold">
                            Bill to:
                        </span>
                        <span>
                            {!invoice?.billing_name
                                ? ''
                                : invoice?.billing_name}
                        </span>
                        <span>
                            {!invoice?.billing_address
                                ? ''
                                : invoice?.billing_address + ' '}
                            {!invoice?.billing_city
                                ? ''
                                : invoice?.billing_city + ', '}
                            {!invoice?.billing_province
                                ? ''
                                : invoice?.billing_province + ', '}
                            {!invoice?.billing_country
                                ? ''
                                : invoice?.billing_country + ', '}
                            {!invoice?.billing_postal_code
                                ? ''
                                : invoice?.billing_postal_code}
                        </span>
                    </div>
                    <div className="flex flex-col justify-end float-right w-1/3">
                        <div className="flex flex-row justify-between">
                            <span className="font-bold text-right">
                                Invoice No:
                            </span>
                            <span>
                                {!invoice?.invoice_number
                                    ? ''
                                    : invoice?.invoice_number}
                            </span>
                        </div>

                        <div className="flex flex-row justify-between">
                            <span className="font-bold text-right">
                                Invoice Date:
                            </span>
                            <span>
                                {!invoice?.invoice_date
                                    ? ''
                                    : invoice?.invoice_date}
                            </span>
                        </div>
                        <div className="flex flex-row justify-between">
                            <span className="font-bold text-right">
                                Due Date:
                            </span>
                            <span>
                                {!invoice?.due_date ? '' : invoice?.due_date}
                            </span>
                        </div>
                        <div className="flex flex-row justify-between">
                            <span className="font-bold text-right">
                                Outstanding Balance:
                            </span>
                            <span>
                                {!invoice?.outstanding_balance ? (
                                    <div className="animate-pulse">
                                        <div className="h-2 w-28 bg-slate-200 rounded col-span-2 mt-2"></div>
                                    </div>
                                ) : (
                                    NumberFormatDollars(
                                        invoice?.outstanding_balance
                                    )
                                )}
                            </span>
                        </div>
                    </div>
                </div>

                <table className="min-w-full divide-y divide-gray-300 overflow-hidden ring-1 ring-black ring-opacity-5 md:rounded-lg ">
                    <thead className="bg-gray-50">
                        <tr>
                            <th
                                scope="col"
                                className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                                Description
                            </th>
                            <th
                                scope="col"
                                className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                                Qty
                            </th>
                            <th
                                scope="col"
                                className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                                Unit Price
                            </th>
                            <th
                                scope="col"
                                className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                                Subtotal
                            </th>
                            <th
                                scope="col"
                                className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                                Tax Total
                            </th>
                            <th
                                scope="col"
                                className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                                Total
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {!invoice?.line_items && (
                            <SkeletonLoader
                                rows={3}
                                columns={6}
                                isError={invoiceQuery.isError}
                            />
                        )}
                        {invoice?.line_items &&
                            invoice?.line_items.map((line, index) => (
                                <tr key={index}>
                                    <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 text-left ">
                                        {line.products.name}
                                    </td>
                                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 text-left ">
                                        {line.quantity}
                                    </td>
                                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 text-left ">
                                        {NumberFormatDollars(
                                            line.cost_per_unit
                                        )}
                                    </td>
                                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 text-left ">
                                        {NumberFormatDollars(line.subtotal)}
                                    </td>
                                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 text-left ">
                                        {NumberFormatDollars(line.tax_total)}
                                    </td>
                                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 text-left ">
                                        {NumberFormatDollars(line.grand_total)}
                                    </td>
                                </tr>
                            ))}
                        <tr className="h-10 border-transparent">
                            <th colSpan={5} className="text-right">
                                Subtotal:
                            </th>
                            <td colSpan={3} className="text-center pl-5">
                                {!invoice?.invoice_subtotal ? (
                                    <div className="animate-pulse inline-block">
                                        <div className="h-2 w-20 bg-slate-200 rounded col-span-2 mt-2"></div>
                                    </div>
                                ) : (
                                    NumberFormatDollars(
                                        invoice?.invoice_subtotal
                                    )
                                )}
                            </td>
                        </tr>
                        <tr className="h-10">
                            <th colSpan={5} className="text-right">
                                Tax Total:
                            </th>
                            <td colSpan={1} className="text-center pl-5">
                                {!invoice?.invoice_tax_total ? (
                                    <div className="animate-pulse inline-block">
                                        <div className="h-2 w-20 bg-slate-200 rounded col-span-2 mt-2"></div>
                                    </div>
                                ) : (
                                    NumberFormatDollars(
                                        invoice?.invoice_tax_total
                                    )
                                )}
                            </td>
                        </tr>
                        <tr className="h-10">
                            <th colSpan={5} className="text-right">
                                Grand Total:
                            </th>
                            <td colSpan={1} className="text-center pl-5">
                                {!invoice?.invoice_grand_total ? (
                                    <div className="animate-pulse inline-block">
                                        <div className="h-2 w-20 bg-slate-200 rounded col-span-2 mt-2"></div>
                                    </div>
                                ) : (
                                    NumberFormatDollars(
                                        invoice?.invoice_grand_total
                                    )
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h2 className="font-bold mt-10 mb-5">
                    Payments for this invoice:{' '}
                </h2>

                <table className="min-w-full divide-y divide-gray-300 overflow-hidden ring-1 ring-black ring-opacity-5 md:rounded-lg ">
                    <thead className="bg-gray-50">
                        <tr>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                                Amount
                            </th>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                                Name
                            </th>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                                Date
                            </th>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                                Type
                            </th>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {paymentHistoryQuery.isSuccess &&
                            paymentHistory &&
                            paymentHistory?.length > 0 &&
                            paymentHistory?.map((payment, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="py-4 pl-6 pr-3 text-sm font-medium text-gray-900 text-left ">
                                            ${payment.amount}
                                        </td>
                                        <td className="py-4 pl-6 pr-3 text-sm font-medium text-gray-500 text-left ">
                                            {payment.name_on_card}
                                        </td>
                                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 text-left ">
                                            {formatDate(payment.process_date)}
                                        </td>
                                        <td className="py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 text-left ">
                                            {getHumanReadableType(payment.type)}
                                        </td>
                                        <td className="py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 text-left ">
                                            {getHumanReadableStatus(
                                                payment.status_code
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}

                        {paymentHistoryQuery.isSuccess &&
                            paymentHistory &&
                            paymentHistory?.length === 0 && (
                                <tr className=" bg-gray-50">
                                    <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 text-left italic ">
                                        No Payments Made
                                    </td>
                                </tr>
                            )}

                        {paymentHistoryQuery.isError && (
                            <tr className="bg-gray-50">
                                <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-red-600  italic text-left ">
                                    There was an error loading the payments. If
                                    this error persists, please contact support.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <h2 className="font-bold mt-10 mb-5">
                    Payment methods for this invoice
                </h2>
                <fieldset>
                    <legend className="sr-only">Default Payment Options</legend>
                    {getPaymentMethodsQuery.isSuccess &&
                        !getPaymentMethodsQuery.isFetching &&
                        !getPaymentMethodsQuery.isLoading && (
                            <div className="space-y-5">
                                <div className="relative flex items-start">
                                    <div className="flex h-6 items-center">
                                        <input
                                            id="credit-card"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0 hover:cursor-not-allowed bg-gray-200"
                                            checked={
                                                paymentOptions?.credit_card
                                            }
                                        />
                                    </div>
                                    <div className="ml-3 text-sm leading-6">
                                        <label
                                            htmlFor="credit-card"
                                            className="font-medium text-gray-900"
                                        >
                                            Credit Card
                                        </label>
                                    </div>
                                </div>
                                <div className="relative flex items-start">
                                    <div className="flex h-6 items-center">
                                        <input
                                            id="e-transfer"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0 hover:cursor-not-allowed bg-gray-200"
                                            checked={paymentOptions?.etransfer}
                                        />
                                    </div>
                                    <div className="ml-3 text-sm leading-6">
                                        <label
                                            htmlFor="e-transfer"
                                            className="font-medium text-gray-900"
                                        >
                                            Interac e-Transfer
                                        </label>
                                    </div>
                                </div>
                                <div className="relative flex items-start">
                                    <div className="flex h-6 items-center">
                                        <input
                                            id="wire-transfer"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0 hover:cursor-not-allowed bg-gray-200"
                                            checked={
                                                paymentOptions?.wire_transfer
                                            }
                                        />
                                    </div>
                                    <div className="ml-3 text-sm leading-6">
                                        <label
                                            htmlFor="wire-transfer"
                                            className="font-medium text-gray-900"
                                        >
                                            Wire Transfer
                                        </label>
                                    </div>
                                </div>
                                <div className="relative flex items-start">
                                    <div className="flex h-6 items-center">
                                        <input
                                            id="crypto"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0 hover:cursor-not-allowed bg-gray-200"
                                            checked={paymentOptions?.crypto}
                                        />
                                    </div>
                                    <div className="ml-3 text-sm leading-6">
                                        <label
                                            htmlFor="crypto"
                                            className="font-medium text-gray-900"
                                        >
                                            Crypto
                                        </label>
                                    </div>
                                </div>
                                <div className="relative flex items-start">
                                    <div className="flex h-6 items-center">
                                        <input
                                            id="check"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0 hover:cursor-not-allowed bg-gray-200"
                                            checked={paymentOptions?.check}
                                        />
                                    </div>
                                    <div className="ml-3 text-sm leading-6">
                                        <label
                                            htmlFor="check"
                                            className="font-medium text-gray-900"
                                        >
                                            Check
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )}
                    {getPaymentMethodsQuery.isLoading ||
                        (getPaymentMethodsQuery.isFetching ? (
                            <SkeletonLoader
                                rows={4}
                                columns={1}
                                isError={getPaymentMethodsQuery.isError}
                            />
                        ) : null)}
                    {getPaymentMethodsQuery.isError &&
                        !getPaymentMethodsQuery.isLoading &&
                        !getPaymentMethodsQuery.isFetching && (
                            <p className="text-red-600 font-medium">
                                There was an error loading the payment methods
                                for this invoice. Please refresh the page and
                                try again.
                            </p>
                        )}
                </fieldset>
            </div>
        </div>
    )
}
