import { useEffect, useState } from 'react'
import { ScaleIcon } from '@heroicons/react/24/outline'
import Error from '../shared-components/Error'
import { useAppSelector } from '../redux/hooks'
import { useQuery } from 'react-query'
import { HomeRepository } from '../api/HomeRepository'
import Loading from '../shared-components/Loading'
import NumberFormatDollars from '../utils/NumberFormatDollars'
export default function Home() {
    const authUser = useAppSelector((state) => state.auth_user)
    const branding = useAppSelector((state) => state.branding)
    const name = authUser?.merchant_name
    const homeRepository = new HomeRepository()
    const [transactionItems, setTransactionItems] =
        useState<Array<Paywell.API.V2.TransactionOverViewItem>>()

    useEffect(() => {
        getTransactionOverview.refetch()
    }, [transactionItems])

    const getTransactionOverview = useQuery(
        'transaction-overview',
        async () => {
            return (await homeRepository.getHome()).data
        },
        {
            enabled: true,
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                setTransactionItems(data)
            },
            onError: (error) => {},
        }
    )

    if (getTransactionOverview.isLoading || getTransactionOverview.isFetching)
        return (
            <div className="flex h-screen">
                <div className="m-auto">
                    <Loading height="8" width="8" />
                </div>
            </div>
        )

    if (getTransactionOverview.isError)
        return (
            <div className="flex items-center justify-center mt-4">
                <Error
                    title="An error has occurred"
                    message={`Please reach out to one of our representatives at ${branding.contact_email} if this error persists.`}
                />
            </div>
        )

    return (
        <div className="min-h-full">
            <div className="flex flex-1 flex-col ">
                <div className="flex-1">
                    <div className="">
                        <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
                            <div className="py-6 md:flex md:items-center md:justify-between">
                                <div className="min-w-0 flex-1">
                                    <div className="flex items-center">
                                        <div>
                                            <div className="flex items-center">
                                                <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                                                    {name}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* 
                                //WILL BE USED LATER
                                <div className="mt-6 flex space-x-3 md:ml-4 md:mt-0">
                                    <button
                                        type="button"
                                        className="inline-flex items-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                                    >
                                        View Recent Transactions
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="mt-8">
                        <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                            <h2 className="text-lg font-medium leading-6 text-gray-900">
                                Overview
                            </h2>
                            <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                                {/* Card */}
                                {transactionItems &&
                                    transactionItems.length > 0 &&
                                    transactionItems.map((card, idx) => (
                                        <div
                                            key={card.name}
                                            className="overflow-hidden rounded-lg bg-white shadow"
                                        >
                                            <div className="p-5">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        <ScaleIcon
                                                            className="h-6 w-6 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </div>
                                                    <div className="ml-5 w-0 flex-1">
                                                        <div>
                                                            <div className="text-sm font-medium text-gray-500">
                                                                {card.name}
                                                            </div>
                                                            <div>
                                                                <div className="text-lg font-medium text-gray-900">
                                                                    Total:{' '}
                                                                    {NumberFormatDollars(
                                                                        card.volume
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {transactionItems[
                                                    idx
                                                ].hasOwnProperty('count') && (
                                                    <div className="mt-2">
                                                        Total Transactions :{' '}
                                                        {card.count}
                                                    </div>
                                                )}

                                                {transactionItems[
                                                    idx
                                                ].hasOwnProperty('fees') && (
                                                    <div className="mt-2">
                                                        Total Fees:{' '}
                                                        {NumberFormatDollars(
                                                            card.fees
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
