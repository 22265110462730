import { useQuery, useQueryClient } from 'react-query'
import { EftsRepository } from '../api/EftsRepository'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import NumberFormatDollars from '../utils/NumberFormatDollars'
import { useAppDispatch } from '../redux/hooks'
import { setError } from '../redux/state-slices/GlobalNotificationSlice'
import Loading from '../shared-components/Loading'

export default function Efts() {
    const eftsRepository = new EftsRepository()
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const queryClient = useQueryClient()

    const getEfts = useQuery(
        ['efts', id],
        async () => {
            return await eftsRepository.getEfts(id || '0')
        },
        {
            enabled: false,
            retry: false,
            refetchOnWindowFocus: true,
            onSuccess: (data) => {},
        }
    )

    const fetchEft = (selectedEftId: number) => {
        return eftsRepository.downloadEft(selectedEftId)
    }

    const handleDownloadClick = (selectedEftId: number) => {
        // Invalidate queries to ensure fresh fetch
        queryClient
            .invalidateQueries(['download-eft', selectedEftId])
            .then(() => {
                return queryClient.fetchQuery(
                    ['download-eft', selectedEftId],
                    () => fetchEft(selectedEftId),
                    {
                        staleTime: Infinity,
                        retry: false,
                    }
                )
            })
            .then((data) => {
                // Handle success
            })
            .catch((error) => {
                dispatch(setError(['An error has occurred.']))
            })
    }

    useEffect(() => {
        if (id) {
            getEfts.refetch()
        }
    }, [id])

    const efts = getEfts.data?.data

    return (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">
                            Efts
                        </h1>
                    </div>
                </div>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                        >
                                            Scheduled Date
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Eft Provider
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total Transactions
                                        </th>
                                        <th
                                            scope="col"
                                            className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                                        >
                                            <span className="sr-only">
                                                download
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {getEfts.isSuccess &&
                                        efts &&
                                        efts.map((eft) => {
                                            return (
                                                <tr key={eft.id}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                        {eft.scheduled_date}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {NumberFormatDollars(
                                                            eft.total
                                                        )}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {eft.eft_provider_name}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {
                                                            eft.number_of_transactions
                                                        }
                                                    </td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                        <button
                                                            onClick={() =>
                                                                handleDownloadClick(
                                                                    eft.id
                                                                )
                                                            }
                                                            className="text-indigo-600 hover:text-indigo-900 disabled:opacity-50"
                                                        >
                                                            Download
                                                            <span className="sr-only">
                                                                download
                                                            </span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    {getEfts.isLoading && (
                                        <tr>
                                            <td
                                                colSpan={4}
                                                className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                                            >
                                                <Loading
                                                    height={'8'}
                                                    width={'8'}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {getEfts.isError && (
                                        <tr>
                                            <td
                                                colSpan={4}
                                                className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                                            >
                                                <span className="text-red-500">
                                                    There was an error loading
                                                    EFTs
                                                </span>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
