import { configureStore } from '@reduxjs/toolkit'
import userReducer from './state-slices/NewUserSlice'
import authUserReducer from './state-slices/AuthUserSlice'
import identityInfoReducer from './state-slices/IdentityInfoSlice'
import businessInfoReducer from './state-slices/BusinessInfoSlice'
import bankingInfoReducer from './state-slices/BankingInfoSlice'
import stepSliceReducer from './state-slices/StepSlice'
import globalNotificationReducer from './state-slices/GlobalNotificationSlice'
import invoiceReducer from './state-slices/InvoiceSlice'
import emailReducer from './state-slices/EmailSlice'
import brandingSliceReducer from './state-slices/BrandingSlice'
import onboardingSliceReducer from './state-slices/OnboardingTypeSlice'

export const store = configureStore({
    reducer: {
        // You are free to call the LHS what you like, but it must have a reducer on the RHS.
        new_user: userReducer,
        auth_user: authUserReducer,
        identity_info: identityInfoReducer,
        business_info: businessInfoReducer,
        banking_info: bankingInfoReducer,
        steps: stepSliceReducer,
        global_notification: globalNotificationReducer,
        invoice: invoiceReducer,
        customer: emailReducer,
        branding: brandingSliceReducer,
        onboarding_type: onboardingSliceReducer,
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
