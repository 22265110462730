import { PlusIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { useIsMutating, useMutation } from 'react-query'
import { LineItemsRepository } from '../../api/LineIttemsRepository'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
    setError,
    setWarning,
} from '../../redux/state-slices/GlobalNotificationSlice'
import {
    addLineItems,
    updateInvoice,
} from '../../redux/state-slices/InvoiceSlice'
import Loading from '../../shared-components/Loading'
import Line from './Line'
import ProductModal from '../invoices/components/ProductModal'

export default function LineItem({
    id,
    lineItems,
    products,
    defaultTax,
    taxes,
    invoiceUpdate,
}: {
    id: any
    lineItems: Paywell.API.V2.LineItems[]
    products: Paywell.API.V2.Products[]
    defaultTax: number
    taxes: Paywell.API.V2.Taxes[]
    invoiceUpdate: (product: any, tax: any) => void
}) {
    const dispatch = useAppDispatch()
    const state = useAppSelector((state) => state)
    const isInvoiceUpdating = useIsMutating(['invoiceLineitemUpdate'])
    const isLineItemDeleting = useIsMutating(['deleteLineItem'])
    const lineItemsRepository = new LineItemsRepository()

    const [addProduct, setAddProduct] = useState(false)
    const [loadingAdd, setLoadingAdd] = useState(false)

    const addLineItemMutation = useMutation({
        mutationFn: () =>
            lineItemsRepository.addLineItem(
                id,
                products ? products[0].id : 0,
                defaultTax,
                1,
                products ? products[0].price : 0
            ),
        onError: (err: any) => {
            if (err.data.message) dispatch(setError([err.data.message]))
            else
                dispatch(
                    setError([
                        'An error has occured. If this keeps happening, please contact support.',
                    ])
                )
        },

        onSuccess: (data) => {
            dispatch(
                addLineItems({
                    id: data?.data?.id,
                    quantity: data?.data?.quantity,
                    cost_per_unit: data?.data?.cost_per_unit,
                    product_id: data?.data?.product_id,
                    tax_id: data?.data?.tax_id,
                    subtotal: data?.data?.subtotal,
                    tax_total: data?.data?.tax_total,
                    grand_total: data?.data?.grand_total,
                })
            )
            dispatch(
                updateInvoice({
                    subtotal: data?.data?.invoice_subtotal,
                    tax_total: data?.data?.invoice_tax_total,
                    grand_total: data?.data?.invoice_grand_total,
                })
            )
            if (addProduct) {
                setAddProduct(false)
            }
            if (loadingAdd) setLoadingAdd(false)
        },
    })

    const handlePlusClick = (data?: any) => {
        if (data) {
            handleInvoiceUpdate(true, false)
            setAddProduct(false)
            setLoadingAdd(true)
            setTimeout(function () {
                addLineItemMutation.mutate()
            }, 1500)

            return
        }
        if (!state.invoice.customer_id) {
            console.log(state.invoice)
            dispatch(
                setWarning([
                    'Please select a customer before adding any line items',
                ])
            )
            return
        }
        if (products.length <= 0) {
            dispatch(setWarning(['You must create a product first']))
            setAddProduct(true)
            return
        }

        addLineItemMutation.mutate()
    }

    const handleClose = () => {
        setAddProduct(false)
    }

    const handleInvoiceUpdate = (product: any, tax: any) => {
        invoiceUpdate(product, tax)
    }

    return (
        <>
            <ProductModal
                show={addProduct}
                onClose={handleClose}
                callback={handlePlusClick}
            />
            {lineItems.map((line: Paywell.API.V2.LineItems, index: number) => (
                <Line
                    index={index}
                    invoice_id={id}
                    item={line}
                    products={products}
                    taxes={taxes}
                    lineitemUpdate={handleInvoiceUpdate}
                />
            ))}
            <tr>
                <td colSpan={7}></td>
                <td
                    colSpan={1}
                    className="px-6 py-4 text-sm font-medium text-right float-right whitespace-nowrap"
                >
                    {addLineItemMutation.isLoading || loadingAdd ? (
                        <Loading width="6" height="6" />
                    ) : (
                        <PlusIcon
                            className="fill-blue-500 cursor-pointer h-8 w-8"
                            onClick={() => handlePlusClick()}
                        />
                    )}
                </td>
            </tr>
            <tr className="h-5 border-transparent">
                <th colSpan={6} className="text-right">
                    Sub Total:
                </th>
                <td colSpan={1} className="float-right text-right ">
                    {isInvoiceUpdating ||
                    isLineItemDeleting ||
                    addLineItemMutation.isLoading ? (
                        <Loading width="5" height="5" />
                    ) : (
                        `$${state.invoice.invoice_subtotal}`
                    )}
                </td>
            </tr>
            <tr className="h-5">
                <th colSpan={6} className="text-right">
                    Tax Total:
                </th>
                <td colSpan={1} className="float-right text-right ">
                    {isInvoiceUpdating ||
                    isLineItemDeleting ||
                    addLineItemMutation.isLoading ? (
                        <Loading width="5" height="5" />
                    ) : (
                        `$${state.invoice.invoice_tax_total}`
                    )}
                </td>
            </tr>
            <tr className="h-5">
                <th colSpan={6} className="text-right">
                    Grand Total:
                </th>
                <td colSpan={1} className="float-right text-right ">
                    {isInvoiceUpdating ||
                    isLineItemDeleting ||
                    addLineItemMutation.isLoading ? (
                        <Loading width="5" height="5" />
                    ) : (
                        `$${state.invoice.invoice_grand_total}`
                    )}
                </td>
            </tr>
        </>
    )
}
