import { createSlice } from '@reduxjs/toolkit'
import { brandingConfig } from '../../utils/BrandingConfig'

const brandingName = process.env.REACT_APP_BRANDING_NAME || 'paywell'

const initialState: Paywell.ReduxStore.Branding = brandingConfig[brandingName]
export const BrandingInfoSlice = createSlice({
    name: 'branding_info',
    initialState: initialState,

    reducers: {},
})

// Action creators are generated for each case reducer function

export default BrandingInfoSlice.reducer
