import { useMutation, useQuery, useQueryClient } from 'react-query'
import { EtransferV2DemoRepository } from '../api/EtransferV2DemoRepository'
import { ChangeEvent, useEffect, useState } from 'react'
import { testEmailRegex } from '../utils/RegexHelper'
import { LoginRepository } from '../api/LoginRepository'
import PendingDemoPayment from './PendingDemoPayment'
import { useAppSelector } from '../redux/hooks'
import './Demo.scss'
import Loading from '../shared-components/Loading'

export default function Demo() {
    const [email, setEmail] = useState('')
    const [emailValid, setEmailValid] = useState(false)
    const [uuid, setUuid] = useState('')
    const [url, setUrl] = useState('')
    const EtransferV2DemoRepo = new EtransferV2DemoRepository()
    const loginRepository = new LoginRepository()
    const [paymentStatus, setPaymentStatus] = useState('')
    const branding = useAppSelector((state) => state.branding)
    const companyIcon = branding.company_icon

    useEffect(() => {
        getCSRFQuery.refetch()
    }, [])

    const V2DemoMutation = useMutation({
        mutationFn: () => EtransferV2DemoRepo.postEtransferV2Demo(email),

        onSuccess: (data) => {
            setUuid(data.data.uuid)
            setUrl(data.data.url)
            setPaymentStatus('pending')
            window.open(data.data.url, '_blank')
        },
        onError: (err: any) => {},
    })

    const getPaymentStatusQuery = useQuery(
        'v2-demo-payment-status',
        async () => {
            return await EtransferV2DemoRepo.getPaymentStatus(uuid)
        },
        {
            enabled: !!uuid,
            staleTime: Infinity,
            retry: true,
            refetchInterval: 5000,
            refetchIntervalInBackground: true,
            onSuccess: (data) => {
                setPaymentStatus(data.data.status)
                if (data.data.status !== 'pending') {
                    setUuid('')
                }
            },
            onError: (error) => {},
        }
    )
    useEffect(() => {
        if (uuid) {
            getPaymentStatusQuery.refetch()
        }
    }, [uuid])

    const getCSRFQuery = useQuery(
        'get-csrf',
        async () => {
            return (await loginRepository.getCSRF()).data
        },
        {
            enabled: true,
            staleTime: Infinity,
            retry: true,
            onSuccess: () => {},
        }
    )

    const emailChanged = (e: ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value

        if (val.length < 40) {
            setEmail(val)
            setEmailValid(true)

            setEmailValid(testEmailRegex(val))
        }
    }

    const paymentSuccess = paymentStatus === 'approved'
    const paymentPending = paymentStatus === 'pending'
    const paymentExpired = paymentStatus === 'expired'
    const paymentError = paymentStatus === 'error'
    const paymentCancelled = paymentStatus === 'cancelled'

    if (paymentPending) {
        return <PendingDemoPayment url={url} />
    }

    if (paymentCancelled) {
        return (
            <div className="flex min-h-full flex-1 flex-col justify-center iframe-container pb-4">
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <h2 className="mt-6 text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                Payment Cancelled
                            </h2>
                            <p className="mt-4 text-center text-lg leading-8 text-gray-600">
                                Your payment has been cancelled.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (paymentSuccess) {
        return (
            <div className="flex min-h-full flex-1 flex-col justify-center iframe-container pb-4">
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <h2 className="mt-6 text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                Payment Successful!
                            </h2>
                            <p className="mt-4 text-center text-lg leading-8 text-gray-600">
                                Your payment has been successfully processed.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (paymentError) {
        return (
            <div className="flex min-h-full flex-1 flex-col justify-center iframe-container pb-4">
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <h2 className="mt-6 text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                Error Processing Payment
                            </h2>
                            <p className="mt-4 text-center text-lg leading-8 text-gray-600">
                                We're sorry, but there was an error processing
                                your payment. Please try again.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (paymentExpired) {
        return (
            <div className="flex min-h-full flex-1 flex-col justify-center iframe-container pb-4">
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <h2 className="mt-6 text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                Payment Expired
                            </h2>
                            <p className="mt-4 text-center text-lg leading-8 text-gray-600">
                                Sorry, the payment session has expired. Please
                                try again.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center iframe-container pb-4">
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                    <form className="space-y-6" action="#" method="POST">
                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={emailChanged}
                                    value={email}
                                    type="email"
                                    name="email"
                                    id="email"
                                    autoComplete="email"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            {V2DemoMutation.isLoading ? (
                                <div className="flex justify-center mt-2">
                                    <Loading height='8' width='8'/>
                                </div>
                            ) : (
                                <button
                                    onClick={() => V2DemoMutation.mutate()}
                                    disabled={!emailValid}
                                    type="button"
                                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Try Now!
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
