import { BaseRepository } from './BaseRepository'

export class CommissionsRepository extends BaseRepository {
    uploadData = async (file: FormData, supplierType: number) => {
        let url = `/api/v2/commissions`
        //clear previously added supplier type
        file.delete('supplier_type')
        file.append('supplier_type', supplierType.toString())

        return await super.getHttpClientInstance().post(url, file)
    }
}
