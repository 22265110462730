import { BaseRepository } from './BaseRepository'

export class LoginRepository extends BaseRepository {
    getCSRF = async () => {
        return await super.getHttpClientInstance().get(`/sanctum/csrf-cookie`)
    }

    login = async (email: string, password: string) => {
        return await super.getHttpClientInstance().post(`/api/login`, {
            email: email,
            password: password,
        })
    }

    checkUserValid = async () => {
        return await super
            .getHttpClientInstance()
            .get(`/api/v2/check-valid-login`)
    }
}
