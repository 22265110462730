import Loading from '../shared-components/Loading'

interface PendingDemoPaymentProps {
    url: string
}

export default function PendingDemoPayment({ url }: PendingDemoPaymentProps) {
    return (
        <div className="flex min-h-full flex-1 flex-col justify-center iframe-container pb-4">
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="flex justify-center mt-4">
                            <Loading height='8' width='8'/>
                        </div>
                        <h2 className="mt-6 text-center text-5xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                            Transaction Pending
                        </h2>
                        <p className="mt-4 text-center text-lg leading-8 text-gray-600">
                            Please complete the transaction with your bank in
                            the new tab that was opened.{' '}
                        </p>
                    </div>
                    <div className="mt-4 p-2">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">
                            Tab Didn't Open?
                        </h3>
                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                            <p>
                                If a new tab wasn't opened, you can click on the
                                button below to open the link.
                            </p>
                            <a
                                target="_blank"
                                href={url}
                                rel="noreferrer"
                                type="button"
                                className="inline-flex mt-2 items-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                            >
                                Open Link
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
