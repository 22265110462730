import { BaseRepository } from './BaseRepository'

type AgentAdminResponse = {
    [key: string]: string
}

export class UserRepository extends BaseRepository {
    getUserMerchants = async () => {
        return await super
            .getHttpClientInstance()
            .get<Array<Paywell.API.V2.User>>(`/api/v2/user/merchants`)
    }

    getUsers = async (
        page: string | null,
        searchTerm: string | null,
        accountStatusId: string | null
    ) => {
        let url = `/api/v2/user?page=${page}`
        let queryParams: string[] = []
        if (searchTerm) {
            queryParams.push(`searchTerm=${encodeURIComponent(searchTerm)}`)
        }
        if (accountStatusId) {
            queryParams.push(
                `accountStatusId=${encodeURIComponent(accountStatusId)}`
            )
        }
        if (queryParams.length) {
            url += `&${queryParams.join('&')}`
        }
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.UsersPaginated>(url)
    }

    getUser = async (userId: string) => {
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.User>(`/api/v2/user/${userId}`)
    }

    getBankingDetails = async (userId: number) => {
        return await super
            .getHttpClientInstance()
            .get<Array<Paywell.API.V2.UserBankingInfo>>(
                `/api/v2/bank-account/get-banking-details/${userId}`
            )
    }

    getUserInteracInfo = async (userId: number) => {
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.UserInterac>(
                `/api/v2/user/user-interac/${userId}`
            )
    }

    updateUser = async (user: Paywell.API.V2.User) => {
        return await super
            .getHttpClientInstance()
            .put(`/api/v2/user/${user.id}`, user)
    }

    getAgentsAndAdmins = async () => {
        return await super
            .getHttpClientInstance()
            .get<AgentAdminResponse>(`/api/v2/user/agents-and-admins`)
    }

    uploadDriversLicence = async (
        file: FormData,
        contactId?: number | null
    ) => {
        let url = `/api/v2/contacts/upload-drivers-licence/${contactId}`

        return await super.getHttpClientInstance().post(url, file)
    }

    deleteContact = async (contactId: number) => {
        let url = `/api/v2/contacts/${contactId}`

        return await super.getHttpClientInstance().delete(url)
    }

    uploadProofOfAddress = async (file: FormData, userId: number) => {
        let url = `/api/v2/user/upload-proof-of-address/${userId}`

        return await super.getHttpClientInstance().post(url, file)
    }

    uploadBusinessRegistration = async (file: FormData, userId: number) => {
        let url = `/api/v2/user/upload-business-registration/${userId}`

        return await super.getHttpClientInstance().post(url, file)
    }

    updateUserInteracInfo = async (
        userInterac: Paywell.API.V2.UserInterac,
        userId: number
    ) => {
        let url = `/api/v2/user-interac/${userId}`
        return await super.getHttpClientInstance().put(url, userInterac)
    }

    createNewUser = async (
        name: string,
        email: string,
        password: string,
        passwordConfirmation: string
    ) => {
        let url = `/api/v2/user`
        return await super
            .getHttpClientInstance()
            .post<Paywell.API.V2.User>(url, {
                name: name,
                email: email,
                password: password,
                password_confirmation: passwordConfirmation,
            })
    }
}
