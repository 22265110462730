import { useEffect } from 'react'
import { useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
interface MessageData {
    url?: string
}
type StringRef = React.MutableRefObject<string>

const AgencyIFrameComponent = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const iframeRef: StringRef = useRef('')

    const [iframeSrc, setIframeSrc] = useState<string>(
        iframeRef.current.replace('/legacy', '')
    )

    useEffect(() => {
        if (iframeRef.current !== location.pathname) {
            let url =
                process.env.REACT_APP_API_BASE_URL +
                `${location.pathname.replace('/legacy', '')}`
            iframeRef.current = url

            if (iframeSrc == url) {
                // This is a really hacky way to force the iframe to reload
                // but it works for now
                url += '?'
            }

            setIframeSrc(url)
        }
    }, [location.pathname])

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            const data: MessageData = event.data
            if (data.url) {
                const pathPrefix = '/legacy'
                const url = new URL(data.url)
                const pathAfterDomain = url.pathname
                const fullPath = pathPrefix + pathAfterDomain
                iframeRef.current = fullPath
                navigate(fullPath, { replace: true })
            }
        }

        window.addEventListener('message', handleMessage)

        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [navigate])

    return (
        <div className="h-screen w-screen">
            <iframe
                id="iframe"
                src={iframeSrc}
                width="100%"
                height="100%"
                title="Laravel App"
                className="w-full h-full border-none"
            ></iframe>
        </div>
    )
}

export default AgencyIFrameComponent
