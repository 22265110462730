import React, { useEffect } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { VerifyEmailRepository } from '../api/VerifyEmailRepository'
import { useQuery } from 'react-query'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import {
    setSuccess,
    setError,
} from '../redux/state-slices/GlobalNotificationSlice'
import { setLoggedOut } from '../redux/state-slices/AuthUserSlice'
import { LoginRepository } from '../api/LoginRepository'

export default function VerifyEmail() {
    let params = useParams()
    let id = params.id ? params.id : ''
    let hash = params.hash ? params.hash : ''
    const dispatch = useAppDispatch()
    const verifyEmailRepository = new VerifyEmailRepository()
    const authUser = useAppSelector((state) => state.auth_user)
    const navigate = useNavigate()
    const loginRepository = new LoginRepository()
    const supportEmail = useAppSelector((state) => state.branding.contact_email)
    const checkUserValidQuery = useQuery(
        'check-valid-user',
        async () => {
            return await loginRepository.checkUserValid()
        },
        {
            staleTime: 0,
            retry: false,
            refetchOnWindowFocus: false,
            enabled: false,
            onSuccess: (data) => {
                const authUser: Paywell.ReduxStore.Auth.User = {
                    id: data.data.id,
                    email: data.data.email,
                    email_verified: data.data.email_verified_at,
                    account_status: data.data.account_status,
                    merchant_name: data.data.merchant_name,
                    user_type_id: data.data.user_type_id,
                    nmi_user: data.data.nmi_user,
                }
            },
            onError: () => {
                dispatch(setLoggedOut())
            },
        }
    )

    const verifyEmailQuery = useQuery(
        'verify-email',
        async () => {
            return (await verifyEmailRepository.veryfyEmail(id, hash)).data
        },
        {
            enabled: false,
            staleTime: Infinity,
            onSuccess: (data) => {
                dispatch(
                    setSuccess(['Your email has been successfully verified!'])
                )
                checkUserValidQuery.refetch()
            },
            onError: () => {
                dispatch(
                    setError([
                        'Failed to verify email. Please try again. If this problem persists, please contact ' +
                            supportEmail +
                            '.',
                    ])
                )
            },
        }
    )

    useEffect(() => {
        if (authUser) {
            if (parseInt(id) !== authUser.id) {
                navigate('/home')
            } else {
                verifyEmailQuery.refetch()
            }
        } else {
            if (id && hash) {
                verifyEmailQuery.refetch()
            }
        }
    }, [])

    if (verifyEmailQuery.isSuccess) {
        return <Navigate to="/" />
    } else if (verifyEmailQuery.isFetching) {
        return <div>Please wait while we verify your email</div>
    } else if (verifyEmailQuery.isError) {
        return <div>An error has occurred.</div>
    }
    return <div></div>
}
