import { useEffect, useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { InvoicesRepository } from '../../../api/InvoicesRepository'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
    setError,
    setSuccess,
} from '../../../redux/state-slices/GlobalNotificationSlice'
import { CreditCardIcon, XCircleIcon } from '@heroicons/react/20/solid'
import Loading from '../../../shared-components/Loading'
import Error from '../../../shared-components/Error'
import loadNMI from '../LoadNMI'
import {
    testAlphaNumericRegex,
    testNameRegex,
} from '../../../utils/RegexHelper'

declare global {
    interface Window {
        CollectJS: any
        collectJsValidationCallback?: (
            field: string,
            valid: boolean,
            message: string
        ) => void
    }
}

export default function NMIInline({
    uuid,
    price,
    nmi_public_key,
    setDisableOptions,
}: {
    uuid: string | undefined
    price: any
    nmi_public_key: string | undefined
    setDisableOptions: Function
}) {
    const dispatch = useAppDispatch()
    const invoiceRepository = new InvoicesRepository()
    const [isSubmitting, setSubmitting] = useState(false)
    const [name, setName] = useState('')
    const [postal, setPostal] = useState('')
    const [loading, setLoading] = useState(false)
    const [loadingNMI, setLoadingNMI] = useState(true)
    const [collectJS, setCollectJs] = useState(false)
    const [validForm, setValidForm] = useState(false)
    const [validCcNumber, setValidCcNumber] = useState(false)
    const [validCcExp, setValidCcExp] = useState(false)
    const [validCvv, setValidCvv] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const COLLECT_JS_TYPE = typeof window.CollectJS

    const queryClient = useQueryClient()

    const paymentMutation = useMutation({
        mutationKey: 'checkout',
        mutationFn: (token: any) =>
            invoiceRepository.invoiceCheckout(token, name, postal, price, uuid),
        onError: (err: any) => {
            setErrorMessage(err.data.message)
            setLoadingNMI(true)
            setCollectJs(false)
            setValidForm(false)
            setValidCcExp(false)
            setValidCcNumber(false)
            setValidCvv(false)
            setDisableOptions(false)
            setSubmitting(false)
        },
        onSuccess: (data) => {
            queryClient.refetchQueries(['invoice', uuid])
            setSubmitting(false)
            setLoading(true)
            dispatch(setSuccess(['Success']))
        },
    })

    const finishSubmit = (response: any) => {
        paymentMutation.mutate(response.token)
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        setSubmitting(true)
        setDisableOptions(true)
        window.CollectJS.startPaymentRequest()
    }

    const handlePostalCodeChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        if (val.length <= 6 && testAlphaNumericRegex(val)) {
            setPostal(val)
        }
    }

    const handleNameChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        if (testNameRegex(val) || val === '') {
            if (val.length < 35) {
                setName(val)
            }
        }
    }

    useEffect(() => {
        setValidForm(validCcNumber && validCcExp && validCvv)
    }, [validCcNumber, validCcExp, validCvv])

    useEffect(() => {
        // Define the function in the global scope so that the CollectJS script can call it
        window.collectJsValidationCallback = (field, valid, message) => {
            if (field === 'ccnumber') {
                setValidCcNumber(valid)
            }

            if (field === 'ccexp') {
                setValidCcExp(valid)
            }

            if (field === 'cvv') {
                setValidCvv(valid)
            }
        }

        return () => {
            delete window.collectJsValidationCallback
        }
    }, [])

    useEffect(() => {
        setSubmitting(false)
        if (nmi_public_key) {
            const nmiScript = document.getElementById('collectjs')
            if (!nmiScript) {
                loadNMI(nmi_public_key, setLoadingNMI, setCollectJs)
            } else {
                setLoadingNMI(false)
            }
        }
        if (typeof window.CollectJS !== 'undefined') {
            setCollectJs(true)
            window.CollectJS.configure({
                variant: 'inline',
                styleSniffer: false,
                callback: (token: any) => {
                    finishSubmit(token)
                },
                fields: {
                    ccnumber: {
                        placeholder: 'CC Number',
                        selector: '.ccnumber',
                    },
                    ccexp: {
                        placeholder: 'CC Expiration',
                        selector: '.ccexp',
                    },
                    cvv: {
                        placeholder: 'CVV',
                        selector: '.cvv',
                    },
                },
            })
        }
    }, [COLLECT_JS_TYPE, loadingNMI])

    if (paymentMutation.isLoading || loading)
        return (
            <div>
                <Loading width="10" height="10" />
            </div>
        )

    if (loadingNMI) return <Loading width="8" height="8" />

    if (!collectJS)
        return (
            <div>
                <Error
                    title="An error has occurred"
                    message="Error loading, please refresh the page."
                />
            </div>
        )
    return (
        <form onSubmit={handleSubmit}>
            <fieldset>
                <legend className="block text-sm font-medium leading-6 text-gray-900">
                    Card Details
                </legend>
                <div className="mt-2 -space-y-px rounded-md bg-white shadow-sm">
                    <div>
                        <label htmlFor="ccnumber" className="sr-only">
                            Card number
                        </label>
                        <div
                            className="ccnumber p-1 relative block w-full rounded-none rounded-t-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                            placeholder="Card number"
                        />
                    </div>
                    <div className="flex -space-x-px">
                        <div className="w-1/2 min-w-0 flex-1">
                            <label htmlFor="ccexp" className="sr-only">
                                Expiration date
                            </label>
                            <div
                                className="ccexp p-1 relative block w-full rounded-none rounded-bl-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                placeholder="MM / YY"
                            />
                        </div>
                        <div className="min-w-0 flex-1">
                            <label htmlFor="cvc" className="sr-only">
                                CVC
                            </label>
                            <div
                                className="cvv p-1 relative block w-full rounded-none rounded-br-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                placeholder="CVC"
                            />
                        </div>
                    </div>
                </div>
            </fieldset>
            <fieldset className="mt-6">
                <legend className="block text-sm font-medium leading-6 text-gray-900">
                    Billing
                </legend>
                <div className="mt-2 -space-y-px rounded-md shadow-sm bg-white">
                    <div>
                        <label htmlFor="billing_name" className="sr-only">
                            Billing Name
                        </label>
                        <input
                            value={name}
                            required
                            type="text"
                            placeholder="Billing name"
                            name="billing_name"
                            className="relative block w-full rounded-none rounded-t-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                            onChange={handleNameChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="postal-code" className="sr-only">
                            ZIP / Postal code
                        </label>
                        <input
                            value={postal}
                            required
                            type="text"
                            name="postal-code"
                            id="postal-code"
                            autoComplete="postal-code"
                            className="relative block w-full rounded-none rounded-b-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                            placeholder="ZIP / Postal code"
                            onChange={handlePostalCodeChange}
                        />
                    </div>
                </div>
            </fieldset>
            <div>
                <button
                    type="submit"
                    disabled={isSubmitting || !validForm}
                    className="group mt-2 relative flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 disabled:bg-gray-400"
                >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <CreditCardIcon
                            className="h-5 w-5 text-sky-500 group-hover:text-sky-400"
                            aria-hidden="true"
                        />
                    </span>
                    {isSubmitting ? <Loading height="4" width="4" /> : 'Pay'}
                </button>
            </div>
            {paymentMutation.isError && (
                <div className="mt-4">
                    <Error title="Payment Failed" message={errorMessage} />
                </div>
            )}
        </form>
    )
}
