import { AxiosHttpClient } from './HttpClient/AxiosHttpClient'
import { BaseHttpClient } from './HttpClient/BaseHttpClient'
import { SentryUtils } from '../utils/SentryUtils'

export enum HttpClient {
    AXIOS = 'axios',
}
export const DEFAULT_HTTP_CLIENT = HttpClient.AXIOS
export const API_BASE_URL = SentryUtils.expect<string>(
    process.env.REACT_APP_API_BASE_URL
)
export const DEFAULT_REQUEST_TIMEOUT = 50000 // In milliseconds

export class BaseRepository {
    protected static baseHttpClient: BaseHttpClient
    protected httpClient: BaseHttpClient

    constructor(overrideBaseUrl?: string) {
        if (overrideBaseUrl) {
            this.httpClient =
                BaseRepository.getHttpClientByName(DEFAULT_HTTP_CLIENT)
            this.httpClient.configure(
                overrideBaseUrl,
                DEFAULT_REQUEST_TIMEOUT,
                true
            )
        } else {
            if (BaseRepository.baseHttpClient == undefined) {
                BaseRepository.baseHttpClient =
                    BaseRepository.getHttpClientByName(DEFAULT_HTTP_CLIENT)
                BaseRepository.baseHttpClient.configure(
                    API_BASE_URL,
                    DEFAULT_REQUEST_TIMEOUT,
                    true
                )
            }
            this.httpClient = BaseRepository.baseHttpClient
        }
    }

    protected getHttpClientInstance(): BaseHttpClient {
        return this.httpClient
    }

    /**
     * Returns an instance of BaseHttpClient for the given http client name.
     * Defaults to AxiosHttpClient if no httpClientName is provided.
     * @param httpClientName The name of the http client to instantiate.
     */
    private static getHttpClientByName(
        httpClientName: HttpClient
    ): BaseHttpClient {
        switch (httpClientName) {
            case HttpClient.AXIOS:
                return new AxiosHttpClient()
            default:
                return new AxiosHttpClient()
        }
    }
}
