import { Navigate } from 'react-router-dom'
import { useAppSelector } from '../redux/hooks'
export default function ConfirmEmail() {
    const auth_user = useAppSelector((state) => state.auth_user)
    const branding = useAppSelector((state) => state.branding)
    const companyIcon = branding.company_icon

    if (auth_user?.email_verified) {
        return <Navigate to="/home" />
    } else {
        return (
            <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-12 w-auto"
                        src={companyIcon || ''}
                        alt="Logo"
                    />
                    <h2 className="mt-6 text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        Verify your email address
                    </h2>
                    <p className="mt-4 text-center text-lg leading-8 text-gray-600">
                        We've sent an email to{' '}
                        <span className="font-medium">{auth_user?.email}</span>.
                        Please check your email and click the link provided to
                        verify your email address.
                    </p>
                </div>
            </div>
        )
    }
}
