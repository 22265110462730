import React, { ChangeEvent, useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from '../redux/hooks'
import {
    setFirstName,
    setLastName,
    setEmail,
    setPassword,
    setConfirmPassword,
    setCountry,
    setBusinessName,
    setUUID,
} from '../redux/state-slices/NewUserSlice'
import './Register.scss'
import { useMutation } from 'react-query'
import { RegisterUserRepository } from '../api/RegisterUserRepository'
import { Navigate, useSearchParams } from 'react-router-dom'
import { LoginRepository } from '../api/LoginRepository'
import { useQuery } from 'react-query'
import { setLoggedIn } from '../redux/state-slices/AuthUserSlice'
import Loading from '../shared-components/Loading'
import { useNavigate } from 'react-router-dom'
import {
    testBusinessNameRegex,
    testEmailRegex,
    testNameRegex,
    validateBusinessName,
    validateName,
} from '../utils/RegexHelper'
import Error from '../shared-components/Error'
import { TERMS_AND_CONDITIONS_URL } from '../Constants'

interface RegisterInfoValidAttributes {
    email: boolean | undefined
    business_name: boolean | undefined
    first_name: boolean | undefined
    last_name: boolean | undefined
    password: boolean | undefined
    confirm_password: boolean | undefined
}

const Register = () => {
    const dispatch = useAppDispatch()
    const newUserState = useAppSelector((state) => state.new_user)
    const registerUserRepository = new RegisterUserRepository()
    const loginRepository = new LoginRepository()
    const [validForm, setValidForm] = useState(false)
    const navigate = useNavigate()
    const [isChecked, setIschecked] = useState(false)
    const [params] = useSearchParams()
    const planId = params.get('plan') ? params.get('plan') : null
    const branding = useAppSelector((state) => state.branding)
    const companyName = branding.company_name
    const companyIcon = branding.company_icon
    const [registerInfoValid, setRegisterInfoValid] =
        useState<RegisterInfoValidAttributes>({
            email: undefined,
            business_name: undefined,
            first_name: undefined,
            last_name: undefined,
            password: undefined,
            confirm_password: undefined,
        })

    useEffect(() => {
        if (planId) {
            dispatch(setUUID(planId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        validateForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newUserState])

    const getCSRFQuery = useQuery(
        'get-csrf',
        async () => {
            return (await loginRepository.getCSRF()).data
        },
        {
            enabled: false,
            staleTime: Infinity,
            onSuccess: () => {
                registerUserMutation.mutate()
            },
        }
    )

    const registerUserMutation = useMutation({
        mutationFn: () => registerUserRepository.registerUser(newUserState),

        onSuccess: (data) => {
            const user: Paywell.ReduxStore.Auth.User = {
                id: data.data.id,
                email: data.data.email,
                email_verified: data.data.email_verified,
                account_status: data.data.account_status,
                merchant_name: data.data.merchant_name,
                user_type_id: data.data.user_type_id,
                nmi_user: data.data.nmi_user,
            }
            dispatch(setLoggedIn(user))
        },
        onError: (data) => {
            console.log(data)
        },
    })

    const validateForm = () => {
        let pass = true

        Object.keys(newUserState).forEach(function (key) {
            let val =
                newUserState[
                    key as keyof Paywell.ReduxStore.Onboarding.NewUser
                ]?.toString()

            if (key === 'email' && val !== undefined && !testEmailRegex(val)) {
                pass = false
            } else if (key === 'business_name' && !validateBusinessName(val)) {
                pass = false
            } else if (key === 'first_name' && !validateName(val)) {
                pass = false
            } else if (key === 'last_name' && !validateName(val)) {
                pass = false
            } else if (key === 'password' && !validatePassword(val)) {
                pass = false
            } else if (
                key === 'confirm_password' &&
                val !== undefined &&
                !validatePasswordMatch(val)
            ) {
                pass = false
            }
        })
        setValidForm(pass)
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (validForm) {
            getCSRFQuery.refetch()
        }
    }

    const emailChanged = (e: ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value

        if (val.length < 40) {
            setRegisterInfoValid({
                ...registerInfoValid,
                email: testEmailRegex(val),
            })
            dispatch(setEmail(val))
        }
    }

    const firstNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if ((val.length < 20 && testNameRegex(val)) || val === '') {
            setRegisterInfoValid({
                ...registerInfoValid,
                first_name: validateName(val),
            })
            dispatch(setFirstName(val))
        }
    }

    const lastNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value

        if ((val.length < 20 && testNameRegex(val)) || val === '') {
            setRegisterInfoValid({
                ...registerInfoValid,
                last_name: validateName(val),
            })
            dispatch(setLastName(val))
        }
    }

    const passwordChanged = (e: ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value

        if (val.length < 30) dispatch(setPassword(e.target.value))

        setRegisterInfoValid({
            ...registerInfoValid,
            password: validatePassword(val),
        })
    }

    const confirmPasswordChanged = (e: ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (val.length < 30) {
            setRegisterInfoValid({
                ...registerInfoValid,
                confirm_password:
                    validatePassword(val) && validatePasswordMatch(val),
            })
            dispatch(setConfirmPassword(val))
        }
    }

    const countryChanged = (e: React.ChangeEvent<any>) => {
        let val = e.target.value

        dispatch(setCountry(val))
    }

    const businessNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value

        if ((val.length < 50 && testBusinessNameRegex(val)) || val === '') {
            setRegisterInfoValid({
                ...registerInfoValid,
                business_name: validateBusinessName(val),
            })
            dispatch(setBusinessName(val))
        }
    }

    const validatePassword = (val: string | undefined) => {
        if (val && val.length > 6 && val.length < 31) {
            return true
        }

        return false
    }

    const handleInvalidClass = (key: keyof RegisterInfoValidAttributes) => {
        if (registerInfoValid[key] === false) {
            return '!border-2 !border-rose-500'
        }

        return ''
    }

    const validatePasswordMatch = (passWord: string | null) => {
        if ((passWord && passWord !== newUserState.password) || !passWord) {
            return false
        }

        return true
    }

    if (registerUserMutation.isSuccess) {
        return <Navigate to="/onboarding/identity-info" />
    }

    return (
        <div className="h-full shadow-lg">
            <div className="flex min-h-full">
                <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <img
                                className="h-12 w-auto"
                                src={companyIcon || ''}
                                alt={companyName || ''}
                            />
                            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                                Create your {companyName} account
                            </h2>
                        </div>

                        <div className="mt-8">
                            <div className="mt-6">
                                <form
                                    className="space-y-6"
                                    onSubmit={handleSubmit}
                                >
                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Email address
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                value={newUserState.email}
                                                onChange={emailChanged}
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                required
                                                className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${handleInvalidClass(
                                                    'email'
                                                )}`}
                                            />
                                            {registerInfoValid.email ===
                                                false && (
                                                <p
                                                    className="mt-2 text-sm text-red-600"
                                                    id="firstname-invalid"
                                                >
                                                    Please input a valid email
                                                    (x@x.xx)
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="space-y-1">
                                        <label
                                            htmlFor="business-name"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Business Name
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                onChange={businessNameChanged}
                                                value={
                                                    newUserState.business_name
                                                }
                                                id="business-name"
                                                name="business-name"
                                                type="text"
                                                required
                                                className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${handleInvalidClass(
                                                    'business_name'
                                                )}`}
                                            />
                                            {registerInfoValid.business_name ===
                                                false && (
                                                <p
                                                    className="mt-2 text-sm text-red-600"
                                                    id="firstname-invalid"
                                                >
                                                    Minimum 2 characters
                                                    required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="space-y-1">
                                        <label
                                            htmlFor="first-name"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            First Name
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                onChange={firstNameChanged}
                                                value={newUserState.first_name}
                                                id="first-name"
                                                name="first-name"
                                                type="text"
                                                required
                                                className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${handleInvalidClass(
                                                    'first_name'
                                                )}`}
                                            />
                                            {registerInfoValid.first_name ===
                                                false && (
                                                <p
                                                    className="mt-2 text-sm text-red-600"
                                                    id="firstname-invalid"
                                                >
                                                    Minimum 2 characters
                                                    required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="space-y-1">
                                        <label
                                            htmlFor="last-name"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Last Name
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                onChange={lastNameChanged}
                                                value={newUserState.last_name}
                                                id="last-name"
                                                name="last-name"
                                                type="text"
                                                required
                                                className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${handleInvalidClass(
                                                    'last_name'
                                                )}`}
                                            />
                                            {registerInfoValid.last_name ===
                                                false && (
                                                <p
                                                    className="mt-2 text-sm text-red-600"
                                                    id="lastname-invalid"
                                                >
                                                    Minimum 2 characters
                                                    required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="space-y-1">
                                        <label
                                            htmlFor="country"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Country
                                        </label>
                                        <select
                                            onChange={countryChanged}
                                            value={newUserState.country || ''}
                                            id="country"
                                            name="country"
                                            autoComplete="country-name"
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        >
                                            <option value="1">Canada</option>
                                            <option value="2">
                                                United States
                                            </option>
                                        </select>
                                    </div>
                                    <div className="space-y-1">
                                        <label
                                            htmlFor="password"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Password
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                value={newUserState.password}
                                                onChange={passwordChanged}
                                                id="password"
                                                name="password"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${handleInvalidClass(
                                                    'password'
                                                )}`}
                                            />
                                            {registerInfoValid.password ===
                                                false && (
                                                <p
                                                    className="mt-2 text-sm text-red-600"
                                                    id="password-invalid"
                                                >
                                                    Minimum 7 characters
                                                    required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="space-y-1">
                                        <label
                                            htmlFor="confirm-password"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Confirm Password
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                value={
                                                    newUserState.confirm_password
                                                }
                                                onChange={
                                                    confirmPasswordChanged
                                                }
                                                id="confirm-password"
                                                name="confirm-password"
                                                type="password"
                                                required
                                                className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${handleInvalidClass(
                                                    'confirm_password'
                                                )}`}
                                            />
                                        </div>
                                    </div>

                                    {newUserState.password &&
                                        !validatePasswordMatch(
                                            newUserState.confirm_password
                                        ) && (
                                            <p
                                                className="mt-2 text-sm text-red-600"
                                                id="password-mismatch"
                                            >
                                                Passwords do not match
                                            </p>
                                        )}

                                    <fieldset className="space-y-5">
                                        <div className="relative flex items-start">
                                            <div className="flex h-5 items-center">
                                                <input
                                                    id="terms"
                                                    aria-describedby="terms-and-conditions"
                                                    name="terms"
                                                    onChange={() =>
                                                        setIschecked(!isChecked)
                                                    }
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label
                                                    htmlFor="terms"
                                                    className="font-medium text-grey-700"
                                                >
                                                    I have read and agreed to
                                                    the{' '}
                                                    <a
                                                        href={
                                                            TERMS_AND_CONDITIONS_URL
                                                        }
                                                        target="_blank"
                                                        className="cursor-pointer font-medium text-blue-700"
                                                    >
                                                        Terms and Conditions
                                                    </a>
                                                </label>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div>
                                        <button
                                            disabled={!validForm || !isChecked}
                                            type="submit"
                                            className="flex w-full disabled:bg-gray-400 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            Sign Up
                                        </button>

                                        <div className="">
                                            {/* <div className="flex items-center">
                                                <input
                                                    id="remember-me"
                                                    name="remember-me"
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                />
                                                <label
                                                    htmlFor="remember-me"
                                                    className="ml-2 block text-sm text-gray-900"
                                                >
                                                    Remember me
                                                </label>
                                            </div> */}

                                            <div className="text-sm text-center mt-3 cursor-pointer">
                                                <button
                                                    className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                                                    onClick={() =>
                                                        navigate('/login')
                                                    }
                                                >
                                                    Already have an account?
                                                    Click here to login
                                                </button>
                                            </div>
                                        </div>

                                        {registerUserMutation.isLoading && (
                                            <div className="flex justify-center mt-4">
                                                <Loading height="8" width="8" />
                                            </div>
                                        )}
                                        {registerUserMutation.isError && (
                                            <div className="flex justify-center mt-4">
                                                <Error
                                                    title={
                                                        'Email already taken'
                                                    }
                                                    message={
                                                        'Please input a different email'
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src="/store-front.png"
                        alt=""
                    />
                </div>
            </div>
        </div>
    )
}

export default Register
