import { Link, useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { NotesRepository } from '../../api/NotesRepository'
import { useMutation } from 'react-query'
import { useAppDispatch } from '../../redux/hooks'
import { setError } from '../../redux/state-slices/GlobalNotificationSlice'

interface Note {
    userId: number | undefined
}

export default function NotesNew({ userId }: Note) {
    const notesRepository = new NotesRepository()
    const [noteValue, setNoteValue] = useState('')
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const newNoteMutation = useMutation({
        mutationFn: () => {
            if (userId) {
                return notesRepository.addNote(noteValue, userId)
            }
            throw new Error('UserId is not provided')
        },
        onSuccess: () => {
            navigate('/users/' + userId + '/notes')
        },
        onError: (error) => {
            dispatch(
                setError([
                    'There was an error creating the note. Please try again.',
                ])
            )
        },
    })

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (noteValue.length === 0) return
        newNoteMutation.mutate()
    }
    const handleNotesChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        let val = event.target.value
        if (val.length <= 1000) {
            setNoteValue(val)
        }
    }

    let savingNote = newNoteMutation.isLoading

    return (
        <form onSubmit={handleSubmit}>
            <div className="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                <div className="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800">
                    <label htmlFor="note" className="sr-only">
                        Notes
                    </label>
                    <textarea
                        id="note"
                        disabled={savingNote}
                        name="note"
                        onChange={handleNotesChange}
                        rows={10}
                        value={noteValue}
                        placeholder={'Enter your note here...'}
                        className="w-full px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                    ></textarea>
                </div>
                <div className="flex items-center justify-end px-3 py-2 space-x-2">
                    <Link
                        to={'/users/' + userId + '/notes'}
                        className=" rounded-md mb-4 bg-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 text-gray-900 px-3 py-2 text-center text-sm font-semibold  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Cancel
                    </Link>

                    <button
                        type="submit"
                        disabled={savingNote || noteValue.length === 0}
                        className="rounded-md mb-4 bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
                    >
                        Save
                    </button>
                </div>
            </div>
        </form>
    )
}
