import { useAppSelector } from '../redux/hooks'
import { Outlet, Navigate } from 'react-router-dom'
import { useEffect } from 'react'

const RegistrationComplete = () => {
    const authUser = useAppSelector((state) => state.auth_user)

    return authUser &&
        authUser.email_verified &&
        authUser.account_status !== 'REGISTERING' ? (
        <Outlet />
    ) : (
        <Navigate to="/onboarding" replace />
    )
}

export default RegistrationComplete
