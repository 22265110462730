export default function NumberFormatDollars(
    number: number | undefined | null | string
) {
    if (number === null || number === undefined) return ''
    number = parseFloat(number.toString())
    const formattedNumber = number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    })
    return formattedNumber
}
