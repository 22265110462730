import { Link, useNavigate } from 'react-router-dom'
import { CurrencyDollarIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { ProductRepository } from '../../api/ProductRepository'
import {
    setError,
    setSuccess,
} from '../../redux/state-slices/GlobalNotificationSlice'
import { useAppDispatch } from '../../redux/hooks'
import Loading from '../../shared-components/Loading'
import {
    validateProductName,
    validateProductPrice,
} from '../../utils/RegexHelper'

type NewProductProp = {
    source?: any[]
    onClose?: () => void
    onSuccess?: (productId: any) => void
}

export default function ProductsNew({
    source = [],
    onClose,
    onSuccess,
}: NewProductProp) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const productRepository = new ProductRepository()

    const [validations, setValidations] = useState({
        product_name: { value: '', validated: true },
        product_price: { value: '', validated: true },
    })

    const createProductMutation = useMutation({
        mutationFn: () =>
            productRepository.createProduct(
                validations.product_name.value,
                validations.product_price.value
            ),
        onError: (err: any) => {
            if (err.data.message) dispatch(setError([err.data.message]))
            else
                dispatch(
                    setError([
                        'An error has occured. If this keeps happening, please contact support',
                    ])
                )
        },

        onSuccess: (data) => {
            if (onSuccess) onSuccess(data?.data)
            else navigate(`/invoicing/products/${data?.data.id}`)
            dispatch(
                setSuccess([
                    `Your new product (${data?.data.name}) has been added`,
                ])
            )
        },
    })

    const validate = (data: Paywell.API.V2.ValidationData[]) => {
        data.forEach((v) => {
            setValidations((prevValidations) => ({
                ...prevValidations,
                [v.name]: { value: v.value, validated: v.f(v.value) },
            }))
        })
    }

    const onCancel = (e: any) => {
        if (onClose) {
            onClose()
            e.preventDefault()
        }
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        createProductMutation.mutate()
    }

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="sm:flex ">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">
                        New Product
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">Add products</p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <div className="pt-5">
                        <div className="flex justify-end">
                            <Link
                                onClick={(e) => onCancel(e)}
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                to={'/invoicing/products'}
                            >
                                Cancel
                            </Link>
                            <button
                                type="submit"
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                disabled={createProductMutation.isLoading}
                            >
                                {createProductMutation.isLoading ? (
                                    <Loading height="6" width="6" />
                                ) : (
                                    'Create'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div className="space-y-6 sm:space-y-5">
                        <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Product Information
                                </h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="country"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Product name and price
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 flex flex-col gap-4">
                                        <input
                                            type="text"
                                            name="product-name"
                                            id="product-name"
                                            placeholder="Product Name"
                                            className={`block w-full max-w-lg rounded-md ${
                                                validations.product_name
                                                    .validated
                                                    ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                    : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                            } `}
                                            pattern="[a-zA-Z0-9\s]+"
                                            title="Please enter only letters, numbers, and spaces"
                                            maxLength={40}
                                            onKeyUp={(e) =>
                                                validate([
                                                    {
                                                        value: (
                                                            e.target as HTMLInputElement
                                                        ).value,
                                                        f: validateProductName,
                                                        name: 'product_name',
                                                    },
                                                ])
                                            }
                                            required
                                        />
                                        <div className="flex gap-4">
                                            <div>
                                                <div className="relative mt-1 rounded-md shadow-sm">
                                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                        <CurrencyDollarIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </div>
                                                    <input
                                                        type="number"
                                                        step=".01"
                                                        name="rate"
                                                        id="rate"
                                                        className={`block w-32 rounded-md pl-10 ${
                                                            validations
                                                                .product_price
                                                                .validated
                                                                ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                                : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                        } `}
                                                        placeholder="0.00"
                                                        aria-describedby="rate"
                                                        pattern="^(100000(\.00?)?|\d{0,2}(\.\d{1,2})?)$"
                                                        title="Please enter a number between 0 and 100,000 with up to two decimal places"
                                                        min={0}
                                                        max={100000}
                                                        onKeyUp={(e) =>
                                                            validate([
                                                                {
                                                                    value: (
                                                                        e.target as HTMLInputElement
                                                                    ).value,
                                                                    f: validateProductPrice,
                                                                    name: 'product_price',
                                                                },
                                                            ])
                                                        }
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
