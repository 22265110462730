import { BaseRepository } from './BaseRepository'

export class QuickAddsRepository extends BaseRepository {
    getQuickAdds = async (page: string | 1 | null, searchTerm?: string) => {
        let url = `/api/v2/quick-adds?page=${page}`
        if (searchTerm) {
            url += `&searchTerm=${searchTerm}`
        }

        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.QuickAddsPaginated>(url)
    }

    deleteQuickAdd = async (id: number) => {
        let url = `/api/v2/quick-adds/${id}`
        return await super.getHttpClientInstance().delete(url)
    }

    quickAddsRequiredInfo = async (searchTerm?: string) => {
        let url = `/api/v2/quick-adds/info`
        if (searchTerm) {
            url += `?searchTerm=${searchTerm}`
        }
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.QuickAddInfo>(url)
    }

    createQuickAdd = async (quickAdd: Paywell.API.V2.QuickAdd) => {
        let url = `/api/v2/quick-adds`
        return await super.getHttpClientInstance().post(url, quickAdd)
    }
}
