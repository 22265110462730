export interface BrandingConfig {
    [key: string]: Paywell.ReduxStore.Branding
}

export const brandingConfig: BrandingConfig = {
    paywell: {
        company_name: 'Paywell',
        company_logo: '/paywell.svg',
        contact_email: 'support@paywell.tech',
        contact_phone: '18006470292',
        company_icon: '/icon-only.png',
        address: '14845 Yonge Street Unit 210, Aurora, ON L4G 6H8',
        check_payee: 'Quantum Tango Inc.',
        company_description: 'Paywell.ca payment solutions',
        primary_colour: null,
        favicon_url: '/favicon.ico',
    },
}
