import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DialogModal from '../../shared-components/DialogModal'

interface Props {
    user: Paywell.API.V2.User | null
    userCopy: Paywell.API.V2.User | null
    updateUserInfo: (user: Paywell.API.V2.User) => void
    saveChanges: () => void
}

export default function UserTicketSalesEdit({
    user,
    userCopy,
    updateUserInfo,
    saveChanges,
}: Props) {
    const navigate = useNavigate()
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const handleAvgTicketAmountChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (userCopy && val.length <= 8) {
            if (val === '' || !isNaN(val as any)) {
                // Allow empty string or numeric values
                const numericValue = val === '' ? null : Number(val)
                updateUserInfo({
                    ...userCopy,
                    avg_ticket_amount: numericValue,
                })
            }
        }
    }

    const handleHighestTicketAmountChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (userCopy && val.length <= 8) {
            if (val === '' || !isNaN(val as any)) {
                // Allow empty string or numeric values
                const numericValue = val === '' ? null : Number(val)
                updateUserInfo({
                    ...userCopy,
                    highest_ticket_amount: numericValue,
                })
            }
        }
    }

    const handleMonthlySalesVolumeChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (userCopy && val.length <= 8) {
            if (val === '' || !isNaN(val as any)) {
                // Allow empty string or numeric values
                const numericValue = val === '' ? null : Number(val)
                updateUserInfo({
                    ...userCopy,
                    monthly_sales: numericValue,
                })
            }
        }
    }

    const handlePredictedMonthlySalesChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (userCopy && val.length <= 8) {
            if (val === '' || !isNaN(val as any)) {
                // Allow empty string or numeric values
                const numericValue = val === '' ? null : Number(val)
                updateUserInfo({
                    ...userCopy,
                    predicted_monthly_sales: numericValue,
                })
            }
        }
    }

    const handleRequestedHighestTicketAmountChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (userCopy && val.length <= 8) {
            if (val === '' || !isNaN(val as any)) {
                // Allow empty string or numeric values
                const numericValue = val === '' ? null : Number(val)
                updateUserInfo({
                    ...userCopy,
                    requested_highest_ticket_amount: numericValue,
                })
            }
        }
    }

    const handleLowestTicketAmountChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (userCopy && val.length <= 8) {
            if (val === '' || !isNaN(val as any)) {
                // Allow empty string or numeric values
                const numericValue = val === '' ? null : Number(val)
                updateUserInfo({
                    ...userCopy,
                    lowest_ticket_amount: numericValue,
                })
            }
        }
    }
    const handleSaveChanges = () => {
        saveChanges()
    }

    const hasUnsavedChanges = () => {
        return JSON.stringify(user) !== JSON.stringify(userCopy)
    }

    const handleCancel = () => {
        if (!user) return
        if (hasUnsavedChanges()) {
            setIsDialogOpen(true) // Show the dialog if there are unsaved changes
        } else {
            navigate('/users/' + user.id + '/ticket-sales') // No unsaved changes, just navigate away
        }
    }

    const confirmCancel = () => {
        setIsDialogOpen(false) // Close the dialog
        if (user) updateUserInfo(user) // Reset the user to the original state (cancel changes
        navigate('/users/' + user?.id + '/ticket-sales') // Proceed with the cancel action
    }

    const cancelCancel = () => {
        setIsDialogOpen(false) // Close the dialog without canceling changes
    }

    return (
        <div className={`p-10`}>
            <div className="mb-2 flex justify-end space-x-2">
                <button
                    onClick={handleSaveChanges}
                    type="button"
                    className={`block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm disabled:bg-gray-400 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                >
                    Save
                </button>
                <button
                    onClick={handleCancel}
                    type="button"
                    className="flex  rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                    Cancel
                </button>
            </div>
            <div className={'border border-gray-100 rounded-md p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Ticket Sales
                    </h3>
                </div>
                <div className="mt-6 border-t border-gray-100 grid grid-cols-2 gap-2">
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Average Ticket (Sale) Amount
                                </dt>
                                <input
                                    onChange={handleAvgTicketAmountChange}
                                    type="text" // Considering keeping type as text for handling non-numeric inputs
                                    name="avg-ticket-amount"
                                    id="avg-ticket-amount"
                                    value={
                                        userCopy?.avg_ticket_amount !== null
                                            ? userCopy?.avg_ticket_amount
                                                  .toString()
                                                  .replace(/\.00$/, '')
                                            : ''
                                    }
                                    className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Highest Ticket (Sale) Amount
                                </dt>
                                <input
                                    onChange={handleHighestTicketAmountChange}
                                    type="text"
                                    name="highest-ticket-amount"
                                    id="highest-ticket-amount"
                                    value={
                                        userCopy?.highest_ticket_amount !== null
                                            ? userCopy?.highest_ticket_amount
                                                  .toString()
                                                  .replace(/\.00$/, '')
                                            : ''
                                    }
                                    className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Monthly Sales Volume (All Sales)
                                </dt>

                                <input
                                    onChange={handleMonthlySalesVolumeChange}
                                    type="text"
                                    name="monthly-sale-amount"
                                    id="monthly-sale-amount"
                                    value={
                                        userCopy?.monthly_sales !== null
                                            ? userCopy?.monthly_sales
                                                  .toString()
                                                  .replace(/\.00$/, '')
                                            : ''
                                    }
                                    className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Predicted Monthly Sales
                                </dt>
                                <input
                                    onChange={handlePredictedMonthlySalesChange}
                                    type="text"
                                    name="predicted-sale-amount"
                                    id="predicted-sale-amount"
                                    value={
                                        userCopy?.predicted_monthly_sales !==
                                        null
                                            ? userCopy?.predicted_monthly_sales
                                                  .toString()
                                                  .replace(/\.00$/, '')
                                            : ''
                                    }
                                    className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                />
                            </div>
                        </dl>
                    </div>
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Requested Highest Ticket Sales
                                </dt>
                                <input
                                    onChange={
                                        handleRequestedHighestTicketAmountChange
                                    }
                                    type="text"
                                    name="requested-sale-amount"
                                    id="requested-sale-amount"
                                    value={
                                        userCopy?.requested_highest_ticket_amount !==
                                        null
                                            ? userCopy?.requested_highest_ticket_amount
                                                  .toString()
                                                  .replace(/\.00$/, '')
                                            : ''
                                    }
                                    className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Lowest Ticket Sale
                                </dt>
                                <input
                                    onChange={handleLowestTicketAmountChange}
                                    type="text"
                                    name="lowest-sale-amount"
                                    id="lowest-sale-amount"
                                    value={
                                        userCopy?.lowest_ticket_amount !== null
                                            ? userCopy?.lowest_ticket_amount
                                                  .toString()
                                                  .replace(/\.00$/, '')
                                            : ''
                                    }
                                    className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                />
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <DialogModal
                open={isDialogOpen}
                setOpen={setIsDialogOpen}
                title="Unsaved Changes"
                description="You have unsaved changes. Are you sure you want to cancel? Your changes will be lost."
                confirmAction={confirmCancel}
                cancelAction={cancelCancel}
            />
        </div>
    )
}
