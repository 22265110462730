import { BaseRepository } from './BaseRepository'

export class RegisterUserRepository extends BaseRepository {
    registerUser = async (newUser: Paywell.ReduxStore.Onboarding.NewUser) => {
        return await super
            .getHttpClientInstance()
            .post<Paywell.ReduxStore.Auth.User>(`/api/register`, {
                email: newUser.email,
                name: newUser.business_name,
                password: newUser.password,
                password_confirmation: newUser.confirm_password,
                country: newUser.country,
                first_name: newUser.first_name,
                last_name: newUser.last_name,
                uuid: newUser.uuid,
            })
    }
}
