import React, {useContext, useEffect, useState} from 'react'
import {Button, Card, Col, Container, Row} from "react-bootstrap";

import {
  ClockIcon,
  BoltIcon
} from '@heroicons/react/24/outline'

interface PathChooserProps {
  fastClickedFn: (e: any) => void
  manualInstructionsClickedFn: (e: any) => void
}

const PathChooser = (props: PathChooserProps) => {

  const [fastIsHovered, setFastIsHovered] = useState(false);
  const [manualIsHovered, setManualIsHovered] = useState(false);

  const handleMouseEnterFast = () => {
    setFastIsHovered(true);
  };

  const handleMouseLeaveFast = () => {
    setFastIsHovered(false);
  };

  const handleMouseEnterManual = () => {
    setManualIsHovered(true);
  };

  const handleMouseLeaveManual = () => {
    setManualIsHovered(false);
  };


  const actions = [
    {
      title: 'Instant eTransfer',
      text: "You'll be redirected to your bank to complete the transaction.",
      href: '#',
      icon: BoltIcon,
      iconForeground: 'text-teal-700',
      iconBackground: 'bg-teal-50',
      onClick: props.fastClickedFn
    },
    {
      title: 'Manual eTransfer',
      text: "Send an e-Transfer manually by following instructions.",
      href: '#',
      icon: ClockIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
      onClick: props.manualInstructionsClickedFn
    },
  ]

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
  }

  const cardStyleFast = {
    cursor: 'pointer',
    transform: fastIsHovered ? 'scale(1.05)' : 'scale(1)',
    transition: 'transform 0.3s',
    boxShadow: '0px 0px 5px 2px #3175e23b'
  };

  const cardStyleManual = {
    cursor: 'pointer',
    transform: manualIsHovered ? 'scale(1.05)' : 'scale(1)',
    transition: 'transform 0.3s'
  };

  return(
    <>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Please Choose An Option</h2>
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0 mt-3">
          {actions.map((action, actionIdx) => (
            <div
              key={action.title}
              className={classNames(
                actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
              )}
              onClick={action.onClick}
            >
              <div>
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  'inline-flex rounded-lg p-3 ring-4 ring-white'
                )}
              >
                <action.icon className="h-6 w-6" aria-hidden="true" />
              </span>
              </div>
              <div className="mt-8">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  <a href={action.href} className="focus:outline-none">
                    {/* Extend touch target to entire panel */}
                    <span className="absolute inset-0" aria-hidden="true" />
                    {action.title}
                  </a>
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  {action.text}
                </p>
              </div>
              <span
                className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
              >
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default PathChooser