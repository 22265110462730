import { useCallback, useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import {
    Editor,
    Transforms,
    createEditor,
    Descendant,
    Element as SlateElement,
} from 'slate'
import { withHistory } from 'slate-history'
import { Button, Toolbar, deserialize, serialize } from './Components'

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

interface TextEditorProps {
    html: string
    setHtml: Function
}

const TextEditor = ({ html, setHtml }: TextEditorProps) => {
    const document = new DOMParser().parseFromString(html, 'text/html')
    const initialValue = html
        ? deserialize(document.body)
        : [
              {
                  type: 'paragraph',
                  children: [{ text: '' }],
              },
          ]

    const renderElement = useCallback(
        (props: any) => <Element {...props} />,
        []
    )
    const renderLeaf = useCallback((props: any) => <Leaf {...props} />, [])
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])

    const handleValueChange = (value: Descendant[]) => {
        let htmlSerialized = value.map((node) => serialize(node)).join('\n')
        setHtml(htmlSerialized)
    }

    return (
        <div className="border mt-2">
            <Slate
                editor={editor}
                initialValue={initialValue}
                onChange={(value) => {
                    handleValueChange(value)
                }}
            >
                <div className="flex space-x-4 bg-white p-5">
                    <Toolbar>
                        <MarkButton format="bold" icon="format_bold" />
                        <MarkButton format="italic" icon="format_italic" />
                        <MarkButton
                            format="underline"
                            icon="format_underlined"
                        />
                        <BlockButton format="heading-one" icon="looks_one" />
                        <BlockButton format="heading-two" icon="looks_two" />
                        <BlockButton
                            format="numbered-list"
                            icon="format_list_numbered"
                        />
                        <BlockButton
                            format="bulleted-list"
                            icon="format_list_bulleted"
                        />
                        <BlockButton format="left" icon="format_align_left" />
                        <BlockButton
                            format="center"
                            icon="format_align_center"
                        />
                        <BlockButton format="right" icon="format_align_right" />
                        <BlockButton
                            format="justify"
                            icon="format_align_justify"
                        />
                    </Toolbar>
                </div>
                <div>
                    <Editable
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        spellCheck
                        style={{ minHeight: '200px', padding: '10px' }}
                        onKeyDown={(event) => {
                            for (const hotkey in HOTKEYS) {
                                if (isHotkey(hotkey, event as any)) {
                                    event.preventDefault()
                                    // @ts-ignore
                                    const mark = HOTKEYS[hotkey]
                                    toggleMark(editor, mark)
                                }
                            }

                            if (event.key === 'Enter') {
                                event.preventDefault()
                                Editor.insertBreak(editor)
                            }
                        }}
                    />
                </div>
            </Slate>
        </div>
    )
}

const toggleBlock = (editor: any, format: any) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    })
    let newProperties: any
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        }
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
    }
    Transforms.setNodes<SlateElement>(editor, newProperties)
    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

const toggleMark = (editor: any, format: any) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const isBlockActive = (editor: any, format: any, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),

            match: (n) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                // @ts-ignore
                n[blockType] === format,
        })
    )

    return !!match
}

const isMarkActive = (editor: any, format: any) => {
    const marks = Editor.marks(editor)
    // @ts-ignore
    return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element }: any) => {
    const style = { textAlign: element.align }

    switch (element.type) {
        case 'heading-one':
            return (
                <h1 className="text-2xl" style={style} {...attributes}>
                    {children}
                </h1>
            )
        case 'heading-two':
            return (
                <h2 className="text-xl" style={style} {...attributes}>
                    {children}
                </h2>
            )
        case 'bulleted-list':
            return (
                <ul
                    className="list-disc list-inside"
                    style={style}
                    {...attributes}
                >
                    {children}
                </ul>
            )
        case 'numbered-list':
            return (
                <ol
                    style={style}
                    {...attributes}
                    className="list-decimal list-inside"
                >
                    {children}
                </ol>
            )
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            )

        case 'break-line':
            return <br />

        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            )
    }
}

const Leaf = ({ attributes, children, leaf }: any) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }: any) => {
    const editor = useSlate()

    let isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    return (
        <div
            id="blockButton"
            className={`border border-black cursor-pointer p-1 rounded ${
                isActive ? 'bg-blue-300' : ''
            }`}
        >
            <Button
                active={isActive}
                onMouseDown={(event: any) => {
                    event.preventDefault()
                    toggleBlock(editor, format)
                }}
            >
                {getIcon(icon, isActive)}
            </Button>
        </div>
    )
}

const MarkButton = ({ format, icon }: any) => {
    const editor = useSlate()
    const isActive = isMarkActive(editor, format)
    return (
        <div
            id="markButton"
            className={`border border-black cursor-pointer p-1 rounded ${
                isActive ? 'bg-blue-300' : ''
            }`}
        >
            <Button
                active={isActive}
                onMouseDown={(event: any) => {
                    event.preventDefault()
                    toggleMark(editor, format)
                }}
            >
                {getIcon(icon, isActive)}
            </Button>
        </div>
    )
}

const getIcon = (name: string, active: boolean) => {
    switch (name) {
        case 'format_bold':
            return (
                <img
                    className="h-5 w-5"
                    src="/EditorIcons/font-bold.svg"
                    alt="bold"
                />
            )
        case 'format_italic':
            return (
                <img
                    className="h-5 w-5"
                    src="/EditorIcons/font-italic.svg"
                    alt="italic"
                />
            )
        case 'format_underlined':
            return (
                <img
                    className="h-5 w-5 "
                    src="/EditorIcons/font-underline.svg"
                    alt="underline"
                />
            )
        case 'looks_one':
            return (
                <img
                    className="h-5 w-5"
                    src="/EditorIcons/number-1.png"
                    alt="h1"
                />
            )
        case 'looks_two':
            return (
                <img
                    className="h-5 w-5"
                    src="/EditorIcons/number-2.png"
                    alt="h2"
                />
            )
        case 'format_list_numbered':
            return (
                <img
                    className="h-5 w-5"
                    src="/EditorIcons/number-list.svg"
                    alt="list"
                />
            )
        case 'format_list_bulleted':
            return (
                <img
                    className="h-5 w-5"
                    src="/EditorIcons/bullet-list.svg"
                    alt="list-bulleted"
                />
            )
        case 'format_align_left':
            return (
                <img
                    className="h-5 w-5"
                    src="/EditorIcons/format-left.svg"
                    alt="left-aligned"
                />
            )
        case 'format_align_center':
            return (
                <img
                    className="h-5 w-5"
                    src="/EditorIcons/format-center.svg"
                    alt="center-aligned"
                />
            )
        case 'format_align_right':
            return (
                <img
                    className="h-5 w-5"
                    src="/EditorIcons/format-right.svg"
                    alt="right-align"
                />
            )
        case 'format_align_justify':
            return (
                <img
                    className="h-5 w-5"
                    src="/EditorIcons/format-justify.svg"
                    alt="justify"
                />
            )

        default:
    }
}

export default TextEditor
