const STATUS_CODES: Record<Paywell.API.V2.TransactionStatusCode, string> = {
    pending: 'Pending',
    sent: 'Sent',
    error: 'Error',
    settled: 'Settled',
    return: 'Return',
    resend: 'Resend',
    rejected: 'Rejected',
    completed: 'Completed',
    cancelled: 'Cancelled',
    unapproved: 'Unapproved',
    traced: 'Traced',
    incoming: 'Incoming',
    ready_to_send: 'Ready To Send',
    deferred: 'Deferred',
    blocked: 'Blocked',
    insufficient_balance: 'Insufficient Balance',
    authenticated: 'Authenticated',
}

const TRANSACTION_TYPES: Record<Paywell.API.V2.TransactionType, string> = {
    MT: 'Manual Transfer',
    CC: 'Credit Card',
    CK: 'Check',
    WT: 'Wire Transfer',
    CR: 'Crypto',
    EFT: 'Electronic Funds Transfer',
}

export function getHumanReadableType(type: Paywell.API.V2.TransactionType) {
    return TRANSACTION_TYPES[type]
}
export function getHumanReadableStatus(
    status: Paywell.API.V2.TransactionStatusCode
) {
    return STATUS_CODES[status] || 'Unknown Status'
}

export const getBackgroundClassName = (status: string) => {
    switch (status) {
        case 'pending':
            return 'bg-yellow-100 ring-yellow-500'
        case 'sent':
            return 'bg-yellow-100 ring-yellow-500'
        case 'error':
            return 'bg-red-100 ring-red-500'
        case 'settled':
            return 'bg-green-100 ring-green-500'
        case 'return':
            return 'bg-red-100 ring-red-500'
        case 'resend':
            return 'bg-yellow-100 ring-yellow-500'
        case 'rejected':
            return 'bg-red-100 ring-red-500'
        case 'completed':
            return 'bg-green-100 ring-green-500'
        case 'cancelled':
            return 'bg-red-100 ring-red-500'
        case 'unapproved':
            return 'bg-red-100 ring-red-500'
        case 'traced':
            return 'bg-yellow-100 ring-yellow-500'
        case 'incoming':
            return 'bg-green-100 ring-green-500'
        case 'ready_to_send':
            return 'bg-yellow-100 ring-yellow-500'
        case 'deferred':
            return 'bg-yellow-100 ring-yellow-500'
        case 'blocked':
            return 'bg-red-100 ring-red-500'
        case 'insufficient_balance':
            return 'bg-red-100 ring-red-500'
        case 'authenticated':
            return 'bg-green-100 ring-green-500'
        default:
            return 'bg-white ring-gray-900/5'
    }
}
