export default function SkeletonLoader({
    rows,
    columns,
    isError,
}: {
    rows: number
    columns: number
    isError: boolean
}) {
    const skeleton: Array<any> = []
    let column: Array<any> = []
    for (let x = 0; x < rows; x++) {
        column = []
        for (let y = 0; y < columns; y++) {
            column.push(y)
        }
        skeleton.push(column)
    }
    return (
        <>
            {skeleton.map((blank: Array<string>, index: number) => (
                <tr key={index} className="h-9 animate-pulse">
                    {blank.map((loader: any, index: number) => (
                        <td
                            key={index}
                            className="whitespace-nowrap px-2 py-2 text-sm text-gray-500"
                        >
                            <div
                                className={`w-20 h-2.5 ${
                                    isError ? 'bg-red-500' : 'bg-gray-200'
                                } rounded-full dark:bg-gray-700 mr-3`}
                            ></div>
                        </td>
                    ))}
                </tr>
            ))}
        </>
    )
}
