import SkeletonLoader from '../invoices/components/SkeletonLoader'
import NumberFormatDollars from '../utils/NumberFormatDollars'
import { formatDate, formatNumberWithComma } from '../utils/StringUtils'
import PaginationV2 from '../invoices/components/PaginationV2'
import { TransactionsRepository } from '../api/TransactionsRepository'
import { useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import {
    getHumanReadableType,
    getHumanReadableStatus,
    getBackgroundClassName,
} from '../utils/TransactionHelper'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { UserRepository } from '../api/UserRepositoy'
import { setError } from '../redux/state-slices/GlobalNotificationSlice'
import { useMutation } from 'react-query'

export default function Transactions() {
    const transactionRepository = new TransactionsRepository()
    const userRepository = new UserRepository()
    const [searchParams] = useSearchParams()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const authUser = useAppSelector((state) => state.auth_user)
    const isAdminOrAgent =
        authUser?.user_type_id === 3 || authUser?.user_type_id === 2
    const isAdmin = authUser?.user_type_id === 3
    const page = searchParams.get('page') ? searchParams.get('page') : '1'
    const [fromDate, setFromDate] = useState<string | null>(null)
    const [toDate, setToDate] = useState<string | null>(null)
    const [validDates, setValidDates] = useState(true)
    const [user, setUser] = useState<string | null>(null)
    const [searchCriteria, setSearchCriteria] = useState<string | null>(null)
    const [tempFromDate, setTempFromDate] = useState<string | null>(null)
    const [tempToDate, setTempToDate] = useState<string | null>(null)
    const [tempUser, setTempUser] = useState<string | null>(null)
    const [tempSearchCriteria, setTempSearchCriteria] = useState<string | null>(
        null
    )
    const [pagination, setPagination] = useState<string>('50')
    const fetchTransactions = async () => {
        return (
            await transactionRepository.getTransactions(
                pagination,
                page,
                fromDate,
                toDate,
                user,
                searchCriteria
            )
        ).data
    }

    const fetchUsers = async () => {
        return (await userRepository.getUserMerchants()).data
    }

    const downloadTransactionsMutation = useMutation(
        () =>
            transactionRepository.downloadTransactions(
                fromDate,
                toDate,
                user,
                searchCriteria
            ),
        {
            onSuccess: (data) => {
                const url = window.URL.createObjectURL(new Blob([data.data]))
                // @ts-ignore
                const contentDisposition = data.headers['content-disposition']
                let fileName = 'transactions.csv' // Default file name
                if (contentDisposition) {
                    const fileNameMatch =
                        contentDisposition.match(/filename="?([^"]+)"?/)
                    if (fileNameMatch.length === 2) {
                        fileName = fileNameMatch[1]
                    }
                }

                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                link.parentNode?.removeChild(link)
            },
            onError: (error) => {
                dispatch(
                    setError([
                        'There was an error downloading the file. Please try again',
                    ])
                )
            },
        }
    )
    //eslint-disable-next-line
    const transactionsQuery = useQuery(
        [
            'transactions',
            pagination,
            page,
            fromDate,
            toDate,
            user,
            searchCriteria,
            user,
        ],
        () => fetchTransactions(),
        {
            onSuccess: (data) => {},

            retry: false,
            refetchOnWindowFocus: false,
            enabled:
                !!((fromDate && toDate) || (!fromDate && !toDate)) ||
                !isAdminOrAgent,
        }
    )

    const userMerchantsQuery = useQuery(
        ['user_merchants'],
        () => fetchUsers(),
        {
            onSuccess: (data) => {},
            onError: (error) => {
                dispatch(setError(['Error retrieving users. Please try again']))
            },
            refetchOnWindowFocus: false,
            enabled: isAdminOrAgent,
        }
    )

    const handleSearchCriteriaChange = (e: any) => {
        //limit length to 100 characters
        if (e.target.value.length <= 100) {
            setTempSearchCriteria(e.target.value)
        }
    }
    const handleUserChange = (e: any) => {
        setTempUser(e.target.value)
    }
    const handleSearch = () => {
        navigate(`?page=1`)
        setFromDate(tempFromDate)
        setToDate(tempToDate)
        setUser(tempUser)
        setSearchCriteria(tempSearchCriteria)
    }

    const clearSearch = () => {
        setTempFromDate(null)
        setTempToDate(null)
        setFromDate(null)
        setToDate(null)
        setTempUser(null)
        setUser(null)
        setTempSearchCriteria(null)
        setSearchCriteria(null)
        navigate(`?page=1`)
    }

    const handlePaginationChange = (e: any) => {
        setPagination(e.target.value)
    }

    useEffect(() => {
        //if from date is greater than to date, set invalid dates to true
        if (tempFromDate && tempToDate) {
            const fromDateObj = new Date(tempFromDate)
            const toDateObj = new Date(tempToDate)
            if (fromDateObj > toDateObj) {
                setValidDates(false)
            } else {
                setValidDates(true)
            }
        } else if (!tempFromDate && tempToDate) {
            setValidDates(false)
        } else if (tempFromDate && !tempToDate) {
            setValidDates(false)
        } else if (!fromDate && !toDate) {
            setValidDates(true)
        }
    }, [tempFromDate, tempToDate])

    const total = transactionsQuery.data?.transactions?.total
    const from = transactionsQuery.data?.transactions.from
    const to = transactionsQuery.data?.transactions.to
    const next = transactionsQuery.data?.transactions.next_page_url
    const prev = transactionsQuery.data?.transactions.prev_page_url
    const links = transactionsQuery.data?.transactions.links

    const users = userMerchantsQuery.data

    const showClearSearch = fromDate || toDate || user || searchCriteria
    return (
        <div className="p-5">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">
                            Transactions
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                            View your transactions
                        </p>
                    </div>
                </div>
                <div className="mt-4 flow-root">
                    <div className="flex justify-between text-gray-400 focus-within:text-gray-600 mb-5 max-w-1/2">
                        <div>
                            <div className="max-w-sm md:flex md:space-x-2">
                                <div className="">
                                    <label className="text-black font-medium">
                                        From
                                    </label>
                                    <input
                                        id="from-date"
                                        className="mb-3 md:mb-0 w-full rounded-md border-1 focus:border-0 bg-white  text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="Enter Date"
                                        type="date"
                                        name="from-date"
                                        value={tempFromDate || ''}
                                        onChange={(e) =>
                                            setTempFromDate(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="">
                                    <label className="text-black font-medium">
                                        To
                                    </label>
                                    <input
                                        id="to-date"
                                        className=" w-full rounded-md border-1 focus:border-0 bg-white  text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:text-sm sm:leading-6"
                                        type="date"
                                        name="to-date"
                                        value={tempToDate || ''}
                                        onChange={(e) =>
                                            setTempToDate(e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            {isAdminOrAgent && (
                                <div className="max-w-sm mt-3">
                                    <label className="text-black font-medium mb-2">
                                        Select User
                                    </label>
                                    <select
                                        id="user"
                                        className="w-full rounded-md border-1 focus:border-0 bg-white disabled:bg-gray-200 disabled:cursor-not-allowed  text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:text-sm sm:leading-6"
                                        name="user"
                                        disabled={
                                            !isAdminOrAgent ||
                                            userMerchantsQuery.isLoading ||
                                            userMerchantsQuery.isFetching
                                        }
                                        value={tempUser || ''}
                                        onChange={handleUserChange}
                                    >
                                        <option value="">Select User</option>
                                        {users?.map((user) => {
                                            return (
                                                <option
                                                    key={user.id}
                                                    value={user.id}
                                                >
                                                    {user.name}{' '}
                                                    {user.nickname
                                                        ? `- ${user.nickname}`
                                                        : ''}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            )}

                            <div className="max-w-sm mt-3">
                                <label className="text-black font-medium mb-2">
                                    Search criteria{' '}
                                </label>
                                <input
                                    id="search"
                                    className=" w-full rounded-md border-1 focus:border-0 bg-white  text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:text-sm sm:leading-6"
                                    type="text"
                                    name="search"
                                    placeholder="Search name, transaction code, amount"
                                    value={tempSearchCriteria || ''}
                                    onChange={handleSearchCriteriaChange}
                                />
                            </div>

                            <div className="flex text-black items-center mt-4">
                                <button
                                    disabled={
                                        transactionsQuery.isFetching ||
                                        transactionsQuery.isLoading ||
                                        !validDates
                                    }
                                    onClick={handleSearch}
                                    className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    Search
                                </button>

                                {showClearSearch && (
                                    <XMarkIcon
                                        className="h-5 w-5 cursor-pointer ml-2"
                                        onClick={clearSearch}
                                    />
                                )}
                            </div>

                            {!validDates && (
                                <p className="text-red-500 text-sm mt-2">
                                    Please select a valid date range
                                </p>
                            )}
                        </div>
                    </div>

                    {transactionsQuery.isSuccess &&
                        !transactionsQuery.isFetching && (
                            <div className="flex flex-col md:flex-row justify-between text-gray-400 focus-within:text-gray-600 mb-5 max-w-full md:max-w-1/2">
                                <div className="text-black items-center mt-4 mb-4 font-medium">
                                    <div className="mb-2">
                                        Total number of transactions:{' '}
                                        <span className="bg-green-50 ring-green-500 p-1 rounded-md">
                                            {formatNumberWithComma(
                                                transactionsQuery.data
                                                    ?.total_transactions
                                            )}
                                        </span>
                                    </div>
                                    <div>
                                        Total amount:{' '}
                                        <span className="bg-green-50 ring-green-500 p-1 rounded-md">
                                            {NumberFormatDollars(
                                                transactionsQuery.data
                                                    ?.total_amount
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className="text-right">
                                    <button
                                        onClick={() =>
                                            downloadTransactionsMutation.mutate()
                                        }
                                        disabled={
                                            downloadTransactionsMutation.isLoading
                                        }
                                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white inline-flex items-center   shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed"
                                    >
                                        <svg
                                            className="fill-current w-4 h-4 mr-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                                        </svg>
                                        <span>Export</span>
                                    </button>
                                    <select
                                        value={pagination || '50'}
                                        onChange={handlePaginationChange}
                                        id="pagination"
                                        name="pagination"
                                        className={`mt-2 block w-full max-w-sm rounded-md border-gray-300 shadow-sm  sm:text-sm `}
                                    >
                                        <option value="50">50 Per Page</option>
                                        <option value="100">
                                            100 Per Page
                                        </option>
                                        <option value="200">
                                            200 Per Page
                                        </option>
                                    </select>
                                </div>
                            </div>
                        )}
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                        >
                                            Date
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Code
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Type
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Amount
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Sender
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Status
                                        </th>
                                        {isAdmin && (
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                User
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {(transactionsQuery.isLoading ||
                                        transactionsQuery.isFetching) && (
                                        <SkeletonLoader
                                            rows={5}
                                            columns={8}
                                            isError={transactionsQuery.isError}
                                        />
                                    )}
                                    {transactionsQuery.isSuccess &&
                                        !transactionsQuery.isFetching &&
                                        transactionsQuery.data.transactions &&
                                        transactionsQuery.data.transactions.data
                                            ?.length > 0 &&
                                        transactionsQuery.data.transactions.data.map(
                                            (transaction) => {
                                                return (
                                                    <tr
                                                        key={transaction.id}
                                                        className="even:bg-gray-50"
                                                    >
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                                            {formatDate(
                                                                transaction.process_date
                                                            )}
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            {
                                                                transaction.transaction_code
                                                            }
                                                        </td>

                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            {getHumanReadableType(
                                                                transaction.type
                                                            )}
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            {NumberFormatDollars(
                                                                transaction.amount
                                                            )}
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            {
                                                                transaction.name_on_card
                                                            }
                                                        </td>
                                                        <td
                                                            className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500`}
                                                        >
                                                            <span
                                                                className={`inline-flex items-center rounded-md ${getBackgroundClassName(
                                                                    transaction.status_code
                                                                )} px-2 py-1 text-xs font-medium text-black ring-1 ring-inset`}
                                                            >
                                                                {getHumanReadableStatus(
                                                                    transaction.status_code
                                                                )}
                                                            </span>
                                                        </td>
                                                        {isAdmin && (
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                <a
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    href={`/legacy/users/${transaction.user_id}`}
                                                                    className="text-blue-600"
                                                                >
                                                                    View
                                                                </a>
                                                            </td>
                                                        )}

                                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                                            <a
                                                                className="text-indigo-600 hover:text-indigo-900 disabled:text-gray-300"
                                                                href={`/transactions/${transaction.id}`}
                                                            >
                                                                View
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        )}
                                    {transactionsQuery.isSuccess &&
                                        !transactionsQuery.isFetching &&
                                        transactionsQuery.data.transactions.data
                                            ?.length === 0 && (
                                            <tr>
                                                <td
                                                    colSpan={7}
                                                    className="text-center py-4 text-gray-500 italic"
                                                >
                                                    No transactions found.
                                                </td>
                                            </tr>
                                        )}

                                    {transactionsQuery.isError &&
                                        !transactionsQuery.isFetching && (
                                            <tr>
                                                <td
                                                    colSpan={7}
                                                    className="text-center py-4 text-red-500"
                                                >
                                                    Error loading transactions
                                                </td>
                                            </tr>
                                        )}
                                    <tr className="border-t-2">
                                        <td colSpan={8}>
                                            <PaginationV2
                                                pages={links}
                                                from={from}
                                                to={to}
                                                total={total}
                                                next={next}
                                                prev={prev}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
