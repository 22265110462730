import Decimal from 'decimal.js'

export const testNameRegex = (val: string) => {
    let nameRegex = /^[a-z ,.'-]+$/i

    if (val === null || val === '') {
        return false
    }

    return nameRegex.test(val)
}

export const testAlphaNumericRegex = (val: string) => {
    let alphaNumericRegex = /^[a-z0-9]+$/i //https://stackoverflow.com/questions/388996/regex-for-javascript-to-allow-only-alphanumeric
    if (val === '' || val === null) return true
    return alphaNumericRegex.test(val)
}

export const testSinSSnRegex = (val: string | undefined) => {
    let sinSsnRegex = /^\d+$/

    //https://stackoverflow.com/questions/20082855/regular-expression-for-canadian-sin-social-insurance-number

    if (!val) {
        return true
    } else {
        return sinSsnRegex.test(val) && val.toString().length === 9
    }
}

export const testAddressRegex = (val: string) => {
    let addressRegex = /^[#.0-9a-zA-Z\s,-]+$/ //https://stackoverflow.com/questions/11456670/regular-expression-for-address-field-validation

    return addressRegex.test(val)
}

export const testCityRegex = (val: string) => {
    let cityRegex =
        /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/ //https://stackoverflow.com/questions/11757013/regular-expressions-for-city-name
    return cityRegex.test(val)
}

export const billingProvinceRegex = (val: string) => {
    let provinceRegex = /^[A-Za-zÀ-ÿ .'-]+$/

    return provinceRegex.test(val)
}

export const testPostalCodeRegex = (val: string) => {
    let alphaNumericRegex = /^[a-z0-9]+$/i //https://stackoverflow.com/questions/388996/regex-for-javascript-to-allow-only-alphanumeric

    return alphaNumericRegex.test(val)
}

export const testPhoneNumRegex = (val: string | undefined) => {
    let phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{4}$/

    if (val) {
        return phoneRegex.test(val)
    } else {
        return false
    }
}

export const testWebsiteRegex = (val: string) => {
    let urlRegex3 =
        /^(?!:\/\/)([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}?$/i

    return urlRegex3.test(val)
}

export const testEmailRegex = (val: string) => {
    let emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return emailRegex.test(val)
}

export const testAlphaNumericWithSpaces = (val: string) => {
    let regex = /^[\w\-\s]+$/

    return regex.test(val)
}

export const testParagraphRegex = (val: string) => {
    let regex = /^[\w\-\s.,]+$/

    return regex.test(val)
}

export const testBusinessNameRegex = (val: string) => {
    let regex = /^[\w\-\s.#@]+$/

    return regex.test(val)
}

export const validateMinLength = (val: string, length: number = 2) => {
    if (val === null || val.length < length) return false

    return true
}

export const numOnlyRegex = (val: string) => {
    let numOnlyRegex = /^\d+$/
    if (val === null) {
        return false
    }
    return numOnlyRegex.test(val)
}

export const numWithTwoDecimalPlacesRegex = (val: string) => {
    let numRegex = /^(?:\d{0,}(?:\.\d{1,2})?)$/
    if (val === null) {
        return false
    }
    return numRegex.test(val)
}

export const nonInputKeyRegex = (val: string, text: string) => {
    let inputRegex =
        /^(?:Shift|Control|Alt|CapsLock|Tab|Escape|ArrowUp|ArrowDown|ArrowLeft|ArrowRight|Home|End|PageUp|PageDown|Insert|Delete|Backspace|Enter|F[1-9]|F1[0-2])$/
    if (text.trim() !== '') {
        let nonEmptyRegex = /^(Delete|Backspace)$/
        // Allow 'Delete' and 'Backspace' if text is not empty
        if (nonEmptyRegex.test(val)) {
            return false
        }
    }

    // Test for other input keys
    return inputRegex.test(val)
}

export const countryCodeRegex = (val: string) => {
    let countryRegex = /^(USA|CAD|MEX)$/i
    return countryRegex.test(val)
}

export const alphaNumericWithSpacesRegex = (val: string) => {
    let regex = /^[a-zA-Z0-9\s]+$/
    if (val === '' || val === null) return true
    return regex.test(val)
}

export const validateName = (val: string | undefined) => {
    if (
        val &&
        val.length < 20 &&
        validateMinLength(val) &&
        testNameRegex(val)
    ) {
        return true
    }
    return false
}

export const validateBusinessName = (val: string | undefined) => {
    if (
        val &&
        val.length < 50 &&
        validateMinLength(val) &&
        testBusinessNameRegex(val)
    ) {
        return true
    }
    return false
}

export const validateDriversLicenceNum = (val: string | undefined) => {
    if (
        val &&
        testAlphaNumericRegex(val) &&
        val.length >= 5 &&
        val.length <= 20
    ) {
        return true
    }
    return false
}

export const validateDateYYYMMDD = (val: string | undefined) => {
    if (val) {
        let dateRegex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/

        return dateRegex.test(val)
    }
    return false
}

export const validateAgeOver18 = (val: string | undefined) => {
    if (val) {
        if (validateDateYYYMMDD(val)) {
            let date = new Date(val)
            let eighteenYearsAgo = new Date()
            eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18)

            if (date < eighteenYearsAgo) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }
}

export const validateAddress = (val: string | undefined) => {
    if (
        val &&
        testAddressRegex(val) &&
        validateMinLength(val) &&
        val.length < 40
    ) {
        return true
    }
    return false
}

export const validateCity = (val: string | undefined) => {
    if (val && testCityRegex(val) && validateMinLength(val) && val.length < 30)
        return true

    return false
}

export const validateProvince = (val: string | undefined) => {
    if (val && billingProvinceRegex(val)) return true
    return false
}

export const validatePostalCode = (val: string | undefined) => {
    if (
        val &&
        testPostalCodeRegex(val) &&
        (val.length === 5 || val.length === 6)
    )
        return true
    return false
}

export const validateSaleAmount = (val: string | undefined) => {
    if (val && parseInt(val) > 0) return true
    return false
}

export const validateWebsite = (val: string | undefined) => {
    if (val && val.length < 25 && testWebsiteRegex(val)) return true

    return false
}

export const validateBusinessNum = (val: string | undefined) => {
    if (!val) return true
    if (val && val.length === 9 && numOnlyRegex(val)) {
        return true
    }
    return false
}

export const validateMerchandiseSold = (val: string | undefined) => {
    if (
        val &&
        val.length < 75 &&
        testParagraphRegex(val) &&
        validateMinLength(val, 3)
    ) {
        return true
    }
    return false
}

export const validateOccupationTitle = (val: string | undefined) => {
    if (
        val &&
        val.length < 75 &&
        testParagraphRegex(val) &&
        validateMinLength(val, 3)
    ) {
        return true
    }
    return false
}

export const validateBusinessFormation = (val: string | undefined) => {
    if (val) {
        let validValues = [
            'association_estate_trust',
            'corporation',
            'international_organization',
            'llc',
            'medical_legal_corporation',
            'non_profit',
            'partnership',
            'sole_proprietorship',
            'tax_exempt_organization',
        ]

        if (validValues.includes(val)) return true

        return false
    }

    return false
}

export const validateAccountNumber = (val: string | undefined) => {
    if (val) {
        if (
            val.length < 16 &&
            (val === '' || val === null || val.length < 5 || val.length > 15)
        ) {
            return false
        }
        return true
    }
    return false
}

export const validateInstitutionNumber = (val: string | undefined) => {
    if (val) {
        if (val.length < 5 && (val === '' || val === null || val.length < 3))
            return false

        return true
    }

    return false
}

export const validateTransitNumber = (val: string | undefined) => {
    if (val) {
        if (val.length < 10 && (val === '' || val === null || val.length < 5))
            return false
        return true
    }

    return false
}

export const validateDba = (val: string | undefined) => {
    if (val && val.length < 50 && validateMinLength(val)) return true
    return false
}

export const validatePercentageOwned = (percent: number) => {
    if (!percent) return false
    let val = new Decimal(percent)
    if (
        val.lessThan(new Decimal(0)) ||
        (val.greaterThan(new Decimal(0)) && val.lessThan(new Decimal(0.25))) ||
        val.greaterThan(new Decimal(1))
    ) {
        return false
    }
    return true
}

export const validateTotalPercentageOwnership = (
    identityInfosForms: Paywell.ReduxStore.Onboarding.IdentityInfoForm[]
) => {
    let totalPercentOwned = new Decimal(0)
    identityInfosForms.forEach((identityInfo) => {
        totalPercentOwned = totalPercentOwned.add(
            new Decimal(identityInfo.identity_info.percentage_ownership || 0)
        )
    })

    // Only 100% of the company can be owned so we check what the percent owned of all current shareholders is
    // and if less than 25% is available we dont want it (we only want people with 25% or more ownership)
    if (totalPercentOwned.greaterThan(new Decimal(1))) {
        return false
    }
    return true
}
export const validateCountry = (val: number | undefined) => {
    if (!val) return false

    if (val === 1 || val === 2) {
        return true
    }
    return false
}

export const validateCountryV2 = (val: string) => {
    return countryCodeRegex(val)
}

export const validateIdentityInfo = (
    identityInfosForms: Paywell.ReduxStore.Onboarding.IdentityInfoForm[],
    onboardingType: string = 'etransfer'
) => {
    let pass = true

    identityInfosForms.forEach((identityInfo) => {
        Object.keys(identityInfo.identity_info).forEach(function (key) {
            let val =
                identityInfo.identity_info[
                    key as keyof Paywell.API.V2.IdentityInfo
                ]?.toString()
            if (key === 'first_name' && !validateName(val)) {
                pass = false
            } else if (key === 'last_name' && !validateName(val)) {
                pass = false
            } else if (key === 'birthday' && !validateAgeOver18(val)) {
                pass = false
            } else if (key === 'tel_number' && !testPhoneNumRegex(val)) {
                pass = false
            } else if (key === 'address' && !validateAddress(val)) {
                pass = false
            } else if (key === 'city' && !validateCity(val)) {
                pass = false
            } else if (key === 'postal_code' && !validatePostalCode(val)) {
                pass = false
            } else if (key === 'drivers_licence_file_name' && !val) {
                pass = false
            } else if (key === 'provinces_id' && !val) {
                pass = false
            } else if (
                key === 'countries_id' &&
                !validateCountry(parseInt(val))
            ) {
                pass = false
            } else if (key === 'drivers_licence_file_name' && !val) {
                pass = false
            } else if (key === 'email_address' && !testEmailRegex(val)) {
                pass = false
            } else if (key === 'drivers_licence_file_name_back' && !val) {
                pass = false
            }

            if (onboardingType === 'etransfer') {
                if (!validateTotalPercentageOwnership(identityInfosForms)) {
                    pass = false
                } else if (
                    key === 'drivers_licence_number' &&
                    !validateDriversLicenceNum(val)
                ) {
                    pass = false
                } else if (key === 'SIN' && !testSinSSnRegex(val)) {
                    pass = false
                } else if (
                    key === 'percentage_ownership' &&
                    !validatePercentageOwned(val)
                ) {
                    pass = false
                } else if (
                    key === 'occupation_title' &&
                    !validateOccupationTitle(val || '')
                ) {
                    pass = false
                } else if (key === 'liveness_photo_file_name' && !val) {
                    pass = false
                } else if (key === 'proof_of_address_file_name' && !val) {
                    pass = false
                }
            }
        })
    })

    return pass
}

export const validateBusinessInfo = (
    businessInfo: Paywell.API.V2.BusinessInfo,
    onboardingType: string = 'etransfer'
) => {
    let pass = true
    Object.keys(businessInfo).forEach(function (key) {
        let val =
            businessInfo[key as keyof Paywell.API.V2.BusinessInfo]?.toString()

        if (key === 'name') {
            if (!validateBusinessName(val)) {
                pass = false
            }
        } else if (key === 'monthly_sales') {
            if (!validateSaleAmount(val)) {
                //pass = false TODO: do we need to validate this?
            }
        } else if (key === 'business_start_date') {
            if (!validateDateYYYMMDD(val)) {
                pass = false
            }
        } else if (key === 'business_formation') {
            if (!validateBusinessFormation(val)) {
                pass = false
            }
        } else if (key === 'nickname' && !validateDba(val)) {
            pass = false
        } else if (key === 'address' && !validateAddress(val)) {
            pass = false
        } else if (key === 'city' && !validateCity(val)) {
            pass = false
        } else if (key === 'postal_code' && !validatePostalCode(val)) {
            pass = false
        } else if (key === 'provinces_id' && !val) {
            pass = false
        } else if (
            key === 'predicted_monthly_sales' &&
            !validateSaleAmount(val)
        ) {
            pass = false
        }

        if (onboardingType === 'etransfer') {
            if (key === 'jurisdiction' && !validateMinLength(val || '')) {
                pass = false
            } else if (
                key === 'merchandise_sold' &&
                !validateMerchandiseSold(val)
            ) {
                pass = false
            } else if (key === 'business_number' && !validateBusinessNum(val)) {
                pass = false
            } else if (key === 'website' && !validateWebsite(val)) {
                pass = false
            } else if (
                key === 'avg_ticket_amount' &&
                !validateSaleAmount(val)
            ) {
                pass = false
            } else if (
                key === 'highest_ticket_amount' &&
                !validateSaleAmount(val)
            ) {
                pass = false
            } else if (
                key === 'requested_highest_ticket_amount' &&
                !validateSaleAmount(val)
            ) {
                pass = false
            } else if (
                key === 'lowest_ticket_amount' &&
                !validateSaleAmount(val)
            ) {
                pass = false
            } else if (key === 'business_registration_file_name') {
                if (
                    !val &&
                    businessInfo['business_formation'] !== 'sole_proprietorship'
                ) {
                    pass = false
                }
            } else if (key === 'proof_of_address_file_name' && !val) {
                pass = false
            }
        }
    })

    return pass
}

export const validateBankingInfo = (
    bankingInfo: Paywell.API.V2.BankingInfo
) => {
    let pass = true
    Object.keys(bankingInfo).forEach(function (key) {
        let val =
            bankingInfo[key as keyof Paywell.API.V2.BankingInfo]?.toString()
        if (key === 'account_number') {
            if (!validateAccountNumber(val)) pass = false
        } else if (key === 'institution_number') {
            if (!validateInstitutionNumber(val)) pass = false
        } else if (key === 'routing_number') {
            if (!validateTransitNumber(val)) pass = false
        } else if (key === 'void_cheque_file_name') {
            if (!val) pass = false
        }
    })

    return pass
}

export const validateInputKey = (val: string, text: string) => {
    return !nonInputKeyRegex(val, text)
}

export const validateTaxName = (val: string) => {
    if (
        alphaNumericWithSpacesRegex(val) &&
        val.length >= 1 &&
        val.length <= 40
    ) {
        return true
    }
    return false
}

export const validateTaxRate = (val: any) => {
    if (numWithTwoDecimalPlacesRegex(val) && val >= 0 && val <= 100) {
        return true
    }
    return false
}

export const validateProductName = (val: string) => {
    if (
        alphaNumericWithSpacesRegex(val) &&
        val.length >= 1 &&
        val.length <= 40
    ) {
        return true
    }
    return false
}

export const validateProductPrice = (val: any) => {
    if (numWithTwoDecimalPlacesRegex(val) && val > 0 && val <= 100000) {
        return true
    }
    return false
}

export const validateEmailAddress = (val: string) => {
    return testEmailRegex(val)
}

export const validateTax = (val: any) => {
    return numOnlyRegex(val)
}

export const validateNotes = (val: any) => {
    return true
}
