// Tooltip.tsx
import React, { useState } from 'react'

interface TooltipProps {
    children: React.ReactNode
    text: string
}

const Tooltip: React.FC<TooltipProps> = ({ children, text }) => {
    const [visible, setVisible] = useState(false)

    const handleTouchStart = () => {
        setVisible(true)
    }

    const handleTouchEnd = () => {
        setVisible(false)
    }

    return (
        <div className="relative inline-flex">
            <div
                onMouseEnter={() => setVisible(true)}
                onMouseLeave={() => setVisible(false)}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                className="cursor-pointer focus:outline-none p-1"
            >
                {children}
            </div>
            {visible && (
                <div
                    className={`absolute w-50 z-10 px-2 py-1 w-48 text-sm bg-gray-700 text-white rounded-md shadow-lg 
                       top-full mt-1 
                       transform -translate-x-2/3`}
                >
                    {text}
                </div>
            )}
        </div>
    )
}

export default Tooltip
