import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: Paywell.API.V2.Emails = {
    emails: [{ email: '', validated: true }],
    reset: false,
}

export const EmailSlice = createSlice({
    name: 'email',
    initialState,

    reducers: {
        setEmails: (state, action: PayloadAction<any>) => {
            if (action.payload.length < 1) state.emails = initialState.emails
            else state.emails = action.payload
        },
        addEmail: (state, action: PayloadAction<any>) => {
            state.emails = [...state.emails, { ...action.payload }]
        },
        updateEmail: (state, action: PayloadAction<any>) => {
            state.emails[action.payload.index].email = action.payload.value
            state.emails[action.payload.index].validated =
                action.payload.validated
        },
        removeEmail: (state, action: PayloadAction<number>) => {
            state.emails = state.emails.filter(
                (i: any, index: number) => index !== action.payload
            )
        },
    },
})

// Action creators are generated for each case reducer function
export const { setEmails, addEmail, removeEmail, updateEmail } =
    EmailSlice.actions

export default EmailSlice.reducer
