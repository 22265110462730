import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

const initialState: Paywell.API.V2.BusinessInfo = {
    name: null,
    business_formation: 'corporation',
    website: null,
    business_start_date: null,
    business_registration_file_name: null,
    merchandise_sold: null,
    business_number: null,
    avg_ticket_amount: null,
    highest_ticket_amount: null,
    monthly_sales: null,
    nickname: null,
    address: null,
    city: null,
    postal_code: null,
    countries_id: 1,
    provinces_id: null,
    jurisdiction: null,
    predicted_monthly_sales: null,
    requested_highest_ticket_amount: null,
    lowest_ticket_amount: null,
    proof_of_address_file_name: null,
    business_tel_number: null,
}

export const BusinessInfoSlice = createSlice({
    name: 'business_info',
    initialState: initialState as Paywell.API.V2.BusinessInfo,

    reducers: {
        setBusinessInfo: (
            state: Paywell.API.V2.BusinessInfo,
            action: PayloadAction<Paywell.API.V2.BusinessInfo>
        ) => {
            const businessInfo: Paywell.API.V2.BusinessInfo = {
                name: action.payload.name,
                business_formation:
                    action.payload.business_formation || 'corporation',
                merchandise_sold: action.payload.merchandise_sold,
                business_number: action.payload.business_number,
                website: action.payload.website,
                avg_ticket_amount: action.payload.avg_ticket_amount,
                highest_ticket_amount: action.payload.highest_ticket_amount,
                monthly_sales: action.payload.monthly_sales,
                business_start_date: action.payload.business_start_date,
                business_registration_file_name:
                    action.payload.business_registration_file_name,
                nickname: action.payload.nickname,
                address: action.payload.address,
                city: action.payload.city,
                postal_code: action.payload.postal_code,
                countries_id: action.payload.countries_id,
                provinces_id: action.payload.provinces_id,
                jurisdiction: action.payload.jurisdiction,
                predicted_monthly_sales: action.payload.predicted_monthly_sales,
                requested_highest_ticket_amount:
                    action.payload.requested_highest_ticket_amount,
                lowest_ticket_amount: action.payload.lowest_ticket_amount,
                proof_of_address_file_name:
                    action.payload.proof_of_address_file_name,
                business_tel_number: action.payload.business_tel_number,
            }
            return businessInfo
        },
    },
})

// Action creators are generated for each case reducer function
export const { setBusinessInfo } = BusinessInfoSlice.actions

export default BusinessInfoSlice.reducer
