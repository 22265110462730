import React, { useState } from 'react'
import SkeletonLoader from '../components/SkeletonLoader'
import { TrashIcon } from '@heroicons/react/20/solid'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { setIdentityInfo } from '../../redux/state-slices/IdentityInfoSlice'
import { InvoicesRepository } from '../../api/InvoicesRepository'
import { useAppDispatch } from '../../redux/hooks'
import {
    setError,
    setSuccess,
    setWarning,
} from '../../redux/state-slices/GlobalNotificationSlice'
import Loading from '../../shared-components/Loading'
export default function BrandingLogoUpload() {
    const invoicesRepository = new InvoicesRepository()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [dragging, setDragging] = useState<boolean>(false)
    const [file, setFile] = useState<File | null>(null)

    const uploadBrandingLogo = useMutation(
        (formdata: FormData) =>
            invoicesRepository.uploadInvoiceBrandingLogo(formdata),

        {
            onSuccess: (data) => {
                dispatch(setSuccess(['Successfully uploaded logo']))
            },
            onError: (identityInfoIndex: number) => {
                dispatch(
                    setError([
                        'An error occurred while uploading your logo. Please try again.',
                    ])
                )
            },
        }
    )

    const uploadFile = (selectedFile: File) => {
        const formData = new FormData()
        formData.append('file', selectedFile)
        uploadBrandingLogo.mutate(formData)
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            if (e.target.files.length > 1) {
                dispatch(setWarning(['Only one file can be uploaded']))
            } else if (e.target.files.length === 1) {
                setFile(e.target.files[0])
                uploadFile(e.target.files[0])
            }
        }
    }

    return (
        <div className="mt-8 flex flex-col">
            <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="flex justify-end space-x-2">
                    <button
                        onClick={() => navigate(-1)}
                        className=" rounded-md mb-4 bg-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 text-gray-900 px-3 py-2 text-center text-sm font-semibold  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Back
                    </button>
                </div>
            </div>

            <div className="font-sans text-gray-900 border-box">
                <div className="flex justify-center w-full mx-auto sm:max-w-lg">
                    <div className="flex flex-col items-center justify-center bg-white sm:w-3/4 sm:rounded-lg sm:shadow-xl">
                        <div className="mt-10 mb-10 text-center">
                            <h2 className="text-2xl font-semibold mb-2">
                                Upload Your Logo
                            </h2>
                            <p className="text-xs text-gray-500">
                                File should be of format JPG or PNG
                            </p>
                        </div>
                        <div className="relative w-4/5 h-32 max-w-xs mb-10 bg-white rounded-lg shadow-inner">
                            <input
                                type="file"
                                id="file-upload"
                                className="hidden"
                                onChange={handleFileChange}
                                accept=".jpg,.png,.jpeg"
                            />
                            <label
                                htmlFor="file-upload"
                                className="z-20 flex flex-col-reverse items-center justify-center w-full h-full cursor-pointer"
                            >
                                <p className="z-10  font-light text-center">
                                    {file?.name || 'Click to upload logo'}
                                </p>
                                <svg
                                    className="z-10 w-8 h-8 text-indigo-400"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
                                </svg>
                            </label>
                        </div>
                        {uploadBrandingLogo.isLoading && (
                            <div className="mb-4">
                                <Loading height="8" width="8" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
