import { QuickAddsRepository } from '../api/QuickAddsRepository'
import { useMutation, useQuery } from 'react-query'
import { useState } from 'react'
import PaginationV2 from '../invoices/components/PaginationV2'
import NumberFormatDollars from '../utils/NumberFormatDollars'
import SkeletonLoader from '../invoices/components/SkeletonLoader'

import AddQuickAddsModal from './AddQuickAddsModal'
import { useSearchParams } from 'react-router-dom'
import { MagnifyingGlassIcon, TrashIcon } from '@heroicons/react/20/solid'
import useDebounce from '../utils/useDebounce'
import { formatDate } from '../utils/StringUtils'

export default function QuickAdds() {
    const quickAddsRepository = new QuickAddsRepository()
    const [quickAdds, setQuickAdds] = useState<Paywell.API.V2.QuickAdds[]>([])
    const [searchTerm, setSearchTerm] = useState<string>('')
    const searchTermDebounced = useDebounce(searchTerm, 500)
    const [quickAddsModalIsOpen, setQuickAddsModalIsOpen] = useState(false)
    const [searchParams] = useSearchParams()
    const page = searchParams.get('page') ? searchParams.get('page') : 1

    const getQuickAddsQuery = useQuery(
        ['quick-adds', page, searchTermDebounced],
        async () => {
            return await quickAddsRepository.getQuickAdds(
                page,
                searchTermDebounced
            )
        },
        {
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                setQuickAdds(data.data.data)
            },
        }
    )

    const deleteQuickAddMutation = useMutation({
        mutationFn: (id: number) => quickAddsRepository.deleteQuickAdd(id),
        onSuccess: () => {
            getQuickAddsQuery.refetch()
        },
    })

    const searchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }

    const total = getQuickAddsQuery.data?.data?.total
    const from = getQuickAddsQuery.data?.data?.from
    const to = getQuickAddsQuery.data?.data?.to
    const next = getQuickAddsQuery.data?.data?.next_page_url
    const prev = getQuickAddsQuery.data?.data?.prev_page_url
    const links = getQuickAddsQuery.data?.data?.links

    return (
        <div className="pt-10 pl-20 pr-20">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">
                            Quick Adds
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                            Recurring or one-time billing items for merchants or
                            agents
                        </p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                            onClick={() => setQuickAddsModalIsOpen(true)}
                            type="button"
                            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Add Product
                        </button>
                    </div>
                </div>
                <div className="mt-8 flow-root">
                    <div className="relative text-gray-400 focus-within:text-gray-600 w-2/5 mb-5">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <MagnifyingGlassIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                            />
                        </div>
                        <input
                            id="search"
                            className="block w-full rounded-md border-1 focus:border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Search merchants, products, etc."
                            type="search"
                            name="search"
                            value={searchTerm}
                            onChange={searchTermChange}
                        />
                    </div>
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Regular Price
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            My Price
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Quantity
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Billing Cycle
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Billing Date
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Merchant
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Created At
                                        </th>
                                        <th
                                            scope="col"
                                            className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                                        >
                                            <span className="sr-only">
                                                Delete
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {getQuickAddsQuery.isLoading ||
                                        (getQuickAddsQuery.isFetching && (
                                            <SkeletonLoader
                                                rows={5}
                                                columns={7}
                                                isError={
                                                    getQuickAddsQuery.isError
                                                }
                                            />
                                        ))}
                                    {getQuickAddsQuery.isSuccess &&
                                        !getQuickAddsQuery.isFetching &&
                                        quickAdds &&
                                        quickAdds?.length > 0 &&
                                        quickAdds.map((quickAdd) => {
                                            return (
                                                <tr
                                                    key={quickAdd.id}
                                                    className="even:bg-gray-50"
                                                >
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                                        {quickAdd.product_name}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {NumberFormatDollars(
                                                            quickAdd.regular_price
                                                        )}
                                                    </td>

                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {NumberFormatDollars(
                                                            quickAdd.modified_price
                                                        )}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {quickAdd.quantity}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {
                                                            quickAdd.billing_cycle_name
                                                        }
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {formatDate(
                                                            quickAdd.billing_cycle_start
                                                        )}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <a
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            href={`/legacy/users/${quickAdd.user_id}`}
                                                            className="text-blue-600"
                                                        >
                                                            {
                                                                quickAdd.merchant_name
                                                            }
                                                        </a>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {
                                                            quickAdd.formatted_created_at
                                                        }
                                                    </td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                                        <button
                                                            className="text-indigo-600 hover:text-indigo-900 disabled:text-gray-300"
                                                            onClick={() =>
                                                                deleteQuickAddMutation.mutate(
                                                                    quickAdd.id
                                                                )
                                                            }
                                                            disabled={
                                                                deleteQuickAddMutation.isLoading ||
                                                                getQuickAddsQuery.isFetching ||
                                                                getQuickAddsQuery.isLoading
                                                            }
                                                        >
                                                            <TrashIcon className="fill-red-500 h-4 w-4 cursor-pointer" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    {getQuickAddsQuery.isSuccess &&
                                        !getQuickAddsQuery.isFetching &&
                                        quickAdds?.length === 0 && (
                                            <tr>
                                                <td
                                                    colSpan={7}
                                                    className="text-center py-4 text-gray-500 italic"
                                                >
                                                    No quick adds found.
                                                </td>
                                            </tr>
                                        )}

                                    {getQuickAddsQuery.isError &&
                                        !getQuickAddsQuery.isFetching && (
                                            <tr>
                                                <td
                                                    colSpan={7}
                                                    className="text-center py-4 text-red-500"
                                                >
                                                    Error loading quick adds.
                                                </td>
                                            </tr>
                                        )}
                                    <tr className="border-t-2">
                                        <td colSpan={8}>
                                            <PaginationV2
                                                pages={links}
                                                from={from}
                                                to={to}
                                                total={total}
                                                next={next}
                                                prev={prev}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {quickAddsModalIsOpen && (
                <AddQuickAddsModal
                    setQuickAddsModalIsOpen={setQuickAddsModalIsOpen}
                    fetchQuickAdds={getQuickAddsQuery.refetch}
                />
            )}
        </div>
    )
}
