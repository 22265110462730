import { BaseRepository } from './BaseRepository'
import { GenericResponse } from './HttpClient/BaseHttpClient'

export class EtransferV2DemoRepository extends BaseRepository {
    set getPaymentStatus(
        value: (uuid: string) => Promise<GenericResponse<any>>
    ) {
        this._getPaymentStatus = value
    }
    postEtransferV2Demo = async (email: string) => {
        let url = `/api/v2/demo`
        let postBody = {
            email: email,
        }
        return await super.getHttpClientInstance().post(url, postBody)
    }

    private _getPaymentStatus = async (uuid: string) => {
        return await super.getHttpClientInstance().get(`/api/v2/demo/${uuid}`)
    }
}
