import React, {useContext, useEffect, useState} from "react";
import Modal from 'react-modal';
import {Container, Row, Col, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";


interface CancelModalProps {
  isOpen: boolean
  closeModal: () => void
  cancelTransaction: () => void
  returnToMerchant: () => void
}


const CancelModal = (props: CancelModalProps) => {
  const { t } = useTranslation();

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <Modal
      isOpen={props.isOpen}
      style={customStyles}
      onRequestClose={props.closeModal}
    >
      <h4>{t('completequestion')}</h4>
      <Container className="container-fluid">
        <Row className="mb-2">
          <Col sm={{span: 3}}>
            <b>{t('no')}</b>
          </Col>
          <Col>
            <Button variant="primary" size="sm" onClick={props.closeModal}>{t('continuetrans')}</Button>
            <Button variant="outline-secondary" className="ms-2" size="sm" onClick={props.cancelTransaction}>{t('canceltrans')}</Button>
          </Col>
        </Row>
        <Row>
          <Col sm={{span: 3}}>
            <b>{t('yes')}</b>
          </Col>
          <Col>
            <Button variant="outline-secondary" size="sm" onClick={props.returnToMerchant}>{t('cancel')}</Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}

export default CancelModal;