import React from 'react'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'

export default function ErrorV2({
    title,
    message,
}: {
    title: string
    message: string
}) {
    return (
        <div className="rounded-md bg-red-50 p-4 w-full">
            <div className="flex">
                <div className="flex-shrink-0">
                    <ExclamationTriangleIcon
                        className="h-16 w-16 text-red-400"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3">
                    <h3 className="text-red-800 text-lg font-bold">{title}</h3>
                    <div className="mt-2 text-red-700">
                        <ul role="list" className="list-disc space-y-1 pl-5">
                            <li>{message}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
