import Loading from '../../shared-components/Loading'
import { Listbox, Switch, Transition } from '@headlessui/react'
import { UserRepository } from '../../api/UserRepositoy'
import { useAppDispatch } from '../../redux/hooks'
import { useMutation, useQuery } from 'react-query'
import { setError } from '../../redux/state-slices/GlobalNotificationSlice'
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DialogModal from '../../shared-components/DialogModal'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { numOnlyRegex, testEmailRegex } from '../../utils/RegexHelper'
import { EFT_PROVIDER, getReadableEftProvider } from '../../utils/UserUtils'

interface Props {
    user: Paywell.API.V2.User | null
    userCopy: Paywell.API.V2.User | null
    updateUserInfo: (user: Paywell.API.V2.User) => void
    saveChanges: () => void
}

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ')
}
export default function UserApiConfigsEdit({
    user,
    userCopy,
    updateUserInfo,
    saveChanges,
}: Props) {
    const userRepository = new UserRepository()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [validInteracEmail, setValidInteracEmail] = useState(true)
    const [validAliasEmail, setValidAliasEmail] = useState(true)
    const [userInteracInfo, setUserInteracInfo] = useState<
        Paywell.API.V2.UserInterac | undefined
    >(undefined)
    const [userInteracInfoCopy, setUserInteracInfoCopy] = useState<
        Paywell.API.V2.UserInterac | undefined
    >(undefined)

    const [validWireTransferAccountNumber, setValidWireTransferAccountNumber] =
        useState(true)
    const [validWireTransferRoutingNumber, setValidWireTransferRoutingNumber] =
        useState(true)
    const [
        validWireTransferInstitutionNumber,
        setValidWireTransferInstitutionNumber,
    ] = useState(true)

    const validForm =
        validInteracEmail &&
        validAliasEmail &&
        validWireTransferAccountNumber &&
        validWireTransferRoutingNumber &&
        validWireTransferInstitutionNumber

    useEffect(() => {
        validateForm()
    }, [userInteracInfo])

    const validateForm = () => {
        if (!userCopy) return

        setValidInteracEmail(
            Boolean(
                testEmailRegex(userInteracInfo?.interac_email || '') ||
                    !userInteracInfo?.interac_email
            )
        )
        setValidAliasEmail(
            Boolean(
                testEmailRegex(userInteracInfo?.alias_interac_email || '') ||
                    !userInteracInfo?.alias_interac_email
            )
        )

        setValidWireTransferAccountNumber(
            Boolean(
                numOnlyRegex(userCopy.wire_transfer_account_number || '') ||
                    userCopy.wire_transfer_account_number === '' ||
                    userCopy.wire_transfer_account_number === null
            )
        )

        setValidWireTransferRoutingNumber(
            Boolean(
                numOnlyRegex(userCopy.wire_transfer_routing_number || '') ||
                    userCopy.wire_transfer_routing_number === '' ||
                    userCopy.wire_transfer_routing_number === null
            )
        )

        setValidWireTransferInstitutionNumber(
            Boolean(
                numOnlyRegex(userCopy.wire_transfer_institution_number || '') ||
                    userCopy.wire_transfer_institution_number === '' ||
                    userCopy.wire_transfer_institution_number === null
            )
        )
    }

    const fetchUserInteracInfo = async () => {
        if (!userCopy?.id) return
        return (await userRepository.getUserInteracInfo(userCopy?.id)).data
    }

    const updateInteracInfo = async () => {
        if (!userInteracInfo || !user) return
        return (
            await userRepository.updateUserInteracInfo(
                userInteracInfo,
                user?.id
            )
        ).data
    }
    const userInteracInfoQuery = useQuery(
        ['interac-info-edit', userCopy?.id],
        fetchUserInteracInfo,
        {
            onSuccess: (data) => {
                setUserInteracInfo(data)
                setUserInteracInfoCopy(data)
            },
            onError: (error) => {
                dispatch(
                    setError([
                        'An error has occurred while fetching interac details.',
                    ])
                )
            },
            refetchOnWindowFocus: false,
        }
    )

    const updateBankAccountsMutation = useMutation(() => updateInteracInfo(), {
        onSuccess: () => {
            saveChanges()
        },
        onError: () => {
            dispatch(
                setError([
                    `There was an error saving the changes. Please try again.`,
                ])
            )
        },
    })

    const handleNMIPublicKeyChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (!userCopy) return
        if (val.length < 100) {
            updateUserInfo({ ...userCopy, nmi_public_key: e.target.value })
        }
    }

    const handleNMIPrivateKeyChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (!userCopy) return
        if (val.length < 100) {
            updateUserInfo({ ...userCopy, nmi_private_key: e.target.value })
        }
    }

    const handleDefaultCallbackUrlChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (!userCopy) return
        if (val.length < 500) {
            updateUserInfo({
                ...userCopy,
                default_callback_url: e.target.value,
            })
        }
    }

    const handleCallbackTypeChange = (value: 'standard' | 'netcoins') => {
        if (!userCopy) return
        updateUserInfo({ ...userCopy, callback_type: value })
    }

    const handlePaymentSupportEmailChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (!userCopy) return
        if (val.length < 100) {
            updateUserInfo({
                ...userCopy,
                payment_support_email: e.target.value,
            })
        }
    }

    const handleGenOrderNumsChange = (value: boolean) => {
        if (!userCopy) return
        updateUserInfo({ ...userCopy, gen_order_nums: value })
    }

    const handleRequestEtransferApiChange = (value: boolean) => {
        if (!userCopy) return
        updateUserInfo({ ...userCopy, request_money: value })
    }

    const handleInteracDDTIDChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (!userInteracInfo) return
        if (
            (val.length < 11 && numOnlyRegex(val)) ||
            val === '0' ||
            val === ''
        ) {
            let copy = { ...userInteracInfo }
            copy.ddt_id = val
            setUserInteracInfo(copy)
        }
    }

    const handleImapHostnameChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (!userInteracInfo) return
        if (val.length < 255) {
            let copy = { ...userInteracInfo }
            copy.imap_hostname = e.target.value
            setUserInteracInfo(copy)
        }
    }

    const handleInteracEmailChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (!userInteracInfo) return
        if (val.length < 50) {
            let copy = { ...userInteracInfo }
            copy.interac_email = e.target.value
            setUserInteracInfo(copy)
        }
    }

    const handleInteracHashChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (!userInteracInfo) return
        if (val.length < 100) {
            let copy = { ...userInteracInfo }
            copy.interac_hash = e.target.value
            setUserInteracInfo(copy)
        }
    }

    const handleInteracPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (!userInteracInfo) return
        if (val.length < 512) {
            let copy = { ...userInteracInfo }
            copy.interac_password = e.target.value
            setUserInteracInfo(copy)
        }
    }

    const handleInteracAliasEmailChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (!userInteracInfo) return
        if (val.length < 255) {
            let copy = { ...userInteracInfo }
            copy.alias_interac_email = e.target.value
            setUserInteracInfo(copy)
        }
    }
    const handleSaveChanges = () => {
        updateBankAccountsMutation.mutate()
    }

    const handleAutoSettlementChange = (value: boolean) => {
        if (!userCopy) return
        updateUserInfo({ ...userCopy, auto_settlement: value })
    }

    const handleEmailBatchesChange = (value: boolean) => {
        if (!userCopy) return
        updateUserInfo({ ...userCopy, email_batches: value })
    }

    const handleWireTransferAccountNumberChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (!userCopy) return
        let val = e.target.value
        if (val.length < 16) {
            updateUserInfo({
                ...userCopy,
                wire_transfer_account_number: e.target.value,
            })
        }
    }

    const handleWireTransferRoutingNumberChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (!userCopy) return
        let val = e.target.value
        if (val.length < 10) {
            updateUserInfo({
                ...userCopy,
                wire_transfer_routing_number: e.target.value,
            })
        }
    }

    const handleWireTransferInstitutionNumberChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (!userCopy) return
        let val = e.target.value
        if (val.length < 5) {
            updateUserInfo({
                ...userCopy,
                wire_transfer_institution_number: e.target.value,
            })
        }
    }
    const handleEFTProviderChange = (value: number) => {
        if (!userCopy) return
        updateUserInfo({
            ...userCopy,
            eft_provider_id: value,
        })
    }
    const hasUnsavedChanges = () => {
        return (
            JSON.stringify(user) !== JSON.stringify(userCopy) ||
            JSON.stringify(userInteracInfo) !==
                JSON.stringify(userInteracInfoCopy)
        )
    }
    const handleCancel = () => {
        if (!user) return
        if (hasUnsavedChanges()) {
            setIsDialogOpen(true) // Show the dialog if there are unsaved changes
        } else {
            navigate('/users/' + user.id + '/api-configurations') // No unsaved changes, just navigate away
        }
    }

    const confirmCancel = () => {
        setIsDialogOpen(false) // Close the dialog
        if (user) updateUserInfo(user) // Reset the user to the original state (cancel changes
        navigate('/users/' + user?.id + '/api-configurations') // Proceed with the cancel action
    }

    const cancelCancel = () => {
        setIsDialogOpen(false) // Close the dialog without canceling changes
    }

    const invalidClass =
        '!border-2 !border-rose-500 focus:ring-rose-500 focus:border-rose-500'

    return (
        <div className={`p-10`}>
            <div className="mb-2 flex justify-end space-x-2">
                <button
                    disabled={!validForm}
                    onClick={handleSaveChanges}
                    type="button"
                    className={`block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm disabled:bg-gray-400 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                >
                    Save
                </button>
                <button
                    onClick={handleCancel}
                    type="button"
                    className="flex  rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                    Cancel
                </button>
            </div>
            <div className={'border border-gray-100 rounded-md p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        API Configurations
                    </h3>
                </div>
                <div className="mt-6 border-t border-gray-100 grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    NMI Public API Key
                                </dt>
                                <input
                                    type="text"
                                    onChange={handleNMIPublicKeyChange}
                                    name="nmi-public-key"
                                    id="nmi-public-key"
                                    value={userCopy?.nmi_public_key || ''}
                                    className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    NMI Private API Key
                                </dt>

                                <input
                                    type="text"
                                    onChange={handleNMIPrivateKeyChange}
                                    name="nmi-private-key"
                                    id="nmi-private-key"
                                    value={userCopy?.nmi_private_key || ''}
                                    className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Default callback url
                                </dt>

                                <input
                                    type="text"
                                    onChange={handleDefaultCallbackUrlChange}
                                    name="default-callback-url"
                                    id="default-callback-url"
                                    value={userCopy?.default_callback_url || ''}
                                    className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Callback type
                                </dt>
                                <Listbox
                                    value={userCopy?.callback_type}
                                    onChange={handleCallbackTypeChange}
                                >
                                    {({ open }) => (
                                        <>
                                            <div className="relative mt-2">
                                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                    <span className="block truncate">
                                                        {
                                                            userCopy?.callback_type
                                                        }
                                                    </span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <ChevronUpDownIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </Listbox.Button>

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        <Listbox.Option
                                                            className={({
                                                                active,
                                                            }) =>
                                                                classNames(
                                                                    active
                                                                        ? 'bg-indigo-600 text-white'
                                                                        : 'text-gray-900',
                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                )
                                                            }
                                                            value={'standard'}
                                                        >
                                                            {({
                                                                selected,
                                                                active,
                                                            }) => (
                                                                <>
                                                                    <span
                                                                        className={classNames(
                                                                            selected
                                                                                ? 'font-semibold'
                                                                                : 'font-normal',
                                                                            'block truncate'
                                                                        )}
                                                                    >
                                                                        {
                                                                            'Standard'
                                                                        }
                                                                    </span>

                                                                    {selected && (
                                                                        <span
                                                                            className={classNames(
                                                                                active
                                                                                    ? 'text-white'
                                                                                    : 'text-indigo-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                            )}
                                                                        >
                                                                            <CheckIcon
                                                                                className="h-5 w-5"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                        <Listbox.Option
                                                            className={({
                                                                active,
                                                            }) =>
                                                                classNames(
                                                                    active
                                                                        ? 'bg-indigo-600 text-white'
                                                                        : 'text-gray-900',
                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                )
                                                            }
                                                            value={'netcoins'}
                                                        >
                                                            {({
                                                                selected,
                                                                active,
                                                            }) => (
                                                                <>
                                                                    <span
                                                                        className={classNames(
                                                                            selected
                                                                                ? 'font-semibold'
                                                                                : 'font-normal',
                                                                            'block truncate'
                                                                        )}
                                                                    >
                                                                        {
                                                                            'Netcoins'
                                                                        }
                                                                    </span>

                                                                    {selected && (
                                                                        <span
                                                                            className={classNames(
                                                                                active
                                                                                    ? 'text-white'
                                                                                    : 'text-indigo-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                            )}
                                                                        >
                                                                            <CheckIcon
                                                                                className="h-5 w-5"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </>
                                    )}
                                </Listbox>
                            </div>
                        </dl>
                    </div>
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Payment support email
                                </dt>

                                <input
                                    type="text"
                                    onChange={handlePaymentSupportEmailChange}
                                    name="support-email"
                                    id="support-email"
                                    value={
                                        userCopy?.payment_support_email || ''
                                    }
                                    className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Generate Order Numbers
                                </dt>

                                <Listbox
                                    value={userCopy?.gen_order_nums}
                                    onChange={handleGenOrderNumsChange}
                                >
                                    {({ open }) => (
                                        <>
                                            <div className="relative mt-2">
                                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                    <span className="block truncate">
                                                        {userCopy?.gen_order_nums
                                                            ? 'Yes'
                                                            : 'No'}
                                                    </span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <ChevronUpDownIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </Listbox.Button>

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        <Listbox.Option
                                                            className={({
                                                                active,
                                                            }) =>
                                                                classNames(
                                                                    active
                                                                        ? 'bg-indigo-600 text-white'
                                                                        : 'text-gray-900',
                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                )
                                                            }
                                                            value={true}
                                                        >
                                                            {({
                                                                selected,
                                                                active,
                                                            }) => (
                                                                <>
                                                                    <span
                                                                        className={classNames(
                                                                            selected
                                                                                ? 'font-semibold'
                                                                                : 'font-normal',
                                                                            'block truncate'
                                                                        )}
                                                                    >
                                                                        {'Yes'}
                                                                    </span>

                                                                    {selected && (
                                                                        <span
                                                                            className={classNames(
                                                                                active
                                                                                    ? 'text-white'
                                                                                    : 'text-indigo-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                            )}
                                                                        >
                                                                            <CheckIcon
                                                                                className="h-5 w-5"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                        <Listbox.Option
                                                            className={({
                                                                active,
                                                            }) =>
                                                                classNames(
                                                                    active
                                                                        ? 'bg-indigo-600 text-white'
                                                                        : 'text-gray-900',
                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                )
                                                            }
                                                            value={false}
                                                        >
                                                            {({
                                                                selected,
                                                                active,
                                                            }) => (
                                                                <>
                                                                    <span
                                                                        className={classNames(
                                                                            selected
                                                                                ? 'font-semibold'
                                                                                : 'font-normal',
                                                                            'block truncate'
                                                                        )}
                                                                    >
                                                                        {'No'}
                                                                    </span>

                                                                    {selected && (
                                                                        <span
                                                                            className={classNames(
                                                                                active
                                                                                    ? 'text-white'
                                                                                    : 'text-indigo-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                            )}
                                                                        >
                                                                            <CheckIcon
                                                                                className="h-5 w-5"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </>
                                    )}
                                </Listbox>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900 block">
                                    <span>Request e-Transfer API</span>
                                    <div className={'font-light text-gray-500'}>
                                        If request e-Transfer API is on, the
                                        merchant will have the ability to use
                                        the V2 e-Transfer api (V2 e-Transfer).
                                    </div>
                                </dt>
                                <Listbox
                                    value={userCopy?.request_money}
                                    onChange={handleRequestEtransferApiChange}
                                >
                                    {({ open }) => (
                                        <>
                                            <div className="relative mt-2">
                                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                    <span className="block truncate">
                                                        {userCopy?.request_money
                                                            ? 'Yes'
                                                            : 'No'}
                                                    </span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <ChevronUpDownIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </Listbox.Button>

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        <Listbox.Option
                                                            className={({
                                                                active,
                                                            }) =>
                                                                classNames(
                                                                    active
                                                                        ? 'bg-indigo-600 text-white'
                                                                        : 'text-gray-900',
                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                )
                                                            }
                                                            value={true}
                                                        >
                                                            {({
                                                                selected,
                                                                active,
                                                            }) => (
                                                                <>
                                                                    <span
                                                                        className={classNames(
                                                                            selected
                                                                                ? 'font-semibold'
                                                                                : 'font-normal',
                                                                            'block truncate'
                                                                        )}
                                                                    >
                                                                        {'Yes'}
                                                                    </span>

                                                                    {selected && (
                                                                        <span
                                                                            className={classNames(
                                                                                active
                                                                                    ? 'text-white'
                                                                                    : 'text-indigo-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                            )}
                                                                        >
                                                                            <CheckIcon
                                                                                className="h-5 w-5"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                        <Listbox.Option
                                                            className={({
                                                                active,
                                                            }) =>
                                                                classNames(
                                                                    active
                                                                        ? 'bg-indigo-600 text-white'
                                                                        : 'text-gray-900',
                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                )
                                                            }
                                                            value={false}
                                                        >
                                                            {({
                                                                selected,
                                                                active,
                                                            }) => (
                                                                <>
                                                                    <span
                                                                        className={classNames(
                                                                            selected
                                                                                ? 'font-semibold'
                                                                                : 'font-normal',
                                                                            'block truncate'
                                                                        )}
                                                                    >
                                                                        {'No'}
                                                                    </span>

                                                                    {selected && (
                                                                        <span
                                                                            className={classNames(
                                                                                active
                                                                                    ? 'text-white'
                                                                                    : 'text-indigo-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                            )}
                                                                        >
                                                                            <CheckIcon
                                                                                className="h-5 w-5"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </>
                                    )}
                                </Listbox>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <div className={'border border-gray-100 rounded-md p-2 mt-4'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Interac e-Transfer Configuration
                    </h3>
                </div>
                {(userInteracInfoQuery.isLoading ||
                    userInteracInfoQuery.isFetching) && (
                    <div className="flex justify-center items-center h-32">
                        <Loading height={'8'} width={'8'} />
                    </div>
                )}
                {userInteracInfoQuery.isSuccess &&
                    !userInteracInfoQuery.isLoading &&
                    !userInteracInfoQuery.isFetching && (
                        <div className="mt-6 border-t border-gray-100 grid grid-cols-1 md:grid-cols-2 gap-2">
                            <div>
                                <dl className="divide-y divide-gray-100">
                                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                            DC Bank DDT ID
                                        </dt>

                                        <input
                                            type="text"
                                            onChange={handleInteracDDTIDChange}
                                            name="ddt-id"
                                            id="ddt-id"
                                            value={
                                                userInteracInfo?.ddt_id || ''
                                            }
                                            className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                            IMAP Hostname
                                        </dt>

                                        <input
                                            type="text"
                                            onChange={handleImapHostnameChange}
                                            name="imap-hostname"
                                            id="imap-hostname"
                                            value={
                                                userInteracInfo?.imap_hostname ||
                                                ''
                                            }
                                            className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                            IMAP Username/e-Transfer Deposit
                                            Email
                                        </dt>

                                        <input
                                            type="text"
                                            onChange={handleInteracEmailChange}
                                            name="interac-email"
                                            id="interac-email"
                                            value={
                                                userInteracInfo?.interac_email ||
                                                ''
                                            }
                                            className={`${
                                                !validInteracEmail &&
                                                invalidClass
                                            } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                </dl>
                            </div>
                            <div>
                                <dl className="divide-y divide-gray-100">
                                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                            IMAP Password/e-Transfer Deposit
                                            Email Password
                                        </dt>

                                        <input
                                            type="text"
                                            onChange={handleInteracHashChange}
                                            name="interac-hash"
                                            id="interac-hash"
                                            value={
                                                userInteracInfo?.interac_hash ||
                                                ''
                                            }
                                            className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 block">
                                            Password for manual e-Transfers
                                        </dt>

                                        <input
                                            type="text"
                                            onChange={handleInteracPassword}
                                            name="interac-pass"
                                            id="interac-pass"
                                            value={
                                                userInteracInfo?.interac_password ||
                                                ''
                                            }
                                            className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                            e-Transfer Display Email
                                        </dt>

                                        <input
                                            type="text"
                                            onChange={
                                                handleInteracAliasEmailChange
                                            }
                                            name="interac-alias-email"
                                            id="interac-alais-email"
                                            value={
                                                userInteracInfo?.alias_interac_email ||
                                                ''
                                            }
                                            className={`${
                                                !validAliasEmail && invalidClass
                                            } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                </dl>
                            </div>
                        </div>
                    )}
            </div>
            <div className={'border border-gray-100 rounded-md mt-4 p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Wire Transfer Info
                    </h3>
                </div>

                <div className="mt-6 border-t border-gray-100 grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Account Number
                                </dt>
                                <input
                                    type="text"
                                    name="user-wire-transfer-account-number"
                                    onChange={
                                        handleWireTransferAccountNumberChange
                                    }
                                    id="user-wire-transfer-account-number"
                                    value={
                                        userCopy?.wire_transfer_account_number ||
                                        ''
                                    }
                                    className={`${
                                        !validWireTransferAccountNumber &&
                                        invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Routing Number
                                </dt>
                                <input
                                    type="text"
                                    name="user-wire-transfer-routing-number"
                                    onChange={
                                        handleWireTransferRoutingNumberChange
                                    }
                                    id="user-wire-transfer-routing-number"
                                    value={
                                        userCopy?.wire_transfer_routing_number ||
                                        ''
                                    }
                                    className={`${
                                        !validWireTransferRoutingNumber &&
                                        invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                        </dl>
                    </div>
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Institution Number
                                </dt>
                                <input
                                    type="text"
                                    name="user-wire-transfer-institution-number"
                                    onChange={
                                        handleWireTransferInstitutionNumberChange
                                    }
                                    id="user-wire-transfer-institution-number"
                                    value={
                                        userCopy?.wire_transfer_institution_number ||
                                        ''
                                    }
                                    className={`${
                                        !validWireTransferInstitutionNumber &&
                                        invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <div className={'border border-gray-100 rounded-md mt-4 p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        EFT Provider
                    </h3>
                </div>

                <div className="mt-6 border-t border-gray-100 grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Eft Provider
                                </dt>
                                <Listbox
                                    value={userCopy?.eft_provider_id}
                                    onChange={(value) =>
                                        handleEFTProviderChange(value)
                                    }
                                >
                                    {({ open }) => (
                                        <>
                                            <div className="relative mt-2">
                                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                    <span className="block truncate">
                                                        {getReadableEftProvider(
                                                            userCopy?.eft_provider_id ||
                                                                0
                                                        )}
                                                    </span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <ChevronUpDownIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </Listbox.Button>

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {Object.entries(
                                                            EFT_PROVIDER
                                                        ).map(([id, name]) => (
                                                            <Listbox.Option
                                                                key={id}
                                                                className={({
                                                                    active,
                                                                }) =>
                                                                    classNames(
                                                                        active
                                                                            ? 'bg-indigo-600 text-white'
                                                                            : 'text-gray-900',
                                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                    )
                                                                }
                                                                value={id}
                                                            >
                                                                {({
                                                                    selected,
                                                                    active,
                                                                }) => (
                                                                    <>
                                                                        <span
                                                                            className={classNames(
                                                                                selected
                                                                                    ? 'font-semibold'
                                                                                    : 'font-normal',
                                                                                'block truncate'
                                                                            )}
                                                                        >
                                                                            {
                                                                                name
                                                                            }
                                                                        </span>

                                                                        {selected && (
                                                                            <span
                                                                                className={classNames(
                                                                                    active
                                                                                        ? 'text-white'
                                                                                        : 'text-indigo-600',
                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                )}
                                                                            >
                                                                                <CheckIcon
                                                                                    className="h-5 w-5"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </span>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </>
                                    )}
                                </Listbox>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <div className={'border border-gray-100 rounded-md p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Settlement Configuration
                    </h3>
                </div>
                <div className="mt-6 border-t border-gray-100 grid grid-cols-2 gap-2">
                    <dl className="divide-y divide-gray-100">
                        <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                Automatic settlements
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <Switch
                                    checked={userCopy?.auto_settlement}
                                    onChange={handleAutoSettlementChange}
                                    className={classNames(
                                        userCopy?.auto_settlement
                                            ? 'bg-indigo-600'
                                            : 'bg-gray-200',
                                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                                    )}
                                >
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            userCopy?.auto_settlement
                                                ? 'translate-x-5'
                                                : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                        )}
                                    />
                                </Switch>
                            </dd>
                        </div>
                    </dl>
                    <dl className="divide-y divide-gray-100">
                        <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                Daily Batch Email
                            </dt>
                            <Switch
                                checked={userCopy?.email_batches}
                                onChange={handleEmailBatchesChange}
                                className={classNames(
                                    userCopy?.email_batches
                                        ? 'bg-indigo-600'
                                        : 'bg-gray-200',
                                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                                )}
                            >
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        userCopy?.email_batches
                                            ? 'translate-x-5'
                                            : 'translate-x-0',
                                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                    )}
                                />
                            </Switch>
                        </div>
                    </dl>
                </div>
            </div>
            <DialogModal
                open={isDialogOpen}
                setOpen={setIsDialogOpen}
                title="Unsaved Changes"
                description="You have unsaved changes. Are you sure you want to cancel? Your changes will be lost."
                confirmAction={confirmCancel}
                cancelAction={cancelCancel}
            />
        </div>
    )
}
