import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { InvoicesRepository } from '../../api/InvoicesRepository'
import { useMutation, useQuery } from 'react-query'
import Loading from '../../shared-components/Loading'
import ErrorV2 from '../../shared-components/ErrorV2'
import { setError } from '../../redux/state-slices/GlobalNotificationSlice'
import { TrashIcon } from '@heroicons/react/20/solid'
import { useAppDispatch } from '../../redux/hooks'
import EmailReminderPreview from './EmailReminderPreview'

export default function EmailReminderView() {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const invoiceRepository = new InvoicesRepository()

    const getInvoiceReminder = useQuery(
        'invoice-email-reminder',
        async () => {
            if (!id) return
            return await invoiceRepository.getEmailReminder(parseInt(id))
        },
        {
            enabled: !!id,
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            retry: true,
            onSuccess: (data) => {
                //parseHtml(data?.data.email_body || '')
            },
            onError: (error) => {},
        }
    )

    const deleteEmailReminderMutation = useMutation(
        (id: number) => invoiceRepository.deleteEmailReminder(id),
        {
            onSuccess: (data) => {
                navigate('/invoicing/settings/email-reminders')
            },
            onError: (error) => {
                dispatch(
                    setError([
                        'An error has occurred. Please reach out to one of our representatives if this error persists.',
                    ])
                )
            },
        }
    )

    const getHumanReadableTrigger = (
        trigger: string,
        reminderPeriod: number
    ) => {
        switch (trigger) {
            case 'BEFORE_DUE_DATE':
                return `${reminderPeriod} day(s) before due date`
            case 'AFTER_DUE_DATE':
                return `${reminderPeriod} day(s) after due date`
            case 'INITIAL_REMINDER':
                return 'Initial Email'
            default:
                return 'Unknown'
        }
    }

    if (getInvoiceReminder.isLoading || getInvoiceReminder.isFetching)
        return <Loading height={'5'} width={'5'} />

    if (getInvoiceReminder.isError) {
        return (
            <ErrorV2
                title="An error has occured"
                message="We were unable to load the email reminder. Please try again later. If the problem persists, please contact support."
            />
        )
    }
    return (
        <div>
            <div className="p-4 sm:px-0 flex justify-between">
                <button
                    onClick={() => navigate(-1)}
                    className="text-blue-600 hover:text-blue-900"
                >
                    Back
                </button>
                <button
                    onClick={() =>
                        navigate(
                            `/invoicing/settings/email-reminders/edit/${id}`
                        )
                    }
                    className="text-blue-600 hover:text-blue-900"
                >
                    Edit
                </button>
            </div>
            <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <dt className="text-sm font-medium leading-6 text-gray-900  sm:col-span-1">
                            Event Trigger
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {getHumanReadableTrigger(
                                getInvoiceReminder.data?.data.trigger_type ||
                                    '',
                                getInvoiceReminder.data?.data.reminder_period ||
                                    0
                            )}
                        </dd>
                    </div>
                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3">
                        <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-1">
                            Email Body Preview
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0 border rounded p-1">
                            {getInvoiceReminder.data?.data.email_body ? (
                                <EmailReminderPreview
                                    emailBody={
                                        getInvoiceReminder.data?.data
                                            .email_body || ''
                                    }
                                />
                            ) : (
                                'No email body has been set.'
                            )}
                        </dd>
                    </div>
                </dl>
            </div>
            <div className="flex justify-end mt-5">
                <button
                    className=" cursor-pointer disabled:cursor-not-allowed disabled:opacity-60"
                    onClick={() =>
                        deleteEmailReminderMutation.mutate(parseInt(id || ''))
                    }
                    disabled={deleteEmailReminderMutation.isLoading}
                >
                    <TrashIcon className="fill-red-500 h-6 w-6" />
                </button>
            </div>
        </div>
    )
}
