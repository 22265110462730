import { getReadableEftProvider } from '../utils/UserUtils'
import Loading from '../shared-components/Loading'
import React from 'react'
import { UserRepository } from '../api/UserRepositoy'
import { useAppDispatch } from '../redux/hooks'
import { useQuery } from 'react-query'
import { setError } from '../redux/state-slices/GlobalNotificationSlice'
import { useNavigate } from 'react-router-dom'

interface Props {
    userCopy: Paywell.API.V2.User | null
    isAdmin: boolean
}
export default function UserBanking({ userCopy, isAdmin }: Props) {
    const userRepository = new UserRepository()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const fetchBankingDetails = async () => {
        if (!userCopy?.id) return
        return (await userRepository.getBankingDetails(userCopy?.id)).data
    }

    const bankingDetailsQuery = useQuery(
        ['bankingDetails', userCopy?.id],
        fetchBankingDetails,
        {
            onError: (error) => {
                dispatch(
                    setError([
                        'An error has occurred while fetching banking details.',
                    ])
                )
            },
            refetchOnWindowFocus: false,
        }
    )

    const bankAccounts = bankingDetailsQuery.data
    return (
        <div className={`p-10`}>
            {isAdmin && (
                <div className="mb-2 flex justify-end">
                    <button
                        onClick={() =>
                            navigate('/users/' + userCopy?.id + '/banking/edit')
                        }
                        type="button"
                        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Edit Page
                    </button>
                </div>
            )}
            <div className={'border border-gray-100 rounded-md mt-4 p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Banking Information
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                        View and edit banking information.
                    </p>
                </div>
                {(bankingDetailsQuery.isLoading ||
                    bankingDetailsQuery.isFetching) && (
                    <div className="flex justify-center items-center h-32">
                        <Loading height={'8'} width={'8'} />
                    </div>
                )}

                {bankingDetailsQuery.isSuccess &&
                    !bankingDetailsQuery.isLoading &&
                    !bankingDetailsQuery.isFetching && (
                        <ul
                            className={`grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-${bankAccounts?.length} xl:gap-x-8 mt-4`}
                        >
                            {bankAccounts &&
                                bankAccounts.map((bankAccount, index) => (
                                    <li
                                        key={bankAccount.id}
                                        className="overflow-hidden rounded-xl border border-gray-200"
                                    >
                                        <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                                            <div className="text-sm font-medium leading-6 text-gray-900">
                                                Bank Account #{index + 1}
                                            </div>
                                            {bankAccount.primary ? (
                                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                    Primary
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                                            <div className="flex justify-between gap-x-4 py-3">
                                                <dt className="text-gray-500">
                                                    Account Number
                                                </dt>
                                                <dd className="flex items-start gap-x-2">
                                                    <div className="font-medium text-gray-900">
                                                        {
                                                            bankAccount.account_number
                                                        }
                                                    </div>
                                                </dd>
                                            </div>
                                            <div className="flex justify-between gap-x-4 py-3">
                                                <dt className="text-gray-500">
                                                    Institution Number
                                                </dt>
                                                <dd className="flex items-start gap-x-2">
                                                    <div className="font-medium text-gray-900">
                                                        {
                                                            bankAccount.institution_number
                                                        }
                                                    </div>
                                                </dd>
                                            </div>
                                            <div className="flex justify-between gap-x-4 py-3">
                                                <dt className="text-gray-500">
                                                    Routing Number
                                                </dt>
                                                <dd className="flex items-start gap-x-2">
                                                    <div className="font-medium text-gray-900">
                                                        {
                                                            bankAccount.routing_number
                                                        }
                                                    </div>
                                                </dd>
                                            </div>
                                            <div className="flex justify-between gap-x-4 py-3">
                                                <dt className="text-gray-500">
                                                    Settlement Distribution
                                                </dt>
                                                <dd className="flex items-start gap-x-2">
                                                    <div className="font-medium text-gray-900">
                                                        {
                                                            bankAccount.settlement_weight
                                                        }
                                                        %
                                                    </div>
                                                </dd>
                                            </div>
                                            <div className="flex justify-between gap-x-4 py-3">
                                                <dt className="text-gray-500">
                                                    Void Cheque
                                                </dt>
                                                <dd className="flex items-start gap-x-2">
                                                    <div className="font-medium text-gray-900">
                                                        {bankAccount.void_cheque_file_name ? (
                                                            <a
                                                                href={
                                                                    bankAccount.image_url
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className="text-blue-500"
                                                            >
                                                                View
                                                            </a>
                                                        ) : (
                                                            'Not available'
                                                        )}
                                                    </div>
                                                </dd>
                                            </div>
                                        </dl>
                                    </li>
                                ))}
                        </ul>
                    )}
            </div>
        </div>
    )
}
