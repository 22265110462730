import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { RegistrationFormValuesRepository } from '../api/RegistrationFormValuesRepository'
import './Onboarding.scss'
import { setIdentityInfo } from '../redux/state-slices/IdentityInfoSlice'
import Loading from '../shared-components/Loading'
import IdentityInfo from './onboarding_flow/IdentityInfo'
import BusinessInfo from './onboarding_flow/BusinessInfo'
import BankingInfo from './onboarding_flow/BankingInfo'
import { setBusinessInfo } from '../redux/state-slices/BusinessInfoSlice'
import { setBankingInfo } from '../redux/state-slices/BankingInfoSlice'
import { CheckIcon } from '@heroicons/react/24/solid'
import { useAppSelector, useAppDispatch } from '../redux/hooks'
import Error from '../shared-components/Error'
import {
    validateBankingInfo,
    validateBusinessInfo,
    validateIdentityInfo,
} from '../utils/RegexHelper'
import Pricing from './onboarding_flow/Pricing'
import { setOnboardingType } from '../redux/state-slices/OnboardingTypeSlice'
import DebitIdentityInfo from './debit_onboarding_flow/DebitIdentityInfo'
import DebitBusinessInfo from './debit_onboarding_flow/DebitBusinessInfo'
import DebitBankingInfo from './debit_onboarding_flow/DebitBankingInfo'
import DebitPricing from './debit_onboarding_flow/DebitPricing'
import DebitIntegratedContract from './debit_onboarding_flow/DebitIntegratedContract'
export default function OnboardingDebit() {
    const navigate = useNavigate()
    const formValuesRepository = new RegistrationFormValuesRepository()
    const dispatch = useAppDispatch()
    const steps = useAppSelector((state) => state.steps)

    const getFormValues = useQuery(
        'register-form-values',
        async () => {
            return await formValuesRepository.getFormValues()
        },
        {
            staleTime: Infinity,
            retry: false,
            onSuccess: (data) => {
                let identityInfo: Paywell.API.V2.IdentityInfo[] =
                    data.data.identity_infos
                let bankingInfo: Paywell.API.V2.BankingInfo =
                    data.data.banking_info
                let businessInfo: Paywell.API.V2.BusinessInfo =
                    data.data.business_info

                redirectToStepAndSetStates(
                    identityInfo,
                    businessInfo,
                    bankingInfo
                )
            },
            onError: (error) => {
                console.log(error)
            },
        }
    )

    useEffect(() => {
        getFormValues.refetch()
    }, [])

    const redirectToStepAndSetStates = (
        identityInfos: Paywell.API.V2.IdentityInfo[],
        businessInfo: Paywell.API.V2.BusinessInfo,
        bankingInfo: Paywell.API.V2.BankingInfo
    ) => {
        let identityInfoForm: Paywell.ReduxStore.Onboarding.IdentityInfoForm[] =
            []
        identityInfos.forEach((info) => {
            let identityInfoFormObj: Paywell.ReduxStore.Onboarding.IdentityInfoForm =
                {
                    identity_info: info,
                    validations: {
                        first_name: undefined,
                        last_name: undefined,
                        address: undefined,
                        city: undefined,
                        SIN: undefined,
                        provinces_id: undefined,
                        id: undefined,
                        birthday: undefined,
                        postal_code: undefined,
                        tel_number: undefined,
                        drivers_licence_number: undefined,
                        drivers_licence_file_name: undefined,
                        countries_id: undefined,
                        percentage_ownership: undefined,
                        occupation_title: undefined,
                        email_address: undefined,
                        drivers_licence_file_name_back: undefined,
                        proof_of_address_file_name: undefined,
                        liveness_photo_file_name: undefined,
                    },
                }
            identityInfoForm.push(identityInfoFormObj)
        })

        let validIdentityInfo = validateIdentityInfo(identityInfoForm, 'debit')
        let validBusinessInfo = validateBusinessInfo(businessInfo, 'debit')
        let validBankingInfo = validateBankingInfo(bankingInfo)

        dispatch(setIdentityInfo([...identityInfoForm]))
        dispatch(setBusinessInfo(businessInfo))
        dispatch(setBankingInfo(bankingInfo))

        if (!validIdentityInfo) {
            navigate('/onboarding/debit/identity-info')
        } else if (!validBusinessInfo) {
            navigate('/onboarding/debit/business-info')
        } else if (!validBankingInfo) {
            navigate('/onboarding/debit/banking-info')
        } else {
            navigate('/onboarding/debit/pricing')
        }
    }

    if (getFormValues.isLoading || getFormValues.isFetching) {
        return (
            <div className="flex h-screen">
                <div className="m-auto">
                    <Loading width="8" height="8" />
                </div>
            </div>
        )
    } else if (getFormValues.isError) {
        return (
            <div>
                <Error
                    message="Please reach out to one of our representatives if this error persists"
                    title="An error has occurred"
                />
            </div>
        )
    } else {
        return (
            <div className="mt-5 p-4">
                <div className={'w-full flex justify-end mb-4'}>
                    <button
                        onClick={() => dispatch(setOnboardingType(null))}
                        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Change Application
                    </button>
                </div>
                <nav aria-label="Progress" className="mb-10">
                    <ol
                        role="list"
                        className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0 bg-white"
                    >
                        {steps.map((step: any, stepIdx: any) => (
                            <li
                                key={step.name}
                                className="relative md:flex md:flex-1"
                            >
                                {step.status === 'complete' ? (
                                    <a
                                        //href={step.href}
                                        onClick={
                                            step.status === 'complete' ||
                                            step.status === 'current'
                                                ? () => navigate(step.url)
                                                : undefined
                                        }
                                        className="group flex w-full items-center cursor-pointer"
                                    >
                                        <span className="flex items-center px-6 py-4 text-sm font-medium">
                                            <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                                                <CheckIcon
                                                    className="h-6 w-6 text-white"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                            <span className="ml-4 text-sm font-medium text-gray-900">
                                                {step.name}
                                            </span>
                                        </span>
                                    </a>
                                ) : step.status === 'current' ? (
                                    <a
                                        //href={step.href}
                                        className="flex items-center px-6 py-4 text-sm font-medium"
                                        aria-current="step"
                                        onClick={
                                            step.status === 'complete' ||
                                            step.status === 'current'
                                                ? () => navigate(step.url)
                                                : undefined
                                        }
                                    >
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                                            <span className="text-indigo-600">
                                                {step.id}
                                            </span>
                                        </span>
                                        <span className="ml-4 text-sm font-medium text-indigo-600">
                                            {step.name}
                                        </span>
                                    </a>
                                ) : (
                                    <a
                                        //href={step.href}
                                        className="group flex items-center cursor-pointer"
                                        onClick={
                                            step.status === 'complete' ||
                                            step.status === 'current'
                                                ? () => navigate(step.url)
                                                : undefined
                                        }
                                    >
                                        <span className="flex items-center px-6 py-4 text-sm font-medium">
                                            <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                                <span className="text-gray-500 group-hover:text-gray-900">
                                                    {step.id}
                                                </span>
                                            </span>
                                            <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                                                {step.name}
                                            </span>
                                        </span>
                                    </a>
                                )}

                                {stepIdx !== steps.length - 1 ? (
                                    <>
                                        {/* Arrow separator for lg screens and up */}
                                        <div
                                            className="absolute top-0 right-0 hidden h-full w-5 md:block"
                                            aria-hidden="true"
                                        >
                                            <svg
                                                className="h-full w-full text-gray-300"
                                                viewBox="0 0 22 80"
                                                fill="none"
                                                preserveAspectRatio="none"
                                            >
                                                <path
                                                    d="M0 -2L20 40L0 82"
                                                    vectorEffect="non-scaling-stroke"
                                                    stroke="currentcolor"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </>
                                ) : null}
                            </li>
                        ))}
                    </ol>
                </nav>
                <Routes>
                    <Route
                        path="debit/identity-info"
                        element={<DebitIdentityInfo />}
                    />
                    <Route
                        path="debit/business-info"
                        element={<DebitBusinessInfo />}
                    />
                    <Route
                        path="debit/banking-info"
                        element={<DebitBankingInfo />}
                    />
                    <Route path="debit/pricing" element={<DebitPricing />} />
                    <Route
                        path="debit/contract"
                        element={<DebitIntegratedContract />}
                    />
                </Routes>
            </div>
        )
    }
}
