interface Props {
    user: Paywell.API.V2.User | undefined
}

export default function UserInvoices({ user }: Props) {
    const invoices = user?.invoices
    const getInvoiceNumber = (invoiceVersion: string, invoiceId: number) => {
        if (invoiceVersion === '2.0') {
            return invoiceId + 3976
        } else {
            return invoiceId + 1000
        }
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                        Invoices
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        View and manage{' '}
                        <span className={'italic font-medium'}>
                            {user?.name}'s{' '}
                        </span>{' '}
                        invoices.
                    </p>
                </div>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                        Invoice Number
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Amount
                                    </th>
                                    <th
                                        scope="col"
                                        className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                                    >
                                        <span className="sr-only">View</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {invoices &&
                                    invoices.length > 0 &&
                                    invoices?.map((invoice) => (
                                        <tr key={invoice.invoice_id}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                {getInvoiceNumber(
                                                    invoice.version,
                                                    invoice.invoice_id
                                                )}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {new Date(
                                                    invoice.invoice_date
                                                ).toDateString()}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {invoice.billing_name}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {invoice.invoice_grand_total}
                                            </td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                <a
                                                    target="_blank"
                                                    href={invoice.url}
                                                    className="text-indigo-600 hover:text-indigo-900"
                                                >
                                                    View
                                                </a>
                                            </td>
                                        </tr>
                                    ))}

                                {invoices && invoices.length === 0 && (
                                    <tr>
                                        <td
                                            colSpan={5}
                                            className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                                        >
                                            No invoices found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
