import React from "react";

interface AppContextInterface {
  locale: string
  languageOnly: (langCode: string) => string;
  setLocale: any
}

const defaultValue: AppContextInterface = {
  locale: 'en',
  languageOnly: (langCode: string) => langCode.split("-")[0],
  setLocale: () => {}
}

export default React.createContext<AppContextInterface>(defaultValue);