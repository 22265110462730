import { createSlice, PayloadAction, current } from '@reduxjs/toolkit'
import { MouseEventHandler } from 'react'

interface GlobalNotificationInterface {
    warning: Array<string>
    error: Array<string>
    notice: Array<string>
    success: Array<string>
    confirm: Confirm
    loading: boolean
    tax: NewTax
    product: NewProduct
}

interface Confirm {
    title: string
    message: string
    onClick: MouseEventHandler<HTMLButtonElement>
}

interface NewTax {
    show: boolean
    onClose: () => void
    callback: (taxId: any) => void
}

interface NewProduct {
    show: boolean
    onClose: () => void
    callback: (productId: any) => void
}

const initialState: GlobalNotificationInterface = {
    warning: [],
    error: [],
    notice: [],
    success: [],
    confirm: {title: '', message: '', onClick: () => {}},
    loading: false,
    tax: { show: false, onClose: () => { }, callback: () => { } },
    product: { show: false, onClose: () => { }, callback: () => { } }
}

export const GlobalNotificationSlice = createSlice({
    name: 'globalnotifications',
    initialState,

    reducers: {
        setWarning: (state, action: PayloadAction<Array<string>>) => {
            state.warning = action.payload
            
        },
        setError: (state, action: PayloadAction<Array<string>>) => {
            state.error = action.payload
        },
        setNotice: (state, action: PayloadAction<Array<string>>) => {
            state.notice = action.payload
        },
        setSuccess: (state, action: PayloadAction<Array<string>>) => {
            state.success = action.payload
        },
        setConfirm: (state, action: PayloadAction<Confirm>) => {
            state.confirm = action.payload
        },
        resetConfirm: (state, action: PayloadAction<any>) => {
            state.confirm = {title: '', message: '', onClick: () => {}};
        },
        setLoad: (state, action: PayloadAction<any>) => {
            state.loading = action.payload
        },
        addTax: (state, action: PayloadAction<any>) => {
            state.tax = action.payload
        },
        addProduct: (state, action: PayloadAction<any>) => {
            state.loading = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setWarning, setError, setNotice, setSuccess, setConfirm, resetConfirm, setLoad, addTax, addProduct } =
    GlobalNotificationSlice.actions

export default GlobalNotificationSlice.reducer
