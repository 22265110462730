import { TrashIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { InvoicesRepository } from '../../api/InvoicesRepository'
import PaginationV2 from '../components/PaginationV2'
import SkeletonLoader from '../components/SkeletonLoader'
import { setError } from '../../redux/state-slices/GlobalNotificationSlice'
import { useAppDispatch } from '../../redux/hooks'

export default function InvoiceEmailReminders() {
    const dispatch = useAppDispatch()
    const invoiceRepository = new InvoicesRepository()
    const [searchParams] = useSearchParams()

    const page = searchParams.get('page') ? searchParams.get('page') : 1

    const [reminders, setReminders] = useState<Paywell.API.V2.EmailReminder[]>(
        []
    )

    const navigate = useNavigate()

    const emailRemindersQuery = useQuery(
        'invoice-email-reminders',
        async () => {
            return await invoiceRepository.getEmailReminders()
        },
        {
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            enabled: true,
            retry: false,
            onSuccess: (data) => {
                setReminders(data.data.data)
            },
            onError: (error) => {
                dispatch(
                    setError([
                        'An error has occurred. Please reach out to one of our representatives if this error persists.',
                    ])
                )
            },
        }
    )

    let total = emailRemindersQuery.data?.data?.total
    let from = emailRemindersQuery.data?.data?.from
    let to = emailRemindersQuery.data?.data?.to
    let next = emailRemindersQuery.data?.data?.next_page_url
    let prev = emailRemindersQuery.data?.data?.prev_page_url
    let reminderLinks = emailRemindersQuery.data?.data?.links

    const deleteEmailReminderMutation = useMutation(
        (id: number) => invoiceRepository.deleteEmailReminder(id),
        {
            onSuccess: (data) => {
                emailRemindersQuery.refetch()
            },
            onError: (error) => {
                dispatch(
                    setError([
                        'An error has occurred. Please reach out to one of our representatives if this error persists.',
                    ])
                )
            },
        }
    )

    const getHumanReadableTrigger = (
        trigger: string | null,
        reminderPeriod: number | null
    ) => {
        switch (trigger) {
            case 'BEFORE_DUE_DATE':
                return `${reminderPeriod} day(s) before due date`
            case 'AFTER_DUE_DATE':
                return `${reminderPeriod} day(s) after due date`
            case 'INITIAL_REMINDER':
                return 'Initial Email'
            default:
                return 'Unknown'
        }
    }

    const deleteEmailReminder = (id: number | null) => {
        if (!id) return

        if (deleteEmailReminderMutation.isLoading) return
        deleteEmailReminderMutation.mutate(id)
    }

    return (
        <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
                    {reminders &&
                        reminders.length < 10 &&
                        !emailRemindersQuery.isLoading &&
                        !emailRemindersQuery.isFetching && (
                            <div className="flex justify-end space-x-2">
                                <button
                                    onClick={() => navigate(-1)}
                                    className=" rounded-md mb-4 bg-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 text-gray-900 px-3 py-2 text-center text-sm font-semibold  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Back
                                </button>

                                <button
                                    onClick={() =>
                                        navigate(
                                            '/invoicing/settings/email-reminders/create'
                                        )
                                    }
                                    type="button"
                                    className=" rounded-md mb-4 bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    New Reminder
                                </button>
                            </div>
                        )}
                    <div className="w-full ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300 overflow-scroll">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                        Trigger
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {emailRemindersQuery.isFetching && (
                                    <SkeletonLoader
                                        rows={5}
                                        columns={4}
                                        isError={false}
                                    />
                                )}
                                {emailRemindersQuery.isSuccess &&
                                    !emailRemindersQuery.isLoading &&
                                    !emailRemindersQuery.isFetching &&
                                    reminders &&
                                    reminders.length > 0 &&
                                    reminders.map((reminder, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                    {getHumanReadableTrigger(
                                                        reminder.trigger_type,
                                                        reminder.reminder_period
                                                    )}
                                                </td>

                                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                    <Link
                                                        className="text-blue-600 hover:text-blue-900"
                                                        to={`/invoicing/settings/email-reminders/${reminder.id}`}
                                                    >
                                                        View
                                                    </Link>
                                                </td>
                                                <td className='className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6'>
                                                    <Link
                                                        className="text-blue-600 hover:text-blue-900"
                                                        to={`/invoicing/settings/email-reminders/edit/${reminder.id}`}
                                                    >
                                                        Edit
                                                    </Link>
                                                </td>

                                                <td>
                                                    <button
                                                        onClick={() => {
                                                            deleteEmailReminder(
                                                                reminder.id ||
                                                                    null
                                                            )
                                                        }}
                                                        type="button"
                                                        disabled={
                                                            deleteEmailReminderMutation.isLoading
                                                        }
                                                        className="text-gray-400 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-60 hover:text-gray-500"
                                                    >
                                                        <TrashIcon className="fill-red-500 h-4 w-4 cursor-pointer" />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <PaginationV2
                pages={reminderLinks}
                from={from}
                to={to}
                total={total}
                next={next}
                prev={prev}
            />
        </div>
    )
}
