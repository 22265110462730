import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { InvoicesRepository } from '../../../api/InvoicesRepository'
import { useParams } from 'react-router'
import { useQuery } from 'react-query'
import { useAppSelector } from '../../../redux/hooks'
import Loading from '../../../shared-components/Loading'
import ErrorV2 from '../../../shared-components/ErrorV2'
import InvoiceCheckoutWireTransfer from './InvoiceCheckoutWireTransfer'
import InvoiceCheckoutCreditCard from './InvoiceCheckoutCreditCard'
import InvoiceCheckoutETransfer from './InvoiceCheckoutETransfer'
import InvoiceCheckoutCrypto from './InvoiceCheckoutCrypto'
import NumberFormatDollars from '../../../utils/NumberFormatDollars'
import Error from '../../../shared-components/Error'
import InvoiceCheckoutCheck from './InvoiceCheckoutCheck'
import { formatDate } from '../../../utils/StringUtils'
import {
    getHumanReadableStatus,
    getHumanReadableType,
} from '../../../utils/TransactionHelper'
import Tooltip from '../../../shared-components/Tooltip'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'

export default function InvoiceCheckoutPathChooser() {
    const { id } = useParams()
    const invoiceRepository = new InvoicesRepository()

    const [paymentMethods, setPaymentMethods] = useState([])
    const [selectedPaymentOption, setSelectedPaymentOption] = useState(
        paymentMethods[0]
    )
    const [payMode, setPayMode] = useState(true)
    const [disableOptions, setDisableOptions] = useState(false)
    const invoiceQuery = useQuery(
        ['invoice', id],
        async () => {
            return await invoiceRepository.getCheckoutInvoice(id ? id : '0')
        },
        {
            onSuccess: (data) => {
                let invoicePaid = data.data.payment_status === 'paid'
                let paymentAvailable = data.data.payment_available
                if (!paymentAvailable) {
                    setPayMode(false)
                }
                if (invoicePaid) {
                    setPayMode(false)
                }
            },
        }
    )
    const invoice = invoiceQuery.data?.data
    const getPaymentMethodsQuery = useQuery(
        'payment-methods',
        async () => {
            return await invoiceRepository.getPaymentMethods(invoice?.id)
        },
        {
            enabled: !!invoice?.id,
            refetchOnMount: true,
            refetchOnWindowFocus: true,
            retry: false,
            onSuccess: (data) => {
                const newPaymentMethods: any = []
                const paymentMethods: Paywell.API.V2.PaymentMethodOptions = {
                    id: data?.data?.id,
                    credit_card: data?.data?.credit_card ? true : false,
                    etransfer: data?.data?.etransfer ? true : false,
                    wire_transfer: data?.data?.wire_transfer ? true : false,
                    crypto: data?.data?.crypto ? true : false,
                    check: data?.data?.check ? true : false,
                }
                type Keys =
                    | 'credit_card'
                    | 'etransfer'
                    | 'wire_transfer'
                    | 'crypto'
                    | 'check'
                const paymentMethodKeys: Keys[] = [
                    'credit_card',
                    'etransfer',
                    'wire_transfer',
                    'crypto',
                    'check',
                ]

                paymentMethodKeys.forEach((key) => {
                    if (paymentMethods[key]) {
                        newPaymentMethods.push(key)
                    }
                })

                setPaymentMethods(newPaymentMethods)
                setSelectedPaymentOption(newPaymentMethods[0])
            },
            onError: (error) => {},
        }
    )

    const paymentHistoryQuery = useQuery(
        ['paymentHistory', invoice?.uuid],
        async () => {
            return await invoiceRepository.getPaymentHistory(
                invoice?.uuid || ''
            )
        },
        {
            retry: true,
            refetchOnWindowFocus: true,
        }
    )

    const branding = useAppSelector((state) => state.branding)

    const classNames = (...classes: string[]) => {
        return classes.filter(Boolean).join(' ')
    }

    const getCurrentPaymentOption = () => {
        const selectedOption = paymentMethods.find(
            (paymentOption) => paymentOption === selectedPaymentOption
        )
        return selectedOption
    }

    const getTitle = (value: string) => {
        switch (value) {
            case 'credit_card':
                return 'Credit Card'
            case 'etransfer':
                return 'e-Transfer'
            case 'wire_transfer':
                return 'Wire Transfer'
            case 'crypto':
                return 'Crypto'
            case 'check':
                return 'Check'
            default:
                return ''
        }
    }

    const getDescription = (value: string) => {
        switch (value) {
            case 'credit_card':
                return 'Pay with your credit card.'
            case 'etransfer':
                return 'Pay with Interac e-Transfer.'
            case 'wire_transfer':
                return 'Pay with Wire Transfer.'
            case 'crypto':
                return 'Pay with Crypto.'
            case 'check':
                return 'Pay with a check.'
            default:
                return ''
        }
    }

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
        })
    }

    const getColumnSize = (paymentMethodsLength: number) => {
        if (paymentMethods.length > 4) {
            return 4
        }

        return paymentMethodsLength
    }

    const wireTransferSelected = getCurrentPaymentOption() === 'wire_transfer'
    const eTransferSelected = getCurrentPaymentOption() === 'etransfer'
    const cryptoSelected = getCurrentPaymentOption() === 'crypto'
    const creditCardSelected = getCurrentPaymentOption() === 'credit_card'
    const checkSelected = getCurrentPaymentOption() === 'check'

    const invoicePaid = invoice?.payment_status === 'paid'
    const invoicePending = invoice?.payment_status === 'pending'
    const paymentAvailable = invoice?.payment_available

    const getBackgroundClassName = () => {
        switch (invoice?.payment_status) {
            case 'paid':
                return 'bg-green-50'
            case 'pending':
                return 'bg-yellow-50'
            case 'unpaid':
                return 'bg-red-50'
            case 'partial':
                return 'bg-yellow-50'
            default:
                return ''
        }
    }

    const getTextColorClassName = () => {
        switch (invoice?.payment_status) {
            case 'paid':
                return 'text-green-600'
            case 'pending':
                return 'text-yellow-600'
            case 'unpaid':
                return 'text-red-600'
            case 'partial':
                return 'text-yellow-600'
            default:
                return ''
        }
    }

    const getPaymentStatus = () => {
        switch (invoice?.payment_status) {
            case 'paid':
                return 'Paid'
            case 'pending':
                return 'Pending'
            case 'unpaid':
                return 'Unpaid'
            case 'partial':
                return 'Partial'
            default:
                return ''
        }
    }

    if (invoiceQuery.isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Loading height="8" width="8" />
            </div>
        )
    }

    if (invoiceQuery.isError)
        return (
            <div>
                <ErrorV2
                    title="An error has occured"
                    message="Something went wrong while loading the invoice. Please try again later."
                />
            </div>
        )

    if (payMode) {
        return (
            <>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-3xl">
                        <div className="relative flex flex-col bg-white shadow-lg rounded-xl dark:bg-gray-800 mt-10">
                            <div className="relative overflow-hidden min-h-[8rem] bg-indigo-600 text-center rounded-t-xl">
                                <figure className="absolute inset-x-0 bottom-0">
                                    <svg
                                        preserveAspectRatio="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 1920 100.1"
                                    >
                                        <path
                                            fill="currentColor"
                                            className="fill-white dark:fill-gray-800"
                                            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                                        ></path>
                                    </svg>
                                </figure>
                            </div>

                            <div className="relative z-10 -mt-12">
                                <span className="mx-auto flex justify-center items-center w-[62px] h-[62px] rounded-full border border-gray-200 bg-white text-gray-700 shadow-sm dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400">
                                    <svg
                                        className="w-6 h-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
                                        <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
                                    </svg>
                                </span>
                            </div>

                            <div className="p-4 sm:p-7 overflow-y-auto">
                                <div className="text-center">
                                    <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                                        Invoice from{' '}
                                        {invoice?.user_business_name}
                                    </h3>
                                    <p className="text-sm text-gray-500">
                                        {invoice?.invoice_number
                                            ? 'Invoice #' +
                                              invoice?.invoice_number
                                            : ''}
                                    </p>
                                </div>

                                <div className="mt-5 sm:mt-10 grid grid-cols-2 sm:grid-cols-3 gap-5">
                                    <div>
                                        <span className="block text-xs uppercase text-gray-500">
                                            Amount Due:
                                        </span>
                                        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                                            {NumberFormatDollars(
                                                invoice?.outstanding_balance
                                            )}
                                        </span>
                                    </div>

                                    <div>
                                        <span className="block text-xs uppercase text-gray-500">
                                            Due Date:
                                        </span>
                                        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                                            {invoice?.due_date}
                                        </span>
                                    </div>

                                    <div>
                                        <span className="block text-xs uppercase text-gray-500">
                                            Bill To:
                                        </span>
                                        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                                            {invoice?.billing_name}
                                        </span>
                                    </div>
                                </div>

                                <div className="mt-5 sm:mt-10">
                                    <h4 className="text-xs font-semibold uppercase text-gray-800 dark:text-gray-200">
                                        Summary
                                    </h4>

                                    <ul className="mt-3 flex flex-col">
                                        <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                                            <div className="flex items-center justify-between w-full">
                                                <span>Subtotal</span>
                                                <span>
                                                    {NumberFormatDollars(
                                                        invoice?.invoice_subtotal
                                                    )}
                                                </span>
                                            </div>
                                        </li>
                                        <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                                            <div className="flex items-center justify-between w-full">
                                                <span>Tax Total</span>
                                                <span>
                                                    {NumberFormatDollars(
                                                        invoice?.invoice_tax_total
                                                    )}
                                                </span>
                                            </div>
                                        </li>
                                        <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-semibold bg-gray-50 border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-slate-800 dark:border-gray-700 dark:text-gray-200">
                                            <div className="flex items-center justify-between w-full">
                                                <span>Grand Total</span>
                                                <span>
                                                    {NumberFormatDollars(
                                                        invoice?.invoice_grand_total
                                                    )}
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className="mt-5 flex justify-between gap-x-2">
                                    <button
                                        onClick={() => setPayMode(false)}
                                        className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                                    >
                                        View Detailed Invoice
                                    </button>
                                    <button
                                        onClick={scrollToBottom}
                                        className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                                    >
                                        Pay Now
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="mt-10 bg-white border rounded-lg ">
                            <div className="border  rounded-tl-lg rounded-tr-lg text-lg font-medium  w-full bg-indigo-600 text-white p-6">
                                Select A Payment Method
                            </div>

                            <div className="p-4 sm:p-7 ">
                                {(getPaymentMethodsQuery.isLoading ||
                                    getPaymentMethodsQuery.isFetching) && (
                                    <Loading height="8" width="8" />
                                )}
                                {getPaymentMethodsQuery.isSuccess && (
                                    <>
                                        <div className="mb-4">
                                            <RadioGroup
                                                value={selectedPaymentOption}
                                                onChange={
                                                    setSelectedPaymentOption
                                                }
                                                disabled={disableOptions}
                                            >
                                                <div
                                                    className={`mt-4 mb-8 grid gap-2 grid-cols-1
                                                        paymentMethods.length
                                                    )}  sm:grid-cols-${getColumnSize(
                                                        paymentMethods.length
                                                    )}`}
                                                >
                                                    {paymentMethods.map(
                                                        (paymentMethod) => (
                                                            <RadioGroup.Option
                                                                key={
                                                                    paymentMethod
                                                                }
                                                                value={
                                                                    paymentMethod
                                                                }
                                                                className={({
                                                                    checked,
                                                                    active,
                                                                }) =>
                                                                    classNames(
                                                                        checked
                                                                            ? 'border-transparent'
                                                                            : 'border-gray-300',
                                                                        active
                                                                            ? 'ring-2 ring-indigo-500'
                                                                            : '',
                                                                        `relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none ${
                                                                            disableOptions
                                                                                ? 'bg-gray-300 cursor-not-allowed'
                                                                                : ''
                                                                        }`
                                                                    )
                                                                }
                                                            >
                                                                {({
                                                                    checked,
                                                                    active,
                                                                }) => (
                                                                    <>
                                                                        <span className="w-full">
                                                                            <span className="">
                                                                                <RadioGroup.Label
                                                                                    as="span"
                                                                                    className="block text-sm font-medium text-gray-900"
                                                                                >
                                                                                    {getTitle(
                                                                                        paymentMethod
                                                                                    )}
                                                                                </RadioGroup.Label>
                                                                                <RadioGroup.Description
                                                                                    as="span"
                                                                                    className="mt-1 flex items-center text-sm text-gray-500"
                                                                                >
                                                                                    {getDescription(
                                                                                        paymentMethod
                                                                                    )}
                                                                                </RadioGroup.Description>
                                                                            </span>
                                                                        </span>
                                                                        {getCurrentPaymentOption() ===
                                                                            paymentMethod && (
                                                                            <CheckCircleIcon
                                                                                className="h-5 w-5 text-indigo-600"
                                                                                aria-hidden="true"
                                                                            />
                                                                        )}
                                                                        <span
                                                                            className={classNames(
                                                                                active
                                                                                    ? 'border'
                                                                                    : 'border-2',
                                                                                checked
                                                                                    ? 'border-indigo-500'
                                                                                    : 'border-transparent',
                                                                                'pointer-events-none absolute -inset-px rounded-lg'
                                                                            )}
                                                                            aria-hidden="true"
                                                                        />
                                                                    </>
                                                                )}
                                                            </RadioGroup.Option>
                                                        )
                                                    )}
                                                </div>
                                            </RadioGroup>
                                        </div>
                                        {wireTransferSelected && (
                                            <InvoiceCheckoutWireTransfer />
                                        )}
                                        {creditCardSelected && (
                                            <InvoiceCheckoutCreditCard
                                                uuid={id}
                                                price={
                                                    invoice?.outstanding_balance
                                                }
                                                nmi_public_key={
                                                    invoice?.nmi_public_key
                                                }
                                                setDisableOptions={
                                                    setDisableOptions
                                                }
                                            />
                                        )}
                                        {eTransferSelected && (
                                            <InvoiceCheckoutETransfer
                                                invoiceNumber={invoice?.id || 0}
                                                invoiceTotal={
                                                    invoice?.outstanding_balance ||
                                                    0
                                                }
                                            />
                                        )}
                                        {cryptoSelected && (
                                            <InvoiceCheckoutCrypto />
                                        )}

                                        {checkSelected && (
                                            <InvoiceCheckoutCheck
                                                invoiceTotal={
                                                    invoice?.outstanding_balance ||
                                                    0
                                                }
                                            />
                                        )}
                                    </>
                                )}

                                {getPaymentMethodsQuery.isError && (
                                    <Error
                                        title="An error has occurred"
                                        message={`Please reach out to one of our representatives at ${branding.contact_email} if this error persists.`}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="bg-indigo-900 mt-10">
                    <div className="p-5">
                        <div className="flex justify-center items-center space-x-2 ">
                            <img
                                className="h-8 w-auto"
                                src={branding.company_icon || ''}
                                alt="Your Company"
                            />
                            <p className="text-xs leading-5 text-white md:order-1 md:mt-0">
                                Powered by Paywell &copy;
                            </p>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
    return (
        <>
            <main>
                <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {/* Invoice summary */}
                        <div className="lg:col-start-3 lg:row-end-1 print:hidden">
                            <h2 className="sr-only">Summary</h2>
                            <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                                <dl className="flex flex-wrap">
                                    <div className="flex-auto pl-6 pt-6">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900">
                                            Amount Due
                                        </dt>
                                        <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
                                            {NumberFormatDollars(
                                                invoice?.outstanding_balance
                                            )}
                                        </dd>
                                    </div>
                                    <div className="flex items-center px-6 pt-4">
                                        <dt className="sr-only">Status</dt>
                                        <dd
                                            className={`rounded-md ${getBackgroundClassName()} px-2 py-1 text-2xl font-medium ${getTextColorClassName()} ring-1 ring-inset ring-green-600/20`}
                                        >
                                            {getPaymentStatus()}
                                        </dd>
                                        {invoicePending && (
                                            <Tooltip
                                                text={
                                                    'Your payment is currently pending. Please allow us some time to process it. For the time being, payment options have been disabled. You can view your transaction history at the bottom of this page'
                                                }
                                            >
                                                <ExclamationCircleIcon
                                                    className="h-6 w-6 text-blue-600"
                                                    aria-hidden="true"
                                                />
                                            </Tooltip>
                                        )}
                                    </div>
                                </dl>
                                <div className="mt-6 border-t border-gray-900/5 px-6 py-6 w-full flex justify-between">
                                    {!invoicePaid && paymentAvailable && (
                                        <button
                                            onClick={() => setPayMode(true)}
                                            type="button"
                                            className="inline-flex items-center rounded-md  px-3 py-2 font-semibold shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600  bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                                        >
                                            Pay Invoice{' '}
                                        </button>
                                    )}
                                    <button
                                        className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                                        onClick={() => window.print()}
                                        type="button"
                                    >
                                        Print
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Invoice */}
                        <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
                            <h2 className="text-base font-semibold leading-6 text-gray-900">
                                {invoice?.invoice_number
                                    ? 'Invoice #' + invoice?.invoice_number
                                    : 'Invoice'}
                            </h2>
                            <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                                <div className="sm:pr-4">
                                    <dt className="inline text-gray-500">
                                        Issued on
                                    </dt>{' '}
                                    <dd className="inline text-gray-700">
                                        {invoice?.due_date}
                                    </dd>
                                </div>
                                <div className="mt-2 sm:mt-0 sm:pl-4">
                                    <dt className="inline text-gray-500">
                                        Due on
                                    </dt>{' '}
                                    <dd className="inline text-gray-700">
                                        {invoice?.due_date}
                                    </dd>
                                </div>
                                <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                                    <dt className="font-semibold text-gray-900">
                                        From
                                    </dt>
                                    <dd className="mt-2 text-gray-500">
                                        <span className="font-medium text-gray-900">
                                            {invoice?.user_business_name}
                                        </span>
                                        {invoice?.tax_number ? (
                                            <>
                                                <br />
                                                <span>
                                                    {invoice?.tax_number}
                                                </span>
                                            </>
                                        ) : null}
                                        <br />
                                        {invoice?.user_address}
                                        <br />
                                        {invoice?.user_city},{' '}
                                        {invoice?.user_province},{' '}
                                        {invoice?.user_postal}
                                    </dd>
                                </div>
                                <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                                    <dt className="font-semibold text-gray-900">
                                        To
                                    </dt>
                                    <dd className="mt-2 text-gray-500">
                                        <span className="font-medium text-gray-900">
                                            {invoice?.billing_name}
                                        </span>
                                        <br />
                                        {invoice?.billing_address}
                                        <br />
                                        {invoice?.billing_city},{' '}
                                        {invoice?.billing_province}{' '}
                                        {invoice?.billing_postal_code}
                                    </dd>
                                </div>
                            </dl>
                            <table className="mt-16 w-full whitespace-nowrap text-left text-sm leading-6">
                                <colgroup>
                                    <col className="w-full" />
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <thead className="border-b border-gray-200 text-gray-900">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-0 py-3 font-semibold"
                                        >
                                            Description
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
                                        >
                                            Qty
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
                                        >
                                            Rate
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-3 pl-8 pr-0 text-right font-semibold"
                                        >
                                            Price
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoice?.line_items.map((item) => {
                                        return (
                                            <tr
                                                key={item.id}
                                                className="border-b border-gray-100"
                                            >
                                                <td className="max-w-0 px-0 py-5 align-top">
                                                    <div className="truncate font-medium text-gray-900">
                                                        {item.products.name}
                                                        {item.description ? (
                                                            <div
                                                                className="bg-gray-100 p-2 mt-1 text-sm font-light"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item.description,
                                                                }}
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                                                    {item.quantity}
                                                </td>
                                                <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                                                    {NumberFormatDollars(
                                                        item.cost_per_unit
                                                    )}
                                                </td>
                                                <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                                                    {NumberFormatDollars(
                                                        item.subtotal
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th
                                            scope="row"
                                            className="px-0 pb-0 pt-6 font-normal text-gray-700 sm:hidden"
                                        >
                                            Subtotal
                                        </th>
                                        <th
                                            scope="row"
                                            colSpan={3}
                                            className="hidden px-0 pb-0 pt-6 text-right font-normal text-gray-700 sm:table-cell"
                                        >
                                            Subtotal
                                        </th>
                                        <td className="pb-0 pl-8 pr-0 pt-6 text-right tabular-nums text-gray-900">
                                            {NumberFormatDollars(
                                                invoice?.invoice_subtotal
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th
                                            scope="row"
                                            className="pt-4 font-normal text-gray-700 sm:hidden"
                                        >
                                            Tax Total
                                        </th>
                                        <th
                                            scope="row"
                                            colSpan={3}
                                            className="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
                                        >
                                            Tax Total
                                        </th>
                                        <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900">
                                            {NumberFormatDollars(
                                                invoice?.invoice_tax_total
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th
                                            scope="row"
                                            className="pt-4 font-semibold text-gray-900 sm:hidden"
                                        >
                                            Grand Total
                                        </th>
                                        <th
                                            scope="row"
                                            colSpan={3}
                                            className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
                                        >
                                            Grand Total
                                        </th>
                                        <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
                                            {NumberFormatDollars(
                                                invoice?.invoice_grand_total
                                            )}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    <div className="">
                        <h2 className="font-bold mt-10 mb-5">
                            Payments for this invoice
                        </h2>
                        <div className="mt-2 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                                >
                                                    Amount
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Date
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Type
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {paymentHistoryQuery.isSuccess &&
                                                paymentHistoryQuery?.data.data
                                                    .length > 0 &&
                                                paymentHistoryQuery?.data.data.map(
                                                    (payment) => (
                                                        <tr
                                                            key={payment.id}
                                                            className="even:bg-gray-50"
                                                        >
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                                                {NumberFormatDollars(
                                                                    payment.amount
                                                                )}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {
                                                                    payment.name_on_card
                                                                }
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {formatDate(
                                                                    payment.process_date
                                                                )}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {getHumanReadableType(
                                                                    payment.type
                                                                )}{' '}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {getHumanReadableStatus(
                                                                    payment.status_code
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}

                                            {paymentHistoryQuery.isSuccess &&
                                                paymentHistoryQuery?.data.data
                                                    .length === 0 && (
                                                    <tr className="even:bg-gray-50">
                                                        <td className="whitespace-nowrap bg-gray-50 px-3 py-4 text-sm text-gray-500">
                                                            No Payments Made
                                                        </td>
                                                    </tr>
                                                )}

                                            {paymentHistoryQuery.isLoading && (
                                                <tr className="even:bg-gray-50">
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        Loading...
                                                    </td>
                                                </tr>
                                            )}

                                            {paymentHistoryQuery.isError && (
                                                <tr className="bg-gray-50">
                                                    <td className="whitespace-nowrap bg-gray-50 px-3 py-4 text-sm text-gray-500">
                                                        There was an error
                                                        loading the payments. If
                                                        this error persists,
                                                        please contact support.{' '}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <footer className="bg-indigo-900 mt-10">
                <div className="p-5">
                    <div className="flex justify-center items-center space-x-2 ">
                        <img
                            className="h-8 w-auto"
                            src={branding.company_icon || ''}
                            alt="Your Company"
                        />
                        <p className="text-xs leading-5 text-white md:order-1 md:mt-0">
                            Powered by Paywell &copy;
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}
