import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: string | null = null

export const OnboardingTypeSlice = createSlice({
    name: 'onboarding_type',
    initialState: initialState as string | null,

    reducers: {
        setOnboardingType: (
            state: string | null,
            action: PayloadAction<string | null>
        ) => {
            return action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setOnboardingType } = OnboardingTypeSlice.actions

export default OnboardingTypeSlice.reducer
