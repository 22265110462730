import { NotesRepository } from '../../api/NotesRepository'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { formatDate } from '../../utils/StringUtils'
import ErrorMessage from '../../shared-components/Error'
import Loading from '../../shared-components/Loading'
import PaginationV2 from '../../invoices/components/PaginationV2'
import { TrashIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import { useMutation } from 'react-query'
import DialogModal from '../../shared-components/DialogModal'
import { useAppDispatch } from '../../redux/hooks'
import { setError } from '../../redux/state-slices/GlobalNotificationSlice'

interface Note {
    userId: number | undefined
}
function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function Notes({ userId }: Note) {
    const notesRepository = new NotesRepository()
    const [searchParams] = useSearchParams()
    const page = searchParams.get('page') ? searchParams.get('page') : '1'
    const dispatch = useAppDispatch()

    const [confirmDeleteContactDialogOpen, setConfirmDeleteContactDialogOpen] =
        useState(false)

    const [selectedNoteId, setSelectedNoteId] = useState<number | null>(null)
    const getNotesQuery = useQuery(
        ['notes', page, userId],
        async () => {
            if (!userId) return
            return await notesRepository.getNotesForUser(page, userId)
        },
        {
            refetchOnWindowFocus: false,
        }
    )

    const deleteNoteMutation = useMutation({
        mutationFn: (deleteNoteId: number) => {
            if (userId) {
                return notesRepository.deleteNote(deleteNoteId, userId)
            }
            throw new Error('UserId is not provided')
        },
        onSuccess: () => {
            getNotesQuery.refetch()
        },
        onError: (error) => {
            dispatch(
                setError([
                    'There was an error deleting the note. Please try again.',
                ])
            )
        },
    })

    const handleDeleteContact = (contactId: number) => {
        setSelectedNoteId(contactId)
        setConfirmDeleteContactDialogOpen(true)
    }

    let notes = getNotesQuery.data?.data.data

    const confirmDeleteContact = () => {
        if (!selectedNoteId) return
        deleteNoteMutation.mutate(selectedNoteId)
    }

    const cancelDeleteContact = () => {
        setConfirmDeleteContactDialogOpen(false)
    }

    const total = getNotesQuery?.data?.data?.total
    const from = getNotesQuery?.data?.data?.from
    const to = getNotesQuery?.data?.data?.to
    const next = getNotesQuery.data?.data?.next_page_url
    const prev = getNotesQuery.data?.data?.prev_page_url
    const links = getNotesQuery?.data?.data?.links

    let notesError = getNotesQuery.isError
    let notesLoading = getNotesQuery.isLoading || getNotesQuery.isFetching

    if (notesLoading) {
        return (
            <div className="flex h-screen">
                <div className="m-auto">
                    <Loading width="8" height="8" />
                </div>
            </div>
        )
    }

    if (notesError) {
        return (
            <div className="flex h-screen justify-center mt-4">
                <div className="w-full p-4">
                    <ErrorMessage
                        title="There was an retrieving notes. Please try again."
                        message="Please reach out to one of our representatives if this error persists"
                    />
                </div>
            </div>
        )
    }
    return (
        <div className={`p-10 relative`}>
            {deleteNoteMutation.isLoading && (
                <div
                    id="loading-screen"
                    className=" w-full h-full absolute block top-0 left-0 bg-white opacity-75 z-50"
                >
                    <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
                        <Loading height={'8'} width={'8'} />
                    </span>
                </div>
            )}
            <div className="mb-2 flex justify-end">
                <Link
                    to={'new'}
                    type="button"
                    className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Add Note
                </Link>
            </div>

            <div className={'p-2'}>
                {notes &&
                    notes.length > 0 &&
                    notes.map((note, idx) => (
                        <div
                            key={note.id}
                            className="space-x-4 text-sm text-gray-500"
                        >
                            <div
                                className={classNames(
                                    idx === 0 ? '' : 'border-t border-gray-200',
                                    'py-10'
                                )}
                            >
                                <div className="flex items-center justify-between mb-2">
                                    <div
                                        className={
                                            'flex items-center space-x-2'
                                        }
                                    >
                                        <UserCircleIcon className="h-10 w-10 rounded-full bg-indigo-100" />

                                        <h3 className="font-medium text-lg text-gray-900">
                                            {note.created_by_user_name}
                                        </h3>
                                    </div>
                                    <div className={'flex space-x-2'}>
                                        <button
                                            disabled={
                                                deleteNoteMutation.isLoading
                                            }
                                            onClick={() =>
                                                handleDeleteContact(note.id)
                                            }
                                        >
                                            <TrashIcon className="fill-red-500 h-4 w-4 cursor-pointer text-white" />
                                        </button>
                                        <Link
                                            to={note.id + '/edit'}
                                            rel="noreferrer"
                                            className={
                                                'text-sm font-medium leading-6 text-blue-500'
                                            }
                                        >
                                            Edit
                                        </Link>
                                    </div>
                                </div>
                                <p className={'italic text-xs'}>
                                    Created:{' '}
                                    <time dateTime={note.created_at}>
                                        {formatDate(note.created_at)}
                                    </time>
                                </p>
                                <p className={'italic text-xs'}>
                                    Updated:{' '}
                                    <time dateTime={note.updated_at}>
                                        {formatDate(note.updated_at)}
                                    </time>
                                </p>

                                <p className="sr-only">out of 5 stars</p>

                                <div className="text-lg mt-4 text-gray-500 break-words">
                                    {note.value}
                                </div>
                            </div>
                        </div>
                    ))}

                {notes && notes.length === 0 && (
                    <div>
                        <p className={'italic'}>No notes found</p>
                    </div>
                )}
                <div className={'mt-4'}>
                    <PaginationV2
                        pages={links}
                        from={from}
                        to={to}
                        total={total}
                        next={next}
                        prev={prev}
                    />
                </div>
            </div>
            <DialogModal
                open={confirmDeleteContactDialogOpen}
                setOpen={setConfirmDeleteContactDialogOpen}
                title="Delete Note"
                description="Are you sure you want to delete this note? This action cannot be undone."
                confirmAction={confirmDeleteContact}
                cancelAction={cancelDeleteContact}
            />
        </div>
    )
}
