import { Navigate, Route, Routes } from 'react-router-dom'
import EftSchedules from './EftSchedules'
import EftBatches from './EftBatches'
import Efts from './Efts'

export default function EftsRoutes() {
    return (
        <Routes>
            <Route path="" element={<EftBatches />} />
            <Route path="/:id/eft-transactions" element={<EftSchedules />} />
            <Route path="/:id/efts" element={<Efts />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    )
}
