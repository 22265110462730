import { CurrencyDollarIcon, TrashIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { LineItemsRepository } from '../../api/LineIttemsRepository'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setError } from '../../redux/state-slices/GlobalNotificationSlice'
import {
    removeLineItem,
    updateInvoice,
    updateLineItem,
} from '../../redux/state-slices/InvoiceSlice'
import Loading from '../../shared-components/Loading'
import useDebounce from '../../utils/useDebounce'
import TaxModal from '../invoices/components/TaxModal'
import ProductModal from '../invoices/components/ProductModal'

export default function Line({
    index,
    invoice_id,
    item,
    products,
    taxes,
    lineitemUpdate,
}: {
    index: number
    invoice_id: number
    item: Paywell.API.V2.LineItems
    products: Paywell.API.V2.Products[]
    taxes: Paywell.API.V2.Taxes[]
    lineitemUpdate: (product: boolean, tax: boolean) => void
}) {
    const dispatch = useAppDispatch()
    const state = useAppSelector((state) => state)

    const [update, setUpdate] = useState(false)
    const [addTax, setAddTax] = useState(false)
    const [addProduct, setAddProduct] = useState(false)

    const lineItemsRepository = new LineItemsRepository()
    const lineDebounce = useDebounce(
        `${item.quantity}-${item.cost_per_unit}-${item.product_id}-${item.description}-${item.tax_id}`,
        500
    )
    const updateLineItemMutation = useMutation({
        mutationKey: 'invoiceLineitemUpdate',
        mutationFn: ({
            quantity,
            cost,
            product,
            tax,
            description,
        }: {
            quantity: number
            cost: string | number
            product: number
            tax: number
            description: string
        }) =>
            lineItemsRepository.updateLineItem(
                state.invoice.line_items[index].id,
                invoice_id,
                product,
                tax,
                quantity,
                cost,
                description
            ),
        onError: (err: any) => {
            if (err.data.message) dispatch(setError([err.data.message]))
            else
                dispatch(
                    setError([
                        'An error has occured. If this keeps happening, please contact support.',
                    ])
                )
        },
        onSuccess: (data) => {
            dispatch(
                updateLineItem([
                    {
                        index: index,
                        key: 'subtotal',
                        value: data?.data?.subtotal,
                    },
                    {
                        index: index,
                        key: 'tax_total',
                        value: data?.data?.tax_total,
                    },
                    {
                        index: index,
                        key: 'grand_total',
                        value: data?.data?.grand_total,
                    },
                ])
            )
            dispatch(
                updateInvoice({
                    subtotal: data?.data?.invoice_subtotal,
                    tax_total: data?.data?.invoice_tax_total,
                    grand_total: data?.data?.invoice_grand_total,
                })
            )
        },
    })

    const deleteLineItemMutation = useMutation({
        mutationKey: 'deleteLineItem',
        mutationFn: () =>
            lineItemsRepository.deleteLineItem(
                state.invoice.line_items[index].id,
                invoice_id
            ),
        onError: (data) => {
            console.log(data)
            dispatch(
                setError([
                    'An error has occured. If this keeps happening, please contact support.',
                ])
            )
        },

        onSuccess: (data) => {
            dispatch(removeLineItem(index))
            dispatch(
                updateInvoice({
                    subtotal: data?.data?.invoice_subtotal,
                    tax_total: data?.data?.invoice_tax_total,
                    grand_total: data?.data?.invoice_grand_total,
                })
            )
        },
    })

    const handleProductChange = (
        values: any,
        quantity: number,
        tax: number
    ) => {
        setUpdate(true)
        values = values.split('-')
        dispatch(
            updateLineItem([
                { index: index, key: 'product_id', value: values[0] },
                { index: index, key: 'cost_per_unit', value: values[1] },
            ])
        )
    }

    const updateLines = (value: any) => {
        setUpdate(true)
        dispatch(updateLineItem([value]))
    }

    const handleDescriptionChange = (e: any) => {
        setUpdate(true)
        if (e.target.value.length > 125) return
        dispatch(
            updateLineItem([
                { index: index, key: 'description', value: e.target.value },
            ])
        )
    }
    const onProductClose = () => {
        setAddProduct(false)
    }

    const onTaxClose = () => {
        setAddTax(false)
    }

    const onCallback = (data: any) => {
        lineitemUpdate(addProduct, addTax)
        setUpdate(true)
        setAddProduct(false)
        setAddTax(false)
        console.log(
            'id: ' + data.id + ' product: ' + addProduct + ' tax: ' + addTax
        )
        if (addProduct)
            handleProductChange(
                `${data.id}-${data.price}`,
                item.quantity,
                item.tax_id
            )

        if (addTax)
            updateLines({
                index: index,
                key: 'tax_id',
                value: data.id,
            })

        setTimeout(function () {
            setUpdate(false)
        }, 2000)
    }

    useEffect(() => {
        let quantity = item.quantity
        let cost = item.cost_per_unit
        let product = item.product_id
        let tax = item.tax_id
        let description = item.description

        if (update)
            updateLineItemMutation.mutate({
                quantity,
                cost,
                product,
                tax,
                description,
            })
    }, [lineDebounce])

    return (
        <>
            <TaxModal
                show={addTax}
                onClose={onTaxClose}
                callback={onCallback}
            />

            <ProductModal
                show={addProduct}
                onClose={onProductClose}
                callback={onCallback}
            />

            <tr>
                <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                    <input
                        type="number"
                        defaultValue={item.quantity}
                        onChange={(e) => {
                            updateLines({
                                index: index,
                                key: 'quantity',
                                value: e.target.value,
                            })
                        }}
                        className="focus:border-sky-600 focus:ring-sky-600"
                    />
                </td>
                <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                    <div className="relative mt-1 rounded-md h-15">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <CurrencyDollarIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </div>
                        <input
                            type="number"
                            name="price"
                            step="0.01"
                            id="price"
                            className="pl-10 focus:border-sky-600 focus:ring-sky-600"
                            value={item.cost_per_unit}
                            onChange={(e) => {
                                updateLines({
                                    index: index,
                                    key: 'cost_per_unit',
                                    value: e.target.value,
                                })
                            }}
                        />
                    </div>
                </td>

                <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                    <select
                        className="focus:border-sky-600 focus:ring-sky-600"
                        onChange={(e) => {
                            parseInt(e.target.value) == -1
                                ? setAddProduct(true)
                                : handleProductChange(
                                      e.target.value,
                                      item.quantity,
                                      item.tax_id
                                  )
                        }}
                    >
                        <option value={`-1`} data-title="new_product">
                            New Product
                        </option>
                        {products.map((product, index) => (
                            <option
                                value={`${product.id}-${product.price}`}
                                selected={product.id === item.product_id}
                                data-title={product.name}
                            >
                                {product.name}
                            </option>
                        ))}
                    </select>
                </td>
                <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                    <div className="relative mt-1 rounded-md h-15">
                        <textarea
                            id="description"
                            name="description"
                            className=" focus:border-sky-600 focus:ring-sky-600"
                            value={item.description}
                            onChange={(e) => handleDescriptionChange(e)}
                        />
                    </div>
                </td>
                <td className="px-6 py-4 text-sm font-medium text-left whitespace-nowrap">
                    <select
                        onChange={(e) => {
                            parseInt(e.target.value) == -1
                                ? setAddTax(true)
                                : updateLines({
                                      index: index,
                                      key: 'tax_id',
                                      value: e.target.value,
                                  })
                        }}
                        defaultValue={item.tax_id}
                        className="focus:border-sky-600 focus:ring-sky-600"
                    >
                        <option value={`-1`} data-title="new_product">
                            New Tax
                        </option>
                        {taxes.map((tax, index) => (
                            <option
                                value={tax.id}
                                selected={item.tax_id == tax.id ? true : false}
                            >
                                {tax.name}
                            </option>
                        ))}
                    </select>
                </td>
                <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap focus:border-sky-600 focus:ring-sky-600">
                    {updateLineItemMutation.isLoading ? (
                        <Loading width="5" height="5" />
                    ) : (
                        `$${item.subtotal}`
                    )}
                </td>
                <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap focus:border-sky-600 focus:ring-sky-600">
                    {updateLineItemMutation.isLoading ? (
                        <Loading width="5" height="5" />
                    ) : (
                        `$${item.tax_total}`
                    )}
                </td>
                <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap focus:border-sky-600 focus:ring-sky-600">
                    {updateLineItemMutation.isLoading ? (
                        <Loading width="5" height="5" />
                    ) : (
                        `$${item.grand_total}`
                    )}
                </td>
                <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap float-right focus:border-sky-600 focus:ring-sky-600">
                    <span className="h-7 w-7">
                        {deleteLineItemMutation.isLoading ? (
                            <Loading width="6" height="6" />
                        ) : (
                            <TrashIcon
                                className="fill-red-500 h-7 w-7 cursor-pointer"
                                onClick={() => deleteLineItemMutation.mutate()}
                            />
                        )}
                    </span>
                </td>
            </tr>
        </>
    )
}
