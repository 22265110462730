import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setBusinessInfo } from '../../redux/state-slices/BusinessInfoSlice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import useDebounce from '../../utils/useDebounce'
import { useMutation } from 'react-query'
import { RegistrationFormRepository } from '../../api/RegistrationFormRepository'
import {
    testParagraphRegex,
    validateMerchandiseSold,
    validateBusinessNum,
    validateWebsite,
    validateSaleAmount,
    validateDateYYYMMDD,
    validateBusinessFormation,
    validateBusinessInfo,
    validateBusinessName,
    testBusinessNameRegex,
    numOnlyRegex,
    testAlphaNumericRegex,
    validateMinLength,
    testAddressRegex,
    validateAddress,
    testCityRegex,
    validateCity,
    validatePostalCode,
} from '../../utils/RegexHelper'
import { setStep } from '../../redux/state-slices/StepSlice'
import Error from '../../shared-components/Error'
import Loading from '../../shared-components/Loading'

interface BusinessInfoValidAttributes {
    name: boolean | undefined
    business_formation: boolean | undefined
    merchandise_sold: boolean | undefined
    business_number: boolean | undefined
    website: boolean | undefined
    avg_ticket_amount: boolean | undefined
    highest_ticket_amount: boolean | undefined
    monthly_sales: boolean | undefined
    business_start_date: boolean | undefined
    nickname: boolean | undefined
    address: boolean | undefined
    city: boolean | undefined
    postal_code: boolean | undefined
    countries_id: boolean | undefined
    provinces_id: boolean | undefined
    jurisdiction: boolean | undefined
    predicted_monthly_sales: boolean | undefined
    requested_highest_ticket_amount: boolean | undefined
    lowest_ticket_amount: boolean | undefined
    proof_of_address_file_name: boolean | undefined
}

export default function BusinessInfo() {
    let navigate = useNavigate()
    const dispatch = useAppDispatch()
    const businessInfo = useAppSelector((state) => state.business_info)
    const authUser = useAppSelector((state) => state.auth_user)
    const steps = useAppSelector((state) => state.steps)
    const debounceValue = useDebounce(businessInfo, 1500)
    const registrationFormRepository = new RegistrationFormRepository()
    const [validForm, setValidForm] = useState(false)

    const [businessInfoValid, setBusinessInfoValid] =
        useState<BusinessInfoValidAttributes>({
            name: undefined,
            business_formation: undefined,
            merchandise_sold: undefined,
            business_number: undefined,
            website: undefined,
            avg_ticket_amount: undefined,
            highest_ticket_amount: undefined,
            monthly_sales: undefined,
            business_start_date: undefined,
            nickname: undefined,
            address: undefined,
            city: undefined,
            postal_code: undefined,
            countries_id: undefined,
            provinces_id: undefined,
            jurisdiction: undefined,
            predicted_monthly_sales: undefined,
            requested_highest_ticket_amount: undefined,
            lowest_ticket_amount: undefined,
            proof_of_address_file_name: undefined,
        })

    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setStepState()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validForm])

    useEffect(() => {
        validateForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessInfo])

    useEffect(() => {
        saveFormValuesMutation.mutate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceValue])

    const saveFormValuesMutation = useMutation({
        mutationFn: () =>
            registrationFormRepository.postBusinessInfo(
                businessInfo,
                authUser?.id
            ),
    })

    const submitFormValuesMutation = useMutation({
        mutationFn: () =>
            registrationFormRepository.postBusinessInfo(
                businessInfo,
                authUser?.id
            ),
        onSuccess: () => {
            navigate('/onboarding/etransfer/banking-info')
        },

        onError: () => {},
    })

    const uploadBusinessRegMutation = useMutation({
        mutationFn: (formData: FormData) =>
            registrationFormRepository.uploadBusinessRegistration(formData),

        onSuccess: (data) => {},

        onError: () => {
            setBusinessInfo({
                ...businessInfo,
                business_registration_file_name: null,
            })
        },
    })

    const uploadProofOfAddressMutation = useMutation({
        mutationFn: (formData: FormData) =>
            registrationFormRepository.uploadProofOfAddress(formData),

        onSuccess: (data) => {},

        onError: () => {
            setBusinessInfo({
                ...businessInfo,
                business_registration_file_name: null,
            })
        },
    })

    const setStepState = () => {
        if (steps[1].status !== 'complete' || !validForm) {
            dispatch(setStep(1))
        }
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (validForm) {
            submitFormValuesMutation.mutate()
        }
    }

    const validateForm = () => {
        setValidForm(validateBusinessInfo(businessInfo))
    }

    const handleNameChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        if ((val.length < 50 && testBusinessNameRegex(val)) || val === '') {
            setBusinessInfoValid({
                ...businessInfoValid,
                name: validateBusinessName(val),
            })

            dispatch(setBusinessInfo({ ...businessInfo, name: val }))
        }
    }

    const handleBusinessFormationChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value

        setBusinessInfoValid({
            ...businessInfoValid,
            business_formation: validateBusinessFormation(val),
        })

        dispatch(
            setBusinessInfo({
                ...businessInfo,
                business_formation: val,
            })
        )
    }

    const handleMerchandiseSoldChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        if ((val.length < 75 && testParagraphRegex(val)) || val === '') {
            setBusinessInfoValid({
                ...businessInfoValid,
                merchandise_sold: validateMerchandiseSold(val),
            })
            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    merchandise_sold: val,
                })
            )
        }
    }

    const handleBusinessNumChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        if ((val.length < 10 && numOnlyRegex(val)) || val === '') {
            setBusinessInfoValid({
                ...businessInfoValid,
                business_number: validateBusinessNum(val),
            })

            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    business_number: val,
                })
            )
        }
    }

    const handleWebsiteChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value

        if (val.length < 25) {
            setBusinessInfoValid({
                ...businessInfoValid,
                website: validateWebsite(val),
            })

            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    website: val,
                })
            )
        }
    }

    const handleAvgTicketSaleChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value

        if (val.length < 9) {
            setBusinessInfoValid({
                ...businessInfoValid,
                avg_ticket_amount: validateSaleAmount(val),
            })
            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    avg_ticket_amount: val ? parseInt(val) : null,
                })
            )
        }
    }

    const handleHighestTicketAmountChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value

        if (val.length < 9) {
            setBusinessInfoValid({
                ...businessInfoValid,
                highest_ticket_amount: validateSaleAmount(val),
            })

            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    highest_ticket_amount: val ? parseInt(val) : null,
                })
            )
        }
    }

    const handleMonthlySalesChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value

        if (val.length < 9) {
            setBusinessInfoValid({
                ...businessInfoValid,
                monthly_sales: validateSaleAmount(val),
            })

            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    monthly_sales: val ? parseInt(val) : null,
                })
            )
        }
    }

    const handleBusinessStartDateChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        setBusinessInfoValid({
            ...businessInfoValid,
            business_start_date: validateDateYYYMMDD(val),
        })
        dispatch(
            setBusinessInfo({
                ...businessInfo,
                business_start_date: e.target.value,
            })
        )
    }

    const handleBusinessRegFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formData = new FormData()

        if (e.target.files?.length === 1) {
            let fileName = e.target.files[0].name

            formData.append('businessregistration', e.target.files[0], fileName)

            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    business_registration_file_name: fileName,
                })
            )

            uploadBusinessRegMutation.mutate(formData)
        }
    }

    const handleProofOfAddressFile = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const formData = new FormData()

        if (e.target.files?.length === 1) {
            let fileName = e.target.files[0].name

            formData.append('proofofaddress', e.target.files[0], fileName)

            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    proof_of_address_file_name: fileName,
                })
            )

            uploadProofOfAddressMutation.mutate(formData)
        }
    }

    const handleDbaChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        if (val.length < 50) {
            setBusinessInfoValid({
                ...businessInfoValid,
                nickname: validateMinLength(val),
            })

            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    nickname: val,
                })
            )
        }
    }

    const handleBusinessAddressChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value

        if ((testAddressRegex(val) && val.length < 40) || val === '') {
            setBusinessInfoValid({
                ...businessInfoValid,
                address: validateAddress(val),
            })
            dispatch(setBusinessInfo({ ...businessInfo, address: val }))
        }
    }

    const handleBusinessCityChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value

        if (testCityRegex(val) && val.length < 30) {
            setBusinessInfoValid({
                ...businessInfoValid,
                city: validateCity(val),
            })
            dispatch(setBusinessInfo({ ...businessInfo, city: val }))
        }
    }

    const handlePostalCodeChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        if (val.length <= 6 && testAlphaNumericRegex(val)) {
            setBusinessInfoValid({
                ...businessInfoValid,
                postal_code: validatePostalCode(val),
            })
            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    postal_code: val,
                })
            )
        }
    }
    const handleCountryChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        if (!val) {
            setBusinessInfoValid({
                ...businessInfoValid,
                countries_id: false,
            })
        } else {
            setBusinessInfoValid({
                ...businessInfoValid,
                countries_id: true,
            })
        }
        dispatch(
            setBusinessInfo({ ...businessInfo, countries_id: e.target.value })
        )
    }

    const handleProvincesChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        if (!val) {
            setBusinessInfoValid({
                ...businessInfoValid,
                provinces_id: false,
            })
        } else {
            setBusinessInfoValid({
                ...businessInfoValid,
                provinces_id: true,
            })
        }
        dispatch(
            setBusinessInfo({ ...businessInfo, provinces_id: e.target.value })
        )
    }

    const handleJurisdictionChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        if (val.length < 100) {
            if (!val || !validateMinLength(val)) {
                setBusinessInfoValid({
                    ...businessInfoValid,
                    jurisdiction: false,
                })
            } else {
                setBusinessInfoValid({
                    ...businessInfoValid,
                    jurisdiction: true,
                })
            }

            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    jurisdiction: e.target.value,
                })
            )
        }
    }

    const handlePredictedMonthlySalesChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value

        if (val.length < 9) {
            setBusinessInfoValid({
                ...businessInfoValid,
                predicted_monthly_sales: validateSaleAmount(val),
            })
            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    predicted_monthly_sales: val ? parseInt(val) : null,
                })
            )
        }
    }

    const handleHighestRequestedTicketAmountChange = (
        e: React.ChangeEvent<any>
    ) => {
        let val = e.target.value

        if (val.length < 9) {
            setBusinessInfoValid({
                ...businessInfoValid,
                requested_highest_ticket_amount: validateSaleAmount(val),
            })
            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    requested_highest_ticket_amount: val ? parseInt(val) : null,
                })
            )
        }
    }

    const handleLowestTicketAmountChange = (e: React.FocusEvent<any>) => {
        let val = e.target.value

        if (val.length < 9) {
            setBusinessInfoValid({
                ...businessInfoValid,
                lowest_ticket_amount: validateSaleAmount(val),
            })
            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    lowest_ticket_amount: val ? parseInt(val) : null,
                })
            )
        }
    }

    const handleInvalidClass = (key: keyof BusinessInfoValidAttributes) => {
        if (businessInfoValid[key] === false) {
            return '!border-2 !border-rose-500'
        }

        return ''
    }

    return (
        <form
            className="space-y-8 divide-y divide-gray-200"
            onSubmit={handleSubmit}
        >
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div className="space-y-6 sm:space-y-5">
                    <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Business Information
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            This information will be used to verify your
                            business
                        </p>
                    </div>
                </div>

                <div className="">
                    <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Corporate / Legal Business Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={businessInfo.name || ''}
                                    onChange={handleNameChange}
                                    type="text"
                                    name="name"
                                    id="name"
                                    autoComplete="given-name"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm  sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'name'
                                    )}`}
                                />
                                {businessInfoValid.name === false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-business-name"
                                    >
                                        Minimum 2 characters required
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="dba"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                DBA (Doing Business As)
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={businessInfo.nickname || ''}
                                    onChange={handleDbaChange}
                                    type="text"
                                    name="dba"
                                    id="dba"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm  sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'nickname'
                                    )}`}
                                />
                                {businessInfoValid.nickname === false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-business-name"
                                    >
                                        Minimum 2 characters required
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="business_address"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Business Address
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={businessInfo.address || ''}
                                    onChange={handleBusinessAddressChange}
                                    type="text"
                                    name="business_address"
                                    id="business_address"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm  sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'address'
                                    )}`}
                                />
                                {businessInfoValid.address === false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-business-address"
                                    >
                                        Minimum 2 characters required
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="business_address"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Business City
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={businessInfo.city || ''}
                                    onChange={handleBusinessCityChange}
                                    type="text"
                                    name="business_address"
                                    id="business_address"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm  sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'city'
                                    )}`}
                                />
                                {businessInfoValid.city === false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-business-address"
                                    >
                                        Minimum 2 characters required
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="postal_code"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Postal Code
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={businessInfo.postal_code || ''}
                                    onChange={handlePostalCodeChange}
                                    type="text"
                                    name="postal_code"
                                    id="postal_code"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm  sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'postal_code'
                                    )}`}
                                />
                                {businessInfoValid.postal_code === false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-postal-code"
                                    >
                                        Minimum 5 characters required
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="country"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Country
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <select
                                    required
                                    value={businessInfo.countries_id || ''}
                                    onChange={handleCountryChange}
                                    id="country"
                                    name="country"
                                    autoComplete="country-name"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'countries_id'
                                    )}`}
                                >
                                    <option value="1">Canada</option>
                                    <option value="2">United States</option>
                                </select>
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="province"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Province
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <select
                                    required
                                    onChange={handleProvincesChange}
                                    value={businessInfo.provinces_id || ''}
                                    name="province"
                                    id="province"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'provinces_id'
                                    )}`}
                                >
                                    <option value="">Select</option>
                                    <option value="1">British Columbia</option>
                                    <option value="2">Alberta</option>
                                    <option value="3">Saskatchewan</option>
                                    <option value="4">Manitoba</option>
                                    <option value="5">Ontario</option>
                                    <option value="6">Quebec</option>
                                    <option value="7">New Brunswick</option>
                                    <option value="8">PEI</option>
                                    <option value="9">Nova Scotia</option>
                                    <option value="10">NFLD</option>
                                    <option value="11">Yukon</option>
                                    <option value="12">NWT</option>
                                    <option value="13">Nunavut</option>
                                    <option value="14">United States</option>
                                </select>
                                {businessInfoValid.provinces_id === false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-province"
                                    >
                                        Please select a province
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="jurisdiction"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Jurisdiction of Operation
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <textarea
                                    value={businessInfo.jurisdiction || ''}
                                    onChange={handleJurisdictionChange}
                                    name="jurisdiction"
                                    id="jurisdiction"
                                    rows={3}
                                    className={`block w-full max-w-xs rounded-md border-gray-300 shadow-sm  sm:text-sm ${handleInvalidClass(
                                        'jurisdiction'
                                    )}`}
                                />
                                {businessInfoValid.jurisdiction === false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-business-address"
                                    >
                                        Minimum 2 characters required
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="business-formation"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Business Formation
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <select
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm  sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'business_formation'
                                    )}`}
                                    id="business-formation"
                                    name="business-formation"
                                    value={
                                        businessInfo.business_formation || ''
                                    }
                                    onChange={handleBusinessFormationChange}
                                >
                                    <option value="association_estate_trust">
                                        Association/Estate/Trust
                                    </option>
                                    <option value="corporation">
                                        Corporation
                                    </option>
                                    <option value="international_organization">
                                        International Organization
                                    </option>
                                    <option value="llc">LLC</option>
                                    <option value="medical_legal_corporation">
                                        Medical/Legal Corporation
                                    </option>
                                    <option value="non_profit">
                                        Non-Profit
                                    </option>
                                    <option value="partnership">
                                        Partnership
                                    </option>
                                    <option value="sole_proprietorship">
                                        Sole Proprietorship
                                    </option>
                                    <option value="tax_exempt_organization">
                                        Tax Exempt Organization
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="services"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Merchandise/Services Sold
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <textarea
                                    value={businessInfo.merchandise_sold || ''}
                                    onChange={handleMerchandiseSoldChange}
                                    name="services"
                                    id="services"
                                    rows={3}
                                    className={`block w-full max-w-xs rounded-md border-gray-300 shadow-sm  sm:text-sm ${handleInvalidClass(
                                        'merchandise_sold'
                                    )}`}
                                />
                                <p className="mt-2 text-sm text-gray-500">
                                    Write a few sentences describing the
                                    services offered / merchandise sold
                                </p>
                                {businessInfoValid.merchandise_sold ===
                                    false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-merchandise-sold"
                                    >
                                        Minimum 3 characters required
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="business-number"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Federal Tax ID or Business Number (ex: BN, EIN,
                                HST/GST, VAT) (Optional)
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={businessInfo.business_number || ''}
                                    onChange={handleBusinessNumChange}
                                    type="text"
                                    name="business-number"
                                    id="business-number"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm  sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'business_number'
                                    )}`}
                                />
                                {businessInfoValid.business_number ===
                                    false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-business-num"
                                    >
                                        Minimum 9 characters required
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="website"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Website
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <div className="flex max-w-lg rounded-md">
                                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm shadow-sm">
                                        https://
                                    </span>
                                    <input
                                        value={businessInfo.website || ''}
                                        onChange={handleWebsiteChange}
                                        type="text"
                                        name="website"
                                        id="website"
                                        className={`block w-full max-w-lg rounded-r-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                            'website'
                                        )}`}
                                    />
                                </div>
                                {businessInfoValid.website === false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-website"
                                    >
                                        Please input a valid website
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="avg-ticket-sales"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Average Ticket (Sale) Amount ($)
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={businessInfo.avg_ticket_amount || ''}
                                    onChange={handleAvgTicketSaleChange}
                                    type="text"
                                    name="avg-ticket-sales"
                                    id="avg-ticket-sales"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm  sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'avg_ticket_amount'
                                    )}`}
                                />
                                {businessInfoValid.avg_ticket_amount ===
                                    false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-avg-ticket"
                                    >
                                        Please input a valid value. Value must
                                        be greater than 0
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="highest-ticket-sales"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Highest Ticket (Sale) Amount ($)
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={
                                        businessInfo.highest_ticket_amount || ''
                                    }
                                    onChange={handleHighestTicketAmountChange}
                                    type="text"
                                    name="highest-ticket-sales"
                                    id="highest-ticket-sales"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'highest_ticket_amount'
                                    )}`}
                                />
                                {businessInfoValid.highest_ticket_amount ===
                                    false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-highest-ticket"
                                    >
                                        Please input a valid value. Value must
                                        be greater than 0
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="monthly-ticket-sales"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Monthly Sales Volume (All Sales) ($)
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={businessInfo.monthly_sales || ''}
                                    onChange={handleMonthlySalesChange}
                                    type="text"
                                    name="monthly-ticket-sales"
                                    id="monthly-ticket-sales"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'monthly_sales'
                                    )}`}
                                />
                                {businessInfoValid.monthly_sales === false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-monthly-sales"
                                    >
                                        Please input a valid value. Value must
                                        be greater than 0
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="predicted_monthly_sales"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Predicted Monthly Sales ($)
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={
                                        businessInfo.predicted_monthly_sales ||
                                        ''
                                    }
                                    onChange={handlePredictedMonthlySalesChange}
                                    type="text"
                                    name="predicted_monthly_sales"
                                    id="predicted_monthly_sales"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'predicted_monthly_sales'
                                    )}`}
                                />
                                {businessInfoValid.predicted_monthly_sales ===
                                    false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-predicted-monthly-sales"
                                    >
                                        Please input a valid value. Value must
                                        be greater than 0
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="requested_highest_ticket_amount"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Requested Highest Ticket Sale ($)
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={
                                        businessInfo.requested_highest_ticket_amount ||
                                        ''
                                    }
                                    onChange={
                                        handleHighestRequestedTicketAmountChange
                                    }
                                    type="text"
                                    name="requested_highest_ticket_amount"
                                    id="requested_highest_ticket_amount"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'requested_highest_ticket_amount'
                                    )}`}
                                />
                                {businessInfoValid.requested_highest_ticket_amount ===
                                    false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-requested-highest-ticket-amount"
                                    >
                                        Please input a valid value. Value must
                                        be greater than 0
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="lowest_ticket_amount"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Lowest Ticket Sale ($)
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={
                                        businessInfo.lowest_ticket_amount || ''
                                    }
                                    onChange={handleLowestTicketAmountChange}
                                    type="text"
                                    name="lowest_ticket_amount"
                                    id="lowest_ticket_amount"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'lowest_ticket_amount'
                                    )}`}
                                />
                                {businessInfoValid.lowest_ticket_amount ===
                                    false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-lowest-ticket-amount"
                                    >
                                        Please input a valid value. Value must
                                        be greater than 0
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="street-address"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Business Start Date
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={
                                        businessInfo.business_start_date?.toString() ||
                                        ''
                                    }
                                    onChange={handleBusinessStartDateChange}
                                    type="date"
                                    name="street-address"
                                    id="street-address"
                                    className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                        'business_start_date'
                                    )}`}
                                />
                                {businessInfoValid.business_start_date ===
                                    false && (
                                    <p
                                        className="mt-2 text-sm text-red-600"
                                        id="invalid-business-start-date"
                                    >
                                        Please input a valid date
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                        htmlFor="photo"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Business Registration or License{' '}
                        {businessInfo.business_formation ===
                            'sole_proprietorship' && '(Optional)'}
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <div className="flex items-center">
                            <div className="sm:col-span-6">
                                <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                    <div className="space-y-1 text-center">
                                        <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <div className="flex text-sm text-gray-600">
                                            <label
                                                htmlFor="file-upload"
                                                className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>Upload a file</span>
                                                <input
                                                    required={
                                                        businessInfo.business_registration_file_name ||
                                                        businessInfo.business_formation ===
                                                            'sole_proprietorship'
                                                            ? false
                                                            : true
                                                    }
                                                    id="file-upload"
                                                    name="file-upload"
                                                    type="file"
                                                    className="sr-only"
                                                    onChange={
                                                        handleBusinessRegFile
                                                    }
                                                    accept=".jpg,.png,.pdf,.jpeg"
                                                />
                                            </label>
                                            <p className="pl-1">
                                                or drag and drop
                                            </p>
                                        </div>
                                        <p className="text-xs text-gray-500">
                                            PNG, JPG, PDF, up to 5MB
                                        </p>
                                        {businessInfo.business_registration_file_name ? (
                                            <p className="text-lg text-black-500 mt-100 border-solid border-2">
                                                {
                                                    businessInfo.business_registration_file_name
                                                }
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <div>
                        <label
                            htmlFor="proof-of-address-photo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                        >
                            Proof of Address
                        </label>
                        <p className="text-gray-500 text-sm">
                            Upload a recent utility bill, bank statement, or
                            government-issued letter (max. 3 months old) showing
                            the name of your business, address, and issue date.
                        </p>
                    </div>

                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <div className="flex items-center">
                            <div className="sm:col-span-6">
                                <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                    <div className="space-y-1 text-center">
                                        <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <div className="flex text-sm text-gray-600">
                                            <label
                                                htmlFor="proof-of-address-file-upload"
                                                className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>Upload a file</span>
                                                <input
                                                    required={
                                                        businessInfo.proof_of_address_file_name
                                                            ? false
                                                            : true
                                                    }
                                                    id="proof-of-address-file-upload"
                                                    name="proof-of-address-file-upload"
                                                    type="file"
                                                    className="sr-only"
                                                    onChange={
                                                        handleProofOfAddressFile
                                                    }
                                                    accept=".jpg,.png,.pdf,.jpeg"
                                                />
                                            </label>
                                            <p className="pl-1">
                                                or drag and drop
                                            </p>
                                        </div>
                                        <p className="text-xs text-gray-500">
                                            PNG, JPG, PDF, up to 5MB
                                        </p>
                                        {businessInfo.proof_of_address_file_name ? (
                                            <p className="text-lg text-black-500 mt-100 border-solid border-2">
                                                {
                                                    businessInfo.proof_of_address_file_name
                                                }
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-5">
                <div className="flex justify-start">
                    {!submitFormValuesMutation.isLoading && (
                        <button
                            disabled={!validForm}
                            type="submit"
                            className="ml-3 inline-flex disabled:bg-gray-400 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            Save and Continue
                        </button>
                    )}
                    {submitFormValuesMutation.isLoading && (
                        <Loading width="8" height="8" />
                    )}
                </div>
                {submitFormValuesMutation.isError && (
                    <div className="md:w-2/5 mt-4">
                        <Error
                            title="There was an error saving"
                            message="Please reach out to one of our representatives if this error persists"
                        />
                    </div>
                )}
            </div>
        </form>
    )
}
