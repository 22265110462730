import { ReceiptPercentIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { TaxRepository } from '../../api/TaxRepository'
import { useAppDispatch } from '../../redux/hooks'
import {
    setError,
    setSuccess,
} from '../../redux/state-slices/GlobalNotificationSlice'
import ErrorV2 from '../../shared-components/ErrorV2'
import Loading from '../../shared-components/Loading'
import { SentryUtils } from '../../utils/SentryUtils'
import { validateTaxName, validateTaxRate } from '../../utils/RegexHelper'

export default function TaxesEdit() {
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const taxesRepository = new TaxRepository()
    const [validations, setValidations] = useState({
        tax_name: { value: '', validated: true },
        tax_rate: { value: '', validated: true },
    })

    const taxQuery = useQuery(['tax', id], async () => {
        return await taxesRepository.getTax(
            SentryUtils.expect<number>(id ? parseInt(id) : 0)
        )
    })

    const tax = taxQuery.data?.data

    const updateTaxMutation = useMutation({
        mutationFn: () =>
            taxesRepository.updateTax(
                id ? parseInt(id) : 0,
                validations.tax_name.value,
                validations.tax_rate.value
            ),
        onError: (err: any) => {
            if (err.data.message) dispatch(setError([err.data.message]))
            else
                dispatch(
                    setError([
                        'An error has occured. If this keeps happening, please contact support',
                    ])
                )
        },

        onSuccess: (data) => {
            navigate(`/invoicing/taxes/${id}`)
            dispatch(setSuccess([`Your tax rate has been updated`]))
        },
    })

    const validate = (data: Paywell.API.V2.ValidationData[]) => {
        data.forEach((v) => {
            setValidations((prevValidations) => ({
                ...prevValidations,
                [v.name]: { value: v.value, validated: v.f(v.value) },
            }))
        })
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        updateTaxMutation.mutate()
    }

    useEffect(() => {
        if (tax) {
            validate([
                {
                    value: tax?.name,
                    f: validateTaxName,
                    name: 'tax_name',
                },
                {
                    value: tax?.tax,
                    f: validateTaxRate,
                    name: 'tax_rate',
                },
            ])
        }
    }, [tax])

    if (taxQuery.isLoading) {
        return (
            <div>
                <Loading width="8" height="8" />
            </div>
        )
    }

    if (taxQuery.isError)
        return (
            <div>
                <ErrorV2
                    title="An error has occured"
                    message="An error has occurred while loading your tax rate. Please try refreshing, if this keeps happening contact support."
                />
            </div>
        )

    return (
        <form className="" onSubmit={(e) => handleSubmit(e)}>
            <div className="sm:flex ">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">
                        Update Tax Rate
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Update your Tax Rate(s)
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <div className="pt-5">
                        <div className="flex justify-end">
                            <Link
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2"
                                to={'/invoicing/taxes'}
                            >
                                Cancel
                            </Link>
                            <button
                                type="submit"
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2"
                                disabled={updateTaxMutation.isLoading}
                            >
                                {updateTaxMutation.isLoading ? (
                                    <Loading height="6" width="6" />
                                ) : (
                                    'Save'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div className="space-y-6 sm:space-y-5">
                        <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Tax Information
                                </h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                    Give your tax rate a name and percentage{' '}
                                </p>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="country"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Tax rate and name
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 flex flex-col gap-4">
                                        <input
                                            type="text"
                                            name="tax-name"
                                            id="tax-name"
                                            placeholder="Tax Name"
                                            defaultValue={tax?.name}
                                            className={`block w-full max-w-lg rounded-md ${
                                                validations.tax_name.validated
                                                    ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                    : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                            } `}
                                            pattern="[a-zA-Z0-9\s]+"
                                            title="Please enter only letters, numbers, and spaces"
                                            maxLength={40}
                                            onKeyUp={(e) =>
                                                validate([
                                                    {
                                                        value: (
                                                            e.target as HTMLInputElement
                                                        ).value,
                                                        f: validateTaxName,
                                                        name: 'tax_name',
                                                    },
                                                ])
                                            }
                                            required
                                        />
                                        <div className="flex gap-4">
                                            <div>
                                                <div className="relative mt-1 rounded-md shadow-sm">
                                                    <input
                                                        type="number"
                                                        step="0.01"
                                                        name="rate"
                                                        id="rate"
                                                        className={`block w-full rounded-md ${
                                                            validations.tax_rate
                                                                .validated
                                                                ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                                : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                        } `}
                                                        placeholder="0.00"
                                                        defaultValue={tax?.tax}
                                                        aria-describedby="rate"
                                                        pattern="^(100(\.00?)?|\d{0,2}(\.\d{1,2})?)$"
                                                        title="Please enter a number between 0 and 100 with up to two decimal places"
                                                        min={0}
                                                        max={100}
                                                        onKeyUp={(e) =>
                                                            validate([
                                                                {
                                                                    value: (
                                                                        e.target as HTMLInputElement
                                                                    ).value,
                                                                    f: validateTaxRate,
                                                                    name: 'tax_rate',
                                                                },
                                                            ])
                                                        }
                                                        required
                                                    />
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                        <ReceiptPercentIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
