import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosRequestConfig } from 'axios'
//import type { RootState } from '../store'

const initialState: Paywell.ReduxStore.Auth.User | null = null

export const AuthUserSlice = createSlice({
    name: 'auth_user',
    initialState: initialState as Paywell.ReduxStore.Auth.User | null,
    reducers: {
        setLoggedIn: (
            state: Paywell.ReduxStore.Auth.User | null,
            action: PayloadAction<Paywell.ReduxStore.Auth.User>
        ) => {
            return action.payload
        },
        setLoggedOut: (state) => {
            // We make the api call manually because we're not good enough to make the logout repository work here lol
            let config: AxiosRequestConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                withCredentials: true,
            }
            axios
                .post(
                    process.env.REACT_APP_API_BASE_URL + '/api/logout',
                    {},
                    config
                )
                .then((response) => console.log(response))
                .catch((err) => console.log(err))
            return null
        },
    },
})

// Action creators are generated for each case reducer function
export const { setLoggedIn, setLoggedOut } = AuthUserSlice.actions
/* export const selectCount = (state: RootState) => state.user.
 */

export default AuthUserSlice.reducer
