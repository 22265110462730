import Loading from '../shared-components/Loading'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserRepository } from '../api/UserRepositoy'
import { useMutation } from 'react-query'
import { testEmailRegex } from '../utils/RegexHelper'
import { useAppDispatch } from '../redux/hooks'

interface Props {
    setShowCreateUserModal: Function
}

export default function NewUser({ setShowCreateUserModal }: Props) {
    const navigate = useNavigate()
    const userRepository = new UserRepository()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [name, setName] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [validName, setValidName] = useState<undefined | boolean>(undefined)
    const [validEmail, setValidEmail] = useState(false)
    const [validPassword, setValidPassword] = useState(false)
    const [validConfirmPassword, setValidConfirmPassword] = useState(false)
    const [errorMessage, setErrorMessageMessage] = useState('')
    const validForm =
        validName && validEmail && validPassword && validConfirmPassword

    useEffect(() => {
        generateRandomPassword()
    }, [])

    useEffect(() => {
        validateForm()
    }, [email, password, name, confirmPassword])

    const generateRandomPassword = () => {
        let randomPassword = Math.random().toString(36).slice(-8)
        setPassword(randomPassword)
        setConfirmPassword(randomPassword)
    }
    const validateForm = () => {
        if (testEmailRegex(email)) {
            setValidEmail(true)
        } else {
            setValidEmail(false)
        }

        if (password.length > 7) {
            setValidPassword(true)
        } else {
            setValidPassword(false)
        }

        if (name.length > 2) {
            setValidName(true)
        } else {
            setValidName(false)
        }

        if (password !== confirmPassword) {
            setValidConfirmPassword(false)
        } else {
            setValidConfirmPassword(true)
        }
    }

    const createUser = async () => {
        const user = await userRepository.createNewUser(
            name,
            email,
            password,
            confirmPassword
        )
        return user.data
    }

    const createUserMutation = useMutation(() => createUser(), {
        onSuccess: (data) => {
            let userId = data.id
            navigate(`/users/${userId}/info`)
        },
        onError: (error: any) => {
            if (error.status === 422) {
                if (error.data.message) {
                    setErrorMessageMessage(error.data.message)
                }
            }
        },
    })

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (val.length < 40) {
            setEmail(val)
        }
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (val.length < 50) {
            setPassword(val)
        }
    }

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (val.length < 50) {
            setName(val)
        }
    }

    const handleConfirmPasswordChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (val.length < 50) {
            setConfirmPassword(val)
        }
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setErrorMessageMessage('')
        createUserMutation.mutate()
    }

    const invalidClass =
        '!border-2 !border-rose-500 focus:ring-rose-500 focus:border-rose-500'

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                                <img
                                    className="mx-auto h-10 w-auto"
                                    src="./icon-only.png"
                                    alt="Your Company"
                                />
                                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                    Create a new user
                                </h2>
                            </div>

                            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                                <form
                                    className="space-y-6"
                                    action="#"
                                    method="POST"
                                    onSubmit={handleSubmit}
                                >
                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Email address
                                        </label>

                                        <div className="mt-2">
                                            <input
                                                id="email"
                                                onChange={handleEmailChange}
                                                name="email"
                                                type="email"
                                                value={email}
                                                autoComplete="email"
                                                required
                                                className={`${
                                                    email && !validEmail
                                                        ? invalidClass
                                                        : ''
                                                } block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="name"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Business Name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="name"
                                                onChange={handleNameChange}
                                                name="name"
                                                type="text"
                                                value={name}
                                                required
                                                className={`${
                                                    name && !validName
                                                        ? invalidClass
                                                        : ''
                                                } block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex items-center justify-between">
                                            <label
                                                htmlFor="password"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Password
                                            </label>
                                        </div>
                                        <div className="mt-2">
                                            <input
                                                id="password"
                                                onChange={handlePasswordChange}
                                                name="password"
                                                type="password"
                                                value={password}
                                                required
                                                className={`${
                                                    password && !validPassword
                                                        ? invalidClass
                                                        : ''
                                                } block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex items-center justify-between">
                                            <label
                                                htmlFor="password-confirm"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Confirm Password
                                            </label>
                                        </div>
                                        <div className="mt-2">
                                            <input
                                                id="password-confirm"
                                                onChange={
                                                    handleConfirmPasswordChange
                                                }
                                                name="password-confirm"
                                                type="password"
                                                value={confirmPassword}
                                                required
                                                className={`${
                                                    confirmPassword &&
                                                    !validConfirmPassword
                                                        ? invalidClass
                                                        : ''
                                                } block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                            />
                                            {!validConfirmPassword && (
                                                <p className="mt-2 text-sm text-rose-500">
                                                    Passwords do not match
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            'flex justify-between space-x-2'
                                        }
                                    >
                                        {!createUserMutation.isLoading && (
                                            <>
                                                <button
                                                    onClick={() => {
                                                        setShowCreateUserModal(
                                                            false
                                                        )
                                                    }}
                                                    type="button"
                                                    className="flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    disabled={!validForm}
                                                    type="submit"
                                                    className="disabled:bg-gray-400 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    Create
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </form>

                                {errorMessage && (
                                    <div className="flex justify-center mt-4 text-sm text-rose-500">
                                        {errorMessage}
                                    </div>
                                )}

                                {createUserMutation.isError &&
                                    !errorMessage && (
                                        <div className="flex justify-center mt-4 text-sm text-rose-500">
                                            An error occurred. Please try again.
                                        </div>
                                    )}

                                {createUserMutation.isLoading &&
                                    !createUserMutation.isSuccess &&
                                    !createUserMutation.isError && (
                                        <div className={'flex justify-center'}>
                                            <Loading height={'8'} width={'8'} />
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
