import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { CustomersRepository } from '../../../api/CustomersRepository'
import ErrorV2 from '../../../shared-components/ErrorV2'
import Loading from '../../../shared-components/Loading'
import { SentryUtils } from '../../../utils/SentryUtils'

export default function CustomerDetails() {
    const { id } = useParams()
    const customersRepository = new CustomersRepository()
    const customerQuery = useQuery(['customer', id], async () => {
        return (
            await customersRepository.getCustomer(
                SentryUtils.expect<number>(id ? parseInt(id) : 0)
            )
        ).data
    })

    const customer = customerQuery.data
    const customerEmails = customer?.emails
        ? JSON.parse(customer?.emails)
        : [{ email: 'No email' }]

    if (customerQuery.isLoading) {
        return (
            <div>
                <Loading width="8" height="8" />
            </div>
        )
    }

    if (customerQuery.isError)
        return (
            <div>
                <ErrorV2
                    title="An error has occured"
                    message="An error has occurred while loading your invoice. Please try refreshing, if this keeps happening contact support."
                />
            </div>
        )
    return (
        <div>
            <div className="float-right ">
                <Link
                    to={`/invoicing/customers/edit/${id}`}
                    type="submit"
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    Edit
                </Link>
            </div>
            <div className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div className="space-y-6 sm:space-y-5">
                        <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                            <div className="space-y-6 sm:space-y-5">
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Full Name
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                        <span className="rounded-lg border border-gray-300 bg-slate-50 px-2 py-2 w-2/3">
                                            {customer?.billing_name}
                                        </span>
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Email address
                                    </label>

                                    <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                        <div className="flex flex-col justify-between w-full">
                                            <div className="flex-col gap-4">
                                                {customerEmails &&
                                                    customerEmails.map(
                                                        (
                                                            email: any,
                                                            index: number
                                                        ) => (
                                                            <div className="rounded-lg border border-gray-300 bg-slate-50 px-2 py-2 w-1/2 mb-3">
                                                                {email.email}
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="country"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Address
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 flex flex-col gap-4">
                                        <div className="flex gap-4">
                                            <span className="rounded-lg border border-gray-300 bg-slate-50 px-2 py-2 w-3/4">
                                                {customer?.billing_address}
                                            </span>
                                            <span className="rounded-lg border border-gray-300 bg-slate-50 px-2 py-2 w-1/4">
                                                {customer?.billing_city}
                                            </span>
                                        </div>
                                        <div className="flex gap-4">
                                            <span className="rounded-lg border border-gray-300 bg-slate-50 px-2 py-2 w-1/3">
                                                {customer?.billing_province}
                                            </span>
                                            <span className="rounded-lg border border-gray-300 bg-slate-50 px-2 py-2 w-1/3">
                                                {customer?.billing_postal_code}
                                            </span>
                                            <span className="rounded-lg border border-gray-300 bg-slate-50 px-2 py-2 w-1/3">
                                                {customer?.billing_country}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-y-8 divide-y divide-gray-200 ">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 ">
                    <div className="space-y-6 sm:space-y-5 ">
                        <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10 ">
                            <div className="space-y-6 sm:space-y-5">
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Tax Rate
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                        <span className="rounded-lg border border-gray-300 bg-slate-50 px-2 py-2 w-1/2">
                                            <Link
                                                to={`/invoicing/taxes/${customer?.tax_id}`}
                                            >
                                                {customer?.tax_name}
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Notes
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                        <span className="w-full rounded-lg border border-gray-300 bg-slate-50 px-2 py-2 ">
                                            {customer?.notes}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
