import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { RegistrationFormRepository } from '../../api/RegistrationFormRepository'
import { useAppDispatch } from '../../redux/hooks'
import { setStep } from '../../redux/state-slices/StepSlice'
import Loading from '../../shared-components/Loading'
import Error from '../../shared-components/Error'
import { useAppSelector } from '../../redux/hooks'
import { CheckIcon, ClockIcon } from '@heroicons/react/20/solid'
import { setError } from '../../redux/state-slices/GlobalNotificationSlice'

interface PricingFee {
    name: string
    price: string
    paymentFrequency: string
}

export default function DebitPricing() {
    const registrationFormRepository = new RegistrationFormRepository()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [bigPrice, setBigPrice] = useState({ tx: 0, mdr: 0 })
    const branding = useAppSelector((state) => state.branding)
    const [pricingPlan, setPricingplan] = useState<Array<PricingFee> | null>(
        null
    )
    const [numOfTerminal, setNumOfTerminal] = useState(1)
    useEffect(() => {
        window.scrollTo(0, 0)
        setStepState()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const pricingInfoQuery = useQuery(
        'pricing-info',
        async () => {
            return (await registrationFormRepository.getPricingInfo()).data
        },
        {
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (data: Paywell.API.V2.Pricing) => {
                updatePricingPlan(data)
            },
        }
    )

    const createEquipmentQuickAdd = useMutation({
        mutationFn: () =>
            registrationFormRepository.createEquipmentQuickAdd(numOfTerminal),
        onSuccess: () => {
            navigate('/onboarding/debit/contract')
        },

        onError: (error) => {
            dispatch(setError(['An error has occurred']))
        },
    })

    const updatePricingPlan = (pricingInfo: Paywell.API.V2.Pricing) => {
        let newPricingPlan = []
        newPricingPlan.push({
            name: 'Settlement Fee',
            price: '$' + pricingInfo.settlement,
            paymentFrequency: 'Per Batch',
        })
        newPricingPlan.push({
            name: 'Connectivity Fee',
            price: '$15.00',
            paymentFrequency: 'Monthly',
        })

        setBigPrice({ tx: pricingInfo.tx, mdr: pricingInfo.mdr })
        setPricingplan(newPricingPlan)
    }

    const setStepState = () => {
        dispatch(setStep(3))
    }

    const handleNumOfTerminalChange = (value: string) => {
        setNumOfTerminal(parseInt(value))
    }
    const products: any = [
        {
            id: 1,
            name: 'SwitchCo R2',
            price: '$499.00',
            color: 'White',
            inStock: true,
            imageSrc: '/switchco-r2.png',
        },
        // More products...
    ]

    const handleSubmit = () => {
        createEquipmentQuickAdd.mutate()
    }

    if (pricingInfoQuery.isFetching || pricingInfoQuery.isLoading) {
        return (
            <div className="flex h-screen">
                <div className="m-auto">
                    <Loading height="8" width="8" />
                </div>
            </div>
        )
    } else if (pricingInfoQuery.isError) {
        return (
            <div className="flex items-center justify-center mt-4">
                <Error
                    title="An error has occurred"
                    message={`Please reach out to one of our representatives at ${branding.contact_email} if this error persists.`}
                />
            </div>
        )
    }

    return (
        <div className=" bg-white py-24 px-6 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
                <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
                    Pricing
                </h2>
                <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    The right price for you
                </p>
            </div>
            <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                Our team will get you all the documentation and hardware you
                need to get going and start saving you on fees.
            </p>

            <div className="mx-auto mt-8 items-center sm:mt-20 sm:gap-y-0 lg:max-w-4xl ">
                <div className="rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 relative bg-white shadow-2xl">
                    <h3 className="text-base font-semibold leading-7 text-indigo-600">
                        Select Your Terminal Rental
                    </h3>
                    <div className="px-6 lg:px-8">
                        <div className="mt-8 flow-root">
                            <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <ul
                                        role="list"
                                        className="divide-y divide-gray-200 border-b border-t border-gray-200"
                                    >
                                        {products.map(
                                            (product: any, productIdx: any) => (
                                                <li
                                                    key={product.id}
                                                    className="flex py-6 sm:py-10"
                                                >
                                                    <div className="flex-shrink-0">
                                                        <img
                                                            src={
                                                                product.imageSrc
                                                            }
                                                            alt={
                                                                product.imageAlt
                                                            }
                                                            className="h-24 w-24 rounded-lg object-cover object-center sm:h-32 sm:w-32"
                                                        />
                                                    </div>

                                                    <div className="relative ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                                                        <div>
                                                            <div className="flex justify-between sm:grid sm:grid-cols-2">
                                                                <div className="pr-6">
                                                                    <h3 className="text-sm">
                                                                        <a
                                                                            href={
                                                                                product.href
                                                                            }
                                                                            className="font-medium text-gray-700 hover:text-gray-800"
                                                                        >
                                                                            {
                                                                                product.name
                                                                            }
                                                                        </a>
                                                                    </h3>
                                                                    <p className="mt-1 text-sm text-gray-500">
                                                                        {
                                                                            product.color
                                                                        }
                                                                    </p>
                                                                    {product.size ? (
                                                                        <p className="mt-1 text-sm text-gray-500">
                                                                            {
                                                                                product.size
                                                                            }
                                                                        </p>
                                                                    ) : null}
                                                                </div>

                                                                <p className="text-right text-sm font-medium text-gray-900">
                                                                    {
                                                                        product.price
                                                                    }
                                                                </p>
                                                            </div>

                                                            <div className="mt-4 flex items-center sm:absolute sm:left-1/2 sm:top-0 sm:mt-0 sm:block">
                                                                <label
                                                                    htmlFor={`quantity-${productIdx}`}
                                                                    className="sr-only"
                                                                >
                                                                    Quantity,{' '}
                                                                    {
                                                                        product.name
                                                                    }
                                                                </label>
                                                                <select
                                                                    id={`quantity-${productIdx}`}
                                                                    name={`quantity-${productIdx}`}
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleNumOfTerminalChange(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    className="block max-w-full rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                                                                >
                                                                    <option
                                                                        value={
                                                                            1
                                                                        }
                                                                    >
                                                                        1
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            2
                                                                        }
                                                                    >
                                                                        2
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            3
                                                                        }
                                                                    >
                                                                        3
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            4
                                                                        }
                                                                    >
                                                                        4
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            5
                                                                        }
                                                                    >
                                                                        5
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            6
                                                                        }
                                                                    >
                                                                        6
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            7
                                                                        }
                                                                    >
                                                                        7
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            8
                                                                        }
                                                                    >
                                                                        8
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <p className="mt-4 flex space-x-2 text-sm text-gray-700">
                                                            {product.inStock ? (
                                                                <CheckIcon
                                                                    className="h-5 w-5 flex-shrink-0 text-green-500"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : (
                                                                <ClockIcon
                                                                    className="h-5 w-5 flex-shrink-0 text-gray-300"
                                                                    aria-hidden="true"
                                                                />
                                                            )}

                                                            <span>
                                                                {product.inStock
                                                                    ? 'In stock'
                                                                    : `Ships in ${product.leadTime}`}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mx-auto mt-8 items-center sm:mt-20 sm:gap-y-0 lg:max-w-4xl ">
                <div className="rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 relative bg-white shadow-2xl">
                    <h3 className="text-base font-semibold leading-7 text-indigo-600">
                        Pricing Breakdown
                    </h3>
                    <div className="mt-4 px-6">
                        <div className="flex flex-wrap items-center	">
                            <div className="text-4xl font-bold tracking-tight text-gray-900">
                                {bigPrice.mdr}%
                            </div>
                            <div className="text-2xl text-gray-400 mx-2">+</div>
                            <div className="text-3xl font-bold tracking-tight text-gray-900">
                                ¢{bigPrice.tx}
                            </div>
                        </div>
                        <div className="text-sm font-semibold leading-6 text-gray-600">
                            per transaction
                        </div>
                    </div>
                    <div className="px-6 lg:px-8">
                        <div className="mt-8 flow-root">
                            <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                                >
                                                    Service
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {pricingPlan &&
                                                pricingPlan.map((element) => (
                                                    <tr key={element.name}>
                                                        <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                            {element.name}
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                            {element.price}
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                            {
                                                                element.paymentFrequency
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}

                                            <tr>
                                                <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                    Equipment Fee
                                                </td>

                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                    ${499 * numOfTerminal}.00
                                                </td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                    One Time
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {createEquipmentQuickAdd.isLoading && (
                <div className={'mt-4'}>
                    <Loading height="8" width="8" />
                </div>
            )}

            {!createEquipmentQuickAdd.isLoading && (
                <div className="flex justify-center mt-4">
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className="justify-center rounded-md border bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
                    >
                        Save and Continue
                    </button>
                </div>
            )}
        </div>
    )
}
