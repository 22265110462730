import { BaseRepository } from './BaseRepository'

export class HostedCheckoutRepository extends BaseRepository {
    get(uuid: string) {
        return super
            .getHttpClientInstance()
            .get<Paywell.API.V1.HostedCheckout>(
                `/api/v1/hosted-checkout/${uuid}`
            )
    }
}
