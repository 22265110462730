import { Fragment, useEffect, useState } from 'react'
import { Link, Route, Routes, useNavigate } from 'react-router-dom'
// import Customers from "customers/Customers";
import CustomersView from './customers/CustomersView'
import Invoices from './invoices/Invoices'
import InvoiceEdit from './invoices/InvoicesEdit'
import InvoiceView from './invoices/InvoicesView'
import { useLocation } from 'react-router-dom'
import Customers from './customers/Customers'
import ProductsNew from './products/ProductsNew'
import CustomersNew from './customers/CustomersNew'
import Taxes from './taxes/Taxes'
import Products from './products/Products'
import CustomersEdit from './customers/CustomersEdit'
import ProductView from './products/ProductsView'
import TaxView from './taxes/TaxesView'
import ProductEdit from './products/ProductsEdit'
import { useMutation } from 'react-query'
import { InvoicesRepository } from '../api/InvoicesRepository'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import {
    setError,
    setSuccess,
} from '../redux/state-slices/GlobalNotificationSlice'
import Loading from '../shared-components/Loading'
import TaxesEdit from './taxes/TaxesEdit'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Transition } from '@headlessui/react'
import InvoiceSettings from './settings/InvoiceSettings'
import InvoiceEmailReminders from './settings/InvoiceEmailReminders'
import EmailReminderView from './settings/EmailReminderView'
import EmailReminderEdit from './settings/EmailReminderEdit'
import EmailReminderNew from './settings/EmailReminderNew'
import TaxesNew from './taxes/TaxesNew'
import PaymentOptions from './settings/PaymentOptions'
import BrandingLogoUpload from './settings/BrandingLogoUpload'
import GenerateInvoices from './settings/GenerateInvoices'

const tabs = [
    { name: 'Invoices', link: 'invoices', onClick: '' },
    { name: 'Customers', link: 'customers', onClick: '' },
    { name: 'Products', link: 'products', onClick: '' },
    { name: 'Taxes', link: 'taxes', onClick: '' },
    { name: 'Settings', link: 'settings', onClick: '' },
]

export default function InvoiceSystem() {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const invoiceRepository = new InvoicesRepository()
    const setCurrent = () =>
        tabs.findIndex((tab) =>
            location.pathname.split('/').some((key) => key === tab.link)
        ) >= 0
            ? tabs.findIndex((tab) =>
                  location.pathname.split('/').some((key) => key === tab.link)
              )
            : 0
    const [active, setActive] = useState(setCurrent)
    const [open, setOpen] = useState(false)
    const showButton = ['invoices', 'customers', 'taxes', 'products']

    const checkLocation = (current: string) => {
        return showButton.some(
            (path) =>
                current === '/invoicing' || '/invoicing/' + path === current
        )
    }

    const createInvoiceMutation = useMutation({
        mutationFn: () => invoiceRepository.createInvoice(),
        onError: (err: any) => {
            if (err.data.message) dispatch(setError([err.data.message]))
            else
                dispatch(
                    setError([
                        'An error has occured. If this keeps happening, please contact support',
                    ])
                )
        },
        onSuccess: (data) => {
            dispatch(setSuccess(['Invoice successfully created.']))
            if (data.data['invoice_uuid'] != null)
                navigate(
                    `/invoicing/invoices/edit/${data.data['invoice_uuid']}`
                )
        },
    })

    const createInvoice = (e: any) => {
        e.preventDefault()
        createInvoiceMutation.mutate()
    }

    const settingsPage = window.location.pathname.split('/')[2] === 'settings'

    useEffect(() => {
        setActive(setCurrent())
    }, [location.pathname])
    return (
        <div className="container mx-auto bg-white-200 rounded-xl border p-8 m-10">
            <div className="sm:hidden pb-6 border-b border-gray-200 ">
                <div className="flex flex-col">
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <div
                        className={`flex flex-row justify-between bg-gray-100 cursor-pointer text-gray-900 ${
                            open ? 'rounded-t-md' : 'rounded-md'
                        } py-2 px-3 text-base font-medium`}
                        onClick={() => setOpen(open ? false : true)}
                    >
                        {tabs[active].name}{' '}
                        {open ? (
                            <XMarkIcon className="h-6 w-6" />
                        ) : (
                            <Bars3Icon className="h-6 w-6" />
                        )}
                    </div>
                    {tabs.map((tab, index) => (
                        <Transition
                            key={tab.name}
                            show={open}
                            as={Fragment}
                            enter="transition ease duration-200 transform"
                            enterFrom="opacity-0 -translate-y-12"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease duration-200 transform"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 -translate-y-6"
                        >
                            <div className={`container bg-gray-100`}>
                                {active != index && (
                                    <Link
                                        to={tab.link}
                                        key={tab.name}
                                        onClick={() => setActive(index)}
                                    >
                                        <div className="w-full bg-gray-200 rounded-md shadow-inner my-0.5 px-2">
                                            {tab.name}
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </Transition>
                    ))}
                </div>
            </div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav className="flex justify-between" aria-label="Tabs">
                        <div className="flex space-x-8 -mb-px">
                            {tabs.map((tab, index) => {
                                if (index < 4) {
                                    return (
                                        <Link
                                            to={tab.link}
                                            key={tab.name}
                                            className={`whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm ${
                                                active == index
                                                    ? 'border-sky-600 text-blue-600'
                                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200'
                                            }`}
                                            onClick={() => setActive(index)}
                                            aria-current={
                                                active == index
                                                    ? 'page'
                                                    : undefined
                                            }
                                        >
                                            {tab.name}
                                        </Link>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </div>
                        <div>
                            <Link
                                to="settings"
                                className={`whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm  ${
                                    settingsPage
                                        ? 'border-sky-600 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200'
                                }`}
                                onClick={() => setActive(4)}
                                aria-current={settingsPage ? 'page' : undefined}
                            >
                                Settings
                            </Link>
                        </div>
                    </nav>
                </div>
            </div>
            {checkLocation(location.pathname) === true && (
                <div className="pt-2.5 sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">
                            {tabs[active].name}
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                            View all of your {tabs[active].name}
                        </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <Link
                            to={`${tabs[active].link}/new`}
                            className="inline-flex h-10 w-40 items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            onClick={(e) =>
                                active === 0 ? createInvoice(e) : ''
                            }
                        >
                            {createInvoiceMutation.isLoading ? (
                                <Loading width="8" height="8" />
                            ) : (
                                'New ' + tabs[active].name
                            )}
                        </Link>
                    </div>
                </div>
            )}
            <div className="pt-2.5">
                <Routes>
                    <Route path="/" element={<Invoices />} />
                    <Route path="/invoices" element={<Invoices />} />
                    <Route path="/invoices/:id" element={<InvoiceView />} />
                    <Route
                        path="/invoices/edit/:id"
                        element={<InvoiceEdit />}
                    />

                    <Route path="/customers" element={<Customers />} />
                    <Route
                        path="/customers/:id/*"
                        element={<CustomersView />}
                    />
                    <Route
                        path="/customers/edit/:id"
                        element={<CustomersEdit />}
                    />
                    <Route path="/customers/new" element={<CustomersNew />} />

                    <Route path="taxes" element={<Taxes />} />
                    <Route path="taxes/:id" element={<TaxView />} />
                    <Route path="taxes/edit/:id" element={<TaxesEdit />} />
                    <Route path="taxes/new" element={<TaxesNew />} />

                    <Route path="/products" element={<Products />} />
                    <Route path="/products/:id" element={<ProductView />} />
                    <Route
                        path="/products/edit/:id"
                        element={<ProductEdit />}
                    />
                    <Route path="/products/new" element={<ProductsNew />} />

                    <Route path="/settings" element={<InvoiceSettings />} />
                    <Route
                        path="/settings/email-reminders"
                        element={<InvoiceEmailReminders />}
                    />

                    <Route
                        path="/settings/email-reminders/:id"
                        element={<EmailReminderView />}
                    />
                    <Route
                        path="/settings/email-reminders/edit/:id"
                        element={<EmailReminderEdit />}
                    />
                    <Route
                        path="/settings/email-reminders/create"
                        element={<EmailReminderNew />}
                    />

                    <Route
                        path="/settings/payment-options"
                        element={<PaymentOptions />}
                    />
                    <Route
                        path="/settings/upload-logo"
                        element={<BrandingLogoUpload />}
                    />
                    <Route
                        path="/settings/generate-invoices"
                        element={<GenerateInvoices />}
                    />
                </Routes>
            </div>
        </div>
    )
}
