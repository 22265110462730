import { useEffect, useState } from 'react'
import { Link, Route, Routes } from 'react-router-dom'
// import Customers from "customers/Customers";

import { useLocation } from 'react-router-dom'
import CustomerDetails from './views/CustomerDetails'
import CustomerInvoices from './views/CustomerInvoices'

const tabs = [
    { name: 'Customer Details', link: '' },
    { name: 'Customer Invoices', link: 'invoice' },
]

export default function InvoiceSystem() {
    const [active, setActive] = useState(0)
    const showButton = ['invoices']
    const location = useLocation()

    const changeTab = (index: number) => {
        setActive(index)
    }
    const checkLocation = (current: string) => {
        return showButton.some((path) => current.includes(path))
    }
    useEffect(() => {
        if (checkLocation(location.pathname)) {
            setActive(1)
        }
    }, [])
    return (
        <div className="container">
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8 " aria-label="Tabs">
                        {tabs.map((tab, index) => (
                            <Link
                                to={`${tab.link}`}
                                key={tab.name}
                                className={`whitespace-nowrap w-1/2 flex justify-center py-4 px-1 border-b-2 font-medium text-sm ${
                                    active == index
                                        ? 'border-indigo-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200'
                                }`}
                                onClick={() => changeTab(index)}
                                aria-current={
                                    active == index ? 'page' : undefined
                                }
                            >
                                {tab.name}
                            </Link>
                        ))}
                    </nav>
                </div>
            </div>
            <div className="pt-2.5">
                <Routes>
                    <Route path="/" element={<CustomerDetails />} />
                    <Route path="/invoice" element={<CustomerInvoices />} />
                </Routes>
            </div>
        </div>
    )
}
