import { useQuery } from 'react-query'
import Loading from '../../../shared-components/Loading'
import Error from '../../../shared-components/Error'
import { InvoicesRepository } from '../../../api/InvoicesRepository'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../../redux/hooks'

export default function InvoiceCheckoutWireTransfer() {
    const branding = useAppSelector((state) => state.branding)
    /* const invoiceRepository = new InvoicesRepository()
    const { id } = useParams()

    const userWireTransferInfoQuery = useQuery(
        'user-wire-transfer-info',
        async () => {
            return (await invoiceRepository.getInvoiceWireTransferInfo(id)).data
        },
        {
            enabled: true,
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {},
            onError: () => {},
        }
    )

    if (userWireTransferInfoQuery.isLoading)
        return <Loading height="8" width="8" />

    if (userWireTransferInfoQuery.isError)
        return (
            <Error
                title="An error has occured"
                message="Something went wrong while loading the wire transfer information. Please try again later."
            />
        ) */

    return (
        <div className="bg-white">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                Wire Transfer
            </h2>
            <p className="mt-2">
                Please contact us at{' '}
                <a
                    href={`mailto:${branding.contact_email}`}
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                    {branding.contact_email}
                </a>{' '}
                or{' '}
                <a
                    href={`tel:+${branding.contact_phone}`}
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                    {branding.contact_phone}
                </a>{' '}
                for further information on how to pay via wire transfer.
            </p>
        </div>
    )
    /* return (
        <div className="bg-white">
            <div className="divide-y divide-gray-900/10 ">
                <div className="w-full flex justify-between items-center">
                    <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                        Wire Transfer Payment Details
                    </h2>

                    <img src="/bank-logo.svg" width="50px" />
                </div>

                <dl className="mt-4 space-y-8 divide-y divide-gray-900/10">
                    <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                        <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                            Account Number
                        </dt>
                        <dd className="mt-4 lg:col-span-7 lg:mt-0">
                            <p className="text-base leading-7 text-gray-600">
                                {userWireTransferInfoQuery.data?.account_number}
                            </p>
                        </dd>
                    </div>
                    <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                        <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                            Transit/Routing Number
                        </dt>
                        <dd className="mt-4 lg:col-span-7 lg:mt-0">
                            <p className="text-base leading-7 text-gray-600">
                                {userWireTransferInfoQuery.data?.routing_number}
                            </p>
                        </dd>
                    </div>
                    <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                        <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                            Institution Number
                        </dt>
                        <dd className="mt-4 lg:col-span-7 lg:mt-0">
                            <p className="text-base leading-7 text-gray-600">
                                {
                                    userWireTransferInfoQuery.data
                                        ?.institution_number
                                }
                            </p>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    ) */
}
