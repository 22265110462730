/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/browser'

export class SentryUtils {
  public static expect<T>(variable: T | null | undefined, context?: any): T {
    if (!variable) {
      const debug = {
        variableType: typeof variable,
        variable,
      }

      Sentry.withScope((scope) => {
        scope.setExtras({
          debug,
          context,
        })
        Sentry.captureMessage('Expected variable presence.')
      })
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return variable!
  }

  public static isPresent<T>(variable: any, key: string, context?: any): variable is T {
    if (!variable || !variable[key]) {
      const debug = {
        variableType: typeof variable,
        variable,
        keyType: typeof key,
        key,
      }

      Sentry.withScope((scope) => {
        scope.setExtras({
          debug,
          context,
        })
        Sentry.captureMessage('Expected variable[key] presence.')
      })
      return false
    }

    return true
  }
}
