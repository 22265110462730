import { useEffect } from 'react'
import { UserRepository } from '../../../api/UserRepositoy'
import { useQuery } from 'react-query'
import Loading from '../../../shared-components/Loading'
import { useAppSelector } from '../../../redux/hooks'
import Error from '../../../shared-components/Error'
import NumberFormatDollars from '../../../utils/NumberFormatDollars'
import { InvoicesRepository } from '../../../api/InvoicesRepository'
import { useParams } from 'react-router-dom'

interface InvoiceCheckoutETransferProps {
    invoiceTotal: number
    invoiceNumber: number
}

export default function InvoiceCheckoutETransfer({
    invoiceTotal,
    invoiceNumber,
}: InvoiceCheckoutETransferProps) {
    const invoiceRepository = new InvoicesRepository()
    const branding = useAppSelector((state) => state.branding)
    const { id } = useParams()

    const userInteracInfoQuery = useQuery(
        'invoice-interac-info',
        async () => {
            return (await invoiceRepository.getInvoiceInteracInfo(id)).data
        },
        {
            enabled: true,
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {},
            onError: () => {},
        }
    )

    if (userInteracInfoQuery.isLoading || userInteracInfoQuery.isFetching) {
        return <Loading height="8" width="8" />
    }

    if (userInteracInfoQuery.isError) {
        return (
            <div>
                <Error
                    title="An error has occured"
                    message="Something went wrong while loading the e-Transfer Info. Please try again later."
                />
            </div>
        )
    }

    return (
        <div className="bg-white">
            <div className="divide-y divide-gray-900/10 ">
                <div className="w-full flex justify-between items-center">
                    <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                        Pay With Interac e-Transfer
                    </h2>

                    <img src="/interac_etransfer.png" width="100px" />
                </div>

                <dl className="mt-4 space-y-8 divide-y divide-gray-900/10">
                    <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                        <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                            Step 1
                        </dt>
                        <dd className="mt-4 lg:col-span-7 lg:mt-0">
                            <p className="text-base leading-7 text-gray-600">
                                Login to your online banking portal
                            </p>
                        </dd>
                    </div>
                    <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                        <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                            Step 2
                        </dt>
                        <dd className="mt-4 lg:col-span-7 lg:mt-0">
                            <p className="text-base leading-7 text-gray-600">
                                Select Interac e-Transfer
                            </p>
                        </dd>
                    </div>
                    <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                        <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                            Step 3
                        </dt>
                        <dd className="mt-4 lg:col-span-7 lg:mt-0">
                            <p className="text-base leading-7 text-gray-600">
                                Set the amount to:{' '}
                                <span className="font-medium text-black border border-gray-400 p-2 rounded">
                                    {NumberFormatDollars(invoiceTotal)}
                                </span>
                            </p>
                        </dd>
                    </div>
                    <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                        <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                            Step 4
                        </dt>
                        <dd className="mt-4 lg:col-span-7 lg:mt-0">
                            <p className="text-base leading-7 text-gray-600">
                                Set the message to:{' '}
                                <span className="font-medium text-black border border-gray-400 p-2 rounded">
                                    {invoiceNumber}
                                </span>
                            </p>
                        </dd>
                    </div>
                    <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                        <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                            Step 5
                        </dt>
                        <dd className="mt-4 lg:col-span-7 lg:mt-0">
                            <p className="text-base leading-7 text-gray-600 mb-4">
                                If a security question/answer is requested;
                            </p>
                            <div className="mb-5">
                                Set the question to:{' '}
                                <span className="font-medium text-black border border-gray-400 p-2 rounded">
                                    paywell
                                </span>
                            </div>
                            <div>
                                Set the answer to:{' '}
                                <span className="font-medium text-black border border-gray-400 p-2 rounded">
                                    {' '}
                                    {
                                        userInteracInfoQuery.data
                                            ?.interac_etransfer_password
                                    }
                                </span>
                            </div>
                        </dd>
                    </div>

                    <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                        <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                            Step 6
                        </dt>
                        <dd className="mt-4 lg:col-span-7 lg:mt-0">
                            <p className="text-base leading-7 text-gray-600">
                                Send the e-Transfer to:{' '}
                                <span className="font-medium text-black border border-gray-400 p-2 rounded">
                                    {
                                        userInteracInfoQuery.data
                                            ?.interac_etransfer_receiver_email
                                    }
                                </span>
                            </p>
                        </dd>
                    </div>
                    <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                        <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                            Step 7
                        </dt>
                        <dd className="mt-4 lg:col-span-7 lg:mt-0">
                            <p className="text-base leading-7 text-gray-600">
                                Please allow upto 24 hours for your e-transfer
                                to be reflected on your invoice. If you have
                                further questions about paying by e-transfer,
                                please contact us at{' '}
                                <a
                                    href={`mailto:${branding.contact_email}`}
                                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                                >
                                    {branding.contact_email}
                                </a>{' '}
                                or{' '}
                                <a
                                    href={`tel:+${branding.contact_phone}`}
                                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                                >
                                    {branding.contact_phone}
                                </a>
                            </p>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    )
}
