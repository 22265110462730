import { Route, Routes } from 'react-router-dom'
import Transactions from './Transactions'
import TransactionView from './TransactionView'

export default function TransactionRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Transactions />} />
            <Route path="/:id" element={<TransactionView />} />
        </Routes>
    )
}
