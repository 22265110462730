import { useAppSelector } from '../redux/hooks'
import Onboarding from './Onboarding'
import OnboardingDebit from './OnboardingDebit'

export default function OnboardingRoutes() {
    const onboardingType = useAppSelector((state) => state.onboarding_type)
    if (onboardingType === 'etransfer') {
        return <Onboarding />
    } else {
        return <OnboardingDebit />
    }
}
