import { useQuery } from 'react-query'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { CustomersRepository } from '../../../api/CustomersRepository'
import ErrorV2 from '../../../shared-components/ErrorV2'
import Loading from '../../../shared-components/Loading'
import { SentryUtils } from '../../../utils/SentryUtils'
import PaginationV2 from '../../components/PaginationV2'

export default function CustomerInvoices() {
    const { id } = useParams()
    const [searchParams] = useSearchParams()
    const page = searchParams.get('page') ? searchParams.get('page') : 1
    const customerRepository = new CustomersRepository()

    const customerInvoiceQuery = useQuery(
        ['customerInvoices', page],
        async () => {
            return await customerRepository.getCustomerInvoices(
                SentryUtils.expect<number>(id ? parseInt(id) : 0),
                page
            )
        }
    )
    const total = customerInvoiceQuery?.data?.data?.total
    const from = customerInvoiceQuery?.data?.data?.from
    const to = customerInvoiceQuery?.data?.data?.to
    const next = customerInvoiceQuery.data?.data?.next_page_url
    const prev = customerInvoiceQuery.data?.data?.prev_page_url
    const invoices = customerInvoiceQuery?.data?.data?.data
    const invoiceLinks = customerInvoiceQuery?.data?.data?.links

    if (customerInvoiceQuery.isLoading)
        return (
            <div>
                <Loading width="8" height="8" />
            </div>
        )
    if (customerInvoiceQuery?.isError)
        return (
            <div>
                <ErrorV2
                    title="An error has occured"
                    message="An error has occurred while loading your customers invoice. Please try refreshing, if this keeps happening contact support."
                />
            </div>
        )
    return (
        <div className="">
            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        >
                                            invoice ID
                                        </th>

                                        <th
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Customer
                                        </th>

                                        <th
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Due Date
                                        </th>
                                        <th
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Subtotal
                                        </th>
                                        <th
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Tax total
                                        </th>
                                        <th
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Grand total
                                        </th>
                                        <th
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Balance
                                        </th>
                                        <th
                                            scope="col"
                                            className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6"
                                        >
                                            <span className="sr-only">
                                                Edit
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {invoices &&
                                        invoices.map((invoice) => (
                                            <tr key={invoice.id}>
                                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                    <Link
                                                        to={`/invoicing/invoices/${invoice.uuid}`}
                                                    >
                                                        {invoice.id}
                                                    </Link>
                                                </td>
                                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                    <a
                                                        href={`/invoicing/customers/${invoice.customer_id}`}
                                                    >
                                                        {invoice.billing_name}
                                                    </a>
                                                </td>
                                                <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                    {invoice.due_date}
                                                </td>
                                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                                    {invoice.invoice_subtotal}
                                                </td>
                                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                                    {invoice.invoice_tax_total}
                                                </td>
                                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                                    {
                                                        invoice.invoice_grand_total
                                                    }
                                                </td>
                                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                                    {
                                                        invoice.outstanding_balance
                                                    }
                                                </td>

                                                <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <Link
                                                        to={`/invoicing/invoices/edit/${invoice.uuid}`}
                                                        className="text-blue-600 hover:text-blue-900"
                                                    >
                                                        Edit
                                                        <span className="sr-only"></span>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    <tr>
                                        <td colSpan={8}>
                                            <PaginationV2
                                                pages={invoiceLinks}
                                                from={from}
                                                to={to}
                                                total={total}
                                                next={next}
                                                prev={prev}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
