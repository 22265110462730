import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setBusinessInfo } from '../../redux/state-slices/BusinessInfoSlice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import useDebounce from '../../utils/useDebounce'
import { useMutation } from 'react-query'
import { RegistrationFormRepository } from '../../api/RegistrationFormRepository'
import {
    validateSaleAmount,
    validateDateYYYMMDD,
    validateBusinessFormation,
    validateBusinessInfo,
    validateBusinessName,
    testBusinessNameRegex,
    testAlphaNumericRegex,
    validateMinLength,
    testAddressRegex,
    validateAddress,
    testCityRegex,
    validateCity,
    validatePostalCode,
    testPhoneNumRegex,
} from '../../utils/RegexHelper'
import { setStep } from '../../redux/state-slices/StepSlice'
import Error from '../../shared-components/Error'
import Loading from '../../shared-components/Loading'
import { setIdentityInfo } from '../../redux/state-slices/IdentityInfoSlice'

interface BusinessInfoValidAttributes {
    name: boolean | undefined
    business_formation: boolean | undefined
    merchandise_sold: boolean | undefined
    business_number: boolean | undefined
    website: boolean | undefined
    avg_ticket_amount: boolean | undefined
    highest_ticket_amount: boolean | undefined
    monthly_sales: boolean | undefined
    business_start_date: boolean | undefined
    nickname: boolean | undefined
    address: boolean | undefined
    city: boolean | undefined
    postal_code: boolean | undefined
    countries_id: boolean | undefined
    provinces_id: boolean | undefined
    jurisdiction: boolean | undefined
    predicted_monthly_sales: boolean | undefined
    requested_highest_ticket_amount: boolean | undefined
    lowest_ticket_amount: boolean | undefined
    proof_of_address_file_name: boolean | undefined
    business_tel_number: boolean | undefined
}

export default function DebitBusinessInfo() {
    let navigate = useNavigate()
    const dispatch = useAppDispatch()
    const businessInfo = useAppSelector((state) => state.business_info)
    const authUser = useAppSelector((state) => state.auth_user)
    const steps = useAppSelector((state) => state.steps)
    const debounceValue = useDebounce(businessInfo, 1500)
    const registrationFormRepository = new RegistrationFormRepository()
    const [validForm, setValidForm] = useState(false)
    const [businessInfoValid, setBusinessInfoValid] =
        useState<BusinessInfoValidAttributes>({
            name: undefined,
            business_formation: undefined,
            merchandise_sold: undefined,
            business_number: undefined,
            website: undefined,
            avg_ticket_amount: undefined,
            highest_ticket_amount: undefined,
            monthly_sales: undefined,
            business_start_date: undefined,
            nickname: undefined,
            address: undefined,
            city: undefined,
            postal_code: undefined,
            countries_id: undefined,
            provinces_id: undefined,
            jurisdiction: undefined,
            predicted_monthly_sales: undefined,
            requested_highest_ticket_amount: undefined,
            lowest_ticket_amount: undefined,
            proof_of_address_file_name: undefined,
            business_tel_number: undefined,
        })

    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setStepState()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validForm])

    useEffect(() => {
        validateForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessInfo])

    useEffect(() => {
        saveFormValuesMutation.mutate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceValue])

    const saveFormValuesMutation = useMutation({
        mutationFn: () =>
            registrationFormRepository.postBusinessInfo(
                businessInfo,
                authUser?.id
            ),
    })

    const submitFormValuesMutation = useMutation({
        mutationFn: () =>
            registrationFormRepository.postBusinessInfo(
                businessInfo,
                authUser?.id
            ),
        onSuccess: () => {
            navigate('/onboarding/debit/banking-info')
        },

        onError: () => {},
    })

    const setStepState = () => {
        if (steps[1].status !== 'complete' || !validForm) {
            dispatch(setStep(1))
        }
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (validForm) {
            submitFormValuesMutation.mutate()
        }
    }

    const validateForm = () => {
        setValidForm(validateBusinessInfo(businessInfo, 'debit'))
    }

    const handleNameChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        if ((val.length < 50 && testBusinessNameRegex(val)) || val === '') {
            setBusinessInfoValid({
                ...businessInfoValid,
                name: validateBusinessName(val),
            })

            dispatch(setBusinessInfo({ ...businessInfo, name: val }))
        }
    }

    const handleBusinessFormationChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value

        setBusinessInfoValid({
            ...businessInfoValid,
            business_formation: validateBusinessFormation(val),
        })

        dispatch(
            setBusinessInfo({
                ...businessInfo,
                business_formation: val,
            })
        )
    }

    const handleBusinessStartDateChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        setBusinessInfoValid({
            ...businessInfoValid,
            business_start_date: validateDateYYYMMDD(val),
        })
        dispatch(
            setBusinessInfo({
                ...businessInfo,
                business_start_date: e.target.value,
            })
        )
    }

    const handleDbaChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        if (val.length < 50) {
            setBusinessInfoValid({
                ...businessInfoValid,
                nickname: validateMinLength(val),
            })

            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    nickname: val,
                })
            )
        }
    }

    const handleBusinessAddressChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value

        if ((testAddressRegex(val) && val.length < 40) || val === '') {
            setBusinessInfoValid({
                ...businessInfoValid,
                address: validateAddress(val),
            })
            dispatch(setBusinessInfo({ ...businessInfo, address: val }))
        }
    }

    const handleBusinessCityChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value

        if (testCityRegex(val) && val.length < 30) {
            setBusinessInfoValid({
                ...businessInfoValid,
                city: validateCity(val),
            })
            dispatch(setBusinessInfo({ ...businessInfo, city: val }))
        }
    }

    const handlePostalCodeChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        if (val.length <= 6 && testAlphaNumericRegex(val)) {
            setBusinessInfoValid({
                ...businessInfoValid,
                postal_code: validatePostalCode(val),
            })
            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    postal_code: val,
                })
            )
        }
    }
    const handleCountryChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        if (!val) {
            setBusinessInfoValid({
                ...businessInfoValid,
                countries_id: false,
            })
        } else {
            setBusinessInfoValid({
                ...businessInfoValid,
                countries_id: true,
            })
        }
        dispatch(
            setBusinessInfo({ ...businessInfo, countries_id: e.target.value })
        )
    }

    const handleProvincesChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        if (!val) {
            setBusinessInfoValid({
                ...businessInfoValid,
                provinces_id: false,
            })
        } else {
            setBusinessInfoValid({
                ...businessInfoValid,
                provinces_id: true,
            })
        }
        dispatch(
            setBusinessInfo({ ...businessInfo, provinces_id: e.target.value })
        )
    }

    const handlePredictedMonthlySalesChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value

        if (val.length < 9) {
            setBusinessInfoValid({
                ...businessInfoValid,
                predicted_monthly_sales: validateSaleAmount(val),
            })
            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    predicted_monthly_sales: val ? parseInt(val) : null,
                })
            )
        }
    }

    const handleBusinessTelNumChange = (e: React.ChangeEvent<any>) => {
        let val = e.target.value
        if (val.length < 11) {
            setBusinessInfoValid({
                ...businessInfoValid,
                business_tel_number: testPhoneNumRegex(val),
            })
            dispatch(
                setBusinessInfo({
                    ...businessInfo,
                    business_tel_number: val,
                })
            )
        }
    }
    const handleInvalidClass = (key: keyof BusinessInfoValidAttributes) => {
        if (businessInfoValid[key] === false) {
            return '!border-2 !border-rose-500'
        }

        return ''
    }

    return (
        <form
            className="space-y-8 divide-y divide-gray-200"
            onSubmit={handleSubmit}
        >
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <div className={''}>
                            <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Corporate / Legal Business Name
                            </label>
                            <span className="text-xs text-gray-700">
                                The name given must match the void cheque
                                provided in the next step
                            </span>
                        </div>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                                value={businessInfo.name || ''}
                                onChange={handleNameChange}
                                type="text"
                                name="name"
                                id="name"
                                autoComplete="given-name"
                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm  sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                    'name'
                                )}`}
                            />
                            {businessInfoValid.name === false && (
                                <p
                                    className="mt-2 text-sm text-red-600"
                                    id="invalid-business-name"
                                >
                                    Minimum 2 characters required
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                            htmlFor="dba"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                            DBA (Doing Business As/Store Name)
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                                value={businessInfo.nickname || ''}
                                onChange={handleDbaChange}
                                type="text"
                                name="dba"
                                id="dba"
                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm  sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                    'nickname'
                                )}`}
                            />
                            {businessInfoValid.nickname === false && (
                                <p
                                    className="mt-2 text-sm text-red-600"
                                    id="invalid-business-name"
                                >
                                    Minimum 2 characters required
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                            htmlFor="phone-number"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                            Business Phone Number
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                                required
                                value={businessInfo.business_tel_number || ''}
                                onChange={handleBusinessTelNumChange}
                                min={0}
                                type="number"
                                name="business-phone-number"
                                id="business-phone-number"
                                autoComplete="tel"
                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                    'business_tel_number'
                                )}`}
                            />
                            {businessInfoValid.business_tel_number ===
                                false && (
                                <p
                                    className="mt-2 text-sm text-red-600"
                                    id="telnum-invalid"
                                >
                                    Please input a valid phone number (10
                                    digits)
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <div>
                            <label
                                htmlFor="business_address"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Business Address
                            </label>
                            <span className="text-xs text-gray-700">
                                The location where the terminals will used
                            </span>
                        </div>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                                value={businessInfo.address || ''}
                                onChange={handleBusinessAddressChange}
                                type="text"
                                name="business_address"
                                id="business_address"
                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm  sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                    'address'
                                )}`}
                            />
                            {businessInfoValid.address === false && (
                                <p
                                    className="mt-2 text-sm text-red-600"
                                    id="invalid-business-address"
                                >
                                    Minimum 2 characters required
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                            htmlFor="business_address"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                            Business City
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                                value={businessInfo.city || ''}
                                onChange={handleBusinessCityChange}
                                type="text"
                                name="business_address"
                                id="business_address"
                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm  sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                    'city'
                                )}`}
                            />
                            {businessInfoValid.city === false && (
                                <p
                                    className="mt-2 text-sm text-red-600"
                                    id="invalid-business-address"
                                >
                                    Minimum 2 characters required
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                            htmlFor="postal_code"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                            Postal Code
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                                value={businessInfo.postal_code || ''}
                                onChange={handlePostalCodeChange}
                                type="text"
                                name="postal_code"
                                id="postal_code"
                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm  sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                    'postal_code'
                                )}`}
                            />
                            {businessInfoValid.postal_code === false && (
                                <p
                                    className="mt-2 text-sm text-red-600"
                                    id="invalid-postal-code"
                                >
                                    Minimum 5 characters required
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                            htmlFor="country"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                            Country
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <select
                                required
                                value={businessInfo.countries_id || ''}
                                onChange={handleCountryChange}
                                id="country"
                                name="country"
                                autoComplete="country-name"
                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                    'countries_id'
                                )}`}
                            >
                                <option value="1">Canada</option>
                                <option value="2">United States</option>
                            </select>
                        </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                            htmlFor="province"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                            Province
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <select
                                required
                                onChange={handleProvincesChange}
                                value={businessInfo.provinces_id || ''}
                                name="province"
                                id="province"
                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                    'provinces_id'
                                )}`}
                            >
                                <option value="">Select</option>
                                <option value="1">British Columbia</option>
                                <option value="2">Alberta</option>
                                <option value="3">Saskatchewan</option>
                                <option value="4">Manitoba</option>
                                <option value="5">Ontario</option>
                                <option value="6">Quebec</option>
                                <option value="7">New Brunswick</option>
                                <option value="8">PEI</option>
                                <option value="9">Nova Scotia</option>
                                <option value="10">NFLD</option>
                                <option value="11">Yukon</option>
                                <option value="12">NWT</option>
                                <option value="13">Nunavut</option>
                                <option value="14">United States</option>
                            </select>
                            {businessInfoValid.provinces_id === false && (
                                <p
                                    className="mt-2 text-sm text-red-600"
                                    id="invalid-province"
                                >
                                    Please select a province
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                            htmlFor="business-formation"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                            Business Formation
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <select
                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm  sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                    'business_formation'
                                )}`}
                                id="business-formation"
                                name="business-formation"
                                value={businessInfo.business_formation || ''}
                                onChange={handleBusinessFormationChange}
                            >
                                <option value="sole_proprietorship">
                                    Sole Proprietorship
                                </option>
                                <option value="corporation">Corporation</option>

                                <option value="partnership">Partnership</option>

                                <option value="association_estate_trust">
                                    Association/Estate/Trust
                                </option>
                                <option value="international_organization">
                                    International Organization
                                </option>
                                <option value="llc">LLC</option>
                                <option value="medical_legal_corporation">
                                    Medical/Legal Corporation
                                </option>
                                <option value="non_profit">Non-Profit</option>

                                <option value="tax_exempt_organization">
                                    Tax Exempt Organization
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                            htmlFor="predicted_monthly_sales"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                            Anticipated Monthly Volume (All Sales) $
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                                value={
                                    businessInfo.predicted_monthly_sales || ''
                                }
                                onChange={handlePredictedMonthlySalesChange}
                                type="text"
                                name="predicted_monthly_sales"
                                id="predicted_monthly_sales"
                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                    'predicted_monthly_sales'
                                )}`}
                            />
                            {businessInfoValid.predicted_monthly_sales ===
                                false && (
                                <p
                                    className="mt-2 text-sm text-red-600"
                                    id="invalid-predicted-monthly-sales"
                                >
                                    Please input a valid value. Value must be
                                    greater than 0
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                            htmlFor="street-address"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                            Business Start Date
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                                value={
                                    businessInfo.business_start_date?.toString() ||
                                    ''
                                }
                                onChange={handleBusinessStartDateChange}
                                type="date"
                                name="street-address"
                                id="street-address"
                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                    'business_start_date'
                                )}`}
                            />
                            {businessInfoValid.business_start_date ===
                                false && (
                                <p
                                    className="mt-2 text-sm text-red-600"
                                    id="invalid-business-start-date"
                                >
                                    Please input a valid date
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-5">
                <div className="flex justify-start">
                    {!submitFormValuesMutation.isLoading && (
                        <button
                            disabled={!validForm}
                            type="submit"
                            className="ml-3 inline-flex disabled:bg-gray-400 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            Save and Continue
                        </button>
                    )}
                    {submitFormValuesMutation.isLoading && (
                        <Loading width="8" height="8" />
                    )}
                </div>
                {submitFormValuesMutation.isError && (
                    <div className="md:w-2/5 mt-4">
                        <Error
                            title="There was an error saving"
                            message="Please reach out to one of our representatives if this error persists"
                        />
                    </div>
                )}
            </div>
        </form>
    )
}
