import { useAppSelector } from '../redux/hooks'
import { useQuery } from 'react-query'
import { UserRepository } from '../api/UserRepositoy'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
    getBackgroundClassNameAccountStatus,
    getReadableAccountStatus,
    getReadableUserType,
} from '../utils/UserUtils'
import { XMarkIcon } from '@heroicons/react/20/solid'
import PaginationV2 from '../invoices/components/PaginationV2'
import SkeletonLoader from '../invoices/components/SkeletonLoader'
import NewUser from './NewUser'

export default function Users() {
    const authUser = useAppSelector((state) => state.auth_user)
    const isAdmin = authUser?.user_type_id === 3
    const userRepository = new UserRepository()
    const [searchTerm, setSearchTerm] = useState<null | string>(null)
    const [accountStatus, setAccountStatus] = useState<null | string>(null)
    const [tempAccountStatus, setTempAccountStatus] = useState(null)
    const [tempSearchTerm, setTempSearchTerm] = useState(null)
    const [showCreateUserModal, setShowCreateUserModal] = useState(false)
    const [searchParams] = useSearchParams()
    const page = searchParams.get('page') ? searchParams.get('page') : '1'
    const navigate = useNavigate()
    const fetchUsers = async () => {
        const response = await userRepository.getUsers(
            page,
            searchTerm,
            accountStatus
        )
        return response.data
    }

    useEffect(() => {
        let search = searchParams.get('search')
        let accountStatus = searchParams.get('account_status')
        if (search) {
            setSearchTerm(search)
        }
        if (accountStatus) {
            setAccountStatus(accountStatus)
        }
    }, [searchParams])

    const usersQuery = useQuery(
        ['users', page, searchTerm, accountStatus],
        () => fetchUsers(),
        {
            onSuccess: (data) => {},

            retry: false,
            refetchOnWindowFocus: false,
            enabled: true,
        }
    )

    const handleAccountStatusChange = (e: any) => {
        setTempAccountStatus(e.target.value)
    }

    const handleSearchTermChange = (e: any) => {
        let val = e.target.value
        if (val.length < 50) {
            setTempSearchTerm(val)
        }
    }
    const handleSearch = (e: any) => {
        e.preventDefault()
        let search = tempSearchTerm ? `&search=${tempSearchTerm}` : ''
        let accounts = tempAccountStatus
            ? `&account_status=${tempAccountStatus}`
            : ''
        navigate(`?page=1${search}${accounts}`)
    }

    const clearSearch = () => {
        setAccountStatus(null)
        setSearchTerm(null)
        setTempAccountStatus(null)
        setTempSearchTerm(null)
        navigate(`?page=1`)
    }

    const total = usersQuery.data?.total
    const from = usersQuery.data?.from
    const to = usersQuery.data?.to
    const next = usersQuery.data?.next_page_url
    const prev = usersQuery.data?.prev_page_url
    const links = usersQuery.data?.links

    const users = usersQuery.data?.data

    return (
        <div className="p-5">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">
                            Users
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                            View and manage users.
                        </p>
                    </div>
                    {isAdmin && (
                        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            <button
                                onClick={() => setShowCreateUserModal(true)}
                                type="button"
                                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Create User
                            </button>
                        </div>
                    )}
                </div>
                <div className="mt-4 flow-root">
                    <form
                        onSubmit={handleSearch}
                        className="mt-4 md:flex space-y-2 md:space-y-0 md:space-x-2 align-middle text-gray-400 focus-within:text-gray-600 mb-5 max-w-1/2"
                    >
                        <div className="max-w-sm">
                            <select
                                id="user"
                                className="w-full rounded-md border-1 focus:border-0 bg-white disabled:bg-gray-200 disabled:cursor-not-allowed  text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:text-sm sm:leading-6"
                                name="user"
                                onChange={handleAccountStatusChange}
                                value={tempAccountStatus || ''}
                            >
                                <option value="">Select Account Status</option>
                                <option value="1">Waiting</option>
                                <option value="2">Active</option>
                                <option value="3">Suspended</option>
                                <option value="4">Pending Approval</option>
                                <option value="5">Declined</option>
                                <option value="6">Registering</option>
                            </select>
                        </div>

                        <div className="max-w-sm">
                            <input
                                id="search"
                                className=" w-full rounded-md border-1 focus:border-0 bg-white  text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:text-sm sm:leading-6"
                                type="text"
                                name="search"
                                placeholder="Search name, email..."
                                value={tempSearchTerm || ''}
                                onChange={handleSearchTermChange}
                            />
                        </div>

                        <div className="flex text-black items-center">
                            <button
                                type="submit"
                                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                Search
                            </button>

                            {searchTerm || accountStatus ? (
                                <XMarkIcon
                                    onClick={clearSearch}
                                    className="h-5 w-5 cursor-pointer ml-2"
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </form>
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            DBA
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Merchant No.
                                        </th>

                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Status
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Role
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Agent
                                        </th>
                                        <th scope="col" className="sr-only">
                                            <span className="sr-only">
                                                Edit
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {(usersQuery.isLoading ||
                                        usersQuery.isFetching) && (
                                        <SkeletonLoader
                                            rows={5}
                                            columns={8}
                                            isError={usersQuery.isError}
                                        />
                                    )}
                                    {!usersQuery.isLoading &&
                                        !usersQuery.isFetching &&
                                        usersQuery.isSuccess &&
                                        users &&
                                        users.map((user, index) => (
                                            <tr
                                                className={`group transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg ${
                                                    index % 2 === 0
                                                        ? 'bg-white'
                                                        : 'bg-gray-50'
                                                }`}
                                                key={user.email}
                                            >
                                                <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0 group-hover:font-medium">
                                                    <div className="flex items-center">
                                                        <div className="ml-4">
                                                            <div className="font-medium text-gray-900 group-hover:text-black group-hover:font-semibold">
                                                                <span>
                                                                    {user.name}
                                                                </span>
                                                            </div>
                                                            <div className="mt-1 text-gray-500 group-hover:text-gray-700">
                                                                {user.email}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="whitespace-pre-wrap px-3 py-5 text-sm text-gray-500 group-hover:text-gray-700 group-hover:font-medium">
                                                    <span>{user.nickname}</span>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 group-hover:text-gray-700 group-hover:font-medium">
                                                    <span>{user.mid}</span>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                    <span
                                                        className={`inline-flex items-center rounded-md ${getBackgroundClassNameAccountStatus(
                                                            user.account_status_id
                                                        )} px-2 py-1 text-xs font-medium ring-1 ring-inset ring-green-600/20 group-hover:scale-105 transition-transform`}
                                                    >
                                                        {getReadableAccountStatus(
                                                            user.account_status_id
                                                        )}
                                                    </span>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 group-hover:text-gray-700 group-hover:font-medium">
                                                    {getReadableUserType(
                                                        user.user_type_id
                                                    )}
                                                </td>
                                                <td
                                                    className={`whitespace-nowrap px-3 py-5 text-sm ${
                                                        isAdmin
                                                            ? 'text-indigo-600 group-hover:text-indigo-700'
                                                            : 'text-gray-500 group-hover:text-gray-700'
                                                    } group-hover:font-medium`}
                                                >
                                                    {isAdmin ? (
                                                        <a
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            href={`/users/${user.referrer_user_id}`}
                                                        >
                                                            {user.agent}
                                                        </a>
                                                    ) : (
                                                        user.agent
                                                    )}
                                                </td>
                                                <td
                                                    className={`whitespace-nowrap px-3 py-5 text-sm text-indigo-600 group-hover:text-indigo-700 group-hover:font-medium`}
                                                >
                                                    <a
                                                        href={`/users/${user.id}/info`}
                                                    >
                                                        View
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}

                                    {usersQuery.isError &&
                                        !usersQuery.isFetching && (
                                            <tr>
                                                <td
                                                    colSpan={7}
                                                    className="text-center py-4 text-red-500"
                                                >
                                                    Error loading users
                                                </td>
                                            </tr>
                                        )}

                                    {usersQuery.isSuccess && (
                                        <tr className="border-t-2">
                                            <td colSpan={8}>
                                                <PaginationV2
                                                    pages={links}
                                                    from={from}
                                                    to={to}
                                                    total={total}
                                                    next={next}
                                                    prev={prev}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {showCreateUserModal && (
                <NewUser setShowCreateUserModal={setShowCreateUserModal} />
            )}
        </div>
    )
}
