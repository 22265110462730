import React, { useContext, useEffect, useState } from 'react'
import LocaleContext from "../LocaleContext";
import {Button, Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Loading from "../shared-components/Loading";
import Error from "../shared-components/Error";
import CancelModal from "./CancelModal";

interface ManualInstructionsProps {
  hostedCheckoutData: Paywell.API.V1.HostedCheckout
  returnToMerchantFn: () => void
  hideManualInstructions: () => void
}

const ManualInstructions = (props: ManualInstructionsProps) => {

  const { locale, languageOnly } = useContext(LocaleContext)
  const { t } = useTranslation()

  const [timer, setTimer] = useState<null | NodeJS.Timer>(null)
  const [minutes, setMinutes] = useState<number>(-1)
  const [seconds, setSeconds] = useState<number>(-1)
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false)


  useEffect(() => {
    if (props.hostedCheckoutData.weborder.expiry) {
      // Start the countdown when we have an expiry time
      countDown()
    }
    countDown()
  }, [])

  const countDown = () => {
    if (timer !== null) {
      // Stop further execution if the countDown has already started
      return
    }

    const updateCountdown = () => {
      if (props.hostedCheckoutData.weborder.expiry) {
        const second = 1000,
          minute = second * 60,
          hour = minute * 60,
          day = hour * 24

        const countDown = new Date(
          props.hostedCheckoutData.weborder.expiry
        ).getTime()
        const now = new Date().getTime()
        const distance = countDown - now

        setMinutes(Math.floor((distance % hour) / minute))
        setSeconds(Math.floor((distance % minute) / second))
      }
    }
    // Set the update to happen every 1 second
    setTimer(setInterval(updateCountdown, 1000))
  }

  return (
    <>
        <div>
          <div className="flex justify-center">
            {languageOnly(locale) === 'fr' && (
              <img
                src="/interacvirement.png"
                width="200px"
              ></img>
            )}
            {languageOnly(locale) === 'en' && (
              <img
                src="/interac_etransfer.png"
                width="200px"
              ></img>
            )}
          </div>
          <div className="mt-4">
            {seconds !== -1 && minutes !== -1 && (
              <div className="text-center">
                                  <span className="h5">
                                      {t('timeremaining')}
                                  </span>
                <div id="paywell-countdown">
                  <ul>
                    <li>
                                              <span id="paywell-minutes">
                                                  {minutes}
                                              </span>
                      {t('minutes')}
                    </li>
                    <li
                      style={{
                        paddingLeft: '1em',
                      }}
                    >
                                              <span id="paywell-seconds">
                                                  {seconds}
                                              </span>
                      {t('seconds')}
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>

          <div className=" divide-gray-200 flex justify-center items-center mt-8">
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div className="flex justify-between space-y-6 sm:space-y-5">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t('paywith')}
                  </h3>
                </div>
              </div>

              <div className="">
                <div className="space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 border-t border-gray-200 sm:pt-5">
                    <div className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('step', { step: '1' })}
                    </div>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <p>{t('bankinglogin')}</p>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 border-t border-gray-200 sm:pt-5">
                    <div className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('step', { step: '2' })}
                    </div>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <p> {t('selectetransfer')}</p>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 border-t border-gray-200 sm:pt-5">
                    <div className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('step', { step: '3' })}
                    </div>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <div className="">
                        <p> {t('setamount')}</p>
                        <div className="border border-gray-400 mt-1 p-2 rounded font-medium">
                          {
                            props.hostedCheckoutData.weborder.amount
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 border-t border-gray-200 sm:pt-5">
                    <div className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('step', { step: '4' })}
                    </div>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <div className="">
                        <p> {t('setmessage')}</p>
                        <div className="border border-gray-400 mt-1 p-2 rounded font-medium">
                          {
                            props.hostedCheckoutData.weborder.display_order
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 border-t border-gray-200 sm:pt-5">
                    <div className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-2">
                      {t('step', { step: '5' })}
                    </div>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <div>
                        {t('questionmessage')}{' '}
                      </div>
                      <div>
                        <div className="border border-gray-400 mt-1 p-2 rounded">
                          {t('setquestion') + ' '}
                          <span className="font-medium">
                                                          paywell
                                                      </span>
                        </div>
                        <div className="border border-gray-400 mt-1 p-2 rounded">
                          {t('setpassword') + ' '}
                          <span className="font-medium">
                                                          {
                                                            props.hostedCheckoutData.paymentDetails
                                                              .interac_etransfer_password
                                                          }
                                                      </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 border-t border-gray-200 sm:pt-5">
                    <div className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('step', { step: '6' })}
                    </div>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <p> {t('sendto')}</p>
                      <div className="border border-gray-400 mt-1 p-2 rounded font-medium">
                        {
                          props.hostedCheckoutData.paymentDetails
                            .interac_etransfer_receiver_email
                        }
                      </div>
                    </div>
                  </div>

                  <div className="pt-5 mt-4">
                    <div className="flex justify-center">
                  {props.hostedCheckoutData.weborder.interac_url ?
                      <button
                        onClick={props.hideManualInstructions}
                        disabled={false}
                        type="submit"
                        className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      >
                        Back To Previous Screen
                      </button>: <></>}
                      <button
                        onClick={props.returnToMerchantFn}
                        disabled={false}
                        type="submit"
                        className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900"
                      >
                        {t('cancel')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default ManualInstructions;