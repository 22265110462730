import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'

export default function PaginationV2({
    pages,
    from,
    to,
    total,
    next,
    prev,
    searchTextDebounce = null,
}: {
    pages: any[]
    from: any
    to: any
    total: any
    next: any
    prev: any
    searchTextDebounce?: string | null
}) {
    const [searchParams] = useSearchParams()
    const current = searchParams.get('page') ? searchParams.get('page') : 1
    const navigate = useNavigate()

    const createPageUrl = (pageNumber: string) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('page', pageNumber)
        return '?' + newSearchParams.toString()
    }

    useEffect(() => {
        if (!searchTextDebounce) return
        navigate(createPageUrl('1'))
    }, [searchTextDebounce])

    const nextPageNumber = () => {
        if (!next) return null
        const match = next.match(/\bpage=([^&#]*)/)
        return match && match[1]
    }

    const prevPageNumber = () => {
        if (!prev) return null
        const match = prev.match(/\bpage=([^&#]*)/)
        return match && match[1]
    }

    return (
        <div className="flex items-center justify-between  bg-white px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
                <a
                    href="#"
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                    Previous
                </a>
                <a
                    href="#"
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                    Next
                </a>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        Showing{' '}
                        <span className="font-medium">
                            {from != null ? from : 0}
                        </span>{' '}
                        to{' '}
                        <span className="font-medium">
                            {to != null ? to : 0}
                        </span>{' '}
                        of{' '}
                        <span className="font-medium">
                            {total != null ? total : 0}
                        </span>{' '}
                        results
                    </p>
                </div>
                <div>
                    <nav
                        className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                        aria-label="Pagination"
                    >
                        {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                        {pages &&
                            pages.map((page: any, index: number) => {
                                if (index === 0) {
                                    if (prevPageNumber()) {
                                        return (
                                            <Link
                                                key={index}
                                                to={createPageUrl(
                                                    prevPageNumber() || ''
                                                )}
                                                className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                                            >
                                                <span className="sr-only">
                                                    Previous
                                                </span>
                                                <ChevronLeftIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </Link>
                                        )
                                    } else {
                                        return (
                                            <div
                                                key={index}
                                                className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-gray-300 px-2 py-2 text-sm font-medium text-gray-500 focus:z-20"
                                            >
                                                <span className="sr-only">
                                                    Previous
                                                </span>
                                                <ChevronLeftIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        )
                                    }
                                }
                                if (pages.length - 1 === index) {
                                    if (nextPageNumber()) {
                                        return (
                                            <Link
                                                key={index}
                                                to={createPageUrl(
                                                    nextPageNumber() || ''
                                                )}
                                                className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                                            >
                                                <ChevronRightIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </Link>
                                        )
                                    } else {
                                        return (
                                            <div
                                                key={index}
                                                className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-gray-300 px-2 py-2 text-sm font-medium text-gray-500 focus:z-20"
                                            >
                                                <ChevronRightIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        )
                                    }
                                }
                                return (
                                    <Link
                                        key={index}
                                        to={createPageUrl(page.label)}
                                        aria-current="page"
                                        className={`relative inline-flex items-center px-4 py-2 text-sm font-medium text-black focus:z-20 ${
                                            current == page.label
                                                ? 'bg-blue-500 text-white hover:bg-sky-600'
                                                : 'bg-white hover:bg-gray-50 border border-gray-300 '
                                        }`}
                                    >
                                        {page.label}
                                    </Link>
                                )
                            })}
                    </nav>
                </div>
            </div>
        </div>
    )
}
