import { BaseRepository } from './BaseRepository'

export class InvoicesRepository extends BaseRepository {
    getInvoices = async (
        page: any,
        search: string | null = null,
        sortColumn: string = 'default_column',
        sortOrder: string = 'asc'
    ) => {
        let url = `/api/v2/invoices?page=${page}`
        if (search) {
            url += `&search=${encodeURIComponent(search)}`
        }
        // Add sortColumn and sortOrder to the URL
        url += `&sortColumn=${encodeURIComponent(
            sortColumn
        )}&sortOrder=${encodeURIComponent(sortOrder)}`

        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.InvoicePaginated>(url)
    }

    getInvoice = async (id: string) => {
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.Invoice>(`/api/v2/invoices/${id}`)
    }

    createInvoice = async () => {
        return await super.getHttpClientInstance().post(`/api/v2/invoices`)
    }

    newInvoice = async () => {
        return await super.getHttpClientInstance().post(`/api/v2/invoices`)
    }

    updateInvoice = async (
        id: string,
        due_date: string,
        customer: Paywell.API.V2.Customers,
        invoice_number: string,
        invoice_date: string | undefined
    ) => {
        return await super
            .getHttpClientInstance()
            .put(`/api/v2/invoices/${id}`, {
                due_date: due_date,
                invoice_date: invoice_date,
                invoice_number: invoice_number,
                customer_id: customer?.id,
                first_name: customer?.first_name,
                last_name: customer?.last_name,
                billing_address: customer?.billing_address,
                billing_city: customer?.billing_city,
                billing_province: customer?.billing_province,
                billing_country: customer?.billing_country,
                billing_postal: customer?.billing_postal_code,
            })
    }

    deleteInvoice = async (id: string) => {
        return await super
            .getHttpClientInstance()
            .delete(`/api/v2/invoices/${id}`)
    }

    getCheckoutInvoice = async (id: string) => {
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.Invoice>(`/api/v2/invoice/get/${id}`)
    }

    invoiceCheckout = async (
        payment_token: string,
        name: string,
        postal: string,
        price: number,
        uuid: string | undefined
    ) => {
        return await super
            .getHttpClientInstance()
            .post(`/api/v2/invoice/pay/nmi`, {
                payment_token: payment_token,
                name: name,
                postal: postal,
                price: price,
                uuid: uuid,
            })
    }

    invoiceCheckoutInstant = async (
        uuid: string | undefined,
        price: string,
        customer_name: string,
        customer_email: string
    ) => {
        return await super
            .getHttpClientInstance()
            .post(`/api/v2/invoice/pay/instant`, {
                uuid: uuid,
                price: price,
                customer_name: customer_name,
                customer_email: customer_email,
            })
    }

    sendInvoiceEmail = async (id: string) => {
        return await super
            .getHttpClientInstance()
            .get(`/api/v2/invoice/email/${id}`)
    }

    getPaymentHistory = async (uuid: string) => {
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.Transaction[]>(
                `/api/v2/invoice/payment-history/${uuid}`
            )
    }

    getEmailReminders = async () => {
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.EmailRemindersPaginated>(
                `/api/v2/invoice/email-reminders`
            )
    }

    getEmailReminder = async (id: number) => {
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.EmailReminder>(
                `/api/v2/invoice/email-reminders/${id}`
            )
    }

    saveEmailReminder = async (
        email_reminder: Paywell.API.V2.EmailReminder
    ) => {
        return await super
            .getHttpClientInstance()
            .put(`/api/v2/invoice/email-reminders/${email_reminder.id}`, {
                ...email_reminder,
            })
    }

    createEmailReminder = async (
        email_reminder: Paywell.API.V2.EmailReminder
    ) => {
        return await super
            .getHttpClientInstance()
            .post(`/api/v2/invoice/email-reminders`, {
                ...email_reminder,
            })
    }

    deleteEmailReminder = async (id: number) => {
        return await super
            .getHttpClientInstance()
            .delete(`/api/v2/invoice/email-reminders/${id}`)
    }

    getInvoiceInteracInfo = async (uuid: string | undefined) => {
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.UserInteracInfo>(
                `/api/v2/invoice/interac-info/${uuid}`
            )
    }

    getInvoiceWireTransferInfo = async (uuid: string | undefined) => {
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.UserWireTransferInfo>(
                `/api/v2/invoice/wire-transfer-info/${uuid}`
            )
    }

    getPaymentMethods = async (invoice_id: number | undefined) => {
        let endpoint = `/api/v2/invoice/payment-options`
        if (invoice_id !== undefined) {
            endpoint = `/api/v2/invoice/payment-options/${invoice_id}`
        }
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.PaymentMethodOptions>(endpoint)
    }

    updatePaymentMethodOptions = async (
        paymentOption: Paywell.API.V2.PaymentMethodOptions,
        invoice_id: number | undefined
    ) => {
        let endpoint = `/api/v2/invoice/payment-options`
        if (invoice_id !== undefined) {
            endpoint = endpoint + `/${invoice_id}`
        }

        return await super.getHttpClientInstance().put(endpoint, {
            ...paymentOption,
        })
    }

    saveManualPaymentTransaction = async (
        manualPaymentTransaction: Paywell.API.V2.ManualPaymentTransaction,
        invoice_uuid: string | undefined
    ) => {
        let endpoint = `/api/v2/invoice/transactions/${invoice_uuid}`
        return await super.getHttpClientInstance().post(endpoint, {
            ...manualPaymentTransaction,
        })
    }

    uploadInvoiceBrandingLogo = async (file: FormData) => {
        let url = `/api/v2/invoice/upload-branding-logo`

        return await super.getHttpClientInstance().post(url, file)
    }

    markInvoicesPaid = async (
        invoice_uuids: string[],
        type: 'eft' | 'cheque' | 'e-transfer'
    ) => {
        let url = `/api/v2/invoices/mark-as-paid`

        return await super.getHttpClientInstance().post(url, {
            invoice_uuids: invoice_uuids,
            type: type,
        })
    }

    generateInvoices = async () => {
        let url = 'api/v2/invoices/generate-invoices'

        return await super.getHttpClientInstance().post(url)
    }
}
