import React, { ChangeEvent, useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from '../redux/hooks'
import { LoginRepository } from '../api/LoginRepository'
import { useMutation, useQuery } from 'react-query'
import { Navigate, useSearchParams } from 'react-router-dom'
import { setLoggedIn } from '../redux/state-slices/AuthUserSlice'
import Loading from '../shared-components/Loading'
import { LockClosedIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'
import Error from '../shared-components/Error'
export default function Login() {
    const [searchParams] = useSearchParams()
    const page = searchParams.get('redirect')
        ? searchParams.get('redirect')
        : null
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [invalidLoginAttempt, setInvalidLoginAttempt] = useState(false)
    const [genericErrorOccurred, setGenericErrorOccurred] = useState(false)
    const authUser: Paywell.ReduxStore.Auth.User | null = useAppSelector(
        (state) => state.auth_user
    )
    const logoPath = useAppSelector((state) => state.branding.company_logo)

    const loginRepository = new LoginRepository()

    const getCSRFQuery = useQuery(
        'get-csrf',
        async () => {
            return (await loginRepository.getCSRF()).data
        },
        {
            enabled: false,
            staleTime: Infinity,
            retry: false,
            onSuccess: () => {
                loginUserMutation.mutate()
            },
        }
    )

    const loginUserMutation = useMutation({
        mutationFn: () => loginRepository.login(email, password),
        onError: (err: any) => {
            if (err.status === 422) {
                setInvalidLoginAttempt(true)
            } else {
                setGenericErrorOccurred(true)
            }
        },

        onSuccess: (data) => {
            setInvalidLoginAttempt(false)
            setGenericErrorOccurred(false)

            const authUser: Paywell.ReduxStore.Auth.User = {
                id: data.data.id,
                email: data.data.email,
                account_status: data.data.account_status,
                email_verified: data.data.email_verified_at,
                merchant_name: data.data.merchant_name,
                user_type_id: data.data.user_type_id,
                nmi_user: data.data.nmi_user,
            }
            dispatch(setLoggedIn(authUser))
        },
    })

    const handleLogin = (e: React.FormEvent) => {
        e.preventDefault()
        setInvalidLoginAttempt(false)
        setGenericErrorOccurred(false)
        getCSRFQuery.refetch()
    }
    const emailChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
    }
    const passwordChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    }

    return (
        <div>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8">
                    <div>
                        <img
                            className="mx-auto h-12 w-auto"
                            src={logoPath || ''}
                        />
                        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                            Sign in to your account
                        </h2>
                    </div>
                    <form className="mt-8 space-y-6" onSubmit={handleLogin}>
                        <div className="-space-y-px rounded-md shadow-sm">
                            <div>
                                <label
                                    htmlFor="email-address"
                                    className="sr-only"
                                >
                                    Email address
                                </label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Email address"
                                    value={email}
                                    onChange={emailChanged}
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="sr-only">
                                    Password
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Password"
                                    value={password}
                                    onChange={passwordChanged}
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <div className="text-sm">
                                    <a
                                        onClick={() => navigate('/register')}
                                        className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                                    >
                                        Create an account
                                    </a>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="text-sm">
                                    {/**
                                     * open link in new tab
                                     */}
                                    <a
                                        target="_blank"
                                        href={`${process.env.REACT_APP_API_BASE_URL}/password/reset`}
                                        className="font-medium text-indigo-600 hover:text-indigo-500"
                                    >
                                        Forgot your password?
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                disabled={
                                    loginUserMutation.isLoading ? true : false
                                }
                            >
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <LockClosedIcon
                                        className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                                        aria-hidden="true"
                                    />
                                </span>
                                Sign in
                            </button>
                            {loginUserMutation.isLoading && (
                                <div className="flex items-center justify-center mt-4">
                                    <Loading width="8" height="8" />
                                </div>
                            )}

                            {invalidLoginAttempt && (
                                <div className="flex items-center justify-center mt-4">
                                    <Error
                                        title="Invalid Login"
                                        message="These credentials do not match our records"
                                    />
                                </div>
                            )}

                            {genericErrorOccurred && (
                                <div className="flex items-center justify-center mt-4">
                                    <Error
                                        title="An error has occurred"
                                        message="Please reach out to one of our representatives if this error persists"
                                    />
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
