import { ReceiptPercentIcon } from '@heroicons/react/20/solid'
import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { TaxRepository } from '../../api/TaxRepository'
import ErrorV2 from '../../shared-components/ErrorV2'
import Loading from '../../shared-components/Loading'
import { SentryUtils } from '../../utils/SentryUtils'

export default function TaxView() {
    const { id } = useParams()
    const taxesRepository = new TaxRepository()
    const taxQuery = useQuery(['tax', id], async () => {
        return (
            await taxesRepository.getTax(
                SentryUtils.expect<number>(id ? parseInt(id) : 0)
            )
        ).data
    })

    const tax = taxQuery.data

    if (taxQuery.isLoading) {
        return (
            <div>
                <Loading width="8" height="8" />
            </div>
        )
    }

    if (taxQuery.isError)
        return (
            <div>
                <ErrorV2
                    title="An error has occured"
                    message="An error has occurred while loading your tax rate. Please try refreshing, if this keeps happening contact support."
                />
            </div>
        )

    return (
        <div>
            <div className="float-right ">
                <Link
                    to={`/invoicing/taxes/edit/${id}`}
                    type="submit"
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                >
                    Edit
                </Link>
            </div>
            <div className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div className="space-y-6 sm:space-y-5">
                        <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                            <div className="space-y-6 sm:space-y-5">
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Tax Name
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                        <span className="rounded-lg border border-gray-300 bg-slate-50 px-2 py-2 w-2/3">
                                            {tax?.name}
                                        </span>
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Tax Rate
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                        <span className="rounded-lg border border-gray-300 bg-slate-50 px-2 py-2 w-2/3 flex flex-row">
                                            {tax && tax.tax / 100}
                                            <ReceiptPercentIcon
                                                className="h-5 w-5 pt-1 text-gray-400"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
