import { Suspense, useEffect, useState } from 'react'
import './App.scss'
import { Routes, Route, Navigate } from 'react-router-dom'
import Register from './onboarding/Register'
import Onboarding from './onboarding/Onboarding'
import HostedCheckout from './hosted-checkout/HostedCheckout'
import Home from './home/Home'
import Loading from './shared-components/Loading'
import i18n from 'i18next'
import LocaleContext from './LocaleContext'
import ConfirmEmail from './onboarding/ConfirmEmail'
import PrivateRoutes from './PrivateRoutes'
import Login from './login/Login'
import VerifyEmail from './misc/VerifyEmail'
import TopNav from './navbar/TopNav'
import { useAppSelector, useAppDispatch } from './redux/hooks'
import { LoginRepository } from './api/LoginRepository'
import { useQuery } from 'react-query'
import { setLoggedIn, setLoggedOut } from './redux/state-slices/AuthUserSlice'
import RegistrationComplete from './routes/RegistrationComplete'
import AccountIsActive from './routes/AccountIsActive'
import HandleAccountStatus from './routes/HandleAccountStatus'
import GlobalNotification from './shared-components/GlobalNotification'
import InvoiceSystem from './invoices/main'
import VerifiedUser from './VerifiedUser'
import AgencyIFrameComponent from './AgencyIFrameComponent'
import { ReferalCheck } from './routes/ReferralCheck'

import Demo from './v2-demo/Demo'
import InvoiceCheckoutPathChooser from './invoices/invoices/invoice-checkout-options/InvoiceCheckoutPathChooser'
import QuickAdds from './quick-adds/QuickAdds'
import EftsRoutes from './efts/EftsRoutes'
import TransactionRoutes from './transactions/TransactionRoutes'
import UserRoutes from './users/UserRoutes'
import OnboardingPathChooser from './onboarding/OnboardingPathChooser'
import OnboardingType from './routes/OnboardingType'
import OnboardingRoutes from './onboarding/OnboardingRoutes'
import DataImport from './data-import/DataImport'

const App = () => {
    const [locale, setLocale] = useState(i18n.language)
    i18n.on('languageChanged', (lng) => setLocale(i18n.language))
    const languageOnly = (langCode: string) => langCode.split('-')[0]

    const authUser = useAppSelector((state) => state.auth_user)
    const isAdmin = authUser?.user_type_id === 3
    const isAgent = authUser?.user_type_id === 2
    const isAdminOrAgent = isAdmin || isAgent
    const dispatch = useAppDispatch()
    const loginRepository = new LoginRepository()
    const branding = useAppSelector((state) => state.branding)
    const referral = ReferalCheck()
    const onBoardingType = useAppSelector((state) => state.onboarding_type)
    const checkUserValidQuery = useQuery(
        'check-valid-user',
        async () => {
            return await loginRepository.checkUserValid()
        },
        {
            staleTime: 0,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                const authUser: Paywell.ReduxStore.Auth.User = {
                    id: data.data.id,
                    email: data.data.email,
                    email_verified: data.data.email_verified_at,
                    account_status: data.data.account_status,
                    merchant_name: data.data.merchant_name,
                    user_type_id: data.data.user_type_id,
                    nmi_user: data.data.nmi_user,
                }
                dispatch(setLoggedIn(authUser))
            },
            onError: () => {
                dispatch(setLoggedOut())
            },
        }
    )

    const checkIfUrlContainsDemo = () => {
        if (window.location.href.indexOf('demo') > -1) {
            return true
        }
        return false
    }

    useEffect(() => {
        checkUserValidQuery.refetch()
        const descriptionMetaTag = document.querySelector(
            'meta[name="description"]'
        )

        if (descriptionMetaTag && branding.company_description) {
            descriptionMetaTag.setAttribute(
                'content',
                branding.company_description
            )
        }
        if (branding && branding.company_name) {
            document.title = branding.company_name
        }

        let faviconLink = document.querySelector(
            "link[rel~='icon']"
        ) as HTMLLinkElement
        if (!faviconLink) {
            faviconLink = document.createElement('link')
            faviconLink.rel = 'icon'
            document.getElementsByTagName('head')[0].appendChild(faviconLink)
        }
        faviconLink.href = branding.favicon_url || ''
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (
        checkUserValidQuery.isFetching ||
        checkUserValidQuery.isLoading ||
        checkUserValidQuery.isRefetching
    ) {
        return (
            <div className="flex h-screen">
                <div className="m-auto">
                    <Loading width="8" height="8" />
                </div>
            </div>
        )
    } else if (checkIfUrlContainsDemo()) {
        return (
            <div className="">
                <div className="">
                    <Suspense fallback={<Loading height="8" width="8" />}>
                        <div>
                            <Routes>
                                <Route path="/demo" element={<Demo />} />
                            </Routes>
                        </div>
                    </Suspense>
                </div>
            </div>
        )
    } else {
        return (
            <div className="h-full min-h-screen">
                <LocaleContext.Provider
                    value={{ locale, languageOnly, setLocale }}
                >
                    <Suspense fallback={<Loading width="8" height="8" />}>
                        <TopNav />
                        <GlobalNotification />
                        <div className="">
                            <Routes>
                                <Route
                                    path="/invoice/:id"
                                    element={<InvoiceCheckoutPathChooser />}
                                />
                                <Route element={<PrivateRoutes />}>
                                    <Route element={<VerifiedUser />}>
                                        <Route
                                            element={<RegistrationComplete />}
                                        >
                                            <Route
                                                element={<AccountIsActive />}
                                            >
                                                {/*AUTH ROUTES*/}
                                                <Route
                                                    element={<Home />}
                                                    path="/home"
                                                />
                                                <Route
                                                    element={
                                                        authUser?.nmi_user ? (
                                                            <InvoiceSystem />
                                                        ) : (
                                                            <Navigate to="/home" />
                                                        )
                                                    }
                                                    path="/invoicing/*"
                                                />
                                                <Route
                                                    element={
                                                        isAdmin ? (
                                                            <QuickAdds />
                                                        ) : (
                                                            <Navigate to="/home" />
                                                        )
                                                    }
                                                    path="/quick-add/*"
                                                />
                                                <Route
                                                    element={
                                                        isAdmin ? (
                                                            <EftsRoutes />
                                                        ) : (
                                                            <Navigate to="/home" />
                                                        )
                                                    }
                                                    path="/eft-batches/*"
                                                />
                                                <Route
                                                    element={
                                                        isAdmin ? (
                                                            <DataImport />
                                                        ) : (
                                                            <Navigate to="/home" />
                                                        )
                                                    }
                                                    path="/import/*"
                                                />

                                                <Route
                                                    element={
                                                        <TransactionRoutes />
                                                    }
                                                    path="/transactions/*"
                                                />
                                                <Route
                                                    element={
                                                        isAdminOrAgent ? (
                                                            <UserRoutes />
                                                        ) : (
                                                            <Navigate to="/home" />
                                                        )
                                                    }
                                                    path="/users/*"
                                                />

                                                <Route
                                                    path="/legacy/*"
                                                    element={
                                                        <AgencyIFrameComponent />
                                                    }
                                                />
                                            </Route>
                                            <Route
                                                element={
                                                    <HandleAccountStatus />
                                                }
                                                path="/account"
                                            />
                                        </Route>

                                        <Route element={<OnboardingType />}>
                                            <Route
                                                element={<OnboardingRoutes />}
                                                path="/onboarding/*"
                                            />
                                        </Route>

                                        {authUser?.account_status ===
                                            'REGISTERING' && (
                                            <Route
                                                element={
                                                    <OnboardingPathChooser />
                                                }
                                                path="/application"
                                            />
                                        )}
                                    </Route>

                                    <Route
                                        element={
                                            authUser?.email_verified ? (
                                                <Navigate to={referral} />
                                            ) : (
                                                <ConfirmEmail />
                                            )
                                        }
                                        path="/confirm-email"
                                    />
                                </Route>

                                <Route
                                    path="/register"
                                    element={
                                        authUser ? (
                                            <Navigate to={referral} />
                                        ) : (
                                            <Register />
                                        )
                                    }
                                />
                                <Route
                                    path="/login"
                                    element={
                                        authUser ? (
                                            <Navigate to={referral} />
                                        ) : (
                                            <Login />
                                        )
                                    }
                                />
                                <Route
                                    path="/hosted-checkout/:uuid"
                                    element={<HostedCheckout />}
                                />
                                <Route
                                    path="/verify-email/:id/:hash"
                                    element={<VerifyEmail />}
                                />
                                <Route
                                    path="/*"
                                    element={<Navigate to="/login" />}
                                />
                                {/*page-not-found route */}
                            </Routes>
                        </div>
                    </Suspense>
                </LocaleContext.Provider>
            </div>
        )
    }
}

export default App
