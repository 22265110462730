import React, { useContext, useEffect, useState } from 'react'
import LocaleContext from '../LocaleContext'
import i18n from 'i18next'
import { LogoutRepository } from '../api/LogoutRepository'
import { useMutation } from 'react-query'
import { setLoggedOut } from '../redux/state-slices/AuthUserSlice'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

const TopNav = () => {
    const { locale } = useContext(LocaleContext)
    const [currLang, setCurrLang] = useState('English')
    const logoutRepository = new LogoutRepository()
    const authUser = useAppSelector((state) => state.auth_user)
    const [showBanner, setShowBanner] = useState(false)
    const logoPath =
        useAppSelector((state) => state.branding.company_logo) || '/paywell.svg'

    const [navigationMenu, setNavigationMenu] = useState<Array<any>>([])

    const [accountSettingsNavigation, setAccountSettingsNavigation] = useState<
        Array<any>
    >([])

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const location = useLocation()
    const pathName = location.pathname

    useEffect(() => {
        handleNavigationMenuOptions(authUser?.user_type_id)
    }, [authUser])

    const changeLocale = (l: string) => {
        if (locale !== l) {
            i18n.changeLanguage(l)
        }
    }

    const getLanguage = (langCode: string): string => {
        // Filter out the country code
        langCode = langCode.split('-')[0]
        const languages = {
            en: 'English',
            fr: 'Français',
        }
        type ObjectKey = keyof typeof languages
        return languages[langCode as ObjectKey]
    }

    const logoutUserMutation = useMutation({
        mutationFn: () => logoutRepository.logout(),
        onError: (data) => {
            alert('An error has occurred')
        },

        onSuccess: (data) => {
            dispatch(setLoggedOut())
            navigate('/login')
        },
    })

    const handleNavigationMenuOptions = (accountType: number | undefined) => {
        if (!checkIfUrlContainsHostedCheckout()) {
            if (authUser) {
                if (accountIsActive) {
                    const defaultNavOptions = [
                        {
                            name: 'Transactions',
                            href: 'transactions',
                            current: false,
                        },
                        {
                            name: 'Batches',
                            href: 'legacy/batches',
                            current: false,
                        },
                    ]
                    if (authUser.nmi_user) {
                        defaultNavOptions.push({
                            name: 'Invoices',
                            href: 'invoicing',
                            current: false,
                        })
                    }
                    const defaultAccountSettingsNavigation = [
                        { name: 'Settings', type: 'settings' },
                        { name: 'Sign out', type: 'signout' },
                    ]
                    if (accountType === 3) {
                        const adminOptions = [
                            {
                                name: 'Quick Adds',
                                href: 'quick-add',
                                current: false,
                            },

                            {
                                name: 'Users',
                                href: 'users',
                                current: false,
                            },
                            {
                                name: 'Efts',
                                href: 'eft-batches',
                                current: false,
                            },
                            {
                                name: 'Commissions',
                                href: 'legacy/commissions',
                                current: false,
                            },
                            {
                                name: 'Pricing',
                                href: 'legacy/pricing',
                                current: false,
                            },
                            {
                                name: 'Import',
                                href: 'import',
                                current: false,
                            },
                        ]

                        setNavigationMenu([
                            ...defaultNavOptions,
                            ...adminOptions,
                        ])
                    } else if (accountType === 2) {
                        const agentOptions = [
                            {
                                name: 'Merchant Billing',
                                href: 'legacy/invoices',
                                current: false,
                            },

                            {
                                name: 'Agent Logo',
                                href: 'legacy/images',
                                current: false,
                            },
                            {
                                name: 'Commissions',
                                href: 'legacy/commissions',
                                current: false,
                            },
                            {
                                name: 'Users',
                                href: 'users',
                                current: false,
                            },
                            {
                                name: 'Pricing',
                                href: 'legacy/pricing',
                                current: false,
                            },
                        ]

                        setNavigationMenu([
                            ...defaultNavOptions,
                            ...agentOptions,
                        ])
                    } else if (accountType === 1) {
                        const merchantOptions = [
                            {
                                name: 'Merchant Billing',
                                href: 'legacy/invoices',
                                current: false,
                            },
                        ]
                        setNavigationMenu([
                            ...merchantOptions,
                            ...defaultNavOptions,
                        ])
                    } else if (accountType === 4) {
                        const phantomOptions = [
                            {
                                name: 'Users',
                                href: 'users',
                                current: false,
                            },
                        ]

                        setNavigationMenu([
                            ...defaultNavOptions,
                            ...phantomOptions,
                        ])
                    }
                    if (accountType === 4) {
                        setAccountSettingsNavigation([
                            { name: 'Sign out', type: 'signout' },
                        ])
                    } else {
                        setAccountSettingsNavigation(
                            defaultAccountSettingsNavigation
                        )
                    }
                } else {
                    const inactiveSettingsNav = [
                        { name: 'Sign Out', type: 'signout' },
                    ]

                    setNavigationMenu([])
                    setAccountSettingsNavigation(inactiveSettingsNav)
                }
            } else {
                const unAuthNavOptions = [
                    { name: 'Home', href: 'home', current: false },
                ]

                const unAuthAccountSettingsNavigation = [
                    { name: 'Sign in', type: 'signin' },
                ]

                setNavigationMenu(unAuthNavOptions)
                setAccountSettingsNavigation(unAuthAccountSettingsNavigation)
            }
        }
    }

    const logout = () => {
        logoutUserMutation.mutate()
    }

    const handleAccountSettingsClick = (type: string) => {
        if (type === 'signout') {
            logout()
        }

        if (type === 'settings') {
            navigate('/legacy/settings')
        }

        if (type === 'signin') {
            navigate('/login')
        }
    }

    const checkIfUrlContainsHostedCheckout = () => {
        if (pathName.includes('hosted-checkout')) return true

        return false
    }

    const checkIfInvoiceCheckoutPage = () => {
        return location.pathname.split('/')[1] === 'invoice'
    }

    const hideBanner = () => {
        setShowBanner(false)
    }
    const classNames = (...classes: string[]) => {
        return classes.filter(Boolean).join(' ')
    }

    const isCurrentPage = (href: string) => {
        return pathName.endsWith(href)
    }
    const isInvoiceCheckout = checkIfInvoiceCheckoutPage()
    const isHostedCheckout = checkIfUrlContainsHostedCheckout()
    const accountIsActive = authUser?.account_status === 'ACTIVE'
    const accountNotRegistering = authUser?.account_status !== 'REGISTERING'

    if (isInvoiceCheckout) {
        return <></>
    }

    if (isHostedCheckout) {
        return (
            <Disclosure as="header" className="bg-white shadow">
                {({ open }) => (
                    <>
                        <div className="mx-auto  p-2 sm:px-4 lg:px-8">
                            <div className="relative z-10 flex items-center justify-between lg:hidden">
                                {/* Mobile menu button */}
                                <img
                                    onClick={() => navigate('/home')}
                                    className="block h-8 w-auto lg:hidden"
                                    src={logoPath || ''}
                                    alt="brand logo"
                                />
                                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                    <span className="sr-only">Open menu</span>
                                    {open ? (
                                        <XMarkIcon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <Bars3Icon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <nav
                                className="hidden lg:flex lg:space-x-8 lg:py-2 justify-between"
                                aria-label="Global"
                            >
                                <div className="flex">
                                    <div className="flex flex-shrink-0 items-center">
                                        <img
                                            onClick={() => navigate('/home')}
                                            className="hidden h-8 w-auto lg:block cursor-pointer"
                                            src={logoPath || ''}
                                            alt="brand logo"
                                        />
                                    </div>
                                </div>

                                <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                                    {/* Language gear dropdown */}
                                    <Menu
                                        as="div"
                                        className="relative ml-4 flex-shrink-0"
                                    >
                                        <div>
                                            <Menu.Button className="flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                <span className="sr-only">
                                                    Open settings menu
                                                </span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-6 h-6"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                    />
                                                </svg>
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            onClick={() =>
                                                                changeLocale(
                                                                    'en'
                                                                )
                                                            }
                                                            className={classNames(
                                                                active
                                                                    ? 'bg-gray-100'
                                                                    : '',
                                                                'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                                            )}
                                                        >
                                                            {getLanguage('en')}
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            onClick={() =>
                                                                changeLocale(
                                                                    'fr'
                                                                )
                                                            }
                                                            className={classNames(
                                                                active
                                                                    ? 'bg-gray-100'
                                                                    : '',
                                                                'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                                            )}
                                                        >
                                                            {getLanguage('fr')}
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </nav>
                        </div>
                        <Disclosure.Panel
                            as="nav"
                            className="lg:hidden"
                            aria-label="Global"
                        >
                            <div className="border-t border-gray-200 pb-3 pt-4">
                                <div className="mt-3 space-y-1 px-2">
                                    <Disclosure.Button
                                        as="a"
                                        onClick={() => changeLocale('en')}
                                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                    >
                                        {getLanguage('en')}
                                    </Disclosure.Button>
                                </div>
                                <div className="mt-3 space-y-1 px-2">
                                    <Disclosure.Button
                                        as="a"
                                        onClick={() => changeLocale('fr')}
                                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                    >
                                        {getLanguage('fr')}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        )
    }
    return (
        <>
            {showBanner && (
                <div className="flex items-center justify-between gap-x-6 bg-gray-900 px-6 py-2.5 sm:pr-3.5 lg:pl-8">
                    <p className="text-sm leading-6 text-white">
                        <a>
                            <strong className="font-semibold">Notice: </strong>
                            We're upgrading our site for an improved experience.
                            Some pages may appear inconsistent during this
                            process. Thank you for your patience and
                            understanding!
                        </a>
                    </p>
                    <button
                        onClick={hideBanner}
                        type="button"
                        className="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]"
                    >
                        <span className="sr-only">Dismiss</span>
                        <XMarkIcon
                            className="h-5 w-5 text-white"
                            aria-hidden="true"
                        />
                    </button>
                </div>
            )}
            <Disclosure as="header" className="bg-white shadow">
                {({ open }) => (
                    <>
                        <div className="mx-auto  p-2 sm:px-4 lg:px-8">
                            <div className="relative z-10 flex items-center justify-between lg:hidden">
                                {/* Mobile menu button */}
                                <img
                                    onClick={() => navigate('/home')}
                                    className="block h-8 w-auto lg:hidden"
                                    src={logoPath || ''}
                                    alt="brand logo"
                                />
                                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                    <span className="sr-only">Open menu</span>
                                    {open ? (
                                        <XMarkIcon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <Bars3Icon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <nav
                                className="hidden lg:flex lg:space-x-8 lg:py-2 "
                                aria-label="Global"
                            >
                                <div className="flex">
                                    <div className="flex flex-shrink-0 items-center">
                                        <a
                                            href="/home"
                                            onClick={() => navigate('/home')}
                                        >
                                            <img
                                                onClick={() =>
                                                    navigate('/home')
                                                }
                                                className="block h-8 w-auto lg:hidden"
                                                src={logoPath || ''}
                                                alt="brand logo"
                                            />
                                        </a>
                                        <a
                                            href="/home"
                                            onClick={() => navigate('/home')}
                                        >
                                            <img
                                                className="hidden h-8 w-auto lg:block cursor-pointer"
                                                src={logoPath || ''}
                                                alt="brand logo"
                                            />
                                        </a>
                                    </div>
                                </div>

                                <div className="flex justify justify-between w-full">
                                    <div>
                                        {accountIsActive &&
                                            navigationMenu.map((item) => (
                                                <Link
                                                    to={item.href}
                                                    key={item.name}
                                                    className={classNames(
                                                        isCurrentPage(item.href)
                                                            ? 'bg-gray-100 text-indigo-600 font-medium'
                                                            : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                                                        'inline-flex items-center rounded-md py-2 px-3 text-sm font-medium cursor-pointer'
                                                    )}
                                                    aria-current={
                                                        isCurrentPage(item.href)
                                                            ? 'page'
                                                            : undefined
                                                    }
                                                >
                                                    {item.name}
                                                </Link>
                                            ))}
                                    </div>
                                    <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                                        {/* Profile dropdown */}
                                        <Menu
                                            as="div"
                                            className="relative ml-4 flex-shrink-0"
                                        >
                                            <div className="flex items-center">
                                                {accountNotRegistering && (
                                                    <div className="mr-3">
                                                        <div className="text-base font-medium text-gray-800">
                                                            {
                                                                authUser?.merchant_name
                                                            }
                                                        </div>
                                                        <div className="text-sm font-medium text-gray-500">
                                                            {authUser?.email}
                                                        </div>
                                                    </div>
                                                )}
                                                <Menu.Button className="flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                    <span className="sr-only">
                                                        Open settings menu
                                                    </span>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                                                        />
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                        />
                                                    </svg>
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    {accountSettingsNavigation.map(
                                                        (item) => (
                                                            <Menu.Item
                                                                key={item.name}
                                                            >
                                                                {({
                                                                    active,
                                                                }) => (
                                                                    <a
                                                                        onClick={() =>
                                                                            handleAccountSettingsClick(
                                                                                item.type
                                                                            )
                                                                        }
                                                                        className={classNames(
                                                                            active
                                                                                ? 'bg-gray-100'
                                                                                : '',
                                                                            'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                                                        )}
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </a>
                                                                )}
                                                            </Menu.Item>
                                                        )
                                                    )}
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>
                            </nav>
                        </div>

                        <Disclosure.Panel
                            as="nav"
                            className="lg:hidden"
                            aria-label="Global"
                        >
                            <div className="space-y-1 px-2 pb-3 pt-2">
                                {accountIsActive &&
                                    navigationMenu.map((item) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            as="a"
                                            onClick={() => {
                                                navigate(item.href)
                                            }}
                                            className={classNames(
                                                isCurrentPage(item.href)
                                                    ? 'bg-gray-100 text-indigo-600 font-medium'
                                                    : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                                                'block rounded-md py-2 px-3 text-base font-medium cursor-pointer'
                                            )}
                                            aria-current={
                                                isCurrentPage(item.href)
                                                    ? 'page'
                                                    : undefined
                                            }
                                        >
                                            {item.name}
                                        </Disclosure.Button>
                                    ))}
                            </div>
                            <div className="border-t border-gray-200 pb-3 pt-4">
                                {accountNotRegistering && (
                                    <div className="flex items-center px-4">
                                        <div className="ml-1">
                                            <div className="text-base font-medium text-gray-800">
                                                {authUser?.merchant_name}
                                            </div>
                                            <div className="text-sm font-medium text-gray-500">
                                                {authUser?.email}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="mt-3 space-y-1 px-2">
                                    {accountSettingsNavigation.map((item) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            as="div"
                                            onClick={() =>
                                                handleAccountSettingsClick(
                                                    item.type
                                                )
                                            }
                                            className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900 cursor-pointer"
                                        >
                                            {item.name}
                                        </Disclosure.Button>
                                    ))}
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </>
    )
}

export default TopNav
