import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { InvoicesRepository } from '../../api/InvoicesRepository'
import { useMutation, useQuery } from 'react-query'
import Loading from '../../shared-components/Loading'
import ErrorV2 from '../../shared-components/ErrorV2'
import { useAppDispatch } from '../../redux/hooks'
import {
    setError,
    setNotice,
} from '../../redux/state-slices/GlobalNotificationSlice'
import TextEditor from './TextEditor'

interface ServerError {
    status: number
    statusText: string
    data: {
        message: string
        errors: {
            [key: string]: string[]
        }
    }
}
export default function EmailReminderEdit() {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const invoicesRepository = new InvoicesRepository()
    const [triggerType, setTriggerType] = useState<
        'BEFORE_DUE_DATE' | 'AFTER_DUE_DATE' | 'INITIAL_REMINDER' | null
    >('BEFORE_DUE_DATE')
    const [reminderPeriod, setReminderPeriod] = useState('')
    const [html, setHtml] = useState('')
    const [isFormValid, setIsFormValid] = useState(false)

    useEffect(() => {
        validateForm()
    }, [reminderPeriod, html, triggerType])

    const getInvoiceReminder = useQuery(
        'invoice-email-reminder',
        async () => {
            if (!id) return
            return await invoicesRepository.getEmailReminder(parseInt(id))
        },
        {
            enabled: !!id,
            refetchOnMount: true,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                setTriggerType(data?.data.trigger_type || null)
                setReminderPeriod(data?.data.reminder_period?.toString() || '')
                setHtml(data?.data.email_body || '')
            },
            onError: (error) => {
                dispatch(
                    setError([
                        'An error has occured. Please try again later. If the problem persists, please contact support.',
                    ])
                )
            },
        }
    )

    const saveEmailReminderMutation = useMutation<any, ServerError, any>({
        mutationFn: (emailReminder: Paywell.API.V2.EmailReminder) =>
            invoicesRepository.saveEmailReminder(emailReminder),
        onSuccess: () => {
            navigate(`/invoicing/settings/email-reminders/${id}`)
        },

        onError: (error) => {
            if (error.status === 422) {
                let errors: string[] = []
                Object.keys(error.data.errors).forEach((key) => {
                    errors.push(error.data.errors[key][0])
                })
                dispatch(setNotice(errors))
            } else {
                dispatch(
                    setError([
                        'An error has occured. Please try again later. If the problem persists, please contact support.',
                    ])
                )
            }
        },
    })

    const validateForm = () => {
        let isFormValid = true

        if (triggerType !== 'INITIAL_REMINDER') {
            if (
                parseInt(reminderPeriod) == 0 ||
                parseInt(reminderPeriod) > 365 ||
                parseInt(reminderPeriod) < 0 ||
                !reminderPeriod
            ) {
                isFormValid = false
            }
        }

        if (!triggerType) {
            isFormValid = false
        }

        if (!validateHtml()) {
            isFormValid = false
        }

        setIsFormValid(isFormValid)
    }

    const handleReminderPeriodChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        let inputValue = parseInt(event.target.value)

        //if the value is between 0 and 365, set the state
        if (inputValue > 0 && inputValue <= 365) {
            setReminderPeriod(event.target.value)
        }

        if (!inputValue) {
            setReminderPeriod('')
        }
    }

    const handleTriggerTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setTriggerType(
            event.target.value as
                | 'BEFORE_DUE_DATE'
                | 'AFTER_DUE_DATE'
                | 'INITIAL_REMINDER'
                | null
        )
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (!isFormValid) return

        const emailReminder: Paywell.API.V2.EmailReminder = {
            trigger_type: triggerType,
            reminder_period:
                triggerType === 'INITIAL_REMINDER'
                    ? 1
                    : parseInt(reminderPeriod || ''),
            email_body: html,
            id: parseInt(id || ''),
            created_at: null,
            updated_at: null,
        }

        saveEmailReminderMutation.mutate(emailReminder)
    }

    const validateHtml = () => {
        //remove all html tags
        const textContent = html.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, '')
        const valid = textContent.trim() !== ''
        return valid
    }

    const triggerTypeInitialEmail = triggerType === 'INITIAL_REMINDER'

    if (getInvoiceReminder.isLoading || getInvoiceReminder.isFetching)
        return <Loading height={'5'} width={'5'} />

    if (getInvoiceReminder.isError) {
        return (
            <ErrorV2
                title="An error has occured"
                message="We were unable to load the email reminder. Please try again later. If the problem persists, please contact support."
            />
        )
    }

    return (
        <div className=" mt-6 border-t border-gray-100 bg-gray-50 px-4 py-6 sm:px-3">
            <form
                className="mt-1 text-sm text-gray-700 space-y-4"
                onSubmit={handleSubmit}
            >
                {!triggerTypeInitialEmail && (
                    <div>
                        <label
                            className="text-sm font-medium leading-6 text-gray-900"
                            htmlFor="reminder-period"
                        >
                            Reminder Period (days)
                        </label>
                        <input
                            value={reminderPeriod}
                            onChange={handleReminderPeriodChange}
                            type="number"
                            name="number"
                            id="reminder-period"
                            min={0}
                            max={365}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                )}

                <div>
                    <label
                        className="text-sm font-medium leading-6 text-gray-900"
                        htmlFor="reminder-type"
                    >
                        Reminder Type
                    </label>
                    <select
                        onChange={handleTriggerTypeChange}
                        value={triggerType || ''}
                        id="reminder-type"
                        name="reminder-type"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                        <option value="BEFORE_DUE_DATE">
                            Day(s) before due date
                        </option>
                        <option value="AFTER_DUE_DATE">
                            Day(s) after due date
                        </option>
                        <option value="INITIAL_REMINDER">Initial Email</option>
                    </select>
                </div>

                <div>
                    <label
                        className="text-sm font-medium leading-6 text-gray-900"
                        htmlFor="email-body"
                    >
                        Email Body
                    </label>
                    <TextEditor html={html} setHtml={setHtml} />
                </div>

                <div className="flex justify-end space-x-2">
                    <button
                        onClick={() => navigate(-1)}
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Cancel
                    </button>
                    <button
                        disabled={
                            !isFormValid || saveEmailReminderMutation.isLoading
                        }
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    )
}
