import { createSlice, PayloadAction, current } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { useAppSelector } from '../hooks'

interface ProgressItem {
    id: string
    name: string
    href: string
    status: string
    url: string
}

const initialState: Array<ProgressItem> = [
    {
        id: '01',
        name: 'Identity Info',
        href: '#',
        status: 'current',
        url: 'etransfer/identity-info',
    },
    {
        id: '02',
        name: 'Business Info',
        href: '#',
        status: 'upcoming',
        url: 'etransfer/business-info',
    },
    {
        id: '03',
        name: 'Banking Info',
        href: '#',
        status: 'upcoming',
        url: 'etransfer/banking-info',
    },
    {
        id: '04',
        name: 'Pricing',
        href: '#',
        status: 'upcoming',
        url: 'etransfer/pricing',
    },
]

export const StepSlice = createSlice({
    name: 'steps',
    initialState,

    reducers: {
        setStep: (state, action: PayloadAction<number>) => {
            let currentStepIndex = action.payload

            state.forEach((currStep: any, idx: number) => {
                if (idx < currentStepIndex) {
                    currStep.status = 'complete'
                } else if (idx === currentStepIndex) {
                    currStep.status = 'current'
                } else {
                    currStep.status = 'upcoming'
                }
            })
        },

        setSteps: (state, action: PayloadAction<Array<ProgressItem>>) => {
            return action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setStep, setSteps } = StepSlice.actions

export default StepSlice.reducer
