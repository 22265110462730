import { TrashIcon } from '@heroicons/react/20/solid'
import { useMutation, useQuery } from 'react-query'
import { Link, useSearchParams } from 'react-router-dom'
import { TaxRepository } from '../../api/TaxRepository'
import { useAppDispatch } from '../../redux/hooks'
import {
    resetConfirm,
    setConfirm,
    setError,
    setLoad,
    setSuccess,
} from '../../redux/state-slices/GlobalNotificationSlice'
import ErrorV2 from '../../shared-components/ErrorV2'
import PaginationV2 from '../components/PaginationV2'
import SkeletonLoader from '../components/SkeletonLoader'

export default function Taxes() {
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()
    const page = searchParams.get('page') ? searchParams.get('page') : 1
    const taxRepository = new TaxRepository()
    const taxQuery = useQuery(['taxes', page], async () => {
        return await taxRepository.paginatedTaxes(page)
    })
    const total = taxQuery?.data?.data?.total
    const from = taxQuery?.data?.data?.from
    const to = taxQuery?.data?.data?.to
    const next = taxQuery.data?.data?.next_page_url
    const prev = taxQuery.data?.data?.prev_page_url
    const taxes = taxQuery?.data?.data?.data
    const taxesLinks = taxQuery?.data?.data?.links
    const blankMap = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    const deleteTaxMutation = useMutation({
        mutationKey: 'deleteTax',
        mutationFn: (tax_id: number) => taxRepository.deleteTax(tax_id),
        onError: (err: any) => {
            if (err.data.message) dispatch(setError([err.data.message]))
            else
                dispatch(
                    setError([
                        'An error has occured. If this keeps happening, please contact support',
                    ])
                )

            dispatch(resetConfirm(''))
            dispatch(setLoad(false))
        },

        onSuccess: (data) => {
            dispatch(resetConfirm(''))
            dispatch(setLoad(false))
            dispatch(
                setSuccess(['You have successfully deleted the Tax Rate.'])
            )
            taxQuery.refetch()
        },
    })
    return (
        <div className="">
            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    {taxQuery.isError && (
                        <div>
                            <ErrorV2
                                title="An error has occured"
                                message="An error has occurred while loading your taxes. Please try refreshing, if this keeps happening contact support."
                            />
                        </div>
                    )}
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        >
                                            Tax ID
                                        </th>

                                        <th
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            User ID
                                        </th>

                                        <th
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Tax Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Tax Rate
                                        </th>

                                        <th
                                            scope="col"
                                            className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6"
                                        >
                                            <span className="sr-only">
                                                Edit
                                            </span>
                                        </th>
                                        <th
                                            scope="col"
                                            className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6"
                                        ></th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {(taxQuery.isLoading ||
                                        taxQuery.isError) && (
                                        <SkeletonLoader
                                            rows={25}
                                            columns={6}
                                            isError={taxQuery.isError}
                                        />
                                    )}
                                    {taxes &&
                                        taxes.map((tax) => (
                                            <tr key={tax.id}>
                                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                    <Link
                                                        to={`/invoicing/taxes/${tax.id}`}
                                                    >
                                                        {tax.id}
                                                    </Link>
                                                </td>
                                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                    {tax.user_id}
                                                </td>
                                                <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                    <Link
                                                        to={`/invoicing/taxes/${tax.id}`}
                                                    >
                                                        {tax.name}
                                                    </Link>
                                                </td>
                                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                                    {tax.tax}%
                                                </td>
                                                <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <Link
                                                        to={`/invoicing/taxes/${tax.id}`}
                                                        className="text-blue-600 hover:text-blue-900"
                                                    >
                                                        View
                                                        <span className="sr-only">
                                                            , {tax.id}
                                                        </span>
                                                    </Link>
                                                </td>
                                                <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <Link
                                                        to={`/invoicing/taxes/edit/${tax.id}`}
                                                        className="text-blue-600 hover:text-blue-900"
                                                    >
                                                        Edit
                                                        <span className="sr-only">
                                                            , {tax.id}
                                                        </span>
                                                    </Link>
                                                </td>
                                                <td>
                                                    <TrashIcon
                                                        className="fill-red-500 h-4 w-4 cursor-pointer"
                                                        onClick={(e) =>
                                                            dispatch(
                                                                setConfirm({
                                                                    title: 'Confirm Tax Deletion',
                                                                    message:
                                                                        'Are you sure you would like to delete this tax rate? Doing this cannot be undone!',
                                                                    onClick:
                                                                        () => {
                                                                            dispatch(
                                                                                setLoad(
                                                                                    true
                                                                                )
                                                                            )
                                                                            deleteTaxMutation.mutate(
                                                                                tax.id
                                                                            )
                                                                        },
                                                                })
                                                            )
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    <tr>
                                        <td colSpan={8}>
                                            <PaginationV2
                                                pages={taxesLinks}
                                                from={from}
                                                to={to}
                                                total={total}
                                                next={next}
                                                prev={prev}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
