import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import TaxesNew from '../../taxes/TaxesNew'
import { Link, useNavigate } from 'react-router-dom'
import Loading from '../../../shared-components/Loading'
import { validateTaxName, validateTaxRate } from '../../../utils/RegexHelper'
import { ReceiptPercentIcon } from '@heroicons/react/20/solid'
import { useAppDispatch } from '../../../redux/hooks'
import { TaxRepository } from '../../../api/TaxRepository'
import { useMutation } from 'react-query'
import {
    setError,
    setNotice,
    setSuccess,
} from '../../../redux/state-slices/GlobalNotificationSlice'
import { InvoicesRepository } from '../../../api/InvoicesRepository'
import Decimal from 'decimal.js'

export default function PaymentModal({
    invoice_uuid,
    show,
    onClose,
    callback,
}: {
    invoice_uuid?: string
    show: boolean
    onClose: () => void
    callback: (data: any) => void
}) {
    const cancelButtonRef = useRef(null)
    const invoicesRepository = new InvoicesRepository()
    const handleCallback = (data: any) => {
        callback(data)
    }

    const handleClose = () => {
        onClose()
    }

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [paymentAmount, setPaymentAmount] = useState(0)
    const [paymentName, setPaymentName] = useState('')
    const [paymentType, setPaymentType] = useState<
        'MT' | 'CR' | 'CK' | 'WT' | 'EFT'
    >('MT')

    const savePaymentTransactionMutation = useMutation({
        mutationFn: (manualPayment: Paywell.API.V2.ManualPaymentTransaction) =>
            invoicesRepository.saveManualPaymentTransaction(
                manualPayment,
                invoice_uuid
            ),
        onSuccess: () => {
            callback(null)
        },

        onError: (error) => {
            dispatch(setError(['An error has occurred.']))
        },
    })

    const onCancel = (e: any) => {
        if (onClose) {
            onClose()
            e.preventDefault()
        }
    }

    const handleSubmit = (e: React.FormEvent) => {
        const manualPaymentObject: Paywell.API.V2.ManualPaymentTransaction = {
            name: paymentName,
            amount: paymentAmount,
            type: paymentType,
        }
        savePaymentTransactionMutation.mutate(manualPaymentObject)
        e.preventDefault()
    }

    const handleAmountChange = (amount: number) => {
        let decimalAmount = parseFloat(new Decimal(amount).toFixed(2))
        console.log(decimalAmount)
        setPaymentAmount(decimalAmount)
    }

    const handlePaymentNameChange = (name: string) => {
        setPaymentName(name)
    }

    const handlePaymentTypeChange = (type: string) => {
        const validTypes: Array<'MT' | 'CR' | 'CK' | 'WT' | 'EFT'> = [
            'MT',
            'CR',
            'CK',
            'WT',
            'EFT',
        ]

        if (validTypes.includes(type as 'MT' | 'CR' | 'CK' | 'WT' | 'EFT')) {
            setPaymentType(type as 'MT' | 'CR' | 'CK' | 'WT' | 'EFT')
        }
    }

    return (
        <>
            <Transition.Root show={show} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={onClose}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    <form
                                                        className=""
                                                        onSubmit={(e) =>
                                                            handleSubmit(e)
                                                        }
                                                    >
                                                        <div className="sm:flex ">
                                                            <div className="sm:flex-auto">
                                                                <h1 className="text-xl font-semibold text-gray-900">
                                                                    New Payment
                                                                </h1>
                                                                <p className="mt-2 text-sm text-gray-700">
                                                                    Add a
                                                                    payment on
                                                                    this invoice
                                                                </p>
                                                            </div>
                                                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                                                <div className="pt-5">
                                                                    <div className="flex justify-end">
                                                                        <Link
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                onCancel(
                                                                                    e
                                                                                )
                                                                            }
                                                                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2"
                                                                            to={
                                                                                '/invoicing/taxes'
                                                                            }
                                                                        >
                                                                            Cancel
                                                                        </Link>
                                                                        <button
                                                                            type="submit"
                                                                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2"
                                                                            disabled={
                                                                                savePaymentTransactionMutation.isLoading ||
                                                                                !paymentAmount ||
                                                                                !paymentName
                                                                            }
                                                                        >
                                                                            {savePaymentTransactionMutation.isLoading ? (
                                                                                <Loading
                                                                                    height="6"
                                                                                    width="6"
                                                                                />
                                                                            ) : (
                                                                                'Create'
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                                            <div className="sm:col-span-4">
                                                                <label
                                                                    htmlFor="username"
                                                                    className="block text-sm font-medium leading-6 text-gray-900"
                                                                >
                                                                    Payee Name
                                                                </label>
                                                                <div className="mt-2">
                                                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                                        <input
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                handlePaymentNameChange(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                            type="text"
                                                                            id="name"
                                                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="sm:col-span-4">
                                                                <label
                                                                    htmlFor="country"
                                                                    className="block text-sm font-medium leading-6 text-gray-900"
                                                                >
                                                                    Payment Type
                                                                </label>
                                                                <div className="mt-2">
                                                                    <select
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            handlePaymentTypeChange(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        id="trans_type"
                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                                                    >
                                                                        <option value="MT">
                                                                            e-Transfer
                                                                        </option>
                                                                        <option value="CK">
                                                                            Check
                                                                        </option>
                                                                        <option value="WT">
                                                                            Wire
                                                                        </option>
                                                                        <option value="CR">
                                                                            Crypto
                                                                        </option>
                                                                        <option value="EFT">
                                                                            EFT
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="sm:col-span-4">
                                                                <label
                                                                    htmlFor="username"
                                                                    className="block text-sm font-medium leading-6 text-gray-900"
                                                                >
                                                                    Amount
                                                                </label>
                                                                <div className="mt-2">
                                                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                                        <input
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                handleAmountChange(
                                                                                    parseFloat(
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                )
                                                                            }
                                                                            type="number"
                                                                            id="amount"
                                                                            step="0.01"
                                                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}
