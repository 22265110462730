import { BaseRepository } from './BaseRepository'

export class BankingRepository extends BaseRepository {
    postBankingInfo = async (
        bankAccounts: Paywell.API.V2.UserBankingInfo[] | undefined
    ) => {
        let url = `/api/v2/bank-account/update`

        return await super.getHttpClientInstance().post(url, bankAccounts)
    }

    uploadVoidCheque = async (
        file: FormData,
        bankAccountId?: number | null
    ) => {
        let url = `/api/v2/bank-account/upload-void-cheque/${bankAccountId}`

        return await super.getHttpClientInstance().post(url, file)
    }

    deleteBankAccount = async (bankAccountId: number) => {
        let url = `/api/v2/bank-account/${bankAccountId}`

        return await super.getHttpClientInstance().delete(url)
    }

    createBankAccount = async (userId: number) => {
        let url = `/api/v2/bank-account/create-user-bank-account/${userId}`

        return await super
            .getHttpClientInstance()
            .post<Paywell.API.V2.UserBankingInfo>(url)
    }
}
