import { useAppSelector, useAppDispatch } from '../redux/hooks'
import { LogoutRepository } from '../api/LogoutRepository'

const PendingApproval = () => {
    const authUser = useAppSelector((state) => state.auth_user)
    const branding = useAppSelector((state) => state.branding)
    const supportEmail = branding.contact_email
    const companyIcon = branding.company_icon
    const faqs = [
        {
            question: 'Why does my application need to be reviewed?',
            answer: 'As part of our standard "anti money laundering" and "know your customer" requirements, we have to validate that the information provided on your application is accurate.',
        },
        {
            question: "How will I know when I've been approved?",
            answer: 'You will receive a welcome email notifying you that your account is live!',
        },
        {
            question: 'I entered something wrong, how can I fix it?',
            answer: 'No problem! Our onboarding team will reach out directly to you via email to gather any further information.',
        },
        {
            question: 'How long does this take?',
            answer: 'Application review usually takes 24-48 hours. If anything is wrong, you will be contacted via email.',
        },
    ]

    return (
        <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
            <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
                <div className="flex flex-shrink-0 justify-center">
                    <a href="/" className="inline-flex">
                        <span className="sr-only">{branding.company_name}</span>
                        <img
                            className="h-12 w-auto"
                            src={companyIcon || ''}
                            alt=""
                        />
                    </a>
                </div>
                <div className="py-16">
                    <div className="text-center">
                        <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                            Application submitted!
                        </h1>
                        <p className="mt-2 text-base text-gray-500">
                            Please allow 24-48 hours while we review your
                            application.
                        </p>
                    </div>
                </div>
                <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:py-40 lg:px-8">
                    <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                        <div className="lg:col-span-5">
                            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                                Frequently asked questions
                            </h2>
                            <p className="mt-4 text-base leading-7 text-gray-600">
                                Can’t find the answer you’re looking for? Reach
                                out to our{' '}
                                <a
                                    href={`mailto:${supportEmail}`}
                                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                                >
                                    customer support
                                </a>{' '}
                                team.
                            </p>
                        </div>
                        <div className="mt-10 lg:col-span-7 lg:mt-0">
                            <dl className="space-y-10">
                                {faqs.map((faq) => (
                                    <div key={faq.question}>
                                        <dt className="text-base font-semibold leading-7 text-gray-900">
                                            {faq.question}
                                        </dt>
                                        <dd className="mt-2 text-base leading-7 text-gray-600">
                                            {faq.answer}
                                        </dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default PendingApproval
