import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { RegistrationFormRepository } from '../../api/RegistrationFormRepository'
import { useAppDispatch } from '../../redux/hooks'
import { setLoggedIn } from '../../redux/state-slices/AuthUserSlice'
import { setStep } from '../../redux/state-slices/StepSlice'
import Loading from '../../shared-components/Loading'
import Error from '../../shared-components/Error'
import { useAppSelector } from '../../redux/hooks'
interface PricingFee {
    name: string
    price: string
    paymentFrequency: string
}

export default function Pricing() {
    const registrationFormRepository = new RegistrationFormRepository()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [bigPrice, setBigPrice] = useState({ tx: 0, mdr: 0 })
    const branding = useAppSelector((state) => state.branding)
    const [pricingPlan, setPricingplan] = useState<Array<PricingFee> | null>(
        null
    )

    useEffect(() => {
        window.scrollTo(0, 0)
        setStepState()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const pricingInfoQuery = useQuery(
        'pricing-info',
        async () => {
            return (await registrationFormRepository.getPricingInfo()).data
        },
        {
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (data: Paywell.API.V2.Pricing) => {
                updatePricingPlan(data)
            },
        }
    )

    const completeRegistrationQuery = useQuery(
        'complete-registration',
        async () => {
            return (await registrationFormRepository.completeRegistration())
                .data
        },
        {
            enabled: false,
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                const user: Paywell.ReduxStore.Auth.User = {
                    id: data.id,
                    email: data.email,
                    email_verified: data.email_verified_at,
                    account_status: data.account_status,
                    merchant_name: data.merchant_name,
                    user_type_id: data.user_type_id,
                    nmi_user: data.nmi_user,
                }

                dispatch(setLoggedIn(user))
                navigate('/')
            },
            onError: (error) => {},
        }
    )

    const updatePricingPlan = (pricingInfo: Paywell.API.V2.Pricing) => {
        let newPricingPlan = []
        newPricingPlan.push({
            name: 'Settlement Fee',
            price: '$' + pricingInfo.settlement,
            paymentFrequency: 'Daily',
        })
        newPricingPlan.push({
            name: 'Statement Fee',
            price: '$' + pricingInfo.statement,
            paymentFrequency: 'Monthly',
        })
        newPricingPlan.push({
            name: 'Settlement Hold Fee',
            price: pricingInfo.settlement_hold_fee / 100 + '%',
            paymentFrequency: 'Everyday you hold a settlement',
        })
        newPricingPlan.push({
            name: 'Minimum Monthly Transaction Fee',
            price: '$' + pricingInfo.min,
            paymentFrequency: 'Monthly',
        })
        setBigPrice({ tx: pricingInfo.tx, mdr: pricingInfo.mdr })
        setPricingplan(newPricingPlan)
    }

    const setStepState = () => {
        dispatch(setStep(3))
    }

    if (pricingInfoQuery.isFetching || pricingInfoQuery.isLoading) {
        return (
            <div className="flex h-screen">
                <div className="m-auto">
                    <Loading height="8" width="8" />
                </div>
            </div>
        )
    } else if (pricingInfoQuery.isError) {
        return (
            <div className="flex items-center justify-center mt-4">
                <Error
                    title="An error has occurred"
                    message={`Please reach out to one of our representatives at ${branding.contact_email} if this error persists.`}
                />
            </div>
        )
    }

    return (
        <div className=" bg-white py-24 px-6 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
                <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
                    Pricing
                </h2>
                <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    The right price for you
                </p>
            </div>
            <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                Get the most competitive pricing in the industry - guaranteed!
                Switch to our Interac e-Transfer payment processor and enjoy the
                lowest fees, zero hidden charges, and unbeatable value for your
                money. Join the thousands of satisfied customers who trust us
                for their payment processing needs!
            </p>
            <div className="mx-auto mt-16  max-w-lg  items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl ">
                <div className="rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 relative bg-white shadow-2xl">
                    <h3 className="text-base font-semibold leading-7 text-indigo-600">
                        Pricing Breakdown
                    </h3>
                    <div className="mt-4 px-6">
                        <div className="flex flex-wrap items-center	">
                            <div className="text-4xl font-bold tracking-tight text-gray-900">
                                {bigPrice.mdr}%
                            </div>
                            <div className="text-2xl text-gray-400 mx-2">+</div>
                            <div className="text-3xl font-bold tracking-tight text-gray-900">
                                ¢{bigPrice.tx}
                            </div>
                        </div>
                        <div className="text-sm font-semibold leading-6 text-gray-600">
                            per successful charge
                        </div>
                    </div>

                    <div className="px-6 lg:px-8">
                        <div className="mt-8 flow-root">
                            <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                                >
                                                    Service
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Fee
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {pricingPlan &&
                                                pricingPlan.map((element) => (
                                                    <tr key={element.name}>
                                                        <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                            {element.name}
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                            {element.price}
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                            {
                                                                element.paymentFrequency
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-center mt-4">
                {completeRegistrationQuery.isFetching ||
                completeRegistrationQuery.isLoading ? (
                    <Loading height="8" width="8" />
                ) : (
                    <button
                        type="submit"
                        disabled={
                            completeRegistrationQuery.isLoading ||
                            completeRegistrationQuery.isFetching
                        }
                        onClick={() => {
                            completeRegistrationQuery.refetch()
                        }}
                        className="justify-center rounded-md border bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
                    >
                        Accept and Submit
                    </button>
                )}
            </div>
        </div>
    )
}
