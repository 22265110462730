import { useNavigate } from 'react-router-dom'

export default function InvoiceSettings() {
    const navigate = useNavigate()

    return (
        <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8">
            <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 ">
                <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Invoice Settings
                        </h2>

                        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                            <div className="pt-6 sm:flex">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                    Email Reminders
                                </dt>

                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                    <div className="text-gray-900">
                                        Configure the email reminders that are
                                        sent to your customers.
                                    </div>
                                    <button
                                        type="button"
                                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                                        onClick={() =>
                                            navigate('email-reminders')
                                        }
                                    >
                                        Edit
                                    </button>
                                </dd>
                            </div>
                        </dl>
                        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                            <div className="pt-6 sm:flex">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                    Default Payment Methods
                                </dt>

                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                    <div className="text-gray-900">
                                        Set up the default payment methods for
                                        your invoices.
                                    </div>
                                    <button
                                        type="button"
                                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                                        onClick={() =>
                                            navigate('payment-options')
                                        }
                                    >
                                        Edit
                                    </button>
                                </dd>
                            </div>
                        </dl>
                        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                            <div className="pt-6 sm:flex">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                    Branding Logo
                                </dt>

                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                    <div className="text-gray-900">
                                        Upload a logo to be displayed on your
                                        invoices.
                                    </div>
                                    <button
                                        type="button"
                                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                                        onClick={() => navigate('upload-logo')}
                                    >
                                        Edit
                                    </button>
                                </dd>
                            </div>
                        </dl>
                        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                            <div className="pt-6 sm:flex">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                    Generate Invoices
                                </dt>

                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                    <div className="text-gray-900">
                                        Generate invoices for your customers.
                                    </div>
                                    <button
                                        type="button"
                                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                                        onClick={() =>
                                            navigate('generate-invoices')
                                        }
                                    >
                                        View
                                    </button>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </main>
        </div>
    )
}
