import { BaseRepository } from './BaseRepository'

export class VerifyEmailRepository extends BaseRepository {
    getCSRF = async () => {
        return await super.getHttpClientInstance().get(`/sanctum/csrf-cookie`)
    }

    veryfyEmail = async (id: string, hash: string) => {
        return await super
            .getHttpClientInstance()
            .get<any>(`/api/email/verify/${id}/${hash}`)
    }
}
