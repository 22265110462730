import { useParams } from 'react-router-dom'
import { InvoicesRepository } from '../../../api/InvoicesRepository'

import NMIInline from '../../components/payments/NMIInline'

interface InvoiceCheckoutCreditCardProps {
    uuid: string | undefined
    price: number | undefined
    nmi_public_key: string | undefined
    setDisableOptions: Function
}

export default function InvoiceCheckoutCreditCard({
    uuid,
    price,
    nmi_public_key,
    setDisableOptions,
}: InvoiceCheckoutCreditCardProps) {
    return (
        <div>
            <div className="w-full flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                    Pay With Credit Card
                </h2>

                <img src="/credit-card-logo.svg" width="50px" />
            </div>
            <NMIInline
                uuid={uuid}
                price={price}
                nmi_public_key={nmi_public_key}
                setDisableOptions={setDisableOptions}
            />
        </div>
    )
}
