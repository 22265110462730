import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { RegistrationFormRepository } from '../../api/RegistrationFormRepository'
import { useAppDispatch } from '../../redux/hooks'
import { setLoggedIn } from '../../redux/state-slices/AuthUserSlice'
import { setStep } from '../../redux/state-slices/StepSlice'
import Loading from '../../shared-components/Loading'
import Error from '../../shared-components/Error'
import { useAppSelector } from '../../redux/hooks'
import { CheckIcon, ClockIcon } from '@heroicons/react/20/solid'
import { SignNowRepository } from '../../api/SignNowRepository'
interface PricingFee {
    name: string
    price: string
    paymentFrequency: string
}

export default function DebitIntegratedContract() {
    const registrationFormRepository = new RegistrationFormRepository()
    const signNowRepository = new SignNowRepository()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [bigPrice, setBigPrice] = useState({ tx: 0, mdr: 0 })
    const branding = useAppSelector((state) => state.branding)
    const [pricingPlan, setPricingplan] = useState<Array<PricingFee> | null>(
        null
    )
    const [numOfTerminal, setNumOfTerminal] = useState(1)
    const [contractStatus, setContractStatus] = useState<string | null>(null)
    const [embeddedUrl, setEmbeddedUrl] = useState<string>('')
    const [invalidIframeURL, setInvalidIframeURL] = useState<boolean>(false)
    const [showCountdown, setShowCountdown] = useState<boolean>(false)
    const [contractExpiry, setContractExpiry] = useState<string>('')
    const [remainingTime, setRemainingTime] = useState<string>('')
    const [contractExpired, setContractExpired] = useState<boolean>(false)

    useEffect(() => {
        if (contractStatus === 'signed') {
            setEmbeddedUrl('')
        }
    }, [contractStatus])

    useEffect(() => {
        if (contractExpiry) {
            const intervalId = setInterval(() => {
                getRemainingTime()
            }, 1000)
            return () => clearInterval(intervalId)
        }
    }, [contractExpiry])

    useEffect(() => {
        completeRegistrationQuery.remove()
        getContractDocumentQuery.remove()
        window.scrollTo(0, 0)
        setStepState()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getRemainingTime = () => {
        const now = new Date()

        const diff = Math.floor((+new Date(contractExpiry) - +now) / 1000) // difference in seconds
        if (diff < 0) {
            setRemainingTime('Expired')
            setEmbeddedUrl('')
            setContractExpired(true)
        } else {
            const hours = Math.floor(diff / 3600)
            const minutes = Math.floor((diff % 3600) / 60)
            const seconds = diff % 60
            setRemainingTime(
                `Contract Expires In: ${hours}H:${minutes}M:${seconds}S`
            )
        }
    }

    const getContractDocumentQuery = useQuery(
        'get-document',
        async () => {
            return (await signNowRepository.getDocument()).data
        },
        {
            enabled: true,
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                setRemainingTime('')
                setEmbeddedUrl(data.embedded_url)
                setContractStatus(null)
                setShowCountdown(false)
                completeRegistrationQuery.remove()
            },
            onError: (error) => {},
        }
    )

    const getContractDocumentStatusQuery = useQuery(
        'get-document-status',
        async () => {
            return (await signNowRepository.getContractDocumentStatus()).data
        },
        {
            enabled: !!embeddedUrl,
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
            refetchInterval: 2000,

            onSuccess: (data) => {
                setContractStatus(data.status)
                setContractExpiry(data.expires_at)
            },
            onError: (error) => {},
        }
    )

    const completeRegistrationQuery = useQuery(
        'complete-registration',
        async () => {
            return (
                await registrationFormRepository.completeRegistration(
                    'debit',
                    numOfTerminal
                )
            ).data
        },
        {
            enabled: false,
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                const user: Paywell.ReduxStore.Auth.User = {
                    id: data.id,
                    email: data.email,
                    email_verified: data.email_verified_at,
                    account_status: data.account_status,
                    merchant_name: data.merchant_name,
                    user_type_id: data.user_type_id,
                    nmi_user: data.nmi_user,
                }

                dispatch(setLoggedIn(user))
                navigate('/')
            },
            onError: (error) => {},
        }
    )

    const getDocument = () => {
        setContractExpired(false)
        setContractExpiry('')
        getContractDocumentQuery.refetch()
    }

    const setStepState = () => {
        dispatch(setStep(4))
    }

    const handleSubmit = () => {
        if (contractStatus === 'signed') {
            completeRegistrationQuery.refetch()
        }
    }

    const fullVHClass =
        getContractDocumentQuery.isSuccess &&
        contractStatus === 'pending' &&
        !contractExpired &&
        embeddedUrl
            ? 'h-screen'
            : ''

    const contractDeclined = contractStatus === 'declined'

    return (
        <div className="px-6 lg:px-8">
            <div className={`mx-auto ${fullVHClass}`}>
                <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
                    <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
                        Service Agreement
                    </h2>
                    <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        {contractStatus === 'signed'
                            ? "You're all set! Click below to continue"
                            : 'Sign your contract below'}
                    </p>
                </div>

                {getContractDocumentQuery.isFetching ||
                getContractDocumentQuery.isLoading ? (
                    <div className="text-center mt-4">
                        <p className="italic text-lg text-gray-400">
                            Please wait while we generate your contract
                        </p>
                        <div className="flex justify-center mt-4">
                            <Loading height={'8'} width={'8'} />
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {getContractDocumentQuery.isSuccess &&
                    contractStatus === 'pending' &&
                    !contractExpired &&
                    !invalidIframeURL && (
                        <>
                            {showCountdown && contractExpiry && (
                                <div className="bg-indigo-600 py-2.5 px-6 sm:px-3.5 text-center mt-2">
                                    <p className="text-sm leading-6 text-white">
                                        <strong className="font-semibold">
                                            {remainingTime}
                                        </strong>
                                    </p>
                                </div>
                            )}
                            <iframe
                                src={embeddedUrl}
                                onLoad={() => setShowCountdown(true)}
                                allow="encrypted-media"
                                allowFullScreen={true}
                                sandbox="allow-same-origin allow-scripts"
                                width="100%"
                                height="100%"
                                id="contractIframe"
                                allow-top-navigation="false"
                            />
                        </>
                    )}
            </div>
            {getContractDocumentQuery.isSuccess &&
                !contractDeclined &&
                !contractExpired && (
                    <div className="flex justify-center mt-4">
                        <div className="text-center">
                            {completeRegistrationQuery.isLoading ||
                            completeRegistrationQuery.isFetching ? (
                                <div className="flex justify-center mt-4">
                                    <Loading height={'8'} width={'8'} />
                                </div>
                            ) : (
                                <button
                                    type="submit"
                                    disabled={
                                        completeRegistrationQuery.isLoading ||
                                        completeRegistrationQuery.isFetching ||
                                        contractStatus !== 'signed'
                                    }
                                    onClick={handleSubmit}
                                    className="mt-4 justify-center rounded-md border bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 disabled:bg-gray-400"
                                >
                                    {contractStatus === 'signed'
                                        ? 'Accept & Submit'
                                        : 'Sign contract to continue'}
                                </button>
                            )}
                        </div>
                    </div>
                )}

            {contractExpired &&
                (!getContractDocumentQuery.isLoading ||
                    !getContractDocumentQuery.isFetching) && (
                    <div className="text-center mt-4 text-center">
                        <div>
                            <p className="text-lg font-semibold text-red-600">
                                Contract has expired. Please regenerate a new
                                contract.
                            </p>
                            <button
                                onClick={getDocument}
                                className="mt-4 justify-center rounded-md border bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 disabled:bg-gray-400"
                            >
                                Regenerate Contract
                            </button>
                        </div>
                    </div>
                )}
            {contractDeclined &&
                !getContractDocumentQuery.isLoading &&
                !getContractDocumentQuery.isFetching &&
                !getContractDocumentQuery.isError && (
                    <div className="text-center mt-4 text-center">
                        <div>
                            <p className="text-lg font-semibold text-red-600">
                                You must sign the contract in order to submit
                                your application.
                            </p>
                            <button
                                onClick={getDocument}
                                className="mt-4 justify-center rounded-md border bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 disabled:bg-gray-400"
                            >
                                Regenerate Contract
                            </button>
                        </div>
                    </div>
                )}
            {getContractDocumentQuery.isError &&
                !getContractDocumentQuery.isFetching &&
                !getContractDocumentQuery.isLoading && (
                    <div className="justify-center mt-4">
                        <Error
                            title="Error"
                            message={
                                `There was an error generating the contract. Please try again. If the error persists, please contact support at` +
                                ' ' +
                                `${branding.contact_email}`
                            }
                        />
                        <div className="text-center mt-4">
                            <button
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                onClick={getDocument}
                            >
                                Retry
                            </button>
                        </div>
                    </div>
                )}

            {completeRegistrationQuery.isError && (
                <div className="justify-center mt-4">
                    <Error
                        title="Error"
                        message={
                            `There was an error submitting your application. Please try again. If the error persists, please contact support at` +
                            ' ' +
                            `${branding.contact_email}`
                        }
                    />
                </div>
            )}
        </div>
    )
}
