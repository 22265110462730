import { useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { EftsRepository } from '../api/EftsRepository'
import Loading from '../shared-components/Loading'
import NumberFormatDollars from '../utils/NumberFormatDollars'
import Error from '../shared-components/Error'
import { setError } from '../redux/state-slices/GlobalNotificationSlice'
import { useAppDispatch } from '../redux/hooks'

export default function EftSchedules() {
    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const eftsRepository = new EftsRepository()
    const [hoveredParentId, setHoveredParentId] = useState<number | null>(null)

    useEffect(() => {
        if (id) {
            getEftTransactions.refetch()
        }
    }, [id])

    const getEftTransactions = useQuery(
        ['eft-transactions', id],
        async () => {
            return await eftsRepository.getEftTransactions(id || '0')
        },
        {
            enabled: false,
            retry: false,
            refetchOnWindowFocus: true,
            onSuccess: (data) => {},
        }
    )

    const updateEftTransaction = useMutation({
        mutationFn: (data: {
            scheduleId: number
            transactionId: number
            eftTransactionDate: string
            providerId: number
        }) =>
            eftsRepository.updateEftTransaction(
                data.scheduleId,
                data.transactionId,
                data.eftTransactionDate,
                data.providerId
            ),
        onSuccess: () => {
            getEftTransactions.refetch()
        },
        onError: (error: any) => {
            let message = error.data.message
            if (message) {
                dispatch(setError([message]))
            } else {
                dispatch(setError(['An error has occurred']))
            }
        },
    })

    const generateEftsMutation = useMutation({
        mutationFn: () => eftsRepository.generateEfts(id || ''),
        onSuccess: (data) => {
            navigate(`/eft-batches/${id}/efts`)
        },
        onError: (error: any) => {
            dispatch(setError(['An error has occurred']))
        },
    })

    const providers = getEftTransactions.data?.data.providers

    const generateDatesOptions = () => {
        const dates = getEftTransactions.data?.data.dates
        return dates?.map((date) => {
            return (
                <option key={date} value={date}>
                    {date}
                </option>
            )
        })
    }

    const generateProvidersOptions = () => {
        return providers?.map((provider) => {
            return (
                <option key={provider.id} value={provider.id}>
                    {provider.name}
                </option>
            )
        })
    }

    const generateTransactions = () => {
        const eftBatches = getEftTransactions.data?.data.transactions || {}

        if (getEftTransactions.isLoading) {
            return (
                <div className="flex h-screen">
                    <div className="m-auto">
                        <Loading height="8" width="8" />
                    </div>
                </div>
            )
        } else if (getEftTransactions.isError) {
            return (
                <div className="mt-5">
                    <Error
                        title="An error has occurred"
                        message="Error loading, please refresh the page."
                    />
                </div>
            )
        } else if (getEftTransactions.isSuccess) {
            return Object.keys(eftBatches || {}).map((date) => {
                const transactionsByBank = eftBatches[date]

                return (
                    <div key={date} className="mt-10">
                        <h1 className="font-medium">Eft schedule for {date}</h1>
                        <div className="overflow-x-auto mt-8 flex justify-between  border-b-2 border-gray-200 pb-5 pl-1">
                            {Object.keys(transactionsByBank).map((bankName) => {
                                const transactions =
                                    transactionsByBank[bankName]
                                return (
                                    <div key={bankName} className="w-full ">
                                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                            <h1 className="font-medium mb-2">
                                                {bankName} Efts
                                            </h1>
                                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                            >
                                                                Name
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                            >
                                                                Reference
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                            >
                                                                Amount
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                                            >
                                                                <span className="sr-only">
                                                                    Select
                                                                </span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                        {transactions.map(
                                                            (
                                                                transaction,
                                                                index
                                                            ) => {
                                                                const id =
                                                                    transaction.parent_id
                                                                        ? transaction.parent_id
                                                                        : transaction.id
                                                                return (
                                                                    <tr
                                                                        onMouseEnter={() =>
                                                                            handleMouseEnter(
                                                                                id
                                                                            )
                                                                        }
                                                                        onMouseLeave={
                                                                            handleMouseLeave
                                                                        }
                                                                        key={
                                                                            transaction.id
                                                                        }
                                                                        className={`${
                                                                            hoveredParentId ===
                                                                                transaction.parent_id ||
                                                                            hoveredParentId ===
                                                                                transaction.id
                                                                                ? 'bg-blue-200'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-500">
                                                                            {
                                                                                transaction.name_on_account
                                                                            }{' '}
                                                                            -
                                                                            schedule
                                                                            id:{' '}
                                                                            {
                                                                                transaction.eft_schedule_id
                                                                            }{' '}
                                                                            -
                                                                            transaction
                                                                            id:{' '}
                                                                            {
                                                                                transaction.id
                                                                            }
                                                                        </td>
                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                            <a
                                                                                rel="noreferrer"
                                                                                href={
                                                                                    transaction.resource_url
                                                                                }
                                                                                target="_blank"
                                                                                className="text-indigo-600 hover:text-indigo-900"
                                                                            >
                                                                                View
                                                                            </a>
                                                                        </td>
                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-bold">
                                                                            {NumberFormatDollars(
                                                                                transaction.partial_amount
                                                                            )}
                                                                        </td>
                                                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 space-x-2">
                                                                            <select
                                                                                disabled={
                                                                                    apiIsLoading
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    handleUpdateEftTransaction(
                                                                                        transaction.eft_schedule_id,
                                                                                        transaction.id,
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                        transaction.eft_provider_id
                                                                                    )
                                                                                }
                                                                                className="text-indigo-600 text-sm hover:text-indigo-900"
                                                                                value={
                                                                                    transaction.scheduled_transaction_date
                                                                                }
                                                                            >
                                                                                {generateDatesOptions()}
                                                                            </select>
                                                                            <select
                                                                                disabled={
                                                                                    apiIsLoading
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    handleUpdateEftTransaction(
                                                                                        transaction.eft_schedule_id,
                                                                                        transaction.id,
                                                                                        transaction.scheduled_transaction_date,
                                                                                        Number(
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        )
                                                                                    )
                                                                                }
                                                                                className="text-indigo-600 text-sm hover:text-indigo-900"
                                                                                value={
                                                                                    transaction.eft_provider_id
                                                                                }
                                                                            >
                                                                                {generateProvidersOptions()}
                                                                            </select>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })
        }
    }

    const handleUpdateEftTransaction = (
        scheduleId: number,
        transactionId: number,
        date: string,
        providerId: number
    ) => {
        updateEftTransaction.mutate({
            scheduleId: scheduleId,
            transactionId: transactionId,
            eftTransactionDate: date,
            providerId: providerId,
        })
    }

    const apiIsLoading =
        updateEftTransaction.isLoading || getEftTransactions.isFetching

    const handleMouseEnter = (parentId: number) => {
        setHoveredParentId(parentId)
    }

    const handleMouseLeave = () => {
        setHoveredParentId(null)
    }

    return (
        <div className="mt-8 px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                        Eft Schedule
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Here is a breakdown of the EFT schedule for the next
                        cycle.
                    </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                        disabled={
                            getEftTransactions.isLoading ||
                            getEftTransactions.isFetching ||
                            updateEftTransaction.isLoading
                        }
                        onClick={() => navigate(-1)}
                        type="button"
                        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Back
                    </button>
                </div>
            </div>

            <div>
                {/*White overlay for loading*/}
                {apiIsLoading && (
                    <div className="fixed inset-0 bg-white bg-opacity-50 z-50 flex items-center justify-center"></div>
                )}
                {generateTransactions()}
            </div>
            {getEftTransactions.isSuccess &&
                !generateEftsMutation.isLoading && (
                    <div className="flex justify-end mt-8 mb-8">
                        <button
                            onClick={() => generateEftsMutation.mutate()}
                            type="button"
                            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Generate EFTs
                        </button>
                    </div>
                )}
            {generateEftsMutation.isLoading && (
                <div className="flex justify-end mt-8 mb-8">
                    <Loading height={'8'} width={'8'} />
                </div>
            )}
        </div>
    )
}
