import { BaseRepository } from './BaseRepository'

export class TransactionsRepository extends BaseRepository {
    getTransactions = async (
        pagination: string,
        page: string | null,
        fromDate: string | null,
        toDate: string | null,
        userId: string | null,
        searchCriteria: string | null
    ) => {
        let url = `/api/v2/transactions?page=${page}`
        if (fromDate && toDate) {
            url = url + `&fromDate=${fromDate}&toDate=${toDate}`
        }
        if (userId) {
            url = url + `&userId=${userId}`
        }
        if (searchCriteria) {
            url = url + `&searchCriteria=${searchCriteria}`
        }

        url = url + `&pagination=${pagination}`

        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.TransactionRequest>(url)
    }

    getTransaction = async (id: string) => {
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.Transaction>(`/api/v2/transactions/${id}`)
    }

    downloadTransactions = async (
        fromDate: string | null,
        toDate: string | null,
        userId: string | null,
        searchCriteria: string | null
    ) => {
        let url = '/api/v2/download-transactions'
        let queryParams: string[] = []

        if (fromDate && toDate) {
            queryParams.push(`fromDate=${encodeURIComponent(fromDate)}`)
            queryParams.push(`toDate=${encodeURIComponent(toDate)}`)
        }
        if (userId) {
            queryParams.push(`userId=${encodeURIComponent(userId)}`)
        }
        if (searchCriteria) {
            queryParams.push(
                `searchCriteria=${encodeURIComponent(searchCriteria)}`
            )
        }

        // Only add the query string if there are parameters
        if (queryParams.length) {
            url += `?${queryParams.join('&')}`
        }

        return await super.getHttpClientInstance().get(url)
    }
}
