import { Route, Routes } from 'react-router-dom'
import Users from './Users'
import UserTabsWrapper from './UserTabsWrapper'

export default function UserRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Users />} />
            <Route path="/:id/*" element={<UserTabsWrapper />} />
        </Routes>
    )
}
