import { BaseRepository } from './BaseRepository'

export class TaxRepository extends BaseRepository {
    paginatedTaxes = async (page: any) => {
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.TaxesPaginated>(
                `/api/v2/taxes?paginated=1&page=${page}`
            )
    }
    getTaxes = async () => {
        return await super
            .getHttpClientInstance()
            .get<[Paywell.API.V2.Taxes]>(`/api/v2/taxes?paginated=0`)
    }

    getTax = async (id: number) => {
        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.Taxes>(`/api/v2/taxes/${id}`)
    }

    createTax = async (name: string, tax: string, source: any[]) => {
        return await super
            .getHttpClientInstance()
            .post<Paywell.API.V2.Taxes>(`/api/v2/taxes`, {
                tax_name: name,
                tax_rate: tax,
                source: source,
            })
    }

    updateTax = async (id: number, name: string, tax: string) => {
        return await super.getHttpClientInstance().put(`/api/v2/taxes/${id}`, {
            tax_name: name,
            tax_rate: tax,
        })
    }

    deleteTax = async (id: number) => {
        return await super.getHttpClientInstance().delete(`/api/v2/taxes/${id}`)
    }
}
