import { BaseRepository } from './BaseRepository'

export class NotesRepository extends BaseRepository {
    getNotesForUser = async (page: string | null = '1', userId: number) => {
        const pageNumber = page || '1'

        let url = `/api/v2/users/${userId}/notes?page=${pageNumber}`

        return await super
            .getHttpClientInstance()
            .get<Paywell.API.V2.NotesPaginated>(url)
    }

    getNote = async (noteId: number, userId: number) => {
        let url = `/api/v2/users/${userId}/notes/${noteId}`
        return await super.getHttpClientInstance().get<Paywell.API.V2.Note>(url)
    }

    addNote = async (note: string, userId: number) => {
        let url = `/api/v2/users/${userId}/notes`
        return await super
            .getHttpClientInstance()
            .post<Paywell.API.V2.Note>(url, { note })
    }

    updateNote = async (noteId: number, note: string, userId: number) => {
        let url = `/api/v2/users/${userId}/notes/${noteId}`
        return await super.getHttpClientInstance().put(url, { note })
    }

    deleteNote = async (noteId: number, userId: number) => {
        let url = `/api/v2/users/${userId}/notes/${noteId}`
        return await super.getHttpClientInstance().delete(url)
    }
}
