import { BaseRepository } from './BaseRepository'

export class CustomersRepository extends BaseRepository {

    paginatedCustomers = async (page: any) => {
        return await super.getHttpClientInstance().get<Paywell.API.V2.CustomerPaginated>(`/api/v2/customers?paginated=1&page=${page}`)
    }
    getCustomers = async () => {
        return await super.getHttpClientInstance().get<[Paywell.API.V2.Customers]>(`/api/v2/customers?paginated=0`)
    }
    getCustomer = async (id: number) => {
        return await super.getHttpClientInstance().get<Paywell.API.V2.Customers>(`/api/v2/customers/${id}`)
    }
    getCustomerInvoices = async (id: number, page: any) => { 
        return await super.getHttpClientInstance().get<Paywell.API.V2.InvoicePaginated>(`api/v2/customers/${id}/invoices?page=${page}`)
    }

    updateCustomer = async (
        id: number,
        firstName: string, 
        lastName: string, 
        email: any, 
        address: string, 
        city: string, 
        state: string, 
        postal: string, 
        country: string, 
        tax: number, 
        notes: string,
    ) => {
        return await super
            .getHttpClientInstance()
            .put(`/api/v2/customers/${id}`, {
                first_name: firstName,
                last_name: lastName,
                email: email,
                address: address,
                city: city,
                state: state,
                postal: postal,
                country: country,
                tax_id: tax,
                notes: notes
            })

    }

    createCustomer = async (
        firstName: string, 
        lastName: string, 
        email: string, 
        address: string, 
        city: string, 
        state: string, 
        postal: string, 
        country: string, 
        tax: number, 
        notes: string,
    ) => {
        return await super
            .getHttpClientInstance()
            .post<Paywell.API.V2.Customers>(`/api/v2/customers`, {
                first_name: firstName,
                last_name: lastName,
                email: email,
                address: address,
                city: city,
                state: state,
                postal: postal,
                country: country,
                tax_id: tax,
                notes: notes
            })
    }
    deleteCustomer = async (
        id: number,
    ) => {
        return await super
            .getHttpClientInstance()
            .delete(`/api/v2/customers/${id}`)

    }
}





