import { useAppSelector } from '../redux/hooks'
import { Navigate, Outlet } from 'react-router-dom'

export default function OnboardingType() {
    const onboardingType = useAppSelector((state) => state.onboarding_type)
    const authUser = useAppSelector((state) => state.auth_user)

    if (authUser?.account_status === 'REGISTERING') {
        if (onboardingType === null) {
            return <Navigate to={'/application'} replace />
        } else {
            return <Outlet />
        }
    }
    return <Navigate to={'/'} replace />
}
