import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

const initialState: Paywell.ReduxStore.Onboarding.NewUser | null = {
    email: '',
    first_name: '',
    last_name: '',
    country: 1,
    password: '',
    confirm_password: '',
    business_name: '',
    uuid: null,
}

export const NewUserSlice = createSlice({
    name: 'new_user',
    initialState,

    reducers: {
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload
        },

        setBusinessName: (state, action: PayloadAction<string>) => {
            state.business_name = action.payload
        },

        setFirstName: (state, action: PayloadAction<string>) => {
            state.first_name = action.payload
        },

        setLastName: (state, action: PayloadAction<string>) => {
            state.last_name = action.payload
        },
        setCountry: (state, action: PayloadAction<number>) => {
            state.country = action.payload
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload
        },
        setConfirmPassword: (state, action: PayloadAction<string>) => {
            state.confirm_password = action.payload
        },
        setUUID: (state, action: PayloadAction<string>) => {
            state.uuid = action.payload
        },
        /*  resetNewUserState: (state) => {
            const initialState: Paywell.ReduxStore.Onboarding.NewUser = {
                email: '',
                first_name: '',
                last_name: '',
                country: 1,
                password: '',
                confirm_password: '',
                business_name: '',
            }

            return initialState
        }, */
    },
})

// Action creators are generated for each case reducer function
export const {
    setFirstName,
    setLastName,
    setBusinessName,
    setEmail,
    setCountry,
    setPassword,
    setConfirmPassword,
    setUUID,
    //resetNewUserState,
} = NewUserSlice.actions

export default NewUserSlice.reducer
