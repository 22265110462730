import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { QueryClient, QueryClientProvider } from 'react-query'
import { store } from './redux/store'
import { Provider } from 'react-redux'
import './i18'
import './index.css'
import { BrowserRouter } from 'react-router-dom'

Sentry.init({
    dsn: 'https://8be2b4105daf4b7e8b4b31c50093856d@o925380.ingest.sentry.io/6535183',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
})

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </Provider>
    </BrowserRouter>
    // </React.StrictMode>
)
