import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { useMutation } from 'react-query'
import { RegistrationFormRepository } from '../../api/RegistrationFormRepository'
import { setIdentityInfo } from '../../redux/state-slices/IdentityInfoSlice'
import useDebounce from '../../utils/useDebounce'
import {
    numOnlyRegex,
    testAddressRegex,
    testAlphaNumericRegex,
    testCityRegex,
    testEmailRegex,
    testNameRegex,
    testParagraphRegex,
    testPhoneNumRegex,
    testSinSSnRegex,
    validateAddress,
    validateAgeOver18,
    validateCity,
    validateCountry,
    validateDriversLicenceNum,
    validateIdentityInfo,
    validateName,
    validatePercentageOwned,
    validatePostalCode,
    validateTotalPercentageOwnership,
} from '../../utils/RegexHelper'
import { setStep } from '../../redux/state-slices/StepSlice'
import Error from '../../shared-components/Error'
import Loading from '../../shared-components/Loading'
import {
    CheckCircleIcon,
    PlusCircleIcon,
    PlusIcon,
    XMarkIcon,
} from '@heroicons/react/20/solid'
import { setError } from '../../redux/state-slices/GlobalNotificationSlice'
import Decimal from 'decimal.js'
import Tooltip from '../../shared-components/Tooltip'

export default function DebitIdentityInfo() {
    let navigate = useNavigate()
    const dispatch = useAppDispatch()
    const branding = useAppSelector((state) => state.branding)
    const authUser = useAppSelector((state) => state.auth_user)
    const identityInfos = useAppSelector((state) => state.identity_info)
    const steps = useAppSelector((state) => state.steps)
    const registrationFormRepository = new RegistrationFormRepository()
    const debounceValue = useDebounce(identityInfos, 1500)
    const [validForm, setValidForm] = useState(false)

    useEffect(() => {
        setStepState()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validForm])

    useEffect(() => {
        validateForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [identityInfos])

    useEffect(() => {
        callSaveFormValuesMutation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceValue])

    const submitFormValuesMutation = useMutation(
        (identityInfos: Paywell.API.V2.IdentityInfo[]) =>
            registrationFormRepository.submitIdentityInfos(identityInfos),
        {
            onSuccess: (data) => {
                navigate('/onboarding/debit/business-info')
            },

            onError: () => {
                dispatch(
                    setError([
                        `Error saving. Please reach out to one of our representatives at ${branding.contact_email} if this error persists`,
                    ])
                )
            },
        }
    )

    const saveFormValuesMutation = useMutation(
        (params: { identityInfo: any; id: any }) =>
            registrationFormRepository.postIdentityInfo(
                params.identityInfo,
                params.id
            )
    )
    const callSaveFormValuesMutation = () => {
        identityInfos.forEach((element) => {
            saveFormValuesMutation.mutate({
                identityInfo: element.identity_info,
                id: element.identity_info.id,
            })
        })
    }

    const addContactMutation = useMutation({
        mutationFn: () => registrationFormRepository.addContact(),

        onSuccess: (data) => {
            let identityInfoForm: Paywell.ReduxStore.Onboarding.IdentityInfoForm[] =
                []

            data.data.forEach((element: any) => {
                //find the identity info that matches the contact id and map the validations to the new array
                const mappedIdentityInfo:
                    | Paywell.ReduxStore.Onboarding.IdentityInfoForm
                    | undefined = identityInfos.find(
                    (info: Paywell.ReduxStore.Onboarding.IdentityInfoForm) =>
                        info.identity_info.id === element.id
                )
                const mappedValidations = mappedIdentityInfo?.validations

                identityInfoForm.push({
                    identity_info: {
                        id: element.id,
                        SIN: element.SIN,
                        birthday: element.birthday,
                        drivers_licence_number: element.drivers_licence_number,
                        first_name: element.first_name,
                        last_name: element.last_name,
                        city: element.city,
                        postal_code: element.postal_code,
                        countries_id: element.countries_id,
                        provinces_id: element.provinces_id,
                        tel_number: element.tel_number,
                        address: element.address,
                        drivers_licence_file_name:
                            element.drivers_licence_file_name,
                        percentage_ownership: element.percentage_ownership,
                        occupation_title: element.occupation_title,
                        email_address: element.email_address,
                        drivers_licence_file_name_back:
                            element.drivers_licence_file_name_back,
                        proof_of_address_file_name:
                            element.proof_of_address_file_name,
                        liveness_photo_file_name:
                            element.liveness_photo_file_name,
                    },
                    validations: mappedValidations || {
                        id: undefined,
                        SIN: undefined,
                        birthday: undefined,
                        drivers_licence_number: undefined,
                        first_name: undefined,
                        last_name: undefined,
                        city: undefined,
                        postal_code: undefined,
                        countries_id: undefined,
                        provinces_id: undefined,
                        tel_number: undefined,
                        address: undefined,
                        drivers_licence_file_name: undefined,
                        percentage_ownership: undefined,
                        occupation_title: undefined,
                        email_address: undefined,
                        drivers_licence_file_name_back: undefined,
                        proof_of_address_file_name: undefined,
                        liveness_photo_file_name: undefined,
                    },
                })
            })

            dispatch(setIdentityInfo(identityInfoForm))
        },

        onError: () => {
            dispatch(
                setError([
                    `Please reach out to one of our representatives at ${branding.contact_email} if this error persists`,
                ])
            )
        },
    })

    const deleteContactMutation = useMutation({
        mutationFn: (id: number) =>
            registrationFormRepository.deleteContact(id),

        onSuccess: (data) => {
            let identityInfoForm: Paywell.ReduxStore.Onboarding.IdentityInfoForm[] =
                []

            data.data.forEach((element: any) => {
                //find the identity info that matches the contact id and map the validations to the new array
                const mappedIdentityInfo:
                    | Paywell.ReduxStore.Onboarding.IdentityInfoForm
                    | undefined = identityInfos.find(
                    (info: Paywell.ReduxStore.Onboarding.IdentityInfoForm) =>
                        info.identity_info.id === element.id
                )
                const mappedValidations = mappedIdentityInfo?.validations

                identityInfoForm.push({
                    identity_info: {
                        id: element.id,
                        SIN: element.SIN,
                        birthday: element.birthday,
                        drivers_licence_number: element.drivers_licence_number,
                        first_name: element.first_name,
                        last_name: element.last_name,
                        city: element.city,
                        postal_code: element.postal_code,
                        countries_id: element.countries_id,
                        provinces_id: element.provinces_id,
                        tel_number: element.tel_number,
                        address: element.address,
                        drivers_licence_file_name:
                            element.drivers_licence_file_name,
                        percentage_ownership: element.percentage_ownership,
                        occupation_title: element.occupation_title,
                        email_address: element.email_address,
                        drivers_licence_file_name_back:
                            element.drivers_licence_file_name_back,
                        proof_of_address_file_name:
                            element.proof_of_address_file_name,
                        liveness_photo_file_name:
                            element.liveness_photo_file_name,
                    },
                    validations: mappedValidations || {
                        id: undefined,
                        SIN: undefined,
                        birthday: undefined,
                        drivers_licence_number: undefined,
                        first_name: undefined,
                        last_name: undefined,
                        city: undefined,
                        postal_code: undefined,
                        countries_id: undefined,
                        provinces_id: undefined,
                        tel_number: undefined,
                        address: undefined,
                        drivers_licence_file_name: undefined,
                        percentage_ownership: undefined,
                        occupation_title: undefined,
                        email_address: undefined,
                        drivers_licence_file_name_back: undefined,
                        proof_of_address_file_name: undefined,
                        liveness_photo_file_name: undefined,
                    },
                })
            })
            dispatch(setIdentityInfo(identityInfoForm))
        },

        onError: () => {
            dispatch(
                setError([
                    `Please reach out to one of our representatives at ${branding.contact_email} if this error persists`,
                ])
            )
        },
    })

    const handleRemoveShareholder = (
        id: number | null,
        identityInfo: Paywell.API.V2.IdentityInfo
    ) => {
        if (id && identityInfo.email_address !== authUser?.email) {
            deleteContactMutation.mutate(id)
        }
    }

    const uploadDriversLicenceMutation = useMutation(
        (args: {
            formData: FormData
            identityInfoId: number | null
            identityInfoIndex: number
        }) =>
            registrationFormRepository.uploadDriversLicence(
                args.formData,
                args.identityInfoId
            ),

        {
            onError: (identityInfoIndex: number) => {
                let newIdentityInfos = identityInfos.map((object) => ({
                    identity_info: { ...object.identity_info },
                    validations: { ...object.validations },
                }))

                newIdentityInfos[
                    identityInfoIndex
                ].identity_info.drivers_licence_file_name = null
                newIdentityInfos[
                    identityInfoIndex
                ].validations.drivers_licence_file_name = false
                dispatch(setIdentityInfo(newIdentityInfos))
            },
        }
    )

    const uploadProofofAddressMutation = useMutation(
        (args: {
            formData: FormData
            identityInfoId: number | null
            identityInfoIndex: number
        }) =>
            registrationFormRepository.uploadIdentityProofOfAddress(
                args.formData,
                args.identityInfoId
            ),

        {
            onError: (identityInfoIndex: number) => {
                let newIdentityInfos = identityInfos.map((object) => ({
                    identity_info: { ...object.identity_info },
                    validations: { ...object.validations },
                }))

                newIdentityInfos[
                    identityInfoIndex
                ].identity_info.proof_of_address_file_name = null
                newIdentityInfos[
                    identityInfoIndex
                ].validations.proof_of_address_file_name = false
                dispatch(setIdentityInfo(newIdentityInfos))
            },
        }
    )

    const uploadLivenessPhotoMutation = useMutation(
        (args: {
            formData: FormData
            identityInfoId: number | null
            identityInfoIndex: number
        }) =>
            registrationFormRepository.uploadLivenessPhoto(
                args.formData,
                args.identityInfoId
            ),

        {
            onError: (identityInfoIndex: number) => {
                let newIdentityInfos = identityInfos.map((object) => ({
                    identity_info: { ...object.identity_info },
                    validations: { ...object.validations },
                }))

                newIdentityInfos[
                    identityInfoIndex
                ].identity_info.liveness_photo_file_name = null
                newIdentityInfos[
                    identityInfoIndex
                ].validations.liveness_photo_file_name = false
                dispatch(setIdentityInfo(newIdentityInfos))
            },
        }
    )

    const validateForm = () => {
        setValidForm(validateIdentityInfo(identityInfos, 'debit'))
    }

    const setStepState = () => {
        if (steps[0].status !== 'complete' || !validForm) {
            dispatch(setStep(0))
        }
    }

    const handleFirstNameChange = (e: React.ChangeEvent<any>, i: number) => {
        let val = e.target.value
        if ((val.length < 20 && testNameRegex(val)) || val === '') {
            let newIdentityInfos = identityInfos.map((object) => ({
                identity_info: { ...object.identity_info },
                validations: { ...object.validations },
            }))

            newIdentityInfos[i].identity_info.first_name = val
            newIdentityInfos[i].validations.first_name = validateName(val)
            dispatch(setIdentityInfo(newIdentityInfos))
        }
    }

    const handleLastNameChange = (e: React.ChangeEvent<any>, i: number) => {
        let val = e.target.value
        if ((val.length < 20 && testNameRegex(val)) || val === '') {
            let newIdentityInfos = identityInfos.map((object) => ({
                identity_info: { ...object.identity_info },
                validations: { ...object.validations },
            }))

            newIdentityInfos[i].identity_info.last_name = val
            newIdentityInfos[i].validations.last_name = validateName(val)
            dispatch(setIdentityInfo(newIdentityInfos))
        }
    }

    const handleDriversLicenseNum = (e: React.ChangeEvent<any>, i: number) => {
        let val = e.target.value
        if ((val.length < 20 && testAlphaNumericRegex(val)) || val === '') {
            let newIdentityInfos = identityInfos.map((object) => ({
                identity_info: { ...object.identity_info },
                validations: { ...object.validations },
            }))

            newIdentityInfos[i].identity_info.drivers_licence_number = val
            newIdentityInfos[i].validations.drivers_licence_number =
                validateDriversLicenceNum(val)
            dispatch(setIdentityInfo(newIdentityInfos))
        }
    }

    const handleBirthdayChange = (e: React.ChangeEvent<any>, i: number) => {
        let val = e.target.value

        let newIdentityInfos = identityInfos.map((object) => ({
            identity_info: { ...object.identity_info },
            validations: { ...object.validations },
        }))

        newIdentityInfos[i].identity_info.birthday = val
        newIdentityInfos[i].validations.birthday =
            validateAgeOver18(val) || val === null
        dispatch(setIdentityInfo(newIdentityInfos))
    }

    const handleSinChange = (e: React.ChangeEvent<any>, i: number) => {
        let val = e.target.value
        if ((val.length < 10 && numOnlyRegex(val)) || val === '') {
            let newIdentityInfos = identityInfos.map((object) => ({
                identity_info: { ...object.identity_info },
                validations: { ...object.validations },
            }))

            newIdentityInfos[i].identity_info.SIN = val
            newIdentityInfos[i].validations.SIN = testSinSSnRegex(val)
            dispatch(setIdentityInfo(newIdentityInfos))
        }
    }

    const handleAddressChange = (e: React.ChangeEvent<any>, i: number) => {
        let val = e.target.value

        if ((testAddressRegex(val) && val.length < 40) || val === '') {
            let newIdentityInfos = identityInfos.map((object) => ({
                identity_info: { ...object.identity_info },
                validations: { ...object.validations },
            }))

            newIdentityInfos[i].identity_info.address = val
            newIdentityInfos[i].validations.address = validateAddress(val)
            dispatch(setIdentityInfo(newIdentityInfos))
        }
    }

    const handleCityChange = (e: React.ChangeEvent<any>, i: number) => {
        let val = e.target.value

        if (testCityRegex(val) && val.length < 30) {
            let newIdentityInfos = identityInfos.map((object) => ({
                identity_info: { ...object.identity_info },
                validations: { ...object.validations },
            }))

            newIdentityInfos[i].identity_info.city = val
            newIdentityInfos[i].validations.city = validateCity(val)
            dispatch(setIdentityInfo(newIdentityInfos))
        }
    }

    const handlePostalCodeChange = (e: React.ChangeEvent<any>, i: number) => {
        let val = e.target.value
        if (val.length <= 6 && testAlphaNumericRegex(val)) {
            let newIdentityInfos = identityInfos.map((object) => ({
                identity_info: { ...object.identity_info },
                validations: { ...object.validations },
            }))

            newIdentityInfos[i].identity_info.postal_code = val
            newIdentityInfos[i].validations.postal_code =
                validatePostalCode(val)
            dispatch(setIdentityInfo(newIdentityInfos))
        }
    }

    const handleCountryChange = (e: React.ChangeEvent<any>, i: number) => {
        let val = e.target.value
        let newIdentityInfos = identityInfos.map((object) => ({
            identity_info: { ...object.identity_info },
            validations: { ...object.validations },
        }))

        newIdentityInfos[i].identity_info.countries_id = val
        newIdentityInfos[i].validations.countries_id = validateCountry(
            parseInt(val)
        )
        dispatch(setIdentityInfo(newIdentityInfos))
    }

    const handleProvincesChange = (e: React.ChangeEvent<any>, i: number) => {
        let val = e.target.value

        let newIdentityInfos = identityInfos.map((object) => ({
            identity_info: { ...object.identity_info },
            validations: { ...object.validations },
        }))

        newIdentityInfos[i].identity_info.provinces_id = val
        newIdentityInfos[i].validations.provinces_id = val ? true : false
        dispatch(setIdentityInfo(newIdentityInfos))
    }

    const handleTelNumChange = (e: React.ChangeEvent<any>, i: number) => {
        let val = e.target.value
        if (val.length < 11) {
            let newIdentityInfos = identityInfos.map((object) => ({
                identity_info: { ...object.identity_info },
                validations: { ...object.validations },
            }))

            newIdentityInfos[i].identity_info.tel_number = val
            newIdentityInfos[i].validations.tel_number = testPhoneNumRegex(val)
            dispatch(setIdentityInfo(newIdentityInfos))
        }
    }

    const handlePercentOwnershipChange = (
        e: React.ChangeEvent<any>,
        i: number
    ) => {
        let val = e.target.value
        const parsedValue = parseFloat(val)
        const regex = /^\d+(\.\d{0,2})?$/

        if ((val <= 100 && val >= 0 && regex.test(val)) || val === '') {
            let newIdentityInfos = identityInfos.map((object) => ({
                identity_info: { ...object.identity_info },
                validations: { ...object.validations },
            }))
            let percent = new Decimal(parsedValue).dividedBy(100)

            newIdentityInfos[i].identity_info.percentage_ownership =
                percent.toNumber()

            newIdentityInfos[i].validations.percentage_ownership =
                validatePercentageOwned(percent.toNumber())
            dispatch(setIdentityInfo(newIdentityInfos))
        }
    }

    const handleDriversLicenceFile = (
        e: React.ChangeEvent<HTMLInputElement>,
        identityInfoIndex: number,
        identityInfoId: number | null,
        type: 'front' | 'back'
    ) => {
        const formData = new FormData()
        if (e.target.files?.length === 1) {
            let fileName = e.target.files[0].name

            let fieldName = ''
            let newIdentityInfos = identityInfos.map((object) => ({
                identity_info: { ...object.identity_info },
                validations: { ...object.validations },
            }))

            if (type === 'front') {
                fieldName = 'driverslicencefront'

                newIdentityInfos[
                    identityInfoIndex
                ].identity_info.drivers_licence_file_name = fileName

                newIdentityInfos[
                    identityInfoIndex
                ].validations.drivers_licence_file_name = true

                formData.append(fieldName, e.target.files[0], fileName)

                uploadDriversLicenceMutation.mutate({
                    formData,
                    identityInfoId,
                    identityInfoIndex,
                })
            } else {
                fieldName = 'driverslicenceback'

                newIdentityInfos[
                    identityInfoIndex
                ].identity_info.drivers_licence_file_name_back = fileName

                newIdentityInfos[
                    identityInfoIndex
                ].validations.drivers_licence_file_name_back = true

                formData.append(fieldName, e.target.files[0], fileName)

                uploadDriversLicenceMutation.mutate({
                    formData,
                    identityInfoId,
                    identityInfoIndex,
                })
            }

            dispatch(setIdentityInfo(newIdentityInfos))
        }
    }

    const handleProofOfAddressFile = (
        e: React.ChangeEvent<HTMLInputElement>,
        identityInfoIndex: number,
        identityInfoId: number | null
    ) => {
        const formData = new FormData()
        if (e.target.files?.length === 1) {
            let fileName = e.target.files[0].name

            let fieldName = 'proofofaddress'
            let newIdentityInfos = identityInfos.map((object) => ({
                identity_info: { ...object.identity_info },
                validations: { ...object.validations },
            }))

            newIdentityInfos[
                identityInfoIndex
            ].identity_info.proof_of_address_file_name = fileName

            newIdentityInfos[
                identityInfoIndex
            ].validations.proof_of_address_file_name = true

            formData.append(fieldName, e.target.files[0], fileName)

            uploadProofofAddressMutation.mutate({
                formData,
                identityInfoId,
                identityInfoIndex,
            })

            dispatch(setIdentityInfo(newIdentityInfos))
        }
    }

    const handleLivenessPhoto = (
        e: React.ChangeEvent<HTMLInputElement>,
        identityInfoIndex: number,
        identityInfoId: number | null
    ) => {
        const formData = new FormData()
        if (e.target.files?.length === 1) {
            let fileName = e.target.files[0].name

            let fieldName = 'livenessphoto'
            let newIdentityInfos = identityInfos.map((object) => ({
                identity_info: { ...object.identity_info },
                validations: { ...object.validations },
            }))

            newIdentityInfos[
                identityInfoIndex
            ].identity_info.liveness_photo_file_name = fileName

            newIdentityInfos[
                identityInfoIndex
            ].validations.liveness_photo_file_name = true

            formData.append(fieldName, e.target.files[0], fileName)

            uploadLivenessPhotoMutation.mutate({
                formData,
                identityInfoId,
                identityInfoIndex,
            })

            dispatch(setIdentityInfo(newIdentityInfos))
        }
    }

    const handleOccupationChange = (e: React.ChangeEvent<any>, i: number) => {
        let val = e.target.value
        if ((val.length < 30 && testParagraphRegex(val)) || val === '') {
            let newIdentityInfos = identityInfos.map((object) => ({
                identity_info: { ...object.identity_info },
                validations: { ...object.validations },
            }))
            newIdentityInfos[i].identity_info.occupation_title = val
            newIdentityInfos[i].validations.occupation_title =
                val && val.length >= 2 ? true : false
            dispatch(setIdentityInfo(newIdentityInfos))
        }
    }

    const handleEmailChanged = (
        e: React.ChangeEvent<HTMLInputElement>,
        i: number
    ) => {
        let val = e.target.value

        if (val.length < 40) {
            let newIdentityInfos = identityInfos.map((object) => ({
                identity_info: { ...object.identity_info },
                validations: { ...object.validations },
            }))
            newIdentityInfos[i].identity_info.email_address = val
            newIdentityInfos[i].validations.email_address = testEmailRegex(val)
            dispatch(setIdentityInfo(newIdentityInfos))
        }
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (validForm) {
            let identityInfosToSubmit: Paywell.API.V2.IdentityInfo[] =
                identityInfos.map((identityInfo) => {
                    return identityInfo.identity_info
                })

            submitFormValuesMutation.mutate(identityInfosToSubmit)
        }
    }

    const handleInvalidClass = (
        key: keyof Paywell.API.V2.IdentityInfo,
        idx: number
    ) => {
        if (identityInfos[idx].validations[key] === false) {
            return '!border-2 !border-rose-500'
        }

        return ''
    }

    const canAddShareHolder = () => {
        if (identityInfos.length == 4) {
            return false
        }

        let totalPercentOwned = new Decimal(0)

        identityInfos.forEach((identityInfo) => {
            if (identityInfo.identity_info.percentage_ownership) {
                totalPercentOwned = totalPercentOwned.add(
                    new Decimal(identityInfo.identity_info.percentage_ownership)
                )
            }
        })

        // Only 100% of the company can be owned so we check what the percent owned of all current shareholders is
        // and if less than 25% is available we dont want it (we only want people with 25% or more ownership)
        if (
            new Decimal(1).minus(totalPercentOwned).lessThan(new Decimal(0.25))
        ) {
            return false
        }

        return true
    }
    const getPercentDisplay = (
        value: string | undefined | number | Decimal
    ) => {
        if (value) {
            return new Decimal(value).times(100).toString()
        }
        return ''
    }

    const isMainContact = (email: string | null) => {
        return authUser?.email === email
    }

    return (
        <form className="" onSubmit={(e) => handleSubmit(e)}>
            <ul role="list" className="space-y-3">
                {identityInfos.map((identityInfo, idx) => {
                    return (
                        <li
                            key={idx}
                            className="overflow-hidden rounded-md px-6 py-4 shadow"
                        >
                            <div className="">
                                <div className="space-y-6 sm:space-y-5">
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Email
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                required
                                                disabled={
                                                    authUser?.email ===
                                                    identityInfo.identity_info
                                                        .email_address
                                                        ? true
                                                        : false
                                                }
                                                value={
                                                    identityInfo.identity_info
                                                        .email_address || ''
                                                }
                                                onChange={(e) =>
                                                    handleEmailChanged(e, idx)
                                                }
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                className={`block w-full max-w-lg  rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm disabled:bg-slate-200 ${handleInvalidClass(
                                                    'email_address',
                                                    idx
                                                )}`}
                                            />
                                            {identityInfo.validations
                                                .email_address === false && (
                                                <p
                                                    className="mt-2 text-sm text-red-600"
                                                    id="firstname-invalid"
                                                >
                                                    Please input a valid email
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="first-name"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            First Name
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                required
                                                value={
                                                    identityInfo.identity_info
                                                        .first_name || ''
                                                }
                                                onChange={(e) =>
                                                    handleFirstNameChange(
                                                        e,
                                                        idx
                                                    )
                                                }
                                                type="text"
                                                name="first-name"
                                                id="first-name"
                                                autoComplete="given-name"
                                                className={`block w-full max-w-lg  rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                                    'first_name',
                                                    idx
                                                )}`}
                                            />
                                            {identityInfo.validations
                                                .first_name === false && (
                                                <p
                                                    className="mt-2 text-sm text-red-600"
                                                    id="firstname-invalid"
                                                >
                                                    Minimum 2 characters
                                                    required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="last-name"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Last Name
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                required
                                                value={
                                                    identityInfo.identity_info
                                                        .last_name || ''
                                                }
                                                onChange={(e) =>
                                                    handleLastNameChange(e, idx)
                                                }
                                                type="text"
                                                name="last-name"
                                                id="last-name"
                                                autoComplete="family-name"
                                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                                    'last_name',
                                                    idx
                                                )}`}
                                            />
                                            {identityInfo.validations
                                                .last_name === false && (
                                                <p
                                                    className="mt-2 text-sm text-red-600"
                                                    id="lastname-invalid"
                                                >
                                                    Minimum 2 characters
                                                    required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="phone-number"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Phone Number
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                required
                                                value={
                                                    identityInfo.identity_info
                                                        .tel_number || ''
                                                }
                                                onChange={(e) =>
                                                    handleTelNumChange(e, idx)
                                                }
                                                min={0}
                                                type="number"
                                                name="phone-number"
                                                id="phone-number"
                                                autoComplete="tel"
                                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                                    'tel_number',
                                                    idx
                                                )}`}
                                            />
                                            {identityInfo.validations
                                                .tel_number === false && (
                                                <p
                                                    className="mt-2 text-sm text-red-600"
                                                    id="telnum-invalid"
                                                >
                                                    Please input a valid phone
                                                    number (10 digits)
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <div className="">
                                            <label
                                                htmlFor="birthday"
                                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 w-full"
                                            >
                                                Birthday
                                            </label>
                                            <span className="text-xs text-gray-700">
                                                You must be 18 years or older to
                                                apply
                                            </span>
                                        </div>

                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                required
                                                value={
                                                    identityInfo.identity_info.birthday?.toString() ||
                                                    ''
                                                }
                                                onChange={(e) =>
                                                    handleBirthdayChange(e, idx)
                                                }
                                                type="date"
                                                name="birthday"
                                                id="birthday"
                                                autoComplete="family-name"
                                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                                    'birthday',
                                                    idx
                                                )} ${handleInvalidClass(
                                                    'birthday',
                                                    idx
                                                )}`}
                                            />
                                            {identityInfo.validations
                                                .birthday === false && (
                                                <p
                                                    className="mt-2 text-sm text-red-600"
                                                    id="birthday-invalid"
                                                >
                                                    Please input a valid
                                                    birthday
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="country"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Country
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <select
                                                required
                                                value={
                                                    identityInfo.identity_info
                                                        .countries_id || ''
                                                }
                                                onChange={(e) =>
                                                    handleCountryChange(e, idx)
                                                }
                                                id="country"
                                                name="country"
                                                autoComplete="country-name"
                                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                                    'countries_id',
                                                    idx
                                                )}`}
                                            >
                                                <option value="">Select</option>
                                                <option value="1">
                                                    Canada
                                                </option>
                                                <option value="2">
                                                    United States
                                                </option>
                                            </select>

                                            {identityInfo.validations
                                                .countries_id === false && (
                                                <p
                                                    className="mt-2 text-sm text-red-600"
                                                    id="invalid-country"
                                                >
                                                    Please select a country
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="street-address"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Home Address
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                required
                                                value={
                                                    identityInfo.identity_info
                                                        .address || ''
                                                }
                                                onChange={(e) =>
                                                    handleAddressChange(e, idx)
                                                }
                                                type="text"
                                                name="street-address"
                                                id="street-address"
                                                autoComplete="street-address"
                                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                                    'address',
                                                    idx
                                                )}`}
                                            />
                                            {identityInfo.validations
                                                .address === false && (
                                                <p
                                                    className="mt-2 text-sm text-red-600"
                                                    id="invalid-address"
                                                >
                                                    Minimum 2 characters
                                                    required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="city"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            City
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                required
                                                value={
                                                    identityInfo.identity_info
                                                        .city || ''
                                                }
                                                onChange={(e) =>
                                                    handleCityChange(e, idx)
                                                }
                                                type="text"
                                                name="city"
                                                id="city"
                                                autoComplete="address-level2"
                                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                                    'city',
                                                    idx
                                                )}`}
                                            />
                                            {identityInfo.validations.city ===
                                                false && (
                                                <p
                                                    className="mt-2 text-sm text-red-600"
                                                    id="invalid-city"
                                                >
                                                    Minimum 2 characters
                                                    required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="region"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            State / Province
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <select
                                                required
                                                onChange={(e) =>
                                                    handleProvincesChange(
                                                        e,
                                                        idx
                                                    )
                                                }
                                                value={
                                                    identityInfo.identity_info
                                                        .provinces_id || ''
                                                }
                                                name="region"
                                                id="region"
                                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                                    'provinces_id',
                                                    idx
                                                )}`}
                                            >
                                                <option value="">Select</option>
                                                <option value="1">
                                                    British Columbia
                                                </option>
                                                <option value="2">
                                                    Alberta
                                                </option>
                                                <option value="3">
                                                    Saskatchewan
                                                </option>
                                                <option value="4">
                                                    Manitoba
                                                </option>
                                                <option value="5">
                                                    Ontario
                                                </option>
                                                <option value="6">
                                                    Quebec
                                                </option>
                                                <option value="7">
                                                    New Brunswick
                                                </option>
                                                <option value="8">PEI</option>
                                                <option value="9">
                                                    Nova Scotia
                                                </option>
                                                <option value="10">NFLD</option>
                                                <option value="11">
                                                    Yukon
                                                </option>
                                                <option value="12">NWT</option>
                                                <option value="13">
                                                    Nunavut
                                                </option>
                                                <option value="14">
                                                    United States
                                                </option>
                                            </select>
                                            {identityInfo.validations
                                                .provinces_id === false && (
                                                <p
                                                    className="mt-2 text-sm text-red-600"
                                                    id="invalid-province"
                                                >
                                                    Please select a province
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="postal-code"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            ZIP / Postal code
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                required
                                                value={
                                                    identityInfo.identity_info
                                                        .postal_code || ''
                                                }
                                                onChange={(e) =>
                                                    handlePostalCodeChange(
                                                        e,
                                                        idx
                                                    )
                                                }
                                                type="text"
                                                name="postal-code"
                                                id="postal-code"
                                                autoComplete="postal-code"
                                                className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm sm:max-w-xs sm:text-sm ${handleInvalidClass(
                                                    'postal_code',
                                                    idx
                                                )}`}
                                            />
                                            {identityInfo.validations
                                                .postal_code === false && (
                                                <p
                                                    className="mt-2 text-sm text-red-600"
                                                    id="invalid-postal-code"
                                                >
                                                    Minimum 5 characters
                                                    required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 mt-5">
                                <div className={''}>
                                    <label
                                        htmlFor="photo"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Government Issued ID (Front)
                                    </label>
                                    <span className="text-xs text-gray-700">
                                        Ex. Drivers License, Passport, Status
                                        Card, etc.
                                    </span>
                                </div>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <div className="flex items-center">
                                        <div className="sm:col-span-6">
                                            <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                                <div className="space-y-1 text-center">
                                                    <svg
                                                        className="mx-auto h-12 w-12 text-gray-400"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        viewBox="0 0 48 48"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    <div className="flex text-sm text-gray-600">
                                                        <label
                                                            htmlFor={`drivers-front-${idx}`}
                                                            className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600"
                                                        >
                                                            <span>
                                                                Upload a file
                                                            </span>
                                                            <input
                                                                required={
                                                                    !identityInfo
                                                                        .identity_info
                                                                        .drivers_licence_file_name
                                                                }
                                                                id={`drivers-front-${idx}`}
                                                                name={`drivers-front-${idx}`}
                                                                type="file"
                                                                className={`sr-only `}
                                                                onChange={(e) =>
                                                                    handleDriversLicenceFile(
                                                                        e,
                                                                        idx,
                                                                        identityInfo
                                                                            .identity_info
                                                                            .id,
                                                                        'front'
                                                                    )
                                                                }
                                                                accept=".jpg,.png,.pdf,.jpeg"
                                                            />
                                                        </label>
                                                        <p className="pl-1">
                                                            or drag and drop
                                                        </p>
                                                    </div>
                                                    <p className="text-xs text-gray-500">
                                                        PNG, JPG, PDF, up to 5MB
                                                    </p>

                                                    {identityInfo.identity_info
                                                        .drivers_licence_file_name ? (
                                                        <p className="text-lg text-black-500 mt-100 border-solid border-2">
                                                            {
                                                                identityInfo
                                                                    .identity_info
                                                                    .drivers_licence_file_name
                                                            }{' '}
                                                        </p>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 mt-5">
                                <label
                                    htmlFor="photo"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Government Issued ID (Back)
                                </label>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <div className="flex items-center">
                                        <div className="sm:col-span-6">
                                            <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                                <div className="space-y-1 text-center">
                                                    <svg
                                                        className="mx-auto h-12 w-12 text-gray-400"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        viewBox="0 0 48 48"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    <div className="flex text-sm text-gray-600">
                                                        <label
                                                            htmlFor={`drivers-back-${idx}`}
                                                            className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600"
                                                        >
                                                            <span>
                                                                Upload a file
                                                            </span>
                                                            <input
                                                                required={
                                                                    !identityInfo
                                                                        .identity_info
                                                                        .drivers_licence_file_name_back
                                                                }
                                                                id={`drivers-back-${idx}`}
                                                                name={`drivers-back-${idx}`}
                                                                type="file"
                                                                className={`sr-only `}
                                                                onChange={(e) =>
                                                                    handleDriversLicenceFile(
                                                                        e,
                                                                        idx,
                                                                        identityInfo
                                                                            .identity_info
                                                                            .id,
                                                                        'back'
                                                                    )
                                                                }
                                                                accept=".jpg,.png,.pdf,.jpeg"
                                                            />
                                                        </label>
                                                        <p className="pl-1">
                                                            or drag and drop
                                                        </p>
                                                    </div>
                                                    <p className="text-xs text-gray-500">
                                                        PNG, JPG, PDF, up to 5MB
                                                    </p>

                                                    {identityInfo.identity_info
                                                        .drivers_licence_file_name_back ? (
                                                        <p className="text-lg text-black-500 mt-100 border-solid border-2">
                                                            {
                                                                identityInfo
                                                                    .identity_info
                                                                    .drivers_licence_file_name_back
                                                            }
                                                        </p>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>

            <div className="pt-5">
                <div className="flex justify-between">
                    {!submitFormValuesMutation.isLoading && (
                        <>
                            <button
                                disabled={!validForm}
                                type="submit"
                                className="ml-3 inline-flex disabled:bg-gray-400 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Save and Continue
                            </button>

                            {addContactMutation.isLoading && (
                                <Loading height="8" width="8" />
                            )}
                            {/*{!addContactMutation.isLoading && (
                                <>
                                    {canAddShareHolder() ? (
                                        <button
                                            type="button"
                                            onClick={() =>
                                                addContactMutation.mutate()
                                            }
                                            disabled={!canAddShareHolder()}
                                            className="inline-flex items-center gap-x-1.5 rounded-md bg-orange-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                        >
                                            <PlusCircleIcon
                                                className="-ml-0.5 h-5 w-5"
                                                aria-hidden="true"
                                            />
                                            Shareholder
                                        </button>
                                    ) : (
                                        <Tooltip
                                            text={
                                                'Unable to add a new shareholder as less than 25% of the corporation is available for assignment. Please remove a shareholder or adjust existing shareholder(s) to make room for a new individual.'
                                            }
                                        >
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    addContactMutation.mutate()
                                                }
                                                disabled={!canAddShareHolder()}
                                                className="ml-3 inline-flex disabled:bg-gray-400 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Add Shareholder
                                            </button>
                                        </Tooltip>
                                    )}
                                </>
                            )}*/}
                        </>
                    )}
                    {submitFormValuesMutation.isLoading && (
                        <Loading width="8" height="8" />
                    )}
                </div>
                {submitFormValuesMutation.isError && (
                    <div className="md:w-2/5 mt-4">
                        <Error
                            title="There was an error saving"
                            message={`Please reach out to one of our representatives at ${branding.contact_email} if this error persists`}
                        />
                    </div>
                )}
            </div>
        </form>
    )
}
