import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    CheckCircleIcon,
    XCircleIcon,
    InformationCircleIcon,
    ExclamationCircleIcon,
} from '@heroicons/react/24/outline'
import {
    CheckIcon,
    ExclamationTriangleIcon,
    XMarkIcon,
} from '@heroicons/react/20/solid'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import {
    setNotice,
    setWarning,
    setSuccess,
    setError,
    setConfirm,
    resetConfirm,
} from '../redux/state-slices/GlobalNotificationSlice'
import Loading from './Loading'
import TaxesNew from '../invoices/taxes/TaxesNew'
const GlobalNotification = () => {
    const dispatch = useAppDispatch()
    const globalNotificationState = useAppSelector(
        (state) => state.global_notification
    )

    const handleCallback = (taxId: any) => {
        globalNotificationState.tax.callback(taxId)
    }

    const handleClose = () => {
        globalNotificationState.tax.onClose()
    }

    useEffect(() => {
        if (
            globalNotificationState.success.length > 0 ||
            globalNotificationState.warning.length > 0 ||
            globalNotificationState.error.length > 0 ||
            globalNotificationState.notice.length > 0
        ) {
            const timeId = setTimeout(() => {
                // After 4 seconds clear all notifications
                dispatch(setSuccess([]))
                dispatch(setWarning([]))
                dispatch(setError([]))
                dispatch(setNotice([]))
            }, 4000)
            return () => {
                clearTimeout(timeId)
            }
        }
    }, [
        globalNotificationState.success,
        globalNotificationState.warning,
        globalNotificationState.error,
        globalNotificationState.notice,
    ])

    return (
        <div
            aria-live="assertive"
            className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-10"
        >
            <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                <Transition
                    show={globalNotificationState.error.length > 0}
                    as={Fragment}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="p-4">
                            <div className="flex items-start">
                                <div className="flex-shrink-0">
                                    <XCircleIcon
                                        className="h-6 w-6 text-red-400"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="ml-3 w-0 flex-1 pt-0.5">
                                    <p className="text-sm font-medium text-gray-900">
                                        Error
                                    </p>
                                    <p className="mt-1 text-sm text-gray-500">
                                        {globalNotificationState.error.map(
                                            (e, i) => (
                                                <p key={i}>{e}</p>
                                            )
                                        )}
                                    </p>
                                </div>
                                <div className="ml-4 flex flex-shrink-0">
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => {
                                            dispatch(setError([]))
                                        }}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
                <Transition
                    show={globalNotificationState.notice.length > 0}
                    as={Fragment}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="p-4">
                            <div className="flex items-start">
                                <div className="flex-shrink-0">
                                    <InformationCircleIcon
                                        className="h-6 w-6 text-yellow-400"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="ml-3 w-0 flex-1 pt-0.5">
                                    <p className="text-sm font-medium text-gray-900">
                                        Notice
                                    </p>
                                    <p className="mt-1 text-sm text-gray-500">
                                        {globalNotificationState.notice.map(
                                            (e, i) => (
                                                <p key={i}>{e}</p>
                                            )
                                        )}
                                    </p>
                                </div>
                                <div className="ml-4 flex flex-shrink-0">
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => {
                                            dispatch(setNotice([]))
                                        }}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
                <Transition
                    show={globalNotificationState.success.length > 0}
                    as={Fragment}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="p-4">
                            <div className="flex items-start">
                                <div className="flex-shrink-0">
                                    <CheckCircleIcon
                                        className="h-6 w-6 text-green-400"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="ml-3 w-0 flex-1 pt-0.5">
                                    <p className="text-sm font-medium text-gray-900">
                                        Success
                                    </p>
                                    <p className="mt-1 text-sm text-gray-500">
                                        {globalNotificationState.success.map(
                                            (e, i) => (
                                                <p key={i}>{e}</p>
                                            )
                                        )}
                                    </p>
                                </div>
                                <div className="ml-4 flex flex-shrink-0">
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => {
                                            dispatch(setSuccess([]))
                                        }}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
                <Transition
                    show={globalNotificationState.warning.length > 0}
                    as={Fragment}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="p-4">
                            <div className="flex items-start">
                                <div className="flex-shrink-0">
                                    <ExclamationCircleIcon
                                        className="h-6 w-6 text-orange-400"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="ml-3 w-0 flex-1 pt-0.5">
                                    <p className="text-sm font-medium text-gray-900">
                                        Warning
                                    </p>
                                    <p className="mt-1 text-sm text-gray-500">
                                        {globalNotificationState.warning.map(
                                            (e, i) => (
                                                <p key={i}>{e}</p>
                                            )
                                        )}
                                    </p>
                                </div>
                                <div className="ml-4 flex flex-shrink-0">
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => {
                                            dispatch(setWarning([]))
                                        }}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
            <Transition.Root
                show={globalNotificationState.confirm.title ? true : false}
                as={Fragment}
            >
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => console.log('test')}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-80 transition-opacity" />
                    </Transition.Child>
                    {globalNotificationState.confirm.title && (
                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-start">
                                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <ExclamationTriangleIcon
                                                        className="h-6 w-6 text-red-600"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                    <Dialog.Title
                                                        as="h1"
                                                        className="text-2xl text-left font-semibold leading-3 text-gray-900"
                                                    >
                                                        {
                                                            globalNotificationState
                                                                .confirm.title
                                                        }
                                                    </Dialog.Title>
                                                    <div className="mt-2 pt-8">
                                                        <p className="text-sm text-left text-gray-500">
                                                            {
                                                                globalNotificationState
                                                                    .confirm
                                                                    .message
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row justify-around sm:px-6">
                                            <button
                                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-500 py-1 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
                                                onClick={() =>
                                                    dispatch(resetConfirm(''))
                                                }
                                            >
                                                No
                                            </button>
                                            {!globalNotificationState.loading ? (
                                                <button
                                                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-sky-500 py-1 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2"
                                                    onClick={
                                                        globalNotificationState
                                                            .confirm.onClick
                                                    }
                                                >
                                                    Yes
                                                </button>
                                            ) : (
                                                <button>
                                                    <Loading
                                                        width="8"
                                                        height="8"
                                                    />
                                                </button>
                                            )}
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    )}
                </Dialog>
            </Transition.Root>
            <Transition.Root
                show={globalNotificationState.tax.show}
                as={Fragment}
            >
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={globalNotificationState.tax.onClose}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    <TaxesNew
                                                        source={[]}
                                                        onClose={handleClose}
                                                        onSuccess={
                                                            handleCallback
                                                        }
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )
}

export default GlobalNotification
