import {
    ArrowUpTrayIcon,
    CheckIcon,
    ChevronUpDownIcon,
    PlusCircleIcon,
    TrashIcon,
} from '@heroicons/react/20/solid'
import { Listbox, Transition } from '@headlessui/react'
import {
    CONTACT_TYPE,
    COUNTRIES,
    getReadableContactType,
    getReadableCountry,
    getReadableProvince,
    PROVINCES,
} from '../../utils/UserUtils'
import React, { Fragment, useEffect, useState } from 'react'
import { extractFileName } from '../../utils/StringUtils'
import { useMutation, useQuery } from 'react-query'
import { setError } from '../../redux/state-slices/GlobalNotificationSlice'
import { UserRepository } from '../../api/UserRepositoy'
import { useAppDispatch } from '../../redux/hooks'
import DialogModal from '../../shared-components/DialogModal'
import Loading from '../../shared-components/Loading'
import { ContactRepository } from '../../api/ContactRepository'
import { useNavigate } from 'react-router-dom'
import { numOnlyRegex, validateEmailAddress } from '../../utils/RegexHelper'

interface ContactProps {
    user: Paywell.API.V2.User | null
}

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ')
}
export default function ContactsEdit({ user }: ContactProps) {
    const userRepository = new UserRepository()
    const contactRepository = new ContactRepository()
    const dispatch = useAppDispatch()
    const [confirmDeleteContactDialogOpen, setConfirmDeleteContactDialogOpen] =
        useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const navigate = useNavigate()
    const [selectedContactId, setSelectedContactId] = useState(0)
    const [contacts, setContacts] = useState<Paywell.API.V2.UserContact[]>([])
    const [validForm, setValidForm] = useState(true)
    const [contactsCopy, setContactsCopy] = useState<
        Paywell.API.V2.UserContact[]
    >([])

    useEffect(() => {
        validateForm()
    }, [contacts])

    const validateForm = () => {
        let valid = true
        contacts.forEach((contact) => {
            if (!validateInput('email', contact.email_address || '')) {
                valid = false
            }
            if (!validateInput('phone', contact.tel_number || '')) {
                valid = false
            }
        })
        setValidForm(valid)
    }
    const validateInput = (field: 'email' | 'phone', value: string) => {
        let valid = true
        if (field === 'email') {
            valid = validateEmailAddress(value) || value === ''
        }
        if (field === 'phone') valid = numOnlyRegex(value) || value === ''
        return valid
    }
    const fetchUserContacts = async () => {
        const contacts = await contactRepository.getUsersContacts(user?.id || 0)
        return contacts.data
    }

    const saveContactChanges = async () => {
        const updatedContacts = await contactRepository.updateUserContacts(
            user?.id || 0,
            contacts
        )
        return updatedContacts.data
    }

    const saveChangesQuery = useMutation(() => saveContactChanges(), {
        onSuccess: () => {
            navigate('/users/' + user?.id + '/contacts')
        },
        onError: () => {
            dispatch(
                setError([
                    `There was an error saving the contact information. Please try again.`,
                ])
            )
        },
    })

    const contactsQuery = useQuery(
        ['user-contacts'],
        () => fetchUserContacts(),
        {
            onSuccess: (data) => {
                setContacts(data)
                setContactsCopy(data)
            },
            onError: (error) => {
                dispatch(
                    setError(['Error retrieving contacts. Please try again'])
                )
            },
            refetchOnWindowFocus: false,
        }
    )

    const uploadDriversLicenceMutation = useMutation(
        (args: {
            formData: FormData
            contactId: number
            fileName: string
            index: number
            type: 'front' | 'back'
        }) =>
            userRepository.uploadDriversLicence(args.formData, args.contactId),

        {
            onSuccess: (_, { fileName, index, type }) => {
                updateDriversLicenceFileName(fileName, index, type)
            },
            onError: () => {
                dispatch(setError(['There was an error uploading the file']))
            },
        }
    )

    const createContactMutation = useMutation({
        mutationFn: (userId: number) => contactRepository.createContact(userId),

        onSuccess: (data) => {
            let returnedContact = data.data
            let newContacts = [...contacts]
            newContacts.push(returnedContact)
            setContacts(newContacts)
            setContactsCopy(newContacts)
        },

        onError: () => {
            dispatch(
                setError([
                    `There was an error creating the contact. Please try again.`,
                ])
            )
        },
    })

    const deleteContactMutation = useMutation({
        mutationFn: (id: number) => userRepository.deleteContact(id),

        onSuccess: (data) => {
            let newContacts = contacts.filter(
                (contact) => contact.id !== selectedContactId
            )
            setContacts(newContacts)
            setContactsCopy(newContacts)
            setConfirmDeleteContactDialogOpen(false)
        },

        onError: () => {
            dispatch(
                setError([
                    `There was an error deleting the contact. Please try again.`,
                ])
            )
        },
    })

    const createContactCopy = () => {
        let contactsCopy = [...contacts]
        return contactsCopy
    }

    const handleContactFirstNameChange = (val: string, index: number) => {
        let newContacts = createContactCopy()

        if (newContacts[index] && val.length < 30) {
            newContacts[index] = {
                ...newContacts[index],
                first_name: val,
            }

            setContacts(newContacts)
        }
    }

    const handleContactLastNameChange = (val: string, index: number) => {
        let newContacts = createContactCopy()
        newContacts[index].last_name = val
        if (val.length < 30) {
            newContacts[index] = {
                ...newContacts[index],
                last_name: val,
            }
            setContacts(newContacts)
        }
    }

    const handleContactTypeChange = (val: number, index: number) => {
        let newContacts = createContactCopy()
        newContacts[index] = {
            ...newContacts[index],
            contact_type_id: val,
        }
        setContacts(newContacts)
    }

    const handleContactAddressChange = (val: string, index: number) => {
        let newContacts = createContactCopy()
        if (val.length < 50) {
            newContacts[index] = {
                ...newContacts[index],
                address: val,
            }
            setContacts(newContacts)
        }
    }

    const handleContactPostalChange = (val: string, index: number) => {
        let newContacts = createContactCopy()
        if (val.length < 10) {
            newContacts[index] = {
                ...newContacts[index],
                postal_code: val,
            }

            setContacts(newContacts)
        }
    }

    const handleContactCityChange = (val: string, index: number) => {
        let newContacts = createContactCopy()
        if (val.length < 50) {
            newContacts[index] = {
                ...newContacts[index],
                city: val,
            }
            setContacts(newContacts)
        }
    }

    const handleContactProvinceChange = (val: any, index: number) => {
        let newContacts = createContactCopy()
        newContacts[index] = {
            ...newContacts[index],
            provinces_id: val,
        }

        setContacts(newContacts)
    }

    const handleContactCountryChange = (val: any, index: number) => {
        let newContacts = createContactCopy()
        newContacts[index] = {
            ...newContacts[index],
            countries_id: val,
        }

        setContacts(newContacts)
    }

    const handleContactEmailChange = (val: string, index: number) => {
        let newContacts = createContactCopy()
        if (val.length < 50) {
            newContacts[index] = {
                ...newContacts[index],
                email_address: val,
            }

            setContacts(newContacts)
        }
    }

    const handlePhoneChange = (val: string, index: number) => {
        let newContacts = createContactCopy()
        if (val.length < 25) {
            newContacts[index] = {
                ...newContacts[index],
                tel_number: val,
            }

            setContacts(newContacts)
        }
    }

    const handleBirthdayChange = (val: string, index: number) => {
        let newContacts = createContactCopy()
        newContacts[index] = {
            ...newContacts[index],
            birthday: val,
        }

        setContacts(newContacts)
    }

    const handleDriversLicenceChange = (val: string, index: number) => {
        let newContacts = createContactCopy()
        if (val.length < 45) {
            newContacts[index] = {
                ...newContacts[index],
                drivers_licence_number: val,
            }
        }

        setContacts(newContacts)
    }

    const handleSinChange = (val: string, index: number) => {
        let newContacts = createContactCopy()
        if (val.length < 45) {
            newContacts[index] = {
                ...newContacts[index],
                SIN: val,
            }
        }

        setContacts(newContacts)
    }

    const handleBusinessNameChange = (val: string, index: number) => {
        let newContacts = createContactCopy()
        if (val.length < 50) {
            newContacts[index] = {
                ...newContacts[index],
                business_name: val,
            }
        }

        setContacts(newContacts)
    }

    const handleBusinessTypeChange = (val: string, index: number) => {
        let newContacts = createContactCopy()
        if (val.length < 45) {
            newContacts[index] = {
                ...newContacts[index],
                business_type: val,
            }
        }

        setContacts(newContacts)
    }
    const handleTaxIdChange = (val: string, index: number) => {
        let newContacts = createContactCopy()
        if (val.length < 45) {
            newContacts[index] = {
                ...newContacts[index],
                tax_id: val,
            }
        }

        setContacts(newContacts)
    }
    const handleDriversLicenceFile = (
        e: React.ChangeEvent<HTMLInputElement>,
        contactId: number,
        index: number,
        type: 'front' | 'back'
    ) => {
        const formData = new FormData()
        if (e.target.files?.length === 1) {
            let fileName = e.target.files[0].name
            let fieldName = ''

            if (type === 'front') {
                fieldName = 'driverslicencefront'
            } else {
                fieldName = 'driverslicenceback'
            }

            formData.append(fieldName, e.target.files[0], fileName)

            uploadDriversLicenceMutation.mutate({
                formData,
                contactId,
                fileName,
                index,
                type,
            })
        }

        e.target.value = '' //reset the input field
    }

    const updateDriversLicenceFileName = (
        fileName: string,
        index: number,
        type: 'front' | 'back'
    ) => {
        let newContacts = createContactCopy()
        if (type === 'front') {
            newContacts[index].drivers_licence_file_name = fileName
        } else {
            newContacts[index].drivers_licence_file_name_back = fileName
        }

        setContacts(newContacts)
    }

    const handleSaveChanges = () => {
        if (validForm) {
            saveChangesQuery.mutate()
        }
    }
    const handleAddContact = () => {
        createContactMutation.mutate(user?.id || 0)
    }
    const handleDeleteContact = (contactId: number) => {
        setSelectedContactId(contactId)
        setConfirmDeleteContactDialogOpen(true)
    }

    const confirmDeleteContact = () => {
        deleteContactMutation.mutate(selectedContactId)
    }

    const cancelDeleteContact = () => {
        setConfirmDeleteContactDialogOpen(false)
    }

    const hasUnsavedChanges = () => {
        return JSON.stringify(contacts) !== JSON.stringify(contactsCopy)
    }
    const handleCancel = () => {
        if (!user) return
        if (hasUnsavedChanges()) {
            setIsDialogOpen(true) // Show the dialog if there are unsaved changes
        } else {
            navigate('/users/' + user.id + '/contacts') // No unsaved changes, just navigate away
        }
    }

    const confirmCancel = () => {
        setIsDialogOpen(false) // Close the dialog
        //reset the state to the original data
        navigate('/users/' + user?.id + '/contacts') // Proceed with the cancel action
    }

    const cancelCancel = () => {
        setIsDialogOpen(false) // Close the dialog without canceling changes
    }

    const invalidClass =
        '!border-2 !border-rose-500 focus:ring-rose-500 focus:border-rose-500'

    const isLoading =
        uploadDriversLicenceMutation.isLoading ||
        deleteContactMutation.isLoading ||
        createContactMutation.isLoading

    return (
        <div className={`p-10 relative`}>
            {uploadDriversLicenceMutation.isLoading ||
                (saveChangesQuery.isLoading && (
                    <div
                        id="loading-screen"
                        className=" w-full h-full absolute block top-0 left-0 bg-white opacity-75 z-50"
                    >
                        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
                            <Loading height={'8'} width={'8'} />
                        </span>
                    </div>
                ))}
            <div className="mb-2 flex justify-end space-x-2">
                <button
                    disabled={!validForm}
                    onClick={handleSaveChanges}
                    type="button"
                    className={`block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm disabled:bg-gray-400 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                >
                    Save
                </button>
                <button
                    onClick={handleCancel}
                    type="button"
                    className="flex  rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                    Cancel
                </button>
            </div>
            <div className={'border border-gray-100 rounded-md p-2'}>
                {isLoading && (
                    <div
                        id="loading-screen"
                        className=" w-full h-full absolute block top-0 left-0 bg-white opacity-75 z-50"
                    >
                        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
                            <Loading height={'8'} width={'8'} />
                        </span>
                    </div>
                )}
                <div className={'flex justify-between'}>
                    <div className="px-4 sm:px-0">
                        <h3 className="text-base font-semibold leading-7 text-gray-900">
                            Merchant Contact Information
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                            View and edit merchant contact information.
                        </p>
                    </div>
                    {contacts && contacts?.length < 2 && (
                        <PlusCircleIcon
                            onClick={handleAddContact}
                            className={'h-7 w-7 text-indigo-500 cursor-pointer'}
                        />
                    )}
                </div>
                {(contactsQuery.isLoading || contactsQuery.isFetching) && (
                    <Loading height={'8'} width={'8'} />
                )}

                {contacts && !createContactMutation.isLoading && (
                    <ul
                        className={`grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-${contacts?.length} xl:gap-x-8 mt-4`}
                    >
                        {contacts.map((contact, index) => (
                            <li
                                key={contact.id || index}
                                className="overflow-hidden rounded-xl border border-gray-200"
                            >
                                <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                                    <div className="text-sm font-medium leading-6 text-gray-900 w-full flex justify-between items-center">
                                        <Listbox
                                            value={contact.contact_type_id}
                                            onChange={(value) =>
                                                handleContactTypeChange(
                                                    value,
                                                    index
                                                )
                                            }
                                        >
                                            {({ open }) => (
                                                <>
                                                    <div className="relative mt-2">
                                                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                            <span className="block truncate">
                                                                {getReadableContactType(
                                                                    contact.contact_type_id
                                                                )}
                                                            </span>
                                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                <ChevronUpDownIcon
                                                                    className="h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            </span>
                                                        </Listbox.Button>

                                                        <Transition
                                                            show={open}
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                {Object.entries(
                                                                    CONTACT_TYPE
                                                                )
                                                                    .filter(
                                                                        ([
                                                                            id,
                                                                            name,
                                                                        ]) => {
                                                                            const existsContactWithType1 =
                                                                                contacts &&
                                                                                contacts.some(
                                                                                    (
                                                                                        contact
                                                                                    ) =>
                                                                                        contact.contact_type_id ===
                                                                                        1
                                                                                )
                                                                            return !(
                                                                                existsContactWithType1 &&
                                                                                id ===
                                                                                    '1'
                                                                            )
                                                                        }
                                                                    )
                                                                    .map(
                                                                        ([
                                                                            id,
                                                                            name,
                                                                        ]) => (
                                                                            <Listbox.Option
                                                                                key={
                                                                                    id
                                                                                }
                                                                                className={({
                                                                                    active,
                                                                                }) =>
                                                                                    classNames(
                                                                                        active
                                                                                            ? 'bg-indigo-600 text-white'
                                                                                            : 'text-gray-900',
                                                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    id
                                                                                }
                                                                            >
                                                                                {({
                                                                                    selected,
                                                                                    active,
                                                                                }) => (
                                                                                    <>
                                                                                        <span
                                                                                            className={classNames(
                                                                                                selected
                                                                                                    ? 'font-semibold'
                                                                                                    : 'font-normal',
                                                                                                'block truncate'
                                                                                            )}
                                                                                        >
                                                                                            {
                                                                                                name
                                                                                            }
                                                                                        </span>

                                                                                        {selected && (
                                                                                            <span
                                                                                                className={classNames(
                                                                                                    active
                                                                                                        ? 'text-white'
                                                                                                        : 'text-indigo-600',
                                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                                )}
                                                                                            >
                                                                                                <CheckIcon
                                                                                                    className="h-5 w-5"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            </span>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </Listbox.Option>
                                                                        )
                                                                    )}
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </div>
                                                </>
                                            )}
                                        </Listbox>

                                        {contacts &&
                                            contacts[index].contact_type_id !==
                                                1 && (
                                                <TrashIcon
                                                    onClick={() =>
                                                        handleDeleteContact(
                                                            contact.id
                                                        )
                                                    }
                                                    className="fill-red-500 h-5 w-5 cursor-pointer"
                                                />
                                            )}
                                    </div>
                                </div>
                                <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                                    <div className="lg:flex mb-2 lg:mb-0 justify-between gap-x-4 py-3">
                                        <dt className="text-gray-500 w-1/2">
                                            First Name
                                        </dt>
                                        <input
                                            type="text"
                                            onChange={(e) =>
                                                handleContactFirstNameChange(
                                                    e.target.value,
                                                    index
                                                )
                                            }
                                            name="contact-first-name"
                                            id="contact-first-name"
                                            value={contact.first_name || ''}
                                            className={` block mt-2 lg:mt-0 w-full  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                    <div className="lg:flex mb-2 lg:mb-0 justify-between gap-x-4 py-3">
                                        {' '}
                                        <dt className="text-gray-500 w-1/2">
                                            Last Name
                                        </dt>
                                        <input
                                            type="text"
                                            onChange={(e) =>
                                                handleContactLastNameChange(
                                                    e.target.value,
                                                    index
                                                )
                                            }
                                            name="contact-last-name"
                                            id="contact-last-name"
                                            value={contact.last_name || ''}
                                            className={` block mt-2 lg:mt-0 w-full  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                    <div className="lg:flex mb-2 lg:mb-0 justify-between gap-x-4 py-3">
                                        {' '}
                                        <dt className="text-gray-500 w-1/2">
                                            Address
                                        </dt>
                                        <input
                                            type="text"
                                            onChange={(e) =>
                                                handleContactAddressChange(
                                                    e.target.value,
                                                    index
                                                )
                                            }
                                            name="contact-address"
                                            id="contact-address"
                                            value={contact.address || ''}
                                            className={` block mt-2 lg:mt-0 w-full  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                    <div className="lg:flex mb-2 lg:mb-0 justify-between gap-x-4 py-3">
                                        {' '}
                                        <dt className="text-gray-500 w-1/2">
                                            Postal Code
                                        </dt>
                                        <input
                                            type="text"
                                            onChange={(e) =>
                                                handleContactPostalChange(
                                                    e.target.value,
                                                    index
                                                )
                                            }
                                            name="contact-postal-code"
                                            id="contact-postal-code"
                                            value={contact.postal_code || ''}
                                            className={` block mt-2 lg:mt-0 w-full  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                    <div className="lg:flex mb-2 lg:mb-0 justify-between gap-x-4 py-3">
                                        {' '}
                                        <dt className="text-gray-500 w-1/2">
                                            City
                                        </dt>
                                        <input
                                            type="text"
                                            onChange={(e) =>
                                                handleContactCityChange(
                                                    e.target.value,
                                                    index
                                                )
                                            }
                                            name="contact-city"
                                            id="contact-city"
                                            value={contact.city || ''}
                                            className={` block mt-2 lg:mt-0 w-full  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                    <div className="lg:flex mb-2 lg:mb-0 justify-between gap-x-4 py-3">
                                        {' '}
                                        <dt className="text-gray-500 w-1/2">
                                            Province/State
                                        </dt>
                                        <Listbox
                                            value={contact?.provinces_id}
                                            onChange={(value) =>
                                                handleContactProvinceChange(
                                                    value,
                                                    index
                                                )
                                            }
                                        >
                                            {({ open }) => (
                                                <div className="relative mt-2 w-full">
                                                    <Listbox.Button className="w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                        <span className="block truncate">
                                                            {getReadableProvince(
                                                                contact?.provinces_id ||
                                                                    0
                                                            )}
                                                        </span>
                                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                            <ChevronUpDownIcon
                                                                className="h-5 w-5 text-gray-400"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    </Listbox.Button>

                                                    <Transition
                                                        show={open}
                                                        as={Fragment}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                    >
                                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            {Object.entries(
                                                                PROVINCES
                                                            ).map(
                                                                ([
                                                                    id,
                                                                    name,
                                                                ]) => (
                                                                    <Listbox.Option
                                                                        key={id}
                                                                        className={({
                                                                            active,
                                                                        }) =>
                                                                            classNames(
                                                                                active
                                                                                    ? 'bg-indigo-600 text-white'
                                                                                    : 'text-gray-900',
                                                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                            )
                                                                        }
                                                                        value={
                                                                            id
                                                                        }
                                                                    >
                                                                        {({
                                                                            selected,
                                                                            active,
                                                                        }) => (
                                                                            <>
                                                                                <span
                                                                                    className={classNames(
                                                                                        selected
                                                                                            ? 'font-semibold'
                                                                                            : 'font-normal',
                                                                                        'block truncate'
                                                                                    )}
                                                                                >
                                                                                    {
                                                                                        name
                                                                                    }
                                                                                </span>

                                                                                {selected && (
                                                                                    <span
                                                                                        className={classNames(
                                                                                            active
                                                                                                ? 'text-white'
                                                                                                : 'text-indigo-600',
                                                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                        )}
                                                                                    >
                                                                                        <CheckIcon
                                                                                            className="h-5 w-5"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                    </span>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </Listbox.Option>
                                                                )
                                                            )}
                                                        </Listbox.Options>
                                                    </Transition>
                                                </div>
                                            )}
                                        </Listbox>
                                    </div>
                                    <div className="lg:flex mb-2 lg:mb-0 justify-between gap-x-4 py-3">
                                        <dt className="text-gray-500 w-1/2">
                                            Country
                                        </dt>
                                        <Listbox
                                            value={contact?.countries_id}
                                            onChange={(value) =>
                                                handleContactCountryChange(
                                                    value,
                                                    index
                                                )
                                            }
                                        >
                                            {({ open }) => (
                                                <>
                                                    <div className="relative mt-2 w-full">
                                                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                            <span className="block truncate">
                                                                {getReadableCountry(
                                                                    contact?.countries_id ||
                                                                        0
                                                                )}
                                                            </span>
                                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                <ChevronUpDownIcon
                                                                    className="h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            </span>
                                                        </Listbox.Button>

                                                        <Transition
                                                            show={open}
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                {Object.entries(
                                                                    COUNTRIES
                                                                ).map(
                                                                    ([
                                                                        id,
                                                                        name,
                                                                    ]) => (
                                                                        <Listbox.Option
                                                                            key={
                                                                                id
                                                                            }
                                                                            className={({
                                                                                active,
                                                                            }) =>
                                                                                classNames(
                                                                                    active
                                                                                        ? 'bg-indigo-600 text-white'
                                                                                        : 'text-gray-900',
                                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                )
                                                                            }
                                                                            value={
                                                                                id
                                                                            }
                                                                        >
                                                                            {({
                                                                                selected,
                                                                                active,
                                                                            }) => (
                                                                                <>
                                                                                    <span
                                                                                        className={classNames(
                                                                                            selected
                                                                                                ? 'font-semibold'
                                                                                                : 'font-normal',
                                                                                            'block truncate'
                                                                                        )}
                                                                                    >
                                                                                        {
                                                                                            name
                                                                                        }
                                                                                    </span>

                                                                                    {selected && (
                                                                                        <span
                                                                                            className={classNames(
                                                                                                active
                                                                                                    ? 'text-white'
                                                                                                    : 'text-indigo-600',
                                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                            )}
                                                                                        >
                                                                                            <CheckIcon
                                                                                                className="h-5 w-5"
                                                                                                aria-hidden="true"
                                                                                            />
                                                                                        </span>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    )
                                                                )}
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </div>
                                                </>
                                            )}
                                        </Listbox>
                                    </div>
                                    <div className="lg:flex mb-2 lg:mb-0 justify-between gap-x-4 py-3">
                                        <dt className="text-gray-500 w-1/2">
                                            Email
                                        </dt>
                                        <input
                                            type="text"
                                            onChange={(e) =>
                                                handleContactEmailChange(
                                                    e.target.value,
                                                    index
                                                )
                                            }
                                            name="contact-email"
                                            id="contact-email"
                                            value={contact.email_address || ''}
                                            className={`${
                                                !validateInput(
                                                    'email',
                                                    contact.email_address || ''
                                                ) && invalidClass
                                            } block mt-2 lg:mt-0 w-full  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                    <div className="lg:flex mb-2 lg:mb-0 justify-between gap-x-4 py-3">
                                        {' '}
                                        <dt className="text-gray-500 w-1/2">
                                            Phone
                                        </dt>
                                        <input
                                            type="text"
                                            onChange={(e) =>
                                                handlePhoneChange(
                                                    e.target.value,
                                                    index
                                                )
                                            }
                                            name="contact-phone"
                                            id="contact-phone"
                                            value={contact.tel_number || ''}
                                            className={`${
                                                !validateInput(
                                                    'phone',
                                                    contact.tel_number || ''
                                                ) && invalidClass
                                            } block mt-2 lg:mt-0 w-full  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                    <div className="lg:flex mb-2 lg:mb-0 justify-between gap-x-4 py-3">
                                        {' '}
                                        <dt className="text-gray-500 w-1/2">
                                            Birthday
                                        </dt>
                                        <input
                                            type="date"
                                            name="contact-address"
                                            onChange={(e) =>
                                                handleBirthdayChange(
                                                    e.target.value,
                                                    index
                                                )
                                            }
                                            id="contact-address"
                                            value={
                                                contact.birthday || undefined
                                            }
                                            className={`block mt-2 lg:mt-0 w-full  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                    <div className="lg:flex mb-2 lg:mb-0 justify-between gap-x-4 py-3">
                                        {' '}
                                        <dt className="text-gray-500 w-1/2">
                                            Drivers Licence Number
                                        </dt>
                                        <input
                                            type="text"
                                            onChange={(e) =>
                                                handleDriversLicenceChange(
                                                    e.target.value,
                                                    index
                                                )
                                            }
                                            name="contact-license-number"
                                            id="contact-license-number"
                                            value={
                                                contact.drivers_licence_number ||
                                                ''
                                            }
                                            className={`block mt-2 lg:mt-0 w-full  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                    <div className="lg:flex mb-2 lg:mb-0 justify-between gap-x-4 py-3">
                                        {' '}
                                        <dt className="text-gray-500 w-1/2">
                                            SIN
                                        </dt>
                                        <input
                                            type="text"
                                            name="contact-sin"
                                            onChange={(e) =>
                                                handleSinChange(
                                                    e.target.value,
                                                    index
                                                )
                                            }
                                            id="contact-sin"
                                            value={contact.SIN || ''}
                                            className={`block mt-2 lg:mt-0 w-full  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                    <div className="lg:flex mb-2 lg:mb-0 justify-between gap-x-4 py-3">
                                        {' '}
                                        <dt className="text-gray-500 w-1/2">
                                            Business Name
                                        </dt>
                                        <input
                                            type="text"
                                            name="contact-business-name"
                                            id="contact-business-name"
                                            onChange={(e) =>
                                                handleBusinessNameChange(
                                                    e.target.value,
                                                    index
                                                )
                                            }
                                            value={contact.business_name || ''}
                                            className={`block mt-2 lg:mt-0 w-full  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                    <div className="lg:flex mb-2 lg:mb-0 justify-between gap-x-4 py-3">
                                        {' '}
                                        <dt className="text-gray-500 w-1/2">
                                            Business Type
                                        </dt>
                                        <input
                                            type="text"
                                            name="contact-business-type"
                                            onChange={(e) =>
                                                handleBusinessTypeChange(
                                                    e.target.value,
                                                    index
                                                )
                                            }
                                            id="contact-business-type"
                                            value={contact.business_type || ''}
                                            className={`block mt-2 lg:mt-0 w-full  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                    <div className="lg:flex mb-2 lg:mb-0 justify-between gap-x-4 py-3">
                                        {' '}
                                        <dt className="text-gray-500 w-1/2">
                                            Tax ID
                                        </dt>
                                        <input
                                            type="text"
                                            name="contact-tax-id"
                                            onChange={(e) =>
                                                handleTaxIdChange(
                                                    e.target.value,
                                                    index
                                                )
                                            }
                                            id="contact-tax-id"
                                            value={contact.tax_id || ''}
                                            className={`block mt-2 lg:mt-0 w-full  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                        />
                                    </div>
                                    <div className="lg:flex mb-2 lg:mb-0 justify-between gap-x-4 py-3">
                                        {' '}
                                        <dt className="text-gray-500 w-1/2">
                                            Drivers License (Front)
                                        </dt>
                                        <label
                                            htmlFor={`licence-front-${contact.id}`}
                                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                        >
                                            <div className={'flex space-x-2'}>
                                                <span>
                                                    {extractFileName(
                                                        contact.drivers_licence_file_name ||
                                                            ''
                                                    )}
                                                </span>
                                                <ArrowUpTrayIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <input
                                                id={`licence-front-${contact.id}`}
                                                name={`licence-front-${contact.id}`}
                                                type="file"
                                                onChange={(e) =>
                                                    handleDriversLicenceFile(
                                                        e,
                                                        contact.id,
                                                        index,
                                                        'front'
                                                    )
                                                }
                                                className="sr-only"
                                                accept={
                                                    '.pdf, .png, .jpg, .jpeg'
                                                }
                                            />
                                        </label>
                                    </div>
                                    <div className="lg:flex mb-2 lg:mb-0 justify-between gap-x-4 py-3">
                                        {' '}
                                        <dt className="text-gray-500 w-1/2">
                                            Drivers License (Back)
                                        </dt>
                                        <label
                                            htmlFor={`licence-back-${contact.id}`}
                                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                        >
                                            <div className={'flex space-x-2'}>
                                                <span>
                                                    {extractFileName(
                                                        contact.drivers_licence_file_name_back ||
                                                            ''
                                                    )}
                                                </span>
                                                <ArrowUpTrayIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <input
                                                id={`licence-back-${contact.id}`}
                                                name={`licence-back-${contact.id}`}
                                                type="file"
                                                onChange={(e) =>
                                                    handleDriversLicenceFile(
                                                        e,
                                                        contact.id,
                                                        index,
                                                        'back'
                                                    )
                                                }
                                                className="sr-only"
                                                accept={
                                                    '.pdf, .png, .jpg, .jpeg'
                                                }
                                            />
                                        </label>
                                    </div>
                                </dl>
                            </li>
                        ))}
                    </ul>
                )}

                <DialogModal
                    open={confirmDeleteContactDialogOpen}
                    setOpen={setConfirmDeleteContactDialogOpen}
                    title="Delete Contact"
                    description="Are you sure you want to delete this contact? This action cannot be undone."
                    confirmAction={confirmDeleteContact}
                    cancelAction={cancelDeleteContact}
                />
            </div>
            <DialogModal
                open={isDialogOpen}
                setOpen={setIsDialogOpen}
                title="Unsaved Changes"
                description="You have unsaved changes. Are you sure you want to cancel? Your changes will be lost."
                confirmAction={confirmCancel}
                cancelAction={cancelCancel}
            />
        </div>
    )
}
