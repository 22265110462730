import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { InvoicesRepository } from '../../api/InvoicesRepository'
import { useMutation, useQuery } from 'react-query'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
    setError,
    setSuccess,
    setWarning,
} from '../../redux/state-slices/GlobalNotificationSlice'
import Loading from '../../shared-components/Loading'
import Error from '../../shared-components/Error'

export default function PaymentOptions() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const branding = useAppSelector((state) => state.branding)
    const [validPaymentOptions, setValidPaymentOptions] =
        useState<boolean>(false)

    const invoiceRepository = new InvoicesRepository()
    const [paymentOptions, setSelectedPaymentOptions] =
        useState<Paywell.API.V2.PaymentMethodOptions>({
            id: 0,
            credit_card: false,
            etransfer: false,
            wire_transfer: false,
            crypto: false,
            check: false,
        })

    const getPaymentMethodsQuery = useQuery(
        'payment-methods',
        async () => {
            return await invoiceRepository.getPaymentMethods(undefined)
        },
        {
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            retry: false,
            onSuccess: (data) => {
                const paymentMethods: Paywell.API.V2.PaymentMethodOptions = {
                    id: data?.data?.id,
                    credit_card: data?.data?.credit_card ? true : false,
                    etransfer: data?.data?.etransfer ? true : false,
                    wire_transfer: data?.data?.wire_transfer ? true : false,
                    crypto: data?.data?.crypto ? true : false,
                    check: data?.data?.check ? true : false,
                }

                if (
                    paymentMethods.credit_card ||
                    paymentMethods.etransfer ||
                    paymentMethods.wire_transfer ||
                    paymentMethods.crypto ||
                    paymentMethods.check
                ) {
                    setValidPaymentOptions(true)
                } else {
                    setValidPaymentOptions(false)
                }

                setSelectedPaymentOptions(paymentMethods)
            },
            onError: (error) => {},
        }
    )

    const updatePaymentOptionsMutation = useMutation({
        mutationFn: () =>
            invoiceRepository.updatePaymentMethodOptions(
                paymentOptions,
                undefined
            ),
        onError: (error: any) => {
            if (error.status === 422) {
                dispatch(setWarning([error.data.message]))
            } else {
                dispatch(
                    setError([
                        'An error has occured. Please try again later. If the problem persists, please contact support.',
                    ])
                )
            }
        },
        onSuccess: (data) => {
            dispatch(setSuccess(['Payment options updated successfully.']))
        },
    })

    const handleChange = (paymentMethod: string) => {
        const newPaymentMethods: Paywell.API.V2.PaymentMethodOptions = {
            ...paymentOptions,
        }
        switch (paymentMethod) {
            case 'credit-card':
                newPaymentMethods.credit_card = !paymentOptions.credit_card
                break
            case 'e-transfer':
                newPaymentMethods.etransfer = !paymentOptions.etransfer
                break
            case 'wire-transfer':
                newPaymentMethods.wire_transfer = !paymentOptions.wire_transfer
                break
            case 'crypto':
                newPaymentMethods.crypto = !paymentOptions.crypto
                break
            case 'check':
                newPaymentMethods.check = !paymentOptions.check

                break

            default:
                break
        }
        if (
            newPaymentMethods.credit_card ||
            newPaymentMethods.etransfer ||
            newPaymentMethods.wire_transfer ||
            newPaymentMethods.crypto ||
            newPaymentMethods.check
        ) {
            setValidPaymentOptions(true)
        } else {
            setValidPaymentOptions(false)
        }

        setSelectedPaymentOptions(newPaymentMethods)
    }
    const disableButton =
        getPaymentMethodsQuery.isLoading ||
        getPaymentMethodsQuery.isFetching ||
        updatePaymentOptionsMutation.isLoading ||
        !validPaymentOptions

    return (
        <div className="mt-8">
            <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between mb-4">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Default Payment Options
                </h3>
                <div className="mt-3 flex sm:ml-4 sm:mt-0">
                    <button
                        onClick={() => navigate(-1)}
                        type="button"
                        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        Back
                    </button>
                    {!getPaymentMethodsQuery.isError && (
                        <button
                            disabled={disableButton}
                            onClick={() =>
                                updatePaymentOptionsMutation.mutate()
                            }
                            type="button"
                            className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-300"
                        >
                            Save
                        </button>
                    )}
                </div>
            </div>
            {(getPaymentMethodsQuery.isLoading ||
                getPaymentMethodsQuery.isFetching ||
                updatePaymentOptionsMutation.isLoading) && (
                <Loading height="8" width="8" />
            )}

            {getPaymentMethodsQuery.isSuccess &&
                !getPaymentMethodsQuery.isLoading &&
                !getPaymentMethodsQuery.isFetching &&
                !updatePaymentOptionsMutation.isLoading && (
                    <fieldset>
                        <legend className="sr-only">
                            Default Payment Options
                        </legend>
                        <div className="space-y-5" id="check-box">
                            <div className="relative flex items-start">
                                <div className="flex h-6 items-center">
                                    <input
                                        id="credit-card"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        checked={paymentOptions?.credit_card}
                                        onChange={() =>
                                            handleChange('credit-card')
                                        }
                                    />
                                </div>
                                <div className="ml-3 text-sm leading-6">
                                    <label
                                        htmlFor="credit-card"
                                        className="font-medium text-gray-900"
                                    >
                                        Credit Card
                                    </label>
                                </div>
                            </div>
                            <div className="relative flex items-start">
                                <div className="flex h-6 items-center">
                                    <input
                                        id="e-transfer"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        checked={paymentOptions?.etransfer}
                                        onChange={() =>
                                            handleChange('e-transfer')
                                        }
                                    />
                                </div>
                                <div className="ml-3 text-sm leading-6">
                                    <label
                                        htmlFor="e-transfer"
                                        className="font-medium text-gray-900"
                                    >
                                        Interac e-Transfer
                                    </label>
                                </div>
                            </div>
                            <div className="relative flex items-start">
                                <div className="flex h-6 items-center">
                                    <input
                                        id="wire-transfer"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        checked={paymentOptions?.wire_transfer}
                                        onChange={() =>
                                            handleChange('wire-transfer')
                                        }
                                    />
                                </div>
                                <div className="ml-3 text-sm leading-6">
                                    <label
                                        htmlFor="wire-transfer"
                                        className="font-medium text-gray-900"
                                    >
                                        Wire Transfer
                                    </label>
                                </div>
                            </div>
                            <div className="relative flex items-start">
                                <div className="flex h-6 items-center">
                                    <input
                                        id="crypto"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        checked={paymentOptions?.crypto}
                                        onChange={() => handleChange('crypto')}
                                    />
                                </div>
                                <div className="ml-3 text-sm leading-6">
                                    <label
                                        htmlFor="crypto"
                                        className="font-medium text-gray-900"
                                    >
                                        Crypto
                                    </label>
                                </div>
                            </div>
                            <div className="relative flex items-start">
                                <div className="flex h-6 items-center">
                                    <input
                                        id="check"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        checked={paymentOptions?.check}
                                        onChange={() => handleChange('check')}
                                    />
                                </div>
                                <div className="ml-3 text-sm leading-6">
                                    <label
                                        htmlFor="check"
                                        className="font-medium text-gray-900"
                                    >
                                        Check
                                    </label>
                                </div>
                            </div>
                        </div>
                        {!validPaymentOptions && (
                            <p className="text-red-600 font-medium mt-2">
                                You must have at least one payment option
                                selected
                            </p>
                        )}
                    </fieldset>
                )}

            {getPaymentMethodsQuery.isError &&
                !getPaymentMethodsQuery.isLoading &&
                !getPaymentMethodsQuery.isFetching && (
                    <Error
                        title="An error has occurred"
                        message={`Please reach out to one of our representatives at ${branding.contact_email} if this error persists.`}
                    />
                )}
        </div>
    )
}
