export const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    }
    const date = new Date(dateString)
    const formattedDate = date.toLocaleDateString(undefined, options)
    return formattedDate.toString()
}
export const formatNumberWithComma = (
    number: number | undefined | null | string
) => {
    if (number === null || number === undefined) return ''
    number = parseFloat(number.toString())
    const formattedNumber = number.toLocaleString('en-US')
    return formattedNumber
}

export const extractFileName = (path: string) => {
    return path.split('/').pop()
}
